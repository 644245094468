<template>
  <div v-if="currentField && playerMovedThisTurn">
    <!-- BEREICH 1 -->
    <field-dialogs-requirement
      v-if="currentField.type == 'get-requirement'"
    ></field-dialogs-requirement>
    <field-dialogs-method
      v-else-if="currentField.type == 'get-method'"
    ></field-dialogs-method>
    <field-dialogs-data
      v-else-if="currentField.type == 'get-data'"
    ></field-dialogs-data>
    <field-dialogs-database
      v-else-if="currentField.type == 'view-database'"
    ></field-dialogs-database>

    <!-- BEREICH 2 -->
    <field-dialogs-collect
      v-else-if="currentField.type == 'collect-data'"
    ></field-dialogs-collect>
    <field-dialogs-catalog
      v-else-if="currentField.type == 'catalog'"
    ></field-dialogs-catalog>

    <!-- BEREICH 3 -->
    <field-dialogs-build
      v-else-if="currentField.type == 'build'"
    ></field-dialogs-build>
    <field-dialogs-optimize
      v-else-if="currentField.type == 'optimize'"
    ></field-dialogs-optimize>
    <field-dialogs-simulate
      v-else-if="currentField.type == 'simulate'"
    ></field-dialogs-simulate>
  </div>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";

// Alle einzelnen Dialoge:
// Bereich 1:
import FieldDialogsRequirement from "./FieldDialogsRequirement.vue";
import FieldDialogsMethod from "./FieldDialogsMethod.vue";
import FieldDialogsData from "./FieldDialogsData.vue";
import FieldDialogsDatabase from "./FieldDialogsDatabase.vue";

// Bereich 2:
import FieldDialogsCollect from "./FieldDialogsCollect.vue";
import FieldDialogsCatalog from "./FieldDialogsCatalog.vue";

// Bereich 3:
import FieldDialogsBuild from "./FieldDialogsBuild.vue";
import FieldDialogsOptimize from "./FieldDialogsOptimize.vue";
import FieldDialogsSimulate from "./FieldDialogsSimulate.vue";


export default {
  components: {
    FieldDialogsRequirement,
    FieldDialogsMethod,
    FieldDialogsData,
    FieldDialogsDatabase,

    FieldDialogsCollect,
    FieldDialogsCatalog,

    FieldDialogsBuild,
    FieldDialogsOptimize,
    FieldDialogsSimulate
  },
  mixins: [GameComponentMixin],
  computed: {
    playerMovedThisTurn() {
      return this.currentPlayer.movedThisTurn;
    }
  }
}
</script>

<style lang="scss">

</style>