<template>
  <div
    v-if="selectedPlayer !== null"
    class="data-grid-container phase-2"
    :class="[`-player-${selectedPlayer.id}`]"
  >
    <!-- FLACHE RECHTECKE -->
    <div class="categories -category-1">
      <data-card
        :disabled="true"
        :category="{ title: dataCategories[0].title, amount: selectedPlayer.partRequests[0]}"
        :amount="selectedPlayer.partRequests[0]"
        :small-wide="true"
      ></data-card>
    </div>
    <div class="parts -category-1">
      <div
        class="phase-2__partgroup"
        v-for="partEntry in partsInCategories[0]"
        :key="partEntry.part.uuid"
      >
        <car-part class="phase-2__redeemed-data"
          :part="partEntry.part" 
          :small="true"
          :max-height="240"
          :display-height="54"
        ></car-part>
        <div class="phase-2__partamount">{{ partEntry.amount }}x</div>
      </div>
    </div>

    <!-- HOHE RECHTECKE -->
    <div class="categories">
      <data-card
        :disabled="true"
        :category="{ title: dataCategories[1].title, amount: selectedPlayer.partRequests[1]}"
        :amount="selectedPlayer.partRequests[1]"
        :small-wide="true"
      ></data-card>
    </div>
    <div class="parts -category-2">
      <div
        class="phase-2__partgroup"
        v-for="partEntry in partsInCategories[1]"
        :key="partEntry.part.uuid"
      >
        <car-part class="phase-2__redeemed-data"
          :part="partEntry.part" 
          :small="true"
          :max-height="120"
          :display-height="54"
        ></car-part>
        <div class="phase-2__partamount">{{ partEntry.amount }}x</div>
      </div>
    </div>

    <!-- DREIECKE -->
    <div class="categories">
      <data-card
        :disabled="true"
        :category="{ title: dataCategories[2].title, amount: selectedPlayer.partRequests[2]}"
        :amount="selectedPlayer.partRequests[2]"
        :small-wide="true"
      ></data-card>
    </div>
    <div class="parts -category-3">
      <div
        class="phase-2__partgroup"
        v-for="partEntry in partsInCategories[2]"
        :key="partEntry.part.uuid"
      >
        <car-part class="phase-2__redeemed-data"
          :part="partEntry.part" 
          :small="true"
          :max-height="120"
          :display-height="54"
        ></car-part>
        <div class="phase-2__partamount">{{ partEntry.amount }}x</div>
      </div>
    </div>

    <!-- ACHSEN & RÄDER -->
    <div class="categories">
      <data-card
        :disabled="true"
        :category="{ title: dataCategories[3].title, amount: selectedPlayer.partRequests[3]}"
        :amount="selectedPlayer.partRequests[3]"
        :small-wide="true"
      ></data-card>
    </div>
    <div class="parts -category-4">
      <div
        class="phase-2__partgroup"
        v-for="partEntry in partsInCategories[3]"
        :key="partEntry.part.uuid"
      >
        <car-part class="phase-2__redeemed-data"
          :part="partEntry.part" 
          :small="true"
          :max-height="160"
          :display-height="54"
        ></car-part>
        <div class="phase-2__partamount">{{ partEntry.amount }}x</div>
      </div>
    </div>

    <!-- SCHRAUBEN -->
    <div class="categories">
      <data-card
        :disabled="true"
        :category="{ title: dataCategories[4].title, amount: selectedPlayer.partRequests[4]}"
        :amount="selectedPlayer.partRequests[4]"
        :small-wide="true"
      ></data-card>
    </div>
    <div class="parts -category-5">
      <div
        class="phase-2__partgroup"
        v-for="partEntry in partsInCategories[4]"
        :key="partEntry.part.uuid"
      >
        <car-part class="phase-2__redeemed-data"
          :part="partEntry.part" 
          :small="true"
          :max-height="60"
          :display-height="54"
        ></car-part>
        <div class="phase-2__partamount">{{ partEntry.amount }}x</div>
      </div>
    </div>
  </div>
</template>

<script>
import GameComponentMixin from "../.././GameComponentMixin.js"
import DataCard from "../../Cards/DataCard.vue"
import PartCard from "../../Cards/PartCard.vue"
import CarPart from "../../Car/CarPart.vue"

export default {
  mixins:[GameComponentMixin],
  components:{
    DataCard,
    PartCard,
    CarPart
  },
  props: {
    selectedPlayer: {
      type: Object,
      default: null
    }
  },

  computed: {
    partsInCategories() {
      const result = {};
      this.dataCategories.forEach(cat => {
        result[cat.id] = {};
      });
      this.selectedPlayer.parts.forEach(part => {
        if (!result[part.category][part.databaseIndex]) {
          result[part.category][part.databaseIndex] = {
            amount: 1,
            part: part
          };
        }
        else {
          result[part.category][part.databaseIndex].amount++;
        }
      });
      return result;
    },

    unfilledDatas() {
      var Output="";
      for(var datas in this.selectedPlayer.partRequests){
        Output++;
      }
      return Array(5-Output)
    }
  }


  

}
</script>

<style lang="scss">
.phase-2 {
  padding: 1em 1em;
  &__partgroup {
    user-select: none;
    color: $color-text-light;
    font-size: 14px;
  }
  &__partamount {
    text-align: center;
  }

  &__redeemed-data {
    margin-top: -0.125rem;
  }
}


.data-grid-container {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: repeat(5, auto);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}


.categories{
  padding: 0;
} 

.parts {
  display: flex;
  gap: 0.5rem;
  padding: 0.25em 1em;
  justify-content: start;
  align-items: center;
  background-color: $color-uielement-bg;
  border: 1px solid $color-uielement-border;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  border-radius: 3px;
  height: 100%;
  max-width: 30em;
}
</style>