<template>
  <div class="dashboard">
    <phase-selector
      @select-phase="selectedPhase=$event"
      :selected-phase="selectedPhase"
    ></phase-selector>

    <teammate-selector
      v-if="hasTeammates"
      @select-teammate="selectedPlayerId=$event.id"
      :selected-teammate-id="selectedPlayerId"
    ></teammate-selector>

    <phase-1 v-if="selectedPhase.id == 'phase1'" :selected-player="selectedPlayer" class="dashboard__phase"></phase-1>
    <phase-2 v-if="selectedPhase.id == 'phase2'" :selected-player="selectedPlayer" class="dashboard__phase"></phase-2>
    <phase-3 v-if="selectedPhase.id == 'phase3'" :selected-player="selectedPlayer" class="dashboard__phase"></phase-3>
    <phase-4 v-if="selectedPhase.id == 'grading'" :selected-player="selectedPlayer" class="dashboard__phase"></phase-4>
  </div>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js"
import Phase1 from "./Components/Phase1.vue"
import Phase2 from "./Components/Phase2.vue"
import Phase3 from "./Components/Phase3.vue"
import Phase4 from "./Components/Phase4.vue"
import PhaseSelector from "./Components/PhaseSelector.vue"
import TeammateSelector from "./Components/TeammateSelector.vue"

export default {
  mixins:[GameComponentMixin],
  components:{
    Phase1,
    Phase2,
    Phase3,
    Phase4,
    PhaseSelector,
    TeammateSelector
  },

  data(){
    return{
      selectedPhase:null,
      selectedPlayerId: null
    }
  },
  created(){
    // console.log()
    this.selectedPhase = this.currentPhase;
    this.selectedPlayerId = this.myself.id;
  },

  computed: {
    selectedPlayer() {
      return this.players[this.selectedPlayerId];
    },
    hasTeammates() {
      return Object.values(this.players).length > 1;
    },
    otherPlayers() {
      return Object.values(this.players).filter(player => player.id !== this.myself.id);
    }
  }

}
</script>

<style lang="scss">

.dashboard {
  background-color: transparent;
  border-radius: 1.75em;
  border-bottom-right-radius: 2em;
  border-bottom-left-radius: 2em;
  min-height: 30em;
  width: 36rem;
  display: flex;
  flex-direction: column;
  max-height: min-content;
  color: $color-text;

  &__phase {
    
    border-bottom-left-radius: 2em;
    border-bottom-right-radius: 2em;
    background-color: #fff;

    &.-player-0 {
      border: 4px solid $color-player-0;
    }

    &.-player-1 {
      border: 4px solid $color-player-1;
    }

    &.-player-2 {
      border: 4px solid $color-player-2;
    }

    &.-player-3 {
      border: 4px solid $color-player-3;
    }
  }
}

</style>