<template>
  <tr class="match-result" >
    <td class="td__index">
      {{ index + 1 }}
    </td>
    <td class="td__name">
      <strong class="match-result__name">{{ match.data.matchName}}</strong><br>
      <strong class="match-result__date">{{ formattedDate }}</strong>
    </td>

    <td class="td__teamresult">
      <div class="team-result-admin">

        
        <div class="team-result-admin__container-players">
          <div class="team-result-admin__span">
            <span> Spieler </span>
          </div>
          <div class="team-result-admin__players">
            {{ teamPoints }}
          </div>
        </div>

        <div class="team-result-admin__container-quiz">
          <div class="team-result-admin__span">
            <span> Quiz </span>
          </div>
          <div class="team-result-admin__quiz">
          {{ match.data.quizPoints }} 
          </div>
        </div>

        <div class="team-result-admin__total">
          <strong>{{ totalPoints }} Pkt.</strong><br>
        </div>

        <router-link class="team-result-admin__screen" :to="{ name: 'matchResult', params: { matchId: match.matchId } }"><strong>Ergebnis-Screen</strong></router-link>

      </div>

    </td>
    <td class="td__player-results">
      <ol class="match-result-admin__playerlist">
        <li v-for="player in match.data.players" :key="player.id"><strong class="match-result__semibold">{{ player.name }}</strong>: {{ player.points.total.sum }} Pkt</li>
      </ol>
    </td>
    <td class="td__actions">
      <button class="button button--secondary match-result__button" @click="archiveResult">
        <span>Zu Rangliste hinzufügen</span>
        <!-- <icon class="match-result__buttonicon" name="falsch"></icon> -->
      </button>
    </td>
  </tr>
</template>

<script>
import { patch } from "../../services/api.js";
import Icon from "../ui/Icon.vue";
export default {
  components: { Icon },
  props: {
    match: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    teamPoints() {
      return Object.values(this.match.data.players).reduce((sum, player) => sum + player.points.total.sum, 0);
    },
    totalPoints() {
      return this.teamPoints + this.match.data.quizPoints;
    },
    formattedDate() {
      const d = new Date(this.match.createdAt);
      return d.toLocaleDateString();
    }
  },
  methods: {
    async archiveResult(mouseEvent) {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();
      const archived = this.match.archived || false;
      const newMatch = await patch(`/matchresults/${this.match.matchId}`, {
        archived: !archived
      }, {}, true);
      this.$emit("updated", newMatch);
    }
  }
}
</script>

<style lang="scss">



.match-result {
  color: $color-text;

  h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  h4 {
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  strong {
    font-size: 1.1em;

  }
  
  &__name {
    display:inline-block;
    margin-top: 1em;
    color: #404040;

  }

  &__date {
    color:$color-text-light;
    font-size: 1em !important;
    display:inline-block;
    margin-top: 0.4em;
    font-weight: 700;
  }

  &__results {
    display: grid;
    grid-template-columns: 3fr 2fr 3.5rem;
    grid-gap: 1rem;
  }

  &__playerlist {
    padding-left: 1rem;
    margin: 0;

  }

  &__semibold {
    font-weight: 600;
  }

  &__button {
    display:inline-block;
    margin-top: 1em;
  }

}

.team-result-admin {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  margin: 1em 1.5em 1em 1.5em;
  

  &__container-players {
    grid-area: 1 / 1 / 2 / 2;
  }

  &__container-quiz {
    grid-area: 1 / 2 / 2 / 3;

  }

  &__span {
    text-align: center;

    span {
      display: inline-block;
      margin-bottom: 0.3em;
      font-weight: 600;
      color:#a0a0a0;
    }
  }

  &__players {
    color: #a0a0a0;
    background-color: #fafafa;
    width: 100%;
    text-align: center;
    padding: 1rem;
    border: 2px solid #a0a0a0;
    border-radius: 0.1em;
    font-weight: 600;
    font-size: 1.1em;

  }

  &__quiz {
    color: #a0a0a0;
    background-color: #fafafa;
    width: 100%;
    text-align: center;
    padding: 1rem;
    border: 2px solid #a0a0a0;
    border-radius: 0.1em;
    font-weight: 600;
    font-size: 1.1em;

  }

  &__total {
    grid-area: 2 / 1 / 3 / 3;
    color: #22C5E6;
    background-color: #EDFBFD;
    text-align: center;
    padding: 1rem;
    border: 2px solid #22C5E6;
    max-width: 100%;
    border-radius: 0.1em;
    max-height: 4em;
    vertical-align: middle;

  }

  &__screen {
    grid-area: 3 / 1 / 4 / 3;
     color: white;
    background-color: #22C5E6;
    text-align: center;
    padding: 1rem;
    border: 2px solid #22C5E6;
    max-width: 100%;
    border-radius: 0.1em;
    max-height: 4em;
    vertical-align: middle;
    text-decoration: none;
  }

}
</style>