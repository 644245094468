<template>
  <input-button
    class="button-active-player"
    v-on="$listeners"
    v-bind="{...$attrs}"
    :disabled="disabled || !isActivePlayer"
  >
    <slot></slot>
  </input-button>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";
import InputButton from "../../input/InputButton.vue";
export default {
  mixins: [GameComponentMixin],
  components: {
    InputButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.button-active-player {
  --bg-color-primary: var(--player-color);
  --color-primary: #fff;
  --border-color-primary: transparent;
  --hover-bg-color-primary: var(--player-color);

  --bg-color-secondary: var(--player-color-medium);
  --color-secondary: var(--player-color);
  --border-color-secondary: var(--player-color);
  --hover-bg-color-secondary: var(--player-color-medium);

  --bg-color-tertiary: var(--player-color-light);
  --color-tertiary: var(--player-color);
  --border-color-tertiary: #ccc;
  --hover-bg-color-tertiary: var(--player-color-light);
}
</style>