<template>
  <card
    class="data-card"
    v-bind="{ ...$attrs }"
    :class="{ '-is-horizontal': horizontal }"
    @click="$emit('click', $event)"
  >
    <template v-slot:default>
      <div class="data-card__content">
        <h1 v-if="showTitle">{{ category.title }}</h1>
        <h1 v-else>Kategorie {{ 1*(category.id) + 1 }}</h1>
        <h2 v-if="amount != null">{{amount +"x"}}</h2>
      </div>
    </template>
    <template v-slot:back>
      <icon name="daten" color="#b9b9b9"></icon>
    </template>
  </card>
</template>

<script>
import Card from "./Card.vue";
import Icon from "../../ui/Icon.vue";
export default {
  components: {
    Card,
    Icon
  },
  props: {
    category: {
      type: Object,
      default: null
    },
    amount: {
      type: Number,
      default: null
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.data-card {
  &__content {
    display: flex;
    flex-direction: column;
  }
  &.-is-horizontal &__content {
    flex-direction: row;
    align-items: center;
    gap: 1ch;
    h2 {
      margin: 0;
    }
  }
}
</style>