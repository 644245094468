<template>
  <progress-bar
    :max="quizPointSegments[quizPointSegments.length - 1].max"
    :segments="quizPointSegments"
  ></progress-bar>
</template>

<script>
// A progress bar filled with the points from the quiz.
import { questionIdsByArea } from "../../../../game/Resources/Quiz.js";
import ProgressBar from './ProgressBar.vue'

export default {
  components: { ProgressBar },
  props: {
    answeredQuizQuestions: {
      type: Array,
      default() {
        return [];
      }
    },
    playerAmount: {
      type: Number,
      default: 4
    }
  },
  computed: {
    answeredQuestionsByArea() {
      const ar = {};

      for (const areaId in questionIdsByArea) {
        ar[areaId] = questionIdsByArea[areaId].filter(
          questionId => this.answeredQuizQuestions.includes(questionId)
        );
      }

      return ar;
    },
    quizPointSegments() {
      const segments = [];
      let lastMax = 0;

      const neededQuizQuestions = this.playerAmount + 1;

      for (const areaId in questionIdsByArea) {
        const nextSegment = {
          id: areaId,
          min: lastMax,
          max: lastMax + neededQuizQuestions,
          value: this.answeredQuestionsByArea[areaId].length
        };
        segments.push(nextSegment);
        lastMax = nextSegment.max;
      }

      return segments;
    },
    allQuestions() {
      return Object.values(questionIdsByArea).reduce((sum, val) => sum.concat(val), []);
    },
    questionsByArea() {
      return questionIdsByArea;
    }
  }
}
</script>

<style>

</style>