<template>
  <label class="input-field">
    <div class="input-field__label">{{ label }}</div>
    <input
      class="input-field__input"
      :type="type"
      :name="name"
      :value="value"
      :min="min"
      :required="required"
      @input="$emit('input', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: "input-field",
  props: {
    type: {
      default: "text",
      type: String
    },
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: ""
    },
    min: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import "../../style/variables";
.input-field {
  width: 100%;

  &__input {
    border: 1px solid #777;
    font-size: 1rem;
    display: block;
    padding: 0.25em;
    width: 100%;
    &[type="number"] {
      text-align: right;
    }
    &:focus {
      border: 1px solid $color-primary;
      outline: none;
    }
  }

  &__label {
    padding-bottom: 0.25em;
  }
  &:focus-within &__label {
    color: $color-primary;
  }
}
</style>