<template>
  <div class="fielddialogs-optimize">
    <role-dialogs v-if="hasRoleAndRoleOpen">
      <template v-slot:intro>
        <p>
          Hallo, ich bin eine <strong>Bauteil-Konstrukteurin</strong>.
        </p>
        <p>
          Ich habe die CAD-Daten für meinen Teileumfang erstellt und optimiere diese nun mit Hilfe der Simulationsergebnisse. Ich muss dabei genau auf den Termin für den Daten-Freeze achten und bei Problemen rechtzeitig eingreifen.
        </p>
        <p>
          Gut, dass du nach mir gefragt hast. 
          Mit meiner Hilfe kannst du bis zu 3 Daten zurückgeben und bis
          3 neue Daten einer beliebigen Kategorie auswählen.
        </p>
      </template>
      <template v-slot:question>
        <p>
          Hallo, hier ist wieder eine Bauteil-Konstrukteurin!
        </p>
      </template>
      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die Arbeit gehen. Gut, dass du nach mir
          gefragt hast.
        </p>
        <p>
          Mit meiner Hilfe kannst du bis zu 3 Daten zurückgeben und bis
          3 neue Daten einer beliebigen Kategorie auswählen.
        </p>
      </template>
      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du nochmal Hilfe brauchst.
        </p>
      </template>
    </role-dialogs>

    <!-- QUIZFRAGE -->
    <quiz-dialogs v-else-if="(
      currentField.hasQuiz &&
      !currentMove.handledQuizQuestion
    )"></quiz-dialogs>

    <!-- KOLLABORATION: SPIELER WÄHLEN -->
    <collaboration-dialogs
      v-else-if="currentMove.collaborationSelected">
    </collaboration-dialogs>

    
    <!-- START-AKTION WÄHLEN -->
    <template v-else-if="currentMove.wantsToReturnParts == null">

      <!-- ROLLE: START-AKTION WÄHLEN -->
      <game-dialog v-if="currentMove.solvedRoleQuiz">
        <div class="text-center">
          Du kannst bis zu 3 Daten zurückgeben und anschließend
          bis zu 3 neue Daten aus der Datenbank auswählen.
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endTurn()"
          >
            Zug überspringen
          </button-active-player>
          
          <button-active-player
            @click="moves.updateCurrentMove({ wantsToReturnParts: false })"
          >
            Ohne Rückgabe weiter
          </button-active-player>

          <button-active-player
            @click="moves.updateCurrentMove({ wantsToReturnParts: true })"
          >
            Daten zurückgeben
          </button-active-player>
        </div>
        <div class="row" v-if="isFieldCollaborative">
          <button-active-player
            @click="moves.startCollaboration()"
          >
            Kollaboration: Zug weitergeben
          </button-active-player>
        </div>
      </game-dialog>

      <!-- Nicht ROLLE : START-AKTION -->
      <game-dialog v-else>
        <div class="text-center">
          Wähle bis zu 3 Daten einer Kategorie, die du gegen
          andere Daten der selben Kategorie tauschen möchtest.
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endTurn()"
          >
            Zug überspringen
          </button-active-player>
          <button-active-player
            v-if="isFieldCollaborative"
            @click="moves.startCollaboration()"
          >
            Kollaboration: Zug weitergeben
          </button-active-player>
          <button-active-player
            @click="moves.updateCurrentMove({ wantsToReturnParts: true })"
          >
            Daten auswählen
          </button-active-player>
        </div>
      </game-dialog>
    </template>

    <!-- END-BESTÄTIGUNGS-DIALOG -->
<!--     <game-dialog v-else-if="currentMove.hasConfirmedNewParts">
      <div class="text-center">
        Die Daten wurden übernommen.
      </div>

      <div class="row">
        <button-active-player
          @click="moves.confirmOptimization()"
        >
          OK
        </button-active-player>
      </div>
    </game-dialog> -->


    <!-- AUSGEWÄHLTE NEUE DATEN BESTÄTIGEN -->
    <game-dialog :extra-wide="true" v-else-if="currentMove.hasSelectedNewParts">
      <div class="text-center">
        Du hast folgende Daten ausgewählt:
      </div>

      <div class="fielddialogs-optimize__partgrid" v-if="currentMove.parts.length > 0">
        <part-card
          v-for="part in currentMove.parts"
          :key="part.uuid"
          :part="part"
        ></part-card>
      </div>
      <p v-else class="text-center">
        <strong>Keine Daten gewählt.</strong>
      </p>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateCurrentMove({
            hasSelectedNewParts: false,
            isDatabaseOpen: true
          })"
        >
          Zurück
        </button-active-player>

        <button-active-player
          @click="moves.confirmOptimization()"
        >
          Daten übernehmen
        </button-active-player>
      </div>
    </game-dialog>


    <!-- NEUE DATEN AUS DATENBANK WÄHLEN -->
    <template v-else-if="currentMove.isDatabaseOpen">
      <database-dialog
        :category="currentMove.category"
        :max-selectable="maxAmountOfNewParts"
        :min-selectable="currentMove.solvedRoleQuiz ? 0 : maxAmountOfNewParts"
        @close="moves.updateCurrentMove({
          isDatabaseOpen: false,
          hasSelectedNewParts: true
        })"
      ></database-dialog>
    </template>

    <!-- ROLLE: DATENBANK ÖFFNEN BESTÄTIGEN -->
    <game-dialog
      v-else-if="(
        currentMove.solvedRoleQuiz &&
        (currentMove.partsToDiscardConfirmed 
          || currentMove.wantsToReturnParts === false
        )
      )"
    >
      <div class="text-center">
        Du kannst jetzt bis zu drei neue Daten in der Datenbank auswählen.
      </div>

      <div class="row">
        <button-active-player
          v-if="!currentMove.wantsToReturnParts"
          type="secondary"
          @click="moves.updateCurrentMove({
            wantsToReturnParts: null
          })"
        >
          Zurück
        </button-active-player>
        <button-active-player
          type="secondary"
          v-if="currentMove.wantsToReturnParts"
          @click="moves.confirmOptimization()"
        >
          Zug beenden
        </button-active-player>

        <button-active-player
          @click="moves.updateCurrentMove({
            isDatabaseOpen: true
          })"
        >
          Datenbank öffnen
        </button-active-player>
      </div>
    </game-dialog>

    <!-- DATEN ENTFERNEN -->
    <template v-else-if="currentMove.wantsToReturnParts">

      <game-dialog
        v-if="!currentMove.solvedRoleQuiz && currentMove.category == null"
      >
        <div class="text-center">
          Wähle eine Kategorie, aus der du Daten tauschen möchtest.
        </div>

        <div class="column -categories">
          <data-card
            v-for="category in gameState.dataCategories"
            :key="category.id"
            :category="category"
            :clickable="true"
            :disabled="!isActivePlayer"
            :forbidden="allPartsOfCategory(activePlayer, category.id).length == 0"
            @click="moves.updateCurrentMove({ category: category.id })"
          ></data-card>
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.updateCurrentMove({ wantsToReturnParts: null })"
          >
            Zurück
          </button-active-player>
        </div>
      </game-dialog>


      <!-- AUS BESTEHENDEN WÄHLEN -->
      <game-dialog :extra-wide="true" v-else-if="!currentMove.partsToDiscardSelected">
        <div class="text-center">
          Wähle bis zu 3 Daten aus, die du
          <span v-if="currentMove.solvedRoleQuiz">entfernen</span>
          <span v-if="!currentMove.solvedRoleQuiz">austauschen</span>
          möchtest:
        </div>

        <div class="fielddialogs-optimize__partgrid" v-if="possiblePartsToDiscard.length > 0">
          <part-card
            v-for="part in possiblePartsToDiscard"
            :key="part.uuid"
            :part="part"
            :clickable="true"
            :disabled="!isActivePlayer"
            :selected="currentMove.partsToDiscard.includes(part.uuid)"
            :forbidden="currentMove.partsToDiscard.length >= 3 && !currentMove.partsToDiscard.includes(part.uuid)"
            @click="moves.addPartToDiscard(part.uuid)"
          ></part-card>
        </div>

        <p v-else class="text-center">
          <strong>Alle Daten dieser Kategorie sind bereits verbaut.</strong>
        </p>

        <div class="row">
          <!-- Zurück für Rolle --> 
          <button-active-player
            v-if="currentMove.solvedRoleQuiz"
            type="secondary"
            @click="moves.updateCurrentMove({
              wantsToReturnParts: null,
              partsToDiscard: []
            })"
          >
            Zurück
          </button-active-player>
          <!-- Zurück für Nicht-Rolle --> 
          <button-active-player
            v-if="!currentMove.solvedRoleQuiz"
            type="secondary"
            @click="moves.updateCurrentMove({
              category: null,
              partsToDiscard: []
            })"
          >
            Zurück
          </button-active-player>

          <button-active-player
            :disabled="maxAmountOfNewParts === 0"
            @click="moves.updateCurrentMove({
              partsToDiscardSelected: true
            })"
          >
            Weiter
          </button-active-player>
        </div>
      </game-dialog>

      <!-- ENTFERNTE DATEN BESTÄTIGEN -->
      <game-dialog v-else-if="!currentMove.partsToDiscardConfirmed">
        <template v-if="selectedPartsToDiscard.length > 0">
          <div class="text-center">
            Die folgenden Daten werden entfernt:
          </div>

          <div class="row">
            <part-card
              v-for="part in selectedPartsToDiscard"
              :key="part.uuid"
              :part="part"
            ></part-card>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            Du hast <strong>keine</strong> Daten zum Entfernen gewählt.
          </div>
        </template>

        <div class="text-center" v-if="!currentMove.solvedRoleQuiz">
          Du kannst {{ maxAmountOfNewParts }}
          <span v-if="maxAmountOfNewParts > 1">neue Daten</span> 
          <span v-else>neuen Datensatz</span> 
          in der Datenbank wählen.
        </div>


        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.updateCurrentMove({
              partsToDiscardSelected: false
            })"
          >
            Zurück
          </button-active-player>

          <!-- 
            ROLLENQUIZ hat noch einen
            BESTÄTIGUNGSSCREEN, daher hier 
            nur weiter 
          -->
          <button-active-player
            v-if="currentMove.solvedRoleQuiz"
            @click="moves.updateCurrentMove({
              partsToDiscardConfirmed: true
            })"
          >
            Weiter
          </button-active-player>

          <button-active-player
            v-else
            @click="moves.updateCurrentMove({
              isDatabaseOpen: true
            })"
          >
            Datenbank öffnen
          </button-active-player>
        </div>
      </game-dialog>
    </template>
  </div>
</template>

<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";


// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";

// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";

// Kollaborationsdialoge sind geeignet, um den Zug 
// weiter zu geben.
import CollaborationDialogs from "./CollaborationDialogs.vue";

import DatabaseDialog from "./DatabaseDialog.vue";

import MethodCard from "../Cards/MethodCard.vue";
import DataCard from "../Cards/DataCard.vue";
import PartCard from "../Cards/PartCard.vue";
import CarPart from "../Car/CarPart.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";

export default {
  components: {
    GameDialog,
    RoleDialogs,
    QuizDialogs,
    CollaborationDialogs,
    MethodCard,
    DataCard,
    CarPart,
    PartCard,
    DatabaseDialog,
    ButtonActivePlayer
  },
  mixins: [GameComponentMixin],
  computed: {
    maxAmountOfNewParts() {
      if (this.currentMove.solvedRoleQuiz) {
        return 3;
      }
      else {
        return this.currentMove.partsToDiscard.length;
      }
    },
    selectedPartsToDiscard() {
      return this.activePlayer.parts.filter(
        part => this.currentMove.partsToDiscard.includes(part.uuid)
      );
    },
    possiblePartsToDiscard() {
      const unbuiltParts = this.activePlayer.parts.filter(
        part => !this.activePlayer.builtPartIds.includes(part.uuid)
      );
      if (this.currentMove.category === null) {
        return unbuiltParts;
      }
      return unbuiltParts.filter(
        part => part.category === this.currentMove.category
      );
    },
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    },
    isFieldCollaborative() {
      return this.currentField.isCollaborative && this.isCurrentPlayerActive;
    },
    playerHasMethod() {
      return this.activePlayer.methods.some(method => method.id == this.currentMove.selectedMethod.id);
    }
  },
  methods: {
    selectMethodAndAction(method, action) {
      this.moves.selectMethod(method);
      this.moves.setAction(action);
    },
    cancel() {
      this.moves.endTurn();
    }
  }
}
</script>

<style lang="scss">
.fielddialogs-optimize {

  &__partgrid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
    grid-auto-rows: 7rem;
    gap: 1rem;
  }
}
</style>