<template>
  <div v-if="currentField.hasRole" class="role-dialogs">
    <!-- Erstbegegnung Rolle: -->
    <game-dialog v-if="isHereForFirstTime" :extraWide="true">
      <div class="role-dialogs__rolebox">
        <div class="role-dialogs__roletext">
          <slot name="intro"></slot>
        </div>
        <img class="role-dialogs__image" :src="images[role.image]" />
      </div>

      <div class="row">
        <button-active-player @click="moves.markRoleAsSeen(role.id)">
          OK
        </button-active-player>
      </div>
    </game-dialog>

    <!-- Quiz: -->
    <game-dialog v-else-if="didNotAnswerQuizYet" :extraWide="true">
      <div class="role-dialogs__rolebox">
        <div class="role-dialogs__roletext">
          <slot name="question"></slot>
          <p>
            {{ question.question }}
          </p>
        </div>
        <img class="role-dialogs__image" :src="images[role.image]" />
      </div>

      <div class="role-dialogs__answers">
        <button-active-player
          v-for="option in quizOptions"
          :key="option.id"
          @click="moves.answerRoleQuiz(option.id)"
        >
          {{ option.label }}
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else-if="currentMove.solvedRoleQuiz" :extraWide="true">
      <div class="role-dialogs__rolebox">
        <div class="role-dialogs__roletext">
          <slot name="correct"></slot>
        </div>
        <img class="role-dialogs__image" :src="images[role.image]" />
      </div>

      <div class="row">
        <button-active-player @click="moves.closeQuiz()">
          OK
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else-if="!currentMove.solvedRoleQuiz" :extraWide="true">
      <div class="role-dialogs__rolebox">
        <div class="role-dialogs__roletext">
          <p>
            Oh, das ist leider falsch.
          </p>
          <p>
            {{ question.correctedAnswer }}
          </p>
          <slot name="wrong"></slot>
        </div>
        <img class="role-dialogs__image" :src="images[role.image]" />
      </div>

      <div class="row">
        <button-active-player @click="moves.closeQuiz(currentField.role)">
          OK
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>

<script>
// Mixins
import GameComponentMixin from "../GameComponentMixin.js";
// Components
import GameDialog from "../UIElements/GameDialog.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";

// Images
import Bauteilkonstrukteurin from "../../../assets/roleimages/Bauteilkonstrukteurin.png";
import Datenmanagerin from "../../../assets/roleimages/Datenmanagerin.png";
import Funktionsverantwortlicher from "../../../assets/roleimages/Funktionsverantwortlicher.png";
import Simulationsexperte from "../../../assets/roleimages/Simulationsexperte.png";

export default {
  components: {
    GameDialog,
    ButtonActivePlayer,
  },
  mixins: [GameComponentMixin],
  data() {
    return {
      images: {
        Bauteilkonstrukteurin,
        Datenmanagerin,
        Funktionsverantwortlicher,
        Simulationsexperte
      }
    };
  },
  computed: {
    role() {
      return this.gameState.roles[this.currentField.role];
    },
    question() {
      return this.currentMove.roleQuestion;
    },
    quizOptions() {
      return this.question.options;
    },
    isHereForFirstTime() {
      return !this.gameState.seenRoles[this.role.id];
    },
    didNotAnswerQuizYet() {
      return !this.currentMove.answeredRoleQuestion;
    },
  },
};
</script>

<style lang="scss">
.role-dialogs {
  &__answers {
    display: grid;
    gap: 0.5em;
    grid-template-columns: 1fr;
  }

  &__rolebox {
    display: grid;
    grid-template-columns: 1fr minmax(6rem, 12rem);
    margin-top: -0.5em;
  }
  &__roletext {
    padding: 0.125em 1.25rem;
    border-radius: 1em;
    //border: 1px solid $color-uielement-border;
    align-self: center;
    margin: 0.5em 0;
    //box-shadow: 2px 4px 10px -6px rgba(0,0,0,0.1);
    line-height: 1.4;
  }

  &__image {
    display: block;
    width: 100%;
    align-self: end;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 20px 9px -20px rgba(0,0,0,0.1);
  }
}
</style>