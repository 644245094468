<template>
  <div v-if="currentField.hasQuiz" class="quiz-dialogs">
    <game-dialog v-if="currentMove.solvedQuizQuestion === null">
      <div class="text-center">
        {{ quizQuestion.question }}
      </div>
      
      <div class="quiz-dialogs__options">
        <button-active-player
          class="quiz-dialogs__option"
          v-for="option in quizQuestion.shuffledOptions"
          :key="option.id"
          @click="moves.answerQuizQuestion(option.id)"
        >
          {{ option.label }}
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else-if="currentMove.solvedQuizQuestion == true">
      <div class="text-center">
        Richtig! Ihr habt {{ quizQuestion.points }} Punkte dazu bekommen.
      </div>

      <div class="quiz-dialogs__options">
        <button
          class="button button--primary quiz-dialogs__option"
          :class="{
            '-is-wrong': option.id !== quizQuestion.answer,
            '-is-true': option.id === quizQuestion.answer
          }"
          v-for="option in quizQuestion.shuffledOptions"
          :key="option.id"
        >
          {{ option.label }}
        </button>
      </div>

      <div class="row">
        <button-active-player
          @click="moves.closeQuizQuestion()"
        >
          OK
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else>
      <div class="text-center">
        Leider falsch. Dies wäre die richtige Antwort gewesen:
      </div>

      <div class="quiz-dialogs__options">
        <button
          class="button button--primary quiz-dialogs__option"
          :class="{
            '-is-wrong': option.id !== quizQuestion.answer,
            '-is-true': option.id === quizQuestion.answer,
            '-is-selected': option.id === quizQuestion.chosenAnswer
          }"
          v-for="option in quizQuestion.shuffledOptions"
          :key="option.id"
        >
          {{ option.label }}
        </button>
      </div>

      <div class="row">
        <button-active-player
          @click="moves.closeQuizQuestion()"
        >
          OK
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";

import GameDialog from "../UIElements/GameDialog.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";

export default {
  mixins: [GameComponentMixin],
  components: {
    GameDialog,
    ButtonActivePlayer
  },
  computed: {
    quizQuestion() {
      return this.currentMove.quizQuestion;
    }
  },
}
</script>

<style lang="scss">
.quiz-dialogs {
  &__options {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  &__option {
    padding: 0.5rem 1rem;

    &.-is-true {
      background-color: $color-primary;
      &:hover {
        filter: none;
      }
    }
    &.-is-wrong {
      background-color: $color-text-light;
      &:hover {
        filter: none;
      }
    }
    &.-is-selected {
      //border: 2px dashed var(--active-player-color);
    }
  }
}
</style>