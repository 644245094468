<template>
  <div class="progress-bar">
    <div
      v-for="seg in segments"
      :key="seg.id"
      :style="{ width: `${(seg.value / max) * 100}%`, left: (seg.min / max) * 100 + '%' }"
      class="progress-bar__value"
    ></div>
    <div
      class="progress-bar__segmentseparator"
      v-for="seg in segments"
      :key="`sep${seg.id}`"
      :style="{ left: (seg.min / max) * 100 + '%', display: seg.id == 0 ? 'none' : 'block' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    segments: {
      type: Array,
      default() {
        return [];
      }
    },
    max: {
      type: Number,
      default: 100
    },
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    percentFilled() {
      return this.value / this.max;
    }
  }
}
</script>

<style lang="scss">
.progress-bar {
  width: 100%;
  border-radius: 1000rem;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  margin: 0.5em 0;
  background-color: #E8E8E8;
  position: relative;
  height: 1.5em;

  &__value {
    height: 100%;
    background-color: $color-primary;
    position: absolute;
  }

  &__segmentseparator {
    position: absolute;
    border-left: 1px solid #000;
    height: 100%;
    &:first-of-type {
      display: none;
    }
  }
}
</style>