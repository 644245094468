<template>
  <header class="game-header">

    <div class="game-header__left">
      <div class="game-header__menubuttons" ref="menubuttons">
        <h1 class="game-header__title">Planspiel Virtuelle Produktentwicklung</h1>
        <a class="game-header__menubutton" href="../../../assets/Planspiel_VPE_Spielanleitung_Broschuere.pdf" target="_blank" title="Anleitung">
          <!-- <icon name="notizen" class="game-header__icon"></icon> -->
        </a>
        <!--<button class="game-header__menubutton" @click="$emit('toggle-build')" @click.right.prevent="moves.addAllParts()">
          <icon name="auto" class="game-header__icon"></icon>
        </button>
        <button class="game-header__menubutton" @click="$emit('toggle-sim')">
          <icon name="prozess" class="game-header__icon"></icon>
        </button>-->
      </div>

      <div 
        v-if="currentPhaseData && currentPhaseData.currentRound != null"
        class="game-header__roundcounter"
      >
        Runde: {{ currentPhaseData.currentRound }}/{{ currentPhaseData.maxTurns }}
      </div>
      <div v-else></div>
    </div>

    <div class="game-header__gamestatus" :class="{
      '-is-milestone': currentPhase.type === 'milestone'
    }">
      <span>
        {{ currentPhase.title }}
      </span>
    </div>

    <div class="game-header__quiz">
      <span>Quiz:</span>
      <quiz-progress-bar :answered-quiz-questions="gameState.answeredQuizQuestions" :player-amount="playerAmount"></quiz-progress-bar>
    </div>

    <div class="game-header__nav" ref="nav" v-if="navId != null">
      <div v-if="navId === 'settings'" class="game-header__nav__content">
        <strong>Spiel {{ matchId }}</strong>
        <span>{{ playerName }} ({{ playerId }})</span>
        <span>[{{ credentials }}]</span>
        <button @click="$emit('toggle-debug')" class="button button--secondary">Debug an/aus</button>
        <button @click="$emit('toggle-build')" class="button button--secondary">Baubereich an/aus</button>
        <button @click="$emit('toggle-sim')" class="button button--secondary">SimulationsBereich an/aus</button>

        <button @click="$emit('leave')" class="button button--secondary">Spiel verlassen</button>
      </div>
    </div>
  </header>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";
import QuizProgressBar from "../UIElements/QuizProgressBar.vue";
import Icon from "../../ui/Icon.vue";
export default {
  mixins: [GameComponentMixin],
  components: {
    QuizProgressBar,
    Icon
  },
  props: ['playerName', 'credentials'],
  data() {
    return {
      navId: null,
      navVisible: false,
      boundClose: null
    };
  },
  created() {
    this.boundClose = this.checkCloseNav.bind(this);
  },
  computed: {
    playerAmount() {
      return Object.values(this.players).length;
    }
  },
  methods: {
    openInstructions() {

    },
    openNav(navId) {
      if (this.navId == navId) {
        return this.checkCloseNav();
      }
      this.navId = navId;
      this.navVisible = true;
      window.addEventListener('click', this.boundClose, false);
    },
    /**
     * @param {MouseEvent} event
     */
    checkCloseNav(event) {

      if (event) {
        const path = event.composedPath();
        if (path.includes(this.$refs.menubuttons) || path.includes(this.$refs.nav)) return;
      }
      
      this.navVisible = false;
      this.navId = null;
      window.removeEventListener('click', this.boundClose);
    }
  }
}
</script>

<style lang="scss">
.game-header {
  display: grid;
  grid-template-columns: 1fr 14rem 1fr;
  align-items: center;
  gap: 1rem;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(0,0,0,0.2);
  z-index: 9;
  color: $color-text;
  position: relative;
  height: 3rem;

  &__left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 1.25rem;
    white-space: nowrap;
    align-self: center;
  }

  &__menubuttons {
    padding: 0 1rem;
    display: grid;
    grid-template-columns: 1fr 3rem; // 3rem 3rem;
    gap: 0.5rem;
  }
  &__menubutton {
 /*    border: none;
    background-color: transparent;
    color: $color-text;
    padding: 0.25em 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center; */

    vertical-align: bottom;
    position:relative; /* Anything but static */
    width: 1.5em;
    height: 1.5em;
    text-indent: -9999em;
    display: inline-block;
    color: white;
    font-weight:bold;
    font-size:1em;
    line-height:1em;
    background-color: $color-uielement-table-border;
    margin-left: .25em;
    border-radius:.75em;
    align-self: center;
  }

 &__menubutton:before {
  content:"?";
  position: absolute;
  top: .25em;
  left:0;
  text-indent: 0;
  display:block;
  width:1.5em;
  text-align:center;
  }
 /*  &__icon {
    width: 1.25em;
    height: 1.25em;
  } */

  &__roundcounter {
    font-weight: 600;
    text-align: right;
  }

  &__gamestatus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    min-height: calc(100% + 0.75rem);
    margin-bottom: -0.75rem;
    margin-top: 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    text-align: center;
    background-color: $color-primary;
    font-weight: 600;
    color: #fff;

    &.-is-milestone {
      background-color: $color-primary;
      color: #fff;
    }
  }

  &__quiz {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    gap: 0.5rem;
    padding-right: 1rem;
    font-weight: 600;
  }

  &__nav {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-width: 30rem;
    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }
  }
}
</style>