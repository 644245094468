<template>
  <tr>
    <td class="td__name">
      <strong class="match-result__name">{{match.setupData.matchName}}</strong><br>
    </td>
    <td class="td__join">
      <div class="match-tile__code">
        Beitrittscode: <strong>{{ match.matchID }}</strong>
      </div>
      <div class="match-tile__joinelement">
        <div class="match-tile__joinarea">
          <input
            class="match-tile__joininput"
            ref="joinlink"
            type="text"
            :value="`${domain}/planspiel/${match.matchID}/beitreten`"
            readonly
          />
          <span class="match-tile__joinlink">
            <span>Link:&nbsp;</span><router-link
              :to="{ name: 'joinSpecificMatch', params: { urlMatchId: match.matchID } }"
              target="_blank"
            >
              {{domain}}/planspiel/{{match.matchID}}/beitreten
            </router-link>
          </span>
        </div>
        <button class="match-tile__copybutton button" @click="copyLink">Kopieren</button>
      </div>
    </td>
    <td class="td__players">
      <div class="match-tile__players">
        <div class="match-tile__player" v-for="player in match.players" :key="player.id">
          <span>Platz {{ player.id + 1 }}:</span>
          <strong v-if="player.name">{{ player.name }}</strong>
          <strong v-else>— Frei —</strong>
        </div>
      </div>
    </td>
    <td class="td__actions">
      <router-link
        class="match-tile__button button button--primary"
        :to="{ name: 'spectateMatch', params: { matchId: match.matchID } }"
        target="_blank"
      >
        Zuschauen
      </router-link><br>
      <button class="match-tile__button button button--danger" @click="deletePermanently">Spiel schließen</button>
    </td>
  </tr>
</template>

<script>
import { destroy } from "../../services/api.js";
export default {
  props: {
    match: {
      type: Object,
      required: true,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    domain() {
      return `${location.protocol}//${location.host}`;
    }
  },
  methods: {
    async deletePermanently() {
      try {
        if (!confirm("Sicher, dass Sie dieses Spiel endgültig schließen möchten?")) return;
        await destroy(`/matches/${this.match.matchID}`);
        this.$emit("destroyed");
      } catch(error) {
        console.log(error);
      }
    },
    copyLink() {
      const linkElement = this.$refs.joinlink;
      linkElement.select();
      linkElement.setSelectionRange(0, 99999);

      document.execCommand("copy");

      alert("Kopiert.");
    }
  }
}
</script>

<style lang="scss">


.match-tile {
  padding: 1rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);

  &__code {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__players {
    margin: 0.5em 0;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
  }

  &__button {
    font-size: 14px;
    padding: 0.25rem 0.5rem;
    text-decoration: none;
    margin-bottom: 0.5em;
  }

  &__joinelement {
    display: grid;
    grid-template-columns: minmax(0, max-content) min-content;
    gap: 1rem;
  }
  &__joinarea {
    position: relative;
  }
  &__joininput {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  &__joinlink {
    display: flex;
    position: relative;
    z-index: 1;
    background-color: #fff;
    height: 100%;
    align-items: center;
  }
  &__joinarea {
    width: 100%;
  }
  &__jointitle {
    margin-bottom: 0.5rem;
  }

  &__copybutton {
    font-size: 14px;
  }
}
</style>