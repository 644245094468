<template>
  <div class="page-lobby">
    <div v-if="matchDataLoaded" class="page-lobby__content">
      <h1>Warteraum für Spiel {{ matchID }}</h1>

      <p>Das Spiel beginnt, sobald alle Plätze belegt sind.</p>

      <h3>Spieler im Warteraum</h3>
      <ol>
        <li v-for="player in joinedPlayers" :key="player.id">
          {{ player.name }}
        </li>
      </ol>
      <strong class="page-lobby__status">Warte auf {{ freeSeats}} Spieler...</strong>
    </div>
    <game-footer></game-footer>
  </div>
</template>

<script>
/**
 * Hier warten wir darauf, dass alle Plätze im Spiel belegt sind.
 */

import { get } from "../services/api";
import GameFooter from "../components/ui/GameFooter.vue";

export default {
  components: {
    GameFooter
  },
  data() {
    return {
      match: {},
      intervalId: null
    };
  },
  computed: {
    matchID() {
      return localStorage.getItem("matchID");
    },
    matchDataLoaded() {
      return "matchID" in this.match;
    },
    joinedPlayers() {
      return this.match.players.filter(player => player.name);
    },
    freeSeats() {
      const freeSeats = this.match.players.filter(player => !player.name);
      return freeSeats.length;
    },
    isMatchReady() {
      return this.freeSeats == 0;
    }
  },
  created() {
    this.checkIfAllPlacesTaken();

    this.intervalId = setInterval(() => {
      this.checkIfAllPlacesTaken();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    async checkIfAllPlacesTaken() {
      try {
        const { match } = await get(`/matches/${this.matchID}`);
        this.match = match;
        if (this.isMatchReady) {
          this.$router.push({ name: 'match' });
        }
      } catch(error) {
        console.log(error);
      }
    }
  }
}
</script>

<style lang="scss">
.page-lobby {
  color: $color-text;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  height: 100%;
  overflow: hidden;
  font-size: 18px;

  &__content {
    min-width: 33vw;
    padding: 2rem;
    background-color: #fff;
    border-radius: 2rem;
    margin: 2rem;
    height: 100%;
    align-self: flex-start;
  }

  h3 {
    margin-top: 2em;
    margin-bottom: 1rem;
  }


  &__status {
    display: block;
    margin-top: 3rem;
  }
}
</style>