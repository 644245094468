// CSS
import './style/normalize.css';
import './style/main.scss';

// Vue-Bibliotheken
import Vue from 'vue';
import PortalVue from 'portal-vue';
import VueRouter from 'vue-router';

// Programmdateien
import App from './App.vue';
import { routes } from "./routes";

// Plugins einbauen:
Vue.use(PortalVue);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes
});

console.log(router);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
