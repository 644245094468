<template>
  <div
    v-if="selectedPlayer"
    class="phase-4"
    :class="[`-player-${selectedPlayer.id}`]"
  >
    <section>
      <h3>Produktziel</h3>
      <p>
        Für die erfolgreiche Erfüllung des Produktziels gibt es:
      </p>
      <div class="phase-4__block -is-primary">15 Pkt.</div>
    </section>

    <section>
      <h3>Anforderungen</h3>
      <p>
        Pro Anforderung gibt es 5 bis 20 Punkte, je nach erreichter Qualität.
      </p>
      <div class="phase-4__requirements">
        <div class="phase-4__block">5% Abw.</div>
        <icon name="pfeil" class="phase-4__arrow"></icon>
        <div class="phase-4__block -is-success">20 Pkt.</div>
      </div>
      <div class="phase-4__requirements">
        <div class="phase-4__block">5% Abw.</div>
        <icon name="pfeil" class="phase-4__arrow"></icon>
        <div class="phase-4__block -is-passed">10 Pkt.</div>
      </div>
      <div class="phase-4__requirements">
        <div class="phase-4__block">5% Abw.</div>
        <icon name="pfeil" class="phase-4__arrow"></icon>
        <div class="phase-4__block -is-failed">5 Pkt.</div>
      </div>
    </section>

    <section class="phase-4__data">
      <h3>Aufwände</h3>
      <p>
        Die Aufwände setzen sich aus den Differenzen aus vereinbarten, eingesammelten
        und verbauten Daten, sowie den Bewegungspunkten zusammen:
      </p>
      <div class="phase-4__explanations">
        <div class="phase-4__block -is-wide">
          <span>Anzahl vereinbarter Daten</span> - <span>Anzahl eingesammelter Daten</span>
        </div>
        <div class="phase-4__block -is-wide">
          <span>Anzahl verfügbarer Daten nach der Datenoptimierung</span> - <span>Anzahl verbauter Daten</span>
        </div>
        <div class="phase-4__block -is-wide">Anzahl überzogener Bewegungspunkte</div>
      </div>

      <div class="phase-4__equals">=</div>

      <div class="phase-4__block -is-primary -is-wide">Gesamtwertung Aufwände</div>
    </section>
  </div>
</template>

<script>
import GameComponentMixin from "../../GameComponentMixin.js";
import Icon from "../../../ui/Icon.vue";
export default {
  mixins: [GameComponentMixin],
  components: {
    Icon
  },
  props: {
    selectedPlayer: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">
.phase-4 {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  &__block {
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    width: 5rem;
    padding: 0.25em 0;
    text-align: center;
    background-color: $color-uielement-bg;
    border-color: $color-uielement-border;

    &.-is-primary {
      background-color: $color-primary-light;
      color: $color-primary;
      border-color: $color-primary;
    }
    &.-is-success {
      background-color: $color-succeeded-light;
      color: $color-succeeded;
      border-color: $color-succeeded;
    }
    &.-is-passed {
      background-color: $color-passed-light;
      color: $color-passed;
      border-color: $color-passed;
    }
    &.-is-failed {
      background-color: $color-failed-light;
      color: $color-failed;
      border-color: $color-failed;
    }
    &.-is-wide {
      width: 100%;
    }
  }

  &__requirements {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &__arrow {
    color: $color-text-light;
    width: 1rem;
    height: 1rem;
  }

  &__data {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  &__explanations {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    & > div {
      display: grid;
      padding: 0.25rem 1rem;
      grid-template-columns: 1fr 2rem 1fr;
      gap: 1rem;
      &:last-child {
        grid-template-columns: 1fr;
      }
    }
  }
  &__equals {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    color: $color-primary;
    font-weight: 700;
    font-size: 1.5em;
  }
}
</style>