<template>
  <div class="grading-screen">
    <article v-if="step == 1" class="grading-screen__page">
      <h2>Auswertung</h2>
        <p class="grading-screen__text">
          Ihr habt den Meilenstein <strong>Daten-Freeze</strong> erreicht! An diesem Meilenstein wird der bis dahin verfügbare Datenstand „eingefroren“.
        </p>
        <p class="grading-screen__text">
          In der folgenden Phase <strong>Bewertung</strong> werden die vereinbarten Anforderungen eurer Fahrzeuge durch Simulationen validiert. 
        </p>
        <p class="grading-screen__text">
          Anschließend erhaltet ihr eure Gesamtbewertung, die sich aus euren jeweiligen Einzelbewertungen sowie den erreichten Punkten im Quiz zusammensetzt.
        </p>
        <p class="grading-screen__text">
          Anhand eurer erreichten Punkte könnt ihr besprechen was ihr in der nächsten VPE-Phase anders machen 
          würdet und wo Optimierungsbedarf besteht.
        </p>

      <div class="grading-screen__simulations">
        <div>
          <h3>Verfügbare Simulationsmethoden</h3>
          <ul>
            <li v-for="method of myself.methods" :key="method.id">
              {{ method.label }}
              <icon
                v-if="simulationResults[method.id] != null"
                name="richtig"
                width="1rem"
                height="1rem"
              ></icon>
            </li>
          </ul>
        </div>
        <div class="grading-screen__simulationarea">

          <div
            v-for="method in untestedMethods.slice(0, 1)"
            :key="'sim_' + method.id"
          >
            <h3>Simulationsmethode {{ method.label }}</h3>
            <car-simulation
              :type="method.id"
              :car-parts-data="myBuiltParts"
              :auto-start="true"
              :max-time="method.id === 'simulation' ? 10000 : 3000"
              class="grading-screen__simulation"
              @end="saveSimulationResult(method.id, $event)"
            ></car-simulation>
          </div>
        </div>
      </div>

      <div class="row -right">
        <button
          v-if="allMethodsSimulated"
          class="button"
          @click="submitResults()"
        >
          Weiter
        </button>
      </div>
    </article>

    <!-- ANFORDERUNGEN -->
    <article v-if="step == 2" class="grading-screen__page">
      <h2>Anforderungen</h2>
      <p>
        Vergleich der vereinbarten SOLL- und IST-Werte der 
        im Leistungsumfang vereinbarten Anforderungen.
      </p>

      <div>
        <div
          class="row"
          v-for="requirement in myself.requirements"
          :key="requirement.id"
        >
          <requirement-card
            :requirement="requirement"
          ></requirement-card>
          
          <icon name="pfeil" class="grading-screen__arrow"></icon>

          <measurement-card
            :type="requirement.label"
            :unit="requirement.unit"
            :value="myself.simulationResults[requirement.type]"
            :fulfilled="myself.marks.requirements[requirement.type]"
          ></measurement-card>

          <strong
            class="grading-screen__result"
            :class="{
              '-succeeded': myself.points.requirements[requirement.type] == 20,
              '-passed': myself.points.requirements[requirement.type] < 20 && myself.points.requirements[requirement.type] > 0,
              '-failed': myself.points.requirements[requirement.type] == 0
            }"
          >
            + {{ myself.points.requirements[requirement.type] }} Pkt
          </strong>
        </div>
      </div>

      <div class="row -right">
        <button
          class="button"
          @click="step++"
        >
          Weiter
        </button>
      </div>
    </article>

    <article v-else-if="step == 3" class="grading-screen__page">
      <h2>Aufwände</h2>

      <div class="grading-screen__datapagegrid">

        <div class="grading-screen__collected-data">
          <p>Du hast <strong>{{ myself.points.parts.totalPartRequests }}</strong> Daten vereinbart und davon <strong>{{ myself.points.parts.totalCollectedParts }}</strong> Daten eingesammelt.</p>
        </div>
        <div class="grading-screen__datapoint" :class="{
          '-succeeded': myself.points.parts.remainingPartRequests >= 0,
          '-failed': myself.points.parts.remainingPartRequests < 0
        }">
          <span>{{ myself.points.parts.remainingPartRequests }}</span>
        </div>

        <div class="grading-screen__built-parts">
          <p>Nach der Datenoptimierung waren <strong>{{ myself.points.parts.totalParts }}</strong> Daten verfügbar von denen du <strong>{{ myself.points.parts.builtParts }}</strong> Daten verbaut hast.</p>
        </div>
        <div class="grading-screen__datapoint" :class="{
          '-succeeded': myself.points.parts.unbuiltParts >= 0,
          '-failed': myself.points.parts.unbuiltParts < 0
        }">
          <span>{{ myself.points.parts.unbuiltParts }}</span>
        </div>

        <div class="grading-screen__movementpoints">
          <p>Am Ende des Spiels hattest du <strong>{{ myself.movementPoints }}</strong> Bewegungspunkte übrig.</p>
        </div>
        <div class="grading-screen__datapoint" :class="{
          '-succeeded': myself.points.movement >= 0,
          '-failed': myself.points.movement < 0
        }">
          <span>{{ myself.points.movement }}</span>
        </div>
      </div>
      <div class="grading-screen__datapagegrid">

        <div class="grading-screen__summary">
          <p>Insgesamt hast du zusätzliche Aufwände in Höhe von <strong>{{ myself.points.total.data }} Punkten </strong> verursacht.</p>
        </div>

        <strong
          class="grading-screen__dataresult"
          :class="{
            '-succeeded': myself.points.total.data == 0,
            '-failed': myself.points.total.data < 0
          }"
        >
          {{ myself.points.total.data }}
        </strong>
      </div>



<!--         <div>
          <div class="row">
            <card>
              <div class="grading-screen__cardcontent">
                <strong>Vereinbart</strong>
                <icon name="dokument_stift" class="grading-screen__smallcardicon"></icon>
                <div>{{ myself.points.parts.totalPartRequests }}x</div>
              </div>
            </card>
            
            <card>
              <div class="grading-screen__cardcontent">
                <strong>Getauscht</strong>
                <icon name="download" class="grading-screen__smallcardicon"></icon>
                <div>{{ myself.points.parts.totalCollectedParts }}x</div>
              </div>
            </card>
            
            <card>
              <div class="grading-screen__cardcontent">
                <strong>Verbaut</strong>
                <icon name="auto" class="grading-screen__smallcardicon"></icon>
                <div>{{ myself.points.parts.builtParts }}x</div>
              </div>
            </card>
          </div>



          <div class="row text-center">
            <strong
              class="grading-screen__result -failed"
            >
              {{ myself.points.parts.remainingPartRequests + myself.points.parts.unbuiltParts }} Pkt
            </strong>
          </div>
        </div>

        <div class="column">
          <card :disabled="true" class="grading-screen__movementcard">
            <div class="grading-screen__cardcontent">
              <div><strong>Verbleibende Bewegungspunkte:</strong></div>
              <icon name="schritt" class="grading-screen__smallcardicon"></icon>
              <strong>{{ myself.movementPoints }}</strong>
            </div>
          </card>

          <div class="row text-center">
            <strong
              class="grading-screen__result"
              :class="{
                '-failed': myself.movementPoints < 0
              }"
            >
              <span v-if="myself.movementPoints < 0">{{ myself.movementPoints * 2 }} Pkt</span>
              <span v-else>0 Pkt</span>
            </strong>
          </div>
        </div> -->
      
      <div class="row -right">
        <button
          class="button"
          @click="step++"
        >
          Weiter
        </button>
      </div>
    </article>

    <article v-else-if="step == 4" class="grading-screen__page">
      <h2>Produktziel</h2>
      <p>
        Wenn die durch das Produktziel vorgegebenen Vorgaben erfüllt 
        wurden, erhältst du 15 Punkte.
      </p>

      <div class="row grading-screen__premiseresults">
          <div class="grading-screen__premiseresultcolumn-card">
            <premise-card
              :premise="myself.premise"
            ></premise-card>
          </div>
        
        <icon name="pfeil" class="grading-screen__arrow"></icon>

        <div class="grading-screen__premiseresultcolumn">
          <premise-result-card
            :premise="myself.premise"
            :player="myself"
          >
            <grade-mark class="card__mark" :mark="myself.points.premise > 0 ? 2 : 0"></grade-mark>
          </premise-result-card>
          <strong
            class="grading-screen__result"
            :class="{
              '-succeeded': myself.points.premise > 0,
              '-failed': myself.points.premise === 0
            }"
          >
            +{{ myself.points.premise }} Pkt
          </strong>
        </div>
      </div>

      <div class="row -right">
        <button
          class="button"
          @click="step++"
        >
          Weiter
        </button>
      </div>
    </article>

    <article v-else-if="step == 5" class="grading-screen__page">
      <div class="grading-screen__total">
        <h2 class="grading-screen__total__heading">Gesamtpunkte</h2>
        <strong class="grading-screen__total__value">{{ myself.points.total.sum }}</strong>
      </div>

      <div class="row">

        <button
          class="button"
          :disabled="playerIsReady"
          @click="moves.markAsReady(myself.id)"
        >
          <span v-if="playerIsReady">
            Warte auf {{ currentMove.waitingOnPlayers.length }} Mitspieler<loading-icon></loading-icon>
          </span>
          <span v-else>Weiter zu den Ergebnissen</span>
        </button>
      </div>
    </article>
    <game-footer></game-footer>
  </div>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";

import RequirementCard from "../Cards/RequirementCard.vue";
import MeasurementCard from "../Cards/MeasurementCard.vue";
import PremiseCard from "../Cards/PremiseCard.vue";
import PremiseResultCard from "../Cards/PremiseResultCard.vue";
import Card from "../Cards/Card.vue";

import CarSimulation from "../Car/CarSimulation.vue";
import Car from "../Car/Car.vue";
import Icon from "../../ui/Icon.vue";
import GradeMark from "../UIElements/GradeMark.vue";
import LoadingIcon from "../../ui/LoadingIcon.vue";

import GameFooter from "../../ui/GameFooter.vue";

export default {
  mixins: [GameComponentMixin],
  components: {
    RequirementCard,
    MeasurementCard,
    PremiseCard,
    PremiseResultCard,
    Card,
    CarSimulation,
    Car,
    Icon,
    LoadingIcon,
    GradeMark,
    GameFooter
  },
  data() {
    return {
      step: 1,
      simulationRulerResult: null,
      simulationWayResult: null,
      simulationResults: {
        ruler: null,
        scale: null,
        simulation: null
      }
    }
  },
  computed: {
    myBuiltParts() {
      return this.myself.parts.filter(
        part => this.myself.builtPartIds.includes(part.uuid)
      );
    },
    playerIsReady() {
      return !this.currentMove.waitingOnPlayers.includes(this.myself.id);
    },
    untestedMethods() {
      return this.myself.methods.filter(m => this.simulationResults[m.id] === null);
    },
    allMethodsSimulated() {
      return this.myself.methods.every(m => this.simulationResults[m.id] !== null);
    }
  },
  methods: {
    saveSimulationResult(methodId, result) {
      this.simulationResults[methodId] = result;
    },
    submitResults() {
      this.step++;
      this.moves.submitResults({
        playerId: this.myself.id,
        ...this.simulationResults
      });
    }
  }
}
</script>

<style lang="scss">
.grading-screen {
  height: 100%;
  background-color: #eee;
  overflow: auto;
  color: $color-text;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;

  &__text {
    font-size: 18px;
    line-height: 1.5;
  }

  &__page {
    max-width: 60rem;
    margin: 0 auto;
    background-color: #fff;
    padding: 2rem;
    border-radius: 2rem;
    margin-bottom: 1rem;
    margin-bottom: auto;
  }

  &__arrow {
    color: $color-text-light;
    width: 3rem;
    height: 3rem;
    margin: 0 2rem;
  }

  &__smallcardicon {
    width: 3.5em;
    height: 3.5em;
    display: block;
  }
  &__cardcontent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr min-content;
    justify-items: center;
    gap: 1rem;
  }

  &__simulation {
    max-width: 40rem;
  }

  &__simulations {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 2rem;
  }

  &__result {
    font-size: 2rem;
    line-height: 1em;
    margin: 0.5rem 0;
    width: 4em;
    &.-succeeded {
      color: $color-succeeded;
    }
    &.-passed {
      color: $color-passed;
    }
    &.-failed {
      color: $color-failed;
    }
  }

  &__premiseresults {
    padding-bottom: 3rem;
  }
  &__premiseresultcolumn {
    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    .card {
      margin-bottom: 0.5rem;
    }

  }

&__premiseresultcolumn-card {
    padding-bottom: 1.2rem;
  }

  &__datapagegrid {
    display: grid;
    grid-template-columns: 1fr 5rem;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__collected-data,
  &__built-parts,
  &__movementpoints,
  &__summary {
    background-color: $color-uielement-bg;
    border: 2px solid $color-text-light;
    border-radius: 5px;
    font-size: 18px;
    padding: 0em 1.5em;
    align-self: center;
  }

  &__summary {
    background-color: var(--active-player-color-light);
    border: 2px solid var(--active-player-color);
  }

  &__datapoint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    line-height: 1em;
    background-color: $color-uielement-bg;
    border: 2px solid $color-text-light;
    border-radius: 5px;
    &.-succeeded {
      color: $color-succeeded;
    }
    &.-passed {
      color: $color-passed;
    }
    &.-failed {
      color: $color-failed;
    }
  }
  &__dataresult {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    line-height: 1em;
    background-color: $color-uielement-bg;
    border: 2px solid $color-text-light;
    border-radius: 5px;
    &.-succeeded {
      color: $color-succeeded;
      border-color: $color-succeeded;
    }
    &.-failed {
      color: $color-failed;
      border-color: $color-failed;
    }
  }

  

  &__movementcard {
    margin-bottom: 3rem;
  }

  &__total {
    margin-bottom: 2rem;
    text-align: center;
    &__heading {
      margin: 0;
      margin-bottom: 0.5rem;
    }
    &__value {
      font-size: 3rem;
    }
  }
}
</style>