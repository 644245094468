<template>
  <div class="vpe-app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
.vpe-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
