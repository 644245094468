<template>
  <div class="requirementfield-dialogs">
    
    <!-- ROLLE -->
    <role-dialogs v-if="hasRoleAndRoleOpen">
      
      <template v-slot:intro>
        <p>
          Hallo, ich bin ein <strong>Funktionsverantwortlicher</strong>.
        </p>
        <p>
          Ich definiere die Anforderungen an das Produkt, vereinbare mit den Simulationsexperten den virtuellen Bewertungsumfang und fasse zum Statustermin die Funktionsbewertungen zusammen.
        </p>
        <p>
          Gut, dass du nach mir gefragt hast. Mit meiner Hilfe darfst du in diesem 
          Zug einmal neue Anforderungen ziehen!
        </p>
      </template>

      <template v-slot:question>
        <p>
          Hallo, hier ist wieder ein Funktionsverantwortlicher!
        </p>
      </template>

      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die Arbeit gehen.
        </p>
        <p>
          Mit meiner Hilfe darfst du in diesem 
          Zug einmal neue Anforderungen ziehen!
        </p>
      </template>

      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du nochmal Hilfe brauchst.
        </p>
      </template>

    </role-dialogs>


    
    <!-- QUIZFRAGE -->
    <quiz-dialogs v-else-if="(
      currentField.hasQuiz &&
      !currentMove.handledQuizQuestion
    )"></quiz-dialogs>


    <!-- "EIGENTLICHE" DIALOGE -->

    <!-- Keine Anforderung? -->
    <!-- Anforderung wählen -->
    <game-dialog v-else-if="selectedAction == null">
      <p class="text-center" v-if="isCurrentPlayerActive">
        <span v-if="selectedRequirement == null">
          Wähle eine Anforderung:
        </span>
        <span v-else>
          Möchtest du diese Anforderung behalten?
        </span>
      </p>
      <p v-else>
        {{ playerNames[currentPlayer.id] }} bietet dir eine Anforderung an:
      </p>
      
      <div class="row" v-if="!selectedRequirement">
        <requirement-card
          v-for="requirement in gameState.currentMove.requirements"
          :key="requirement.id"
          :requirement="requirement"
          :clickable="true"
          :disabled="!isActivePlayer"
          :selected="selectedRequirement && selectedRequirement.id == requirement.id"
          @click="moves.selectRequirement(requirement)"
        ></requirement-card>
      </div>
      <div class="row" v-else>
        <requirement-card
          :requirement="selectedRequirement"
        ></requirement-card>
      </div>

      <div v-if="selectedRequirement == null" class="row">
        <button-active-player
          type="secondary"
          @click="moves.endTurn()"
        >
          Abbrechen
        </button-active-player>
        <button-active-player
          v-if="currentMove.solvedRoleQuiz && !currentMove.hasRedrawn"
          @click="moves.redrawRequirements()"
        >
          Neu mischen
        </button-active-player>
      </div>
      <div v-if="selectedRequirement != null" class="row">
        <button-active-player
          type="secondary"
          @click="moves.endTurn()"
        >
          Abwerfen
        </button-active-player>

        <button-active-player
          v-if="isFieldCollaborative"
          @click="moves.setAction('giveAway')"
        >
          Weitergeben
        </button-active-player>

        <button-active-player
          @click="moves.selectRequirementAndProceed()"
        >
          Behalten
        </button-active-player>
      </div>
    </game-dialog>


    <!-- Alle Dialoge für Selbstanwendung -->
    <template v-if="selectedAction == 'use'">

      <!-- Anforderung zum Ersetzen ausgewählt? -->
      <!-- Bestätigung: Anforderung wird entfernt -->
      <game-dialog v-if="requirementToReplaceId != null">
        
        <div class="text-center">
          Diese Anforderung wird entfernt:
        </div>

        <div class="row">
          <requirement-card
            :requirement="ownedRequirements.find(req => req.id == requirementToReplaceId)"
          ></requirement-card>

          <icon name="pfeil" class="color-text-light"></icon>

          <requirement-card
            :requirement="selectedRequirement"
          ></requirement-card>
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.setRequirementToReplace(null)"
          >
            Zurück
          </button-active-player>

          <button-active-player
            v-if="isCurrentPlayerActive && currentField.isCollaborative"
            @click="moves.switchRequirements('giveAway');"
          >
            Weitergeben
          </button-active-player>

          <button-active-player
            @click="moves.confirmRequirement()"
          >
            OK
          </button-active-player>
        </div>
      </game-dialog>

      <!-- Hat schon Karte gleichen Typs? -->
      <!-- Anforderung des gleichen Typs ersetzen. -->
      <game-dialog v-else-if="ownedRequirementOfSameType">
        <p class="text-center">
          Du besitzt bereits eine Anforderung für 
          {{ selectedRequirement.label }}.
        </p>
        <p class="text-center">
          Wenn du diese 
          Anforderung übernimmst, wird deine andere 
          Anforderung für {{ selectedRequirement.label }}
          weggeworfen.
        </p>

        <div class="row">
          <requirement-card
            :requirement="selectedRequirement"
          ></requirement-card>
        </div>
        
        <p class="text-center">
          Bist du damit einverstanden?
        </p>

        <div class="row">
          <button-active-player
            v-if="isCurrentPlayerActive"
            type="secondary"
            @click="moves.setAction(null)"
          >
            Zurück
          </button-active-player>
          <button-active-player
            v-else
            type="secondary"
            @click="moves.endTurn()"
          >
            Abwerfen
          </button-active-player>
          <button-active-player
            @click="moves.setRequirementToReplace(ownedRequirementOfSameType.id)"
          >
            Ja, bestehende ersetzen
          </button-active-player>
        </div>
      </game-dialog>
      <!-- Ende: Gleichen Typ ersetzen -->

      <!-- Volles Feld: Beliebige Anforderung austauschen -->
      <game-dialog v-else-if="ownedRequirements.length >= 3">

        <div class="text-center">
          <p>
            Du besitzt bereits drei Anforderungen, um diese 
            Anforderung zu übernehmen musst du sie gegen eine
            andere in deinem Besitz eintauschen.
          </p>
          <p>
            Wähle eine Anforderung aus, die du eintauschen möchtest:
          </p>
        </div>

        <div class="row">
          <requirement-card
            v-for="requirement in ownedRequirements"
            :key="requirement.id"
            :requirement="requirement"
            :clickable="true"
            :disabled="!isActivePlayer"
            :selected="requirementToReplaceId == requirement.id"
            @click="moves.setRequirementToReplace(requirement.id)"
          ></requirement-card>
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endTurn()"
          >
            Abbrechen
          </button-active-player>
        </div>
      </game-dialog>
      <!-- Ende: Volles Feld -->

      <!-- Anforderung übernommen -->
       <game-dialog v-else>
        <div class="text-center">
          Du hast folgende Anforderung erhalten.
        </div>

        <div class="row">
          <requirement-card
            :requirement="selectedRequirement"
          ></requirement-card>
        </div>

        <div class="row">
          <button-active-player
            v-if="!isCurrentPlayerActive"
            type="secondary"
            @click="moves.endTurn()"
          >
            Ablehnen
          </button-active-player>
          <button-active-player
            @click="moves.confirmRequirement"
          >
            <span v-if="isCurrentPlayerActive">OK</span>
            <span v-else>Annehmen</span>
          </button-active-player>
        </div>
      </game-dialog> 

    </template>


    <!-- Wählen: an welchen Spieler weitergeben -->
    <game-dialog v-if="selectedAction == 'giveAway' && currentMove.selectedReceiver == null">
      <div class="text-center">
        An welchen Spieler möchtest du die Anforderung 
        weitergeben?
      </div>

      <div class="row">
        <requirement-card
          :requirement="selectedRequirement"
        ></requirement-card>
      </div>

      <div class="row">
        <player-element
          v-for="player in inactivePlayers"
          :key="player.id"
          :disabled="!isActivePlayer"
          :player="player"
          @click="moves.activateReceiver({
            receiver: player,
            action: 'use',
            stage: 'receiveRequirement'
          })"
        ></player-element>
      </div>

      <div class="row">
        <button-active-player
          v-if="currentMove.switchedRequirements"
          type="secondary"
          @click="moves.switchRequirements('use');"
        >
          Zurück
        </button-active-player>
        <button-active-player
          v-if="!currentMove.switchedRequirements"
          type="secondary"
          @click="moves.setAction(null)"
        >
          Zurück
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>

<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";


// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";
// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";

import RequirementCard from "../Cards/RequirementCard.vue";
import PlayerElement from "../UIElements/PlayerElement.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
import Icon from "../../ui/Icon.vue";

export default {
  components: {
    GameDialog,
    RequirementCard,
    RoleDialogs,
    QuizDialogs,
    PlayerElement,
    ButtonActivePlayer,
    Icon
  },
  mixins: [GameComponentMixin],
  computed: {
    isFieldCollaborative() {
      return this.currentField.isCollaborative && this.isCurrentPlayerActive;
    },
    selectedRequirement() {
      return this.currentMove.selectedRequirement;
    },
    selectedAction() {
      return this.currentMove.selectedAction;
    },
    ownedRequirements() {
      return this.activePlayer.requirements;
    },
    ownedRequirementOfSameType() {
      return this.ownedRequirements.find(req => req.type == this.selectedRequirement.type);
    },
    requirementToReplaceId() {
      return this.currentMove.selectedReqToReplace;
    },
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    }
  }
}
</script>

<style>

</style>