<template>
  <div class="spectated-match">
    <header class="spectated-match__header">
      <h1>Zuschauer in {{ matchName }}</h1>

      <label>
        <span>Perspektive</span>
        <select v-model="selectedPlayerId">
          <option v-for="player in players" :key="player.id" :value="`${player.id}`">{{ player.name }}</option>
        </select>
      </label>
    </header>

    <game-client
      class="spectated-match__spectatorview"
      :match-id="matchId"
      :selected-player-id="selectedPlayerId"
      :is-spectated="true"
      @match-ready="onMatchReady"
    ></game-client>
  </div>
</template>

<script>
import GameClient from "../components/game/GameClient";

export default {
  props: {
    matchId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedPlayerId: '0',
      players: [ { id: 0, name: "Spieler 1" }],
      matchName: ""
    }
  },
  components: {
    GameClient
  },
  methods: {
    onMatchReady(match) {
      if (match) {
        this.matchName = match.matchName || this.matchId;
        this.players = match.players.slice(0);
      }
    }
  }
}
</script>

<style lang="scss">
.spectated-match {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    padding: 0 1rem;
    border-bottom: 2px dashed $color-uielement-border;
    display: flex;
    justify-content: space-between;
    background-color: $color-uielement-bg;
    color: $color-text;
    align-items: center;
    h1 {
      font-size: 1.5rem;
      margin-bottom: 0.5em;
    }
  }
  &__spectatorview {
    height: 100%;
  }
}
</style>