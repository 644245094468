<!-- Das sind die Standard-Kollaborationsdialoge -->

<template>
  <!-- EMPFÄNGER WÄHLEN -->
  <game-dialog>
    <slot name="playerlist">
      <div class="text-center">
        An welchen Spieler möchtest du den Zug übergeben?
      </div>

      <div class="row">
        <player-element
          v-for="player in inactivePlayers"
          :key="player.id"
          :player="player"
          @click="moves.activateReceiver({
            receiver: player,
            data: {
              selectedReceiver: null,
              collaborationSelected: false
            }
          })"
        ></player-element>
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateCurrentMove({
            collaborationSelected: null
          })"
        >
          Zurück
        </button-active-player>
      </div>
    </slot>
  </game-dialog>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";

import GameDialog from "../UIElements/GameDialog.vue";
import PlayerElement from "../UIElements/PlayerElement.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
import Icon from "../../ui/Icon.vue";

export default {
  mixins: [GameComponentMixin],
  components: {
    GameDialog,
    PlayerElement,
    ButtonActivePlayer,
    Icon
  }
}
</script>

<style>

</style>