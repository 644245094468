<template>
  <div class="fielddialogs-collect">
    <role-dialogs v-if="hasRoleAndRoleOpen">
      <template v-slot:intro>
        <p>
          Hallo, ich bin eine <strong>Bauteil-Konstrukteurin</strong>.
        </p>
        <p>
          Ich erstelle oder beschaffe die CAD-Daten für meinen Teileumfang und lege diese termingerecht in den vorgesehenen Systemen und Datenbanken strukturiert ab..
        </p>
        <p>
          Gut, dass du nach mir gefragt hast. Mit meiner Hilfe darfst aus den 
          bereitgestellten Daten beliebig viele Daten auswählen und bekommst alternative
          Daten hierfür.
        </p>
      </template>
      <template v-slot:question>
        <p>
          Hallo, hier ist wieder eine Bauteil-Konstrukteurin!
        </p>
      </template>
      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die Arbeit gehen. Gut, dass du nach mir
          gefragt hast.
        </p>
        <p>
          Mit meiner Hilfe darfst du aus den bereitgestellten Daten
          beliebig viele Daten auswählen und bekommst alternative Daten hierfür.
        </p>
      </template>
      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du nochmal Hilfe brauchst.
        </p>
      </template>
    </role-dialogs>

    <!-- QUIZFRAGE -->
    <quiz-dialogs v-else-if="currentField.hasQuiz && !currentMove.handledQuizQuestion"></quiz-dialogs>

    <!-- Kollaboration -->
    <collaboration-dialogs
      v-else-if="currentMove.collaborationSelected"
    ></collaboration-dialogs>

    <!-- Abschluss (Dialog schließen) -->
<!--     <template v-else-if="currentMove.confirmed">
      <game-dialog>
        <div class="text-center">
          Die Daten wurden in dein Dashboard übernommen.
        </div>

        <div class="row">
          <button-active-player
            @click="moves.endTurn()"
          >
            OK
          </button-active-player>
        </div>

      </game-dialog>
    </template> -->

    <!-- Bereits alle Daten gesammelt: -->
    <template v-else-if="!playerHasPartRequests">
      <game-dialog>
        <div class="text-center">
          Du hast bereits alle Daten gesammelt.
        </div>

      <div class="row">
        <button-active-player
          @click="moves.endTurn()"
        >
        OK
        </button-active-player>

        <button-active-player 
          v-if="isFieldCollaborative"
          @click="moves.startCollaboration()"
        >
        Kollaboration: Zug weitergeben
        </button-active-player>
      </div>

      </game-dialog>
    </template>

    <!-- Ab hier: playerHasPartRequests == true -->

    <!-- Daten sammeln (ohne Kollaboration)-->
    <template v-else-if="currentMove.parts.length == 0"> 
      <game-dialog v-if="currentMove.category === null">
        <div class="text-center">
          Wähle eine Kategorie, deren Daten du sammeln möchtest:
        </div>

        <div class="column -categories">
          <data-card
            v-for="category in gameState.dataCategories"
            :key="category.id"
            :category="category"
            :clickable="true"
            :disabled="!isActivePlayer"
            :forbidden="remainingPartRequests[category.id] <= 0"
            @click="moves.choosePartCategory(category.id, category.title)"
          ></data-card>
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endTurn()"
          >
            Keine Daten einsammeln
          </button-active-player>

          <button-active-player v-if="isFieldCollaborative"
            @click="moves.startCollaboration()"
          >
            Kollaboration: Zug weitergeben
          </button-active-player>
        </div>
      </game-dialog>

      <game-dialog v-else-if="currentMove.category !== null">

        <div class="row--cardleft">
          <data-card
            :category="{
              title: currentMove.title,
              id: currentMove.category
            }"
            :amount="remainingPartRequests[currentMove.category]"
          ></data-card>
          <span>
            In der Kategorie '{{currentMove.title}}' sind {{remainingPartRequests[currentMove.category]}} Daten angefordert,
            die noch nicht bereitgestellt wurden.
          </span>
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.resetCategory()"
          >
            Zurück
          </button-active-player>

          <button-active-player
            @click="moves.collectRandomParts()"
          >
            Daten einsammeln
          </button-active-player>

        </div>
      </game-dialog>
    </template>


    <!-- Ab hier: currentMove.parts != null -->


    <template v-else-if="!currentMove.confirmed && currentMove.parts.length > 0">

      <!-- ohne ROLLE -->
      <game-dialog :extra-wide="true" v-if="!currentMove.solvedRoleQuiz">
        <div class="text-center">
          Du hast folgende Daten erhalten:
        </div>

        <div class="fielddialogs-optimize__partgrid">
          <part-card
            v-for="part in currentMove.parts"
            :key="part.uuid"
            :part="part"
          ></part-card>
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endTurn()"
          >
          Abbrechen
          </button-active-player>

          <button-active-player
            @click="moves.confirmParts()"
          >
          Daten übernehmen
          </button-active-player>         
        </div>
      </game-dialog>

      <!-- mit ROLLE -->
      <template v-else-if="currentMove.solvedRoleQuiz">
        <game-dialog :extra-wide="true" v-if="!currentMove.startedReroll">
          <div class="text-center">
            Du hast folgende Daten erhalten:
          </div>

          <div class="fielddialogs-optimize__partgrid">
            <part-card
              v-for="part in currentMove.parts"
              :key="part.uuid"
              :part="part"
            ></part-card>
          </div>

          <div class="row">
            <button-active-player
              type="secondary"
              @click="moves.endTurn()"
            >
            Abbrechen
            </button-active-player>

            <button-active-player v-if="!currentMove.hasReselected"
              @click="moves.startReroll()"
            >
              Daten austauschen
            </button-active-player>

            <button-active-player
              @click="moves.confirmParts()"
            >
              Daten übernehmen
            </button-active-player>
        </game-dialog>

        <game-dialog :extra-wide="true" v-else-if="currentMove.startedReroll && !currentMove.hasReselected">
          <div class="text-center">
            Wähle die Daten aus, die durch andere Daten ersetzt werden sollen:
          </div>

          <div class="fielddialogs-optimize__partgrid">
            <part-card
              v-for="part in currentMove.parts"
              :key="part.uuid"
              :part="part"
              :clickable="true"
              :disabled="!isActivePlayer"
              :selected="currentMove.partIDsToReroll.includes(part.uuid)"
              @click="moves.markPartToReroll(part.uuid)"
            ></part-card>
          </div>

          <div class="row">
            <button-active-player
              type="secondary"
              @click="moves.endReroll()"
            >
              Zurück
            </button-active-player>

            <button-active-player
              @click="moves.choosePartsToReroll()"
            >
              Weiter
            </button-active-player>
          </div>

        </game-dialog>
      </template>
    </template>

 
  </div>
</template>

<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";

// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";
// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";
import CollaborationDialogs from "./CollaborationDialogs.vue";

import DataCard from "../Cards/DataCard.vue";
import PartCard from "../Cards/PartCard.vue";
import PlayerElement from "../UIElements/PlayerElement.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";

export default {
  components: {
    GameDialog,
    QuizDialogs,
    RoleDialogs,
    CollaborationDialogs,
    DataCard,
    PlayerElement,
    PartCard,
    ButtonActivePlayer,
  },
  data() {
    return {
      localSelectedMethod: null,
    };
  },
  mixins: [GameComponentMixin],
  computed: {
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    },
    isFieldCollaborative() {
      return this.currentField.isCollaborative && this.isCurrentPlayerActive;
    },
    playerHasMethod() {
      return this.activePlayer.methods.some(
        (method) => method.id == this.currentMove.selectedMethod.id
      );
    },
    playerHasPartRequests() {
      return Object.values(this.remainingPartRequests).some((val) => val > 0);
    },
  },
  methods: {
    selectMethodAndAction(method, action) {
      this.moves.selectMethod(method);
      this.moves.setAction(action);
    },
    cancel() {
      this.moves.endTurn();
    },
  },
};
</script>

<style>
</style>