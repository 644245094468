<template>
  <game-dialog class="confirmmove-dialog">
    <div class="text-center">
      Für <strong>{{ pointsToMove }}</strong>
      <span v-if="pointsToMove > 1">Bewegungspunkte</span>
      <span v-if="pointsToMove == 1">Bewegungspunkt</span>
      auf dieses Feld bewegen?
    </div>
    <div class="row -padding-v">
      <board-field
        class="confirmmove-dialog__field"
        :area="currentArea"
        :field="field"
        :clickable="false"
      ></board-field>
    </div>
    <div class="confirmmove-dialog__text-container">
      <span class="confirmmove-dialog__text">{{field.title}}</span>
    </div>

    <div class="row">
      <button-active-player
        type="secondary"
        @click="moves.updateCurrentMove({
          selectedField: null,
          confirmedTurn: false
        })"
      >
        Abbrechen
      </button-active-player>
      <button-active-player
        @click="moves.goToField()"
      >
        Für {{ pointsToMove }}
        <span v-if="pointsToMove > 1">Bewegungspunkte</span>
        <span v-if="pointsToMove == 1">Bewegungspunkt</span>
        bewegen
      </button-active-player>
    </div>
  </game-dialog>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";
import GameDialog from "../UIElements/GameDialog.vue";
import BoardField from '../Board/BoardField.vue';
import ButtonActivePlayer from '../UIElements/ButtonActivePlayer.vue';
export default {
  mixins: [GameComponentMixin],
  components: {
    GameDialog,
    BoardField,
    ButtonActivePlayer
  },
  computed: {
    field() {
      return this.currentArea.fields.find(f => f.id == this.currentMove.selectedField);
    },
    pointsToMove() {
      return Math.abs(this.currentMove.selectedField - (this.currentPlayer.field || 0));
    },
    fieldTitle() {
      const field = this.currentArea.fields.find(
      field => field.id === this.currentMove.selectedField
      );
      return field.title;
    }
  }
}
</script>
<style lang="scss">
.confirmmove-dialog {
  &__field {
    width: 6rem;

  }

  &__text-container {
    text-align: center;
    margin-top: -1em;
  }

  &__text {
    font-weight:600;
    color: $color-uielement-milestone-border;
  
  }
}
</style>