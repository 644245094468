import { weights, createRectangleData, createTriangleData, createWheelData, createConstraintData, createSlopedRectData } from "../../car/carPartCreator";

// IDs aller Kategorien
export const LOW_RECTANGLE = 0;
export const HIGH_RECTANGLE = 1;
export const TRIANGLE = 2;
export const WHEEL = 3;
export const SCREW = 4;

// Alle Kategorien, lesbar zusammengefasst
export const dataCategories = [
  {
    id: LOW_RECTANGLE,
    title: "Flache Rechtecke"
  },
  {
    id: HIGH_RECTANGLE,
    title: "Hohe Rechtecke"
  },
  {
    id: TRIANGLE,
    title: "Dreiecke"
  },
  {
    id: WHEEL,
    title: "Räder und Achsen"
  },
  {
    id: SCREW,
    title: "Schrauben"
  }
];

export const triangleCorners = {
  TOP_LEFT: 0,
  TOP_RIGHT: 1,
  BOTTOM_LEFT: 2,
  BOTTOM_RIGHT: 3
};

export const allParts = {
  [HIGH_RECTANGLE]: [
    // vertikale rechtecke
    { width: 4, height: 4, weight: weights.LIGHT },
    { width: 8, height: 8, weight: weights.LIGHT },
    { width: 8, height: 4, weight: weights.LIGHT },
    { width: 12, height: 4, weight: weights.LIGHT },

    { width: 4, height: 4, weight: weights.HEAVY },
    { width: 8, height: 8, weight: weights.HEAVY },
    { width: 8, height: 4, weight: weights.HEAVY },
    { width: 12, height: 4, weight: weights.HEAVY },
  ],
  [TRIANGLE]: [
    { width: 4, height: 4, corner: triangleCorners.BOTTOM_LEFT, weight: weights.LIGHT },
    { width: 8, height: 8, corner: triangleCorners.BOTTOM_LEFT, weight: weights.LIGHT },

    { width: 4, height: 4, corner: triangleCorners.BOTTOM_LEFT, weight: weights.HEAVY },
    { width: 8, height: 8, corner: triangleCorners.BOTTOM_LEFT, weight: weights.HEAVY },

    { width: 8, height: 4, corner: triangleCorners.BOTTOM_LEFT, weight: weights.LIGHT },
    { width: 8, height: 4, corner: triangleCorners.BOTTOM_RIGHT, weight: weights.LIGHT },
    { width: 12, height: 4, corner: triangleCorners.BOTTOM_LEFT, weight: weights.LIGHT },
    { width: 12, height: 4, corner: triangleCorners.BOTTOM_RIGHT, weight: weights.LIGHT }
  ],
  [LOW_RECTANGLE]:  [
    { width: 12, height: 2, weight: weights.LIGHT },
    { width: 16, height: 2, weight: weights.LIGHT },
    { width: 20, height: 2, weight: weights.LIGHT },
    { width: 24, height: 2, weight: weights.LIGHT },

    { width: 12, height: 2, weight: weights.HEAVY },
    { width: 16, height: 2, weight: weights.HEAVY },
    { width: 20, height: 2, weight: weights.HEAVY },
    { width: 24, height: 2, weight: weights.HEAVY },
  ],
  [WHEEL]: [
    { width: 8 },
    { width: 12 },
    { width: 16 },
    { width: 0 } // Achse
  ],
  [SCREW]: [
    { vertical: true },
    { vertical: false }
  ]
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

/**
 *
 * @param {Number} category
 * @param {Number} amount
 */
export function getRandomPartsFromCategory(ctx, category, amount, idsToExclude = []) {
  const parts = [];

  let uniqueIdsToExclude = idsToExclude.filter(onlyUnique);

  // Falls der Spieler ALLE Teile ausgeschlossen hat,
  // ignorieren wir die Anfrage, Teile auszuschließen.
  if (uniqueIdsToExclude.length >= allParts[category].length) {
    uniqueIdsToExclude = [];
  }

  for (let i = 0; i < amount; i++) {

    let randomIndex = null;
    do {
      randomIndex = Math.floor(
        ctx.random.Number() * allParts[category].length
      );
    } while(uniqueIdsToExclude.includes(randomIndex));

    parts.push(createPart(category, randomIndex));
  }

  return parts;
}

function createHighRectangle({ width, height, weight } = {}) {
  return createRectangleData(width, height, { category: HIGH_RECTANGLE, weight });
}
function createLowRectangle({ width, height, weight } = {}) {
  return createRectangleData(width, height, { category: LOW_RECTANGLE, weight });
}
function createTriangle({ corner, width, height, weight }) {
  const cornerIsRight = corner === triangleCorners.BOTTOM_RIGHT || corner === triangleCorners.TOP_RIGHT;
  const cornerIsTop = corner === triangleCorners.TOP_LEFT || corner === triangleCorners.TOP_RIGHT;
  return createTriangleData(
    width, height,
    cornerIsRight, cornerIsTop,
    { category: TRIANGLE, weight }
  );
}
function createWheel({ width = 4 }) {
  if (width == 0) {
    return createConstraintData(0, { category: WHEEL });
  }
  return createWheelData(width, { category: WHEEL });
}
function createScrew({ vertical = true }) {
  return createConstraintData(1, { vertical, category: SCREW });
}

/**
 * Gibt eine Instanz eines Bauteils aus dem Katalog zurück
 * @param {Number} category
 * @param {Number} index
 */
export function createPart(category, index) {
  const partConfiguration = allParts[category][index];
  let part;
  switch (category) {
    case WHEEL:
      part = createWheel(partConfiguration);
      part.width = partConfiguration.width;
      break;
    case SCREW:
      part = createScrew(partConfiguration);
      break;
    case LOW_RECTANGLE:
      part = createLowRectangle(partConfiguration);
      part.width = partConfiguration.width;
      part.height = partConfiguration.height;
      break;
    case HIGH_RECTANGLE:
      part = createHighRectangle(partConfiguration);
      part.width = partConfiguration.width;
      part.height = partConfiguration.height;
      break;
    case TRIANGLE:
      part = createTriangle(partConfiguration);
      part.width = partConfiguration.width;
      part.height = partConfiguration.height;
      break;
    default:
      break;
  }
  part.databaseIndex = index;

  return part;
}

// combine all data into one:
export function createAllData() {
  return {
    [HIGH_RECTANGLE]: allParts[HIGH_RECTANGLE].map((part, index) => createPart(HIGH_RECTANGLE, index)),
    [LOW_RECTANGLE]: allParts[LOW_RECTANGLE].map((part, index) => createPart(LOW_RECTANGLE, index)),
    [WHEEL]: allParts[WHEEL].map((part, index) => createPart(WHEEL, index)),
    [TRIANGLE]: allParts[TRIANGLE].map((part, index) => createPart(TRIANGLE, index)),
    [SCREW]: allParts[SCREW].map((part, index) => createPart(SCREW, index))
  };
}
