<template>
  <div class="game-client" v-if="gameState" :class="[
    `player-${visiblePlayerId}`,
    `active-player-${activePlayer.id}`
  ]">
    <template v-if="matchIsRunning">
      <game-header
        :match-id="matchId"
        :player-name="playerName"
        :player-id="playerId"
        :credentials="credentials"
        @toggle-debug="toggleDebugPanel"
        @toggle-build="toggleBuildArea"
        @toggle-sim="toggleSimArea"
        @leave="$emit('leave')"
      ></game-header>

      <!-- SCREENS -->
      <intro-screen v-if="ctx.phase == 'introduction'"></intro-screen>
      <build-screen v-else-if="showBuildArea || (currentMove && currentMove.builderActive)"></build-screen>
      <simulation-screen v-else-if="showSimulationArea"></simulation-screen>
      <board-screen v-else-if="currentPhaseData && currentPhaseData.type == 'board'"></board-screen>

      <lvvp-screen v-else-if="ctx.phase == 'milestoneLVVP'"></lvvp-screen>
      <dbt-screen v-else-if="ctx.phase == 'milestoneDBT'"></dbt-screen>
      <grading-screen v-else-if="ctx.phase == 'grading'"></grading-screen>
      <results-screen v-else-if="ctx.phase == 'results'"></results-screen>

      <portal-target name="overlays" class="game-client__dialogs" multiple></portal-target>
    </template>
    <template v-else>
      <div class="game-client__nomatch">
        Dieses Spiel wurde bereits geschlossen.
      </div>
      <game-footer></game-footer>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { Client } from "boardgame.io/client";
import { SocketIO } from "boardgame.io/multiplayer";

import { VPE } from "../../../game/Game";
import { get, GAME_URL } from "../../services/api";

// Screens
import BoardScreen from "./Screens/BoardScreen.vue";
import LvvpScreen from "./Screens/LvvpScreen.vue";
import DbtScreen from "./Screens/DbtScreen.vue";
import BuildScreen from "./Screens/BuildScreen.vue";
import GradingScreen from "./Screens/GradingScreen.vue";
import ResultsScreen from "./Screens/ResultsScreen.vue";
import SimulationScreen from "./Screens/SimulationScreen.vue"; // for testing
import IntroScreen from "./Screens/IntroScreen.vue";

import GameHeader from "./UIElements/GameHeader.vue";
import GameFooter from "../ui/GameFooter.vue";

import CarSimulation from "./Car/CarSimulation.vue";
import CarBuilder from "./Car/CarBuilder.vue";
import CarPart from "./Car/CarPart.vue";


export default {
  components: {
    BoardScreen,
    LvvpScreen,
    DbtScreen,
    CarSimulation,
    CarBuilder,
    CarPart,
    BuildScreen,
    GradingScreen,
    ResultsScreen,
    GameHeader,
    SimulationScreen,
    IntroScreen,
    GameFooter
  },
  props: {
    playerId: {
      type: String,
      default: undefined
    },
    matchId: {
      type: String,
      default: null
    },
    credentials: {
      type: String,
      default: null
    },
    playerName: {
      type: String,
      default: "Zuschauermodus"
    },
    selectedPlayerId: {
      type: String,
      default: null
    },
    isSpectated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      state: {},
      moves: {},
      stopClient: () => {},
      playerNames: {},
      showBuildArea: false,
      showSimulationArea: false,
      matchIsRunning: false
    }
  },
  computed: {
    visiblePlayerId() {
      return this.playerId != null ? this.playerId : this.selectedPlayerId;
    },
    gameState() {
      if (!this.state) return null;
      return this.state.G;
    },
    ctx() {
      if (!this.state) return null;
      return this.state.ctx;
    },
    gameReady() {
      if (!Object.values(this.players).some(player => player.name == null));
    },
    players() {
      return this.gameState.players;
    },
    currentPlayer() {
      return this.players[this.state.ctx.currentPlayer];
    },
    currentArea() {
      return this.gameState.areas[this.currentPlayer.area];
    },
    currentPhaseData() {
      return this.gameState.phases[this.ctx.phase];
    },
    currentMove() {
      return this.gameState.currentMove;
    },
    activePlayer() {
      if (this.ctx.activePlayers) {
        const activePlayerId = Object.keys(this.ctx.activePlayers)[0];
        return this.gameState.players[activePlayerId];
      }
      return this.currentPlayer;
    }
  },
  provide() {
    return {
      getPlayerId: () => this.visiblePlayerId,
      getGameState: () => this.gameState,
      getCtx: () => this.ctx,
      getMoves: () => this.moves,
      getPlayerNames: () => this.playerNames,
      getMatchId: () => this.matchId,
      getCredentials: () => this.credentials,
      isSpectated: this.isSpectated
    };
  },
  created() {
    this.initGame();
  },
  mounted() {
    this.toggleDebugPanel();
  },
  beforeDestroy() {
    this.stopClient();
  },
  beforeRouteLeave (to, from, next) {
    this.stopClient();
  },
  methods: {
    initGame() {
      const gameOptions = {
        game: VPE,
        debug: false
      };

      // join multiplayer mode if matchID is set
      if (this.matchId) {
        gameOptions.matchID = this.matchId;
        console.log(GAME_URL);
        gameOptions.multiplayer = SocketIO({ server: GAME_URL });
      }

      // add player if the player is set
      if (this.playerId) {
        gameOptions.playerID = this.playerId;
        gameOptions.credentials = this.credentials;
      }

      const client = Client(gameOptions);
      
      // subscribe to state changes:
      client.subscribe(state => this.onGameStateUpdate(_.cloneDeep(state)));

      client.start();

      this.moves = client.moves;

      this.stopClient = () => { client.stop() };
    },
    async onGameStateUpdate(state) {
      if (state && Object.values(this.playerNames).length == 0) {
        await this.getMatchInfo(state.G, state.ctx);
      }
      this.state = state;
      this.$emit("state-changed", state);
    },
    async getMatchInfo(G, ctx) {
      let match = null;
      let matchIsActive = false;
      try {
        if (!this.matchId) {
          throw new Error("no matchId");
        }
        const matchData = await get(`/matches/${this.matchId}`);
        match = matchData.match;
        matchIsActive = true;
        this.matchIsRunning = true;
      } catch(error) {
        console.log(error.status);

        // create dummy data for the match if the real match
        // can not be reached:
        const players = [];
        for (let playerId = 0; playerId < ctx.numPlayers; playerId++) {
          let playerName = "Unbekannt";
          if (playerId == this.playerId) playerName = this.playerName;
          players.push({ id: playerId, name: playerName });
        }
        match = {
          players
        };
      }
      console.log("match", match);

      // update player names
      for (let player of match.players) {
        this.playerNames[player.id] = player.name;
      }

      this.$emit("match-ready", {
        players: match.players,
        matchName: G.matchName,
        active: matchIsActive
      });
    },
    toggleDebugPanel() {
      const debugPanel = document.querySelector(".debug-panel");
      if (debugPanel) {
        console.log("panel", debugPanel);
        if (debugPanel.style.display) {
          debugPanel.style.display = null;
        }
        else {
          debugPanel.style.display = "none";
        }
      }
    },
    toggleBuildArea() {
      this.showBuildArea = !this.showBuildArea;
      this.showSimulationArea = false;
    },
    toggleSimArea() {
      this.showSimulationArea = !this.showSimulationArea;
      this.showBuildArea = false;
    }
  }
}
</script>

<style lang="scss">
.game-client {
  height: 100vh;

  //display: grid;
  //grid-template-columns: 1fr;
  // grid-template-rows: min-content min-content 1fr;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__topbar {
    background-color: #ACACAC;
  }

  &__dialogs {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__nomatch {
    padding: 2rem;
    text-align: center;
    margin: auto;
    background-color: $color-uielement-bg;
    border: 1px solid $color-uielement-border;
    border-radius: 1rem;
  }

  &.player-0 {
    --player-color: #{$color-player-0};
    --player-color-light: #{$color-player-0-light};
    --player-color-medium: #{$color-player-0-medium};
  }
  &.player-1 {
    --player-color: #{$color-player-1};
    --player-color-light: #{$color-player-1-light};
    --player-color-medium: #{$color-player-1-medium};
  }
  &.player-2 {
    --player-color: #{$color-player-2};
    --player-color-light: #{$color-player-2-light};
    --player-color-medium: #{$color-player-2-medium};
  }
  &.player-3 {
    --player-color: #{$color-player-3};
    --player-color-light: #{$color-player-3-light};
    --player-color-medium: #{$color-player-3-medium};
  }
  &.player-4 {
    --player-color: #{$color-player-4};
    --player-color-light: #{$color-player-4-light};
    --player-color-medium: #{$color-player-4-medium};
  }

  &.active-player-0 {
    --active-player-color: #{$color-player-0};
    --active-player-color-light: #{$color-player-0-light};
    --active-player-color-medium: #{$color-player-0-medium};
  }
  &.active-player-1 {
    --active-player-color: #{$color-player-1};
    --active-player-color-light: #{$color-player-1-light};
    --active-player-color-medium: #{$color-player-1-medium};
  }
  &.active-player-2 {
    --active-player-color: #{$color-player-2};
    --active-player-color-light: #{$color-player-2-light};
    --active-player-color-medium: #{$color-player-2-medium};
  }
  &.active-player-3 {
    --active-player-color: #{$color-player-3};
    --active-player-color-light: #{$color-player-3-light};
    --active-player-color-medium: #{$color-player-3-medium};
  }
  &.active-player-4 {
    --active-player-color: #{$color-player-4};
    --active-player-color-light: #{$color-player-4-light};
    --active-player-color-medium: #{$color-player-4-medium};
  }
}
</style>