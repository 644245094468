<template>
  <card
    class="measurement-card"
    v-bind="{...$attrs, ...$props}"
    :disabled="true"
    @click="$emit('click', $event)"
  >
    <template v-slot:default>
      <div class="measurement-card__content">
        <strong>
          {{ type }}
        </strong>
        <icon name="daten" color="#b9b9b9"></icon>
        <div>
          {{ value !== null ? value : "— " }}{{ unit }}
        </div>
      </div>
      <div v-if="fulfilled">
        <grade-mark class="card__mark" :mark="fulfilled"></grade-mark>
      </div>
  </card>
</template>

<script>
import Card from "./Card.vue";
import Icon from "../../ui/Icon.vue";
import GradeMark from "../UIElements/GradeMark.vue";
export default {
  components: {
    Card,
    Icon,
    GradeMark
  },
  props: {
    type: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: null
    },
    fulfilled: {
      type: Number,
      default: null
    },
    unit: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss">
.measurement-card {
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    .icondu-icon {
      margin: 0.5rem 0;
      width: 3.5em;
      height: 3.5em;
    }
  }
}
</style>