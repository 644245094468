<template>
  <div class="details-element">
    <div class="details-element__summary" @click="open = !open">
      <slot name="summary"></slot>
    </div>

    <div v-if="open" class="details-element__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false
    };
  }
}
</script>

<style lang="scss">
.details-element {
  &__summary {
    padding: 1em;

    &:hover {
      background-color: #f0f0f0;
    }
  }
  &__content {
    padding: 1em;
    padding-top: 0;
  }
}
</style>