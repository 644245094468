<template>
  <div class="page-matchoverview">
    <update-password-form
      v-if="updatingPassword"
      @close="updatingPassword = false"
    ></update-password-form>

    <article class="page-matchoverview__content">

      <header class="page-matchoverview__header">
        <div>
          <h1 class="admin-headline">VPE Planspiel - Überblick</h1>
          <a class="game-header__menubutton admin-pdf"  target="_blank" title="Anleitung" href="../assets/Planspiel_VPE_Admin-Anleitung.pdf" >
          </a>
        </div>
      </header>

      <div class="page-matchoverview__body">
        <fieldset class="fieldset page-matchoverview__creatematch">
          <legend class="page-matchoverview__legend">Neues Spiel erstellen</legend>
          <div class="page-matchoverview__input-left">
            <input-field type="text" v-model="matchName" label="Spielname"></input-field>
          </div>
          <div class="page-matchoverview__input-right">
            <input-field type="number" v-model="playerAmount" label="Anzahl Spieler" :max="4"></input-field>
          </div>
          <div class="page-matchoverview__button">
            <button  @click="createMatch" class="button -nobreak">Neues Spiel</button>
          </div>
        </fieldset>

        <div class="grid-list">

          <section class="page-matchoverview__section">
            <h2 class="page-matchoverview__heading">Laufende Spiele</h2>
            <button class="button" style="justify-self: start;" @click="loadMatches">Liste aktualisieren</button>
            <table v-if="matches.length > 0" class="page-matchoverview__table">
              <thead>
                <tr>
                  <th class="page-matchoverview__th-game">Spiel</th>
                  <th class="page-matchoverview__th-join">Beitritt</th>
                  <th class="page-matchoverview__th-players">Spieler</th>
                  <th class="page-matchoverview__th-action">Aktionen</th>
                </tr>
              </thead>
              <tbody>
                <match
                  v-for="(match, index) in orderedMatches"
                  :key="match.matchId"
                  :index="index"
                  :match="match"
                  @destroyed="loadMatches"
                ></match>
              </tbody>
            </table>
            <div v-if="matches.length == 0" class="page-matchoverview__nothing">
              Keine laufenden Spiele.
            </div>
          </section>


          <section class="page-matchoverview__section">
            <h2 class="page-matchoverview__heading">Abgeschlossene Spiele</h2>
            <table v-if="playedMatches.length > 0" class="page-matchoverview__table">
              <thead>
                <tr>
                  <th class="page-matchoverview__th-index">Nr.</th>
                  <th class="page-matchoverview__th-game">Spiel</th>
                  <th class="page-matchoverview__th-teamresult">Teamergebnis</th>
                  <th class="page-matchoverview__th-playersresult">Spielerergebnisse</th>
                  <th class="page-matchoverview__th-actions">Aktionen</th>            
                </tr>
              </thead>
              <tbody>
                <match-result
                  v-for="(match, index) in orderedMatchResults" :key="'result_' + match.matchId"
                  :match="match"
                  :index="index"
                  @updated="overwriteMatchResult"
                ></match-result>
              </tbody>
            </table>
            <div class="page-matchoverview__nothing" v-if="playedMatches.length == 0">
              Keine abgeschlossenen Spiele.
            </div>
          </section>

          <section class="page-matchoverview__section">
            <h2 class="page-matchoverview__heading">Rangliste</h2>
            <table v-if="playedMatches.length > 0" class="page-matchoverview__table">
              <thead>
                <tr>
                  <th class="page-matchoverview__th-index">Nr.</th>
                  <th class="page-matchoverview__th-game">Spiel</th>
                  <th class="page-matchoverview__th-teamresult">Teamergebnis</th>
                  <th class="page-matchoverview__th-playersresult">Spielerergebnisse</th>
                  <th class="page-matchoverview__th-actions">Aktionen</th>            
                </tr>
              </thead>
              <tbody>
                <ranking
                  v-for="(match, index) in orderedHighscoreResults" :key="'result_' + match.matchId"
                  :match="match"
                  :index="index"
                  @updated="overwriteMatchResult"
                  @destroyed="removeMatchResult"
                ></ranking>
              </tbody>
            </table>
            <div class="page-matchoverview__nothing" v-if="playedMatches.length == 0">
              Keine abgeschlossenen Spiele.
            </div>
          </section>
        </div>
      </div>
    </article>

    <footer class="page-matchoverview__footer">
      <nav>
        <button class="button" @click="updatingPassword = true">
          Passwort ändern
        </button>

        <button class="button button--secondary" @click="logout">Logout</button>
      </nav>
    </footer>

    <!--
      <a
        class="button"
        target="_blank"
        href="/planspiel/beitreten"
      >
        Beitritt-Seite
      </a>
    -->
    <game-footer></game-footer>
  </div>
</template>

<script>
import { get, post, clearToken } from "../services/api";

import Match from "../components/admin/Match";
import MatchResult from "../components/admin/MatchResult.vue";
import Ranking from "../components/admin/Ranking.vue";
import InputField from "../components/input/InputField";
import UpdatePasswordForm from "../components/admin/UpdatePasswordForm.vue";

import GameFooter from "../components/ui/GameFooter.vue";

const today = new Date();

export default {
  components: {
    Match,
    MatchResult,
    Ranking,
    InputField,
    UpdatePasswordForm,
    InputField,
    GameFooter
  },
  data() {
    return {
      matchName: `Spiel ${ today.getFullYear() }-${ ((today.getMonth() + 1) + '').padStart(2, 0) }-${ (today.getDate() + '').padStart(2, 0) }`,
      matches: [],
      playedMatches: [],
      playerAmount: 4,
      updatingPassword: false
    };
  },
  mounted() {
    this.loadMatches().then(() => {
      this.loadPlayedMatches();
    });
  },
  watch: {
    playerAmount(newVal, oldVal) {
      if (newVal > 4) this.playerAmount = 4;
    }
  },
  computed: {
    orderedMatches() {
      return this.matches.sort((a, b) => a.createdAt < b.createdAt);
    },
    orderedMatchResults() {
      return this.playedMatches.filter(m => !m.archived).sort((a, b) => {
        return this.getMatchResultTotal(b) - this.getMatchResultTotal(a)
      });
    },
    orderedHighscoreResults() {
      return this.playedMatches.filter(m => m.archived).sort((a, b) => {
      return this.getMatchResultTotal(b) - this.getMatchResultTotal(a)
      });
    }
  },
  methods: {
    async loadMatches() {
      try {
        const { matches } = await get('/matches');
        this.matches = matches;
      } catch (error) {
        if (error.status == 401) {
          this.$router.push({ name: 'login' });
        }
      }
    },
    async loadPlayedMatches() {
      try {
        const matches = await get('/matchresults');
        this.playedMatches = matches;
      } catch (error) {
        console.error(error);
      }
    },
    async createMatch() {
      console.log("create match");
      try {
        const { matchID } = await post('/matches', {
          numPlayers: this.playerAmount,
          setupData: {
            matchName: this.matchName
          }
        });
        this.loadMatches();
      } catch (error) {
        if (error.status == 401) {
          this.$router.push({ name: 'login' });
        }
      }
    },
    getMatchResultTotal(match) {
      if (match.data.totalPoints != null) {
        return match.data.totalPoints;
      }
      const teamPoints = Object.values(match.data.players).reduce((sum, player) => sum + player.points.total.sum, 0);
      return teamPoints + match.data.quizPoints;
    },
    overwriteMatchResult(matchResult) {
      const index = this.playedMatches.findIndex(m => m.matchId === matchResult.matchId);
      this.$set(this.playedMatches, index, matchResult);
    },
    removeMatchResult(matchId) {
      const index = this.playedMatches.findIndex(m => m.matchId === matchId);
      this.playedMatches.splice(index, 1);
    },
    logout() {
      clearToken();
      this.$router.push({ name: 'login' });
    }
  }
}
</script>

<style lang="scss">

.admin-headline {
    color: #22C5E6;
    padding: 0;
    padding-right: 0.3em;
    margin: 0 0 0.5em 0;
    display: inline;

}

.admin-pdf {
  margin-bottom: 0.3em;
}


.page-matchoverview {

  &__content {
    width: 100%;
    max-width: 96rem;
    margin: 0 auto;
  }

  &__legend {
    font-size:1.3em;
    border-radius: 0.1em;
  }

  &__heading {
    color:white;
    background-color:#555;
    padding: 0.2em;
    width:fit-content;
    position: absolute;
    top: -1.5em;
    left: 0.8em;
    border-radius: 0.1em;
    font-size: 1.3em;
    
  }

  &__input-left {
    float:left;
    max-width: 18em;
    margin-right: 1.5em;
    
  }

  &__input-right {
    float:left;
    max-width: 10em;
    margin-right: 1.5em;
    
  }

  &__button {
    float:left;
    margin-top:2.6em;
    font-size: 14px;
  }

 

  &__creatematch {
    margin-bottom: 3em;

    .input-field__label {
      max-width: 8em;  
      margin-top: 1em;
      
    }

    .input-field__input {
      margin: 0.3em 1em 1em 0;
      border-radius: 0.1em;
    }

  }

  &__header {
    padding: 1.5em 0 0 1.5em;
    
    & > h1 {
      color: $color-primary;
      padding:0;
      margin:0 0 0.5em 0;

    }
  }

  &__body {
    align-items: start;
    height: 100%;
    padding: 1.5rem;
    overflow: auto; 
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 2em;
    table-layout: fixed;

    tr, td, th {
      border: 1px solid $color-uielement-table-border;
      
    }

    td, th {
      padding: 0.5em;
      padding-left: 1em;
    }

    th {
      font-size: 1.1em;
      color: $color-text;
    }

    td {
      vertical-align: top;
      text-align: left;
    }

    .td__index {
      text-align: center;
      font-weight: 600;
      padding-top: 1.5rem;
      
    }
  }

  &__th-index {
    width: 3%;
    text-align: center;
    padding-left: 0.5em !important;
  }

  &__th-game {
    width: 20%;
  }

  &__th-join {
    width: 45%;
  }

  &__th-players {
    width: 20%;
  }

  &__th-actions {
    width: 15%;
  }

  &__th-teamresult {
    width: 33%;
  }

  &__th-playerssresult {
    width: 29%;
  }

  &__section {
    padding: 3em 1em 1.5em 1em;
    box-shadow: 0 3px 9px rgba(0,0,0,0.2);  
    position: relative;
    margin-bottom: 2em;
  }

  &__nothing {
    padding: 1rem;
    box-shadow: 0 3px 9px rgba(0,0,0,0.1);
    font-weight: 600;
    border-radius: 0.25rem;

    margin-top: 1rem;
  }

  &__footer {
    padding: 1rem;
    background-color: $color-uielement-bg;
    border-top: 1px solid $color-uielement-border;
  }
}
.grid-list {
  display: grid;
  gap: 1rem;
}
</style>