<template>
  <div class="car-model">
    <svg
      :width="width"
      :height="height"
      :viewBox="`${bounds.min.x} ${bounds.min.y} ${width} ${height}`"
      class="car-model__stage"
    >
      <template v-for="group in partGroups">
        <car-part
          class="car-model__part"
          v-for="part in group"
          :key="part.uuid"
          :part="part"
          :parts="parts"
          :use-transform="true"
        ></car-part>
      </template>
    </svg>
  </div>
</template>

<script>
import CarPart from "./CarPart.vue";
export default {
  components: {
    CarPart
  },
  props: {
    parts: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    bodies() {
      return this.parts.filter(p => p.type === 'body' && p.label != "Wheel");
    },
    screws() {
      return this.parts.filter(p => p.type === 'constraint' && p.label != "Wheel");
    },
    wheels() {
      return this.parts.filter(p => p.type === 'body' && p.label === "Wheel");
    },
    axles() {
      return this.parts.filter(p => p.type === 'constraint' && p.label === "Wheel");
    },
    partGroups() {
      // legt die Reihenfolge fest, in der die Teile gezeichnet werden
      return [this.bodies, this.screws, this.wheels, this.axles];
    },
    bounds() {
      const min = {x: Infinity, y: Infinity};
      const max = {x: -Infinity, y: -Infinity};

      for (const part of this.parts) {
        if (!part.bounds) continue;
        min.x = Math.min(part.bounds.min.x, min.x);
        min.y = Math.min(part.bounds.min.y, min.y);
        max.x = Math.max(part.bounds.max.x, max.x);
        max.y = Math.max(part.bounds.max.y, max.y);
      }

      if (this.parts.length == 0) {
        return {
          min: {x: 0, y: 0},
          max: {x: 0, y: 0}
        }
      }

      return {
        min,
        max
      }
    },
    width() {
      return this.bounds.max.x - this.bounds.min.x;
    },
    height() {
      return this.bounds.max.y - this.bounds.min.y;
    }
  }
}
</script>

<style lang="scss">
.car-model {
  &__stage {
    position: relative;
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>