import { v4 as uuid } from 'uuid';
import decomp from "poly-decomp";
import { Bodies, Body, Composite, Constraint, Vector, Bounds, Vertices, Axes } from "matter-js";
import _ from "lodash";

if (typeof window !== 'undefined') {
  window.decomp = decomp;
}

export const GRID_SIZE = 10;

export const weights = {
  LIGHT: 6,
  HEAVY: 9
};

const COLOR_LIGHT = "#d0d0dc";
const COLOR_HEAVY = "#80808c";

class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

const defaultOptions = {
  x: 0,
  y: 0,
  connect: 'both', // [both, v, h]
  category: -1,
  databaseIndex: -1
};


/**
 * Returns a Matter.js rectangle body.
 * @param {Number} width
 * @param {Number} height
 * @param {Object} options
 */
function createRectangle(width, height, options = {}) {
  const optionsWithDefaults = _.defaults({}, options, defaultOptions);
  const { x, y } = optionsWithDefaults;
  const body = Bodies.rectangle(x, y, width * GRID_SIZE, height * GRID_SIZE, {
    label: "Rectangle",
    friction: 0.5,
    density: 0.003,
    render: {
      strokeStyle: 'transparent',
      fillStyle: options.weight === weights.HEAVY ? COLOR_HEAVY : COLOR_LIGHT
    },
    collisionFilter: {
      group: 1
    }
  });
  body.uuid = uuid();
  body.connect = 'both';
  body.category = options.category;
  body.databaseIndex = options.databaseIndex;
  body.weight = options.weight;

  return body;
}

/**
 * Returns a Matter.js circle body that does only collide with other wheels.
 * @param {Number} radius
 * @param {Object} options
 */
function createWheel(width, options = {}) {
  const optionsWithDefaults = _.defaults({}, options, defaultOptions);
  const { x, y } = optionsWithDefaults;

  const radius = (width / 2) * GRID_SIZE;
  const body = Bodies.circle(x, y, radius, {
    label: "Wheel",
    render: {
      strokeStyle: 'transparent',
      fillStyle: "rgba(200, 200, 200, 0.8)"
    },
    restitution: 1,
    friction: 0.8,
    // make sure wheels only collide with wheels:
    collisionFilter: {
      group: 2,
      category: 0x0002,
      mask: 0x0002
    }
  });

  body.uuid = uuid();
  body.connect = 'both';
  body.category = options.category;
  body.databaseIndex = options.databaseIndex;
  body.weight = options.weight;

  return body;
}


/**
 * Returns a Matter.js triangle body.
 * @param {Number} width
 * @param {Number} height
 * @param {Object} options
 */
function createTriangle(width, height, inverseX = false, inverseY = false, options = {}) {
  const vertices = [];

  const w = width * GRID_SIZE;
  const h = height * GRID_SIZE;

  // top vertex
  inverseX ? vertices.push(Vector.create(w, inverseY ? h : 0)) : vertices.push(Vector.create(0, inverseY ? h : 0));
  vertices.push(Vector.create(w, inverseY ? 0 : h));
  vertices.push(Vector.create(0, inverseY ? 0 : h));

  const body = createVerticesBody(vertices, options);
  body.label = "Triangle";
  return body;
}

/**
 *
 * @param {Point[]} vertices
 * @param {Object} options
 */
function createVerticesBody(vertices, options) {
  const optionsWithDefaults = _.defaults({}, options, defaultOptions);
  const { x, y } = optionsWithDefaults;

  const body = Bodies.fromVertices(x, y, vertices, {
    label: "VerticesBody",
    friction: 0.3,
    render: {
      strokeStyle: 'transparent',
      fillStyle: options.weight === weights.HEAVY ? COLOR_HEAVY : COLOR_LIGHT
    },
    collisionFilter: {
      group: 1
    }
  });
  body.uuid = uuid();
  body.connect = 'both';
  body.category = options.category;
  body.databaseIndex = options.databaseIndex;
  body.weight = options.weight;

  return body;
}



function createConstraint(length, vertical, partA, partB, pointA = { x: 0, y: 0 }, pointB = {x: 0, y: 0}) {
  const constraint = Constraint.create({
    bodyA: partA,
    pointA,
    bodyB: partB,
    pointB,
    length: length * GRID_SIZE,
    stiffness: length > 0 ? 1 : 0.7,
    damping: 0.09
  });

  constraint.uuid = uuid();
  constraint.vertical = vertical;
  if (length > 0) {
    constraint.label = `Screw (${vertical ? 'Vertical' : 'Horizontal'})`;
  }

  return constraint;
}

export function getCompositeData(composite) {
  return {
    bodies: composite.bodies.map(bodyToData),
    composites: composite.composites.map(getCompositeData),
    constraints: composite.constraints.map(constraintToData),
    isModified: composite.isModified,
    label: composite.label,
    type: composite.type
  };
};
export function compositeFromData(data) {
  const bodies = data.bodies.map(bodyFromData);
  const composites = data.composites.map(compositeFromData);
  const constraints = data.constraints.map(constraintData => constraintFromData(constraintData, bodies));
  return Composite.create(Object.assign({}, data, { bodies, composites, constraints }));
}

export function bodyToData(body) {
  return {
    bounds: {
      min: {
        x: body.bounds.min.x,
        y: body.bounds.min.y
      },
      max: {
        x: body.bounds.max.x,
        y: body.bounds.max.y
      }
    },
    category: body.category,
    databaseIndex: body.databaseIndex,
    connect: body.connect,
    density: body.density,
    collisionFilter: {
      category: body.collisionFilter.category,
      group: body.collisionFilter.group,
      mask: body.collisionFilter.mask
    },
    friction: body.friction,
    isStatic: body.isStatic,
    label: body.label,
    position: {
      x: body.position.x,
      y: body.position.y
    },
    render: {
      fillStyle: body.render.fillStyle,
      strokeStyle: body.render.strokeStyle
    },
    restitution: body.restitution,
    type: body.type,
    uuid: body.uuid,
    vertices: body.vertices.map(vertex => ({ x: vertex.x, y: vertex.y })),
    weight: Math.round(body.weight * 2) / 2
  }
}

/**
 * Creates a new matter body from data parsed from JSON
 * @param {Object} relevantData
 */
export function bodyFromData(relevantData) {
  const preparedData = _.cloneDeep(relevantData);
  preparedData.vertices = preparedData.vertices.map(position => Vector.create(position.x, position.y));
  return Body.create(preparedData);
}

/**
 * @param {Number} x
 * @param {Number} y
 * @param {Point[]} points
 */
export function bodyFromPoints(x, y, points) {
  const vertices = points.map(point => Vector.create(point.x, point.y));
  return Bodies.fromVertices(x, y, vertices);
}

/**
 *
 * @param {Object} bodyData
 * @param {Point} position
 */
export function setBodyPosition(bodyData, position) {
  const body = _.cloneDeep(bodyData);
  const delta = Vector.sub(position, bodyData.position);
  body.position = {
    x: position.x,
    y: position.y
  },
  body.positionPrev = {
    x: position.x,
    y: position.y
  };

  if (body.vertices) {
    for (const vertex of body.vertices) {
      const newVertexPosition = Vector.add(vertex, delta);
      vertex.x = newVertexPosition.x;
      vertex.y = newVertexPosition.y;
    }
  }

  Bounds.update(body.bounds, body.vertices, 0);

  return body;
}

/**
 *
 * @param {Object} bodyData
 * @param {Number} degrees
 */
export function rotateBody(bodyData, degrees) {
  // Convert degrees to radians:
  const rotation = degrees * (Math.PI / 180);

  let body = _.cloneDeep(bodyData);

  // move 1
  const offset = {
    x: body.position.x - body.bounds.min.x,
    y: body.position.y - body.bounds.min.y
  };
  body = setBodyPosition(body, Vector.sub(body.position, offset));

  Vertices.rotate(body.vertices, rotation, body.position);
  Bounds.update(body.bounds, body.vertices, 0);

  // move 2
  const offset2 = {
    x: body.position.x - body.bounds.min.x,
    y: body.position.y - body.bounds.min.y
  };
  body = setBodyPosition(body, Vector.add(body.position, offset2));

  return body;
}

export function constraintToData(constraint) {
  return {
    bodyA: constraint.bodyA ? { uuid: constraint.bodyA.uuid } : null,
    bodyB: constraint.bodyB ? { uuid: constraint.bodyB.uuid } : null,
    damping: constraint.damping,
    category: constraint.category,
    databaseIndex: constraint.databaseIndex,
    label: constraint.label,
    length: constraint.length,
    pointA: {
      x: constraint.pointA.x,
      y: constraint.pointA.y
    },
    pointB: {
      x: constraint.pointB.x,
      y: constraint.pointB.y
    },
    render: {
      type: constraint.render.type,
      visible: constraint.render.visible,
      lineWidth: constraint.render.lineWidth,
      strokeStyle: constraint.render.strokeStyle,
      anchors: constraint.render.anchors
    },
    stiffness: constraint.stiffness,
    type: constraint.type,
    uuid: constraint.uuid,
    vertical: constraint.vertical
  };
}
export function constraintFromData(data, bodies) {
  const bodyAData = getBodyAndPosition(data.bodyA, data.pointA, bodies);
  const bodyBData = getBodyAndPosition(data.bodyB, data.pointB, bodies);
  if (bodyAData == null) {
    console.error("body A is null", data.bodyA, _.cloneDeep(bodies));
    return null;
  }
  if (bodyBData == null) {
    console.error("body B is null", data.bodyB, _.cloneDeep(bodies));
    return null;
  }
  const preparedData = Object.assign({}, data, {
    bodyA: bodyAData.body,
    pointA: bodyAData.point,
    bodyB: bodyBData.body,
    pointB: bodyBData.point,
  });

  return Constraint.create(preparedData);
}

/**
 * @param {Object} constraintData
 * @param {String} bodyUuid
 * @param {Point} point
 * @param {Boolean} isBodyB
 */
export function addBodyToConstraint(constraintData, bodyUuid, point, isBodyB = false) {
  const newConstraint = _.cloneDeep(constraintData);
  if (isBodyB) {
    newConstraint.bodyB = { uuid: bodyUuid };
    newConstraint.pointB = {
      x: point.x,
      y: point.y
    }
  }
  else {
    newConstraint.bodyA = { uuid: bodyUuid };
    newConstraint.pointA = {
      x: point.x,
      y: point.y
    }
  }
  return newConstraint;
}

export function shrewBodiesTogether(constraints, bodies) {
  const newBodies = bodies.slice();

  // console.log("newBodies before", _.cloneDeep(newBodies));

  for (const constraint of constraints) {
    const bodyAData = getBodyAndPosition(constraint.bodyA, constraint.pointA, newBodies);
    const bodyBData = getBodyAndPosition(constraint.bodyB, constraint.pointB, newBodies);
    const bodyA = bodyAData.body;
    const bodyB = bodyBData.body;

    // if the two screws are already in the same body,
    // don't do anything.
    if (bodyA.id == bodyB.id) {
      continue;
    }

    // console.log(`combine ${constraint.bodyA}(${bodyA.id}) and ${constraint.bodyB}(${bodyB.id})`);
    // console.log(newBodies.map(body => ({ id: body.id, uuid: body.uuid, parts: body.parts.map(part => ({ id: part.id, uuid: part.uuid, label: part.label })) })));

    // remove the two bodies from the main body array
    const bodyAIndex = newBodies.findIndex(body => body.id == bodyA.id);
    if (bodyAIndex == -1) {
      throw new Error(`Body ${bodyA.id} not found!`);
    }
    newBodies.splice(bodyAIndex, 1);

    const bodyBIndex = newBodies.findIndex(body => body.id == bodyB.id);
    if (bodyAIndex == -1) {
      throw new Error(`Body ${bodyB.id} not found!`);
    }
    newBodies.splice(bodyBIndex, 1);

    const partsA = bodyA.parts.filter(part => part.uuid);
    const partsB = bodyB.parts.filter(part => part.uuid);
    const combinedParts = _.uniqBy(partsA.concat(partsB), part => part.uuid);
    const combinedBody = Body.create({
      parts: combinedParts,
      collisionFilter: { group: 1 }
    });

    for (const part of combinedBody.parts) {
      if (!part.uuid) continue;
      const originalPart = combinedParts.find(p => p.id == part.id);
      if (originalPart) {
        part.uuid = originalPart.uuid;
      } else {
        console.warn("no original part found");
      }
    }

    newBodies.push(combinedBody);
  }

  // console.log("newBodies after", _.cloneDeep(newBodies));

  return newBodies;
}

/**
 *
 * @param {String} bodyUuid
 * @param {Point} position
 * @param {Array} bodies
 */
function getBodyAndPosition(bodySkeleton, point, bodies) {
  const bodyUuid = bodySkeleton ? bodySkeleton.uuid : null;
  let body = bodies.find(body => body.uuid === bodyUuid);
  if (body) {
    return { body, point };
  }

  let newPoint = null;
  for (const parent of bodies) {
    if (parent.parts.length <= 1) continue;
    let part = parent.parts.find(body => body.uuid === bodyUuid);
    if (part) {
      const positionDelta = Vector.sub(part.position, parent.position);
      newPoint = Vector.add(point, positionDelta);
      body = parent;
      break;
    }
  }

  if (!newPoint) {
    return null;
  }

  return { body, point: newPoint };
}


export function createRectangleData(width, height, options) {
  return bodyToData(createRectangle(width, height, options));
}
export function createTriangleData(width, height, cornerIsRight = false, cornerIsTop = false, options) {
  return bodyToData(createTriangle(width, height, cornerIsRight, cornerIsTop, options));
}
export function createWheelData(width, options) {
  return bodyToData(createWheel(width, options));
}
export function createSlopedRectData(width, height, options = {}) {
  return bodyToData(createSlopedRect(width, height, options));
}

export function createConstraintData(length = 0, {
  vertical = false,
  bodyAUuid = null,
  bodyBUuid = null,
  pointA = { x: 0, y: 0},
  pointB = { x: 0, y: 0},
  category = -1,
  databaseIndex = -1
} = {}) {
  const constraint = createConstraint(length, vertical, null, null, pointA, pointB);
  constraint.bodyA = { uuid: bodyAUuid };
  constraint.bodyB = { uuid: bodyBUuid };
  constraint.category = category;
  constraint.databaseIndex = databaseIndex;
  return constraintToData(constraint);
}