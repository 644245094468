<template>
  <footer class="vpe-app__footer">
    <a href="https://icondu.de/impressum/" target="__blank">Impressum</a>
    <a href="https://icondu.de/datenschutz/" target="__blank">Datenschutzerklärung</a>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss">
.vpe-app__footer {
  font-size: 12px;
  background-color: $color-uielement-milestone;
  padding: 0.75em 1.5em;
  text-align: center;
  display: flex;
  justify-content: center;
  a {
    color: $color-text;
    text-decoration: none;
    margin: 0 0.5em;
  }
}
</style>