<template>
  <card
    class="premise-card"
    :disabled="true"
    @click="$emit('click', $event)"
    v-bind="{...$attrs}"
  >

  <template v-slot:default>
    <div class="premise-card__content">
      <h1>{{premise.title}}</h1>
      <!-- Rennwagen -->
      <svg v-if="premise.id=='racecar'" class="premise-card__image" id="racecar" data-name="Racecar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 160"><path d="M40,80H0L40,40Zm240,0v40h40ZM120,40H40v80h80Zm40,0V80H280ZM280,80H240v40h40ZM40,0V40h80Zm80,40v80h40V40ZM240,80H160v40h80ZM40,80H0v40H40Z" style="fill:#b3b3b3"/><circle cx="83" cy="120" r="40" style="fill:gray"/><circle cx="244" cy="120" r="40" style="fill:gray"/></g></g></svg>
      <!-- Truck -->
      <svg v-else-if="premise.id=='truck'" class="premise-card__image" id="truck" data-name="Truck" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 240"><path d="M47,60V180H7V60Zm0-40L7,60H47Zm80,80H47v80h80Zm353,80H240v20H480Zm-240,0H0v20H240ZM472,80H392v80h80ZM207,100H127v80h80Zm80,0H207v80h80Zm0-80H207v80h80ZM392,80H312v80h80Zm0-80H312V80h80ZM127,20H47v80h80Zm80,0H127v80h80ZM472,160H312v20H472ZM392,0V80h80Z" style="fill:#b3b3b3"/><circle cx="55" cy="200" r="40" style="fill:gray"/><circle cx="148.5" cy="200" r="40" style="fill:gray"/><circle cx="242" cy="200" r="40" style="fill:gray"/><circle cx="402" cy="200" r="40" style="fill:gray"/></g></g></svg>
      <!-- Lokomotive -->
      <svg v-else-if="premise.id=='train'" class="premise-card__image" id="train" data-name="Train" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 320"><path d="M120,40H40L120,0Zm0-40V40H240ZM280,160H40v20H280ZM40,180H160V140H40Zm120,0H280V140H160Zm-40,0H40v80h80Zm160,0H200v80h80Zm-80,0H120v80h80Zm160,0H280v80h80Zm80,0H360v80h80Zm-80-80H280v80h80Zm80,0H360v80h80Zm40,180-40-40v40ZM40,280V240L0,280Zm200-20H40v20H240Zm200,0H240v20H440ZM384,20v80h40V20ZM120,60H40v80h80Zm120,0H160v80h80ZM200,40H40V60H200Zm40,0H40V60H240Z" style="fill:#b3b3b3"/><circle cx="80" cy="280" r="40" style="fill:gray"/><circle cx="188.33" cy="280" r="40" style="fill:gray"/><circle cx="296.67" cy="280" r="40" style="fill:gray"/><circle cx="405" cy="280" r="40" style="fill:gray"/></g></g></svg>
      <!-- Traktor -->
      <svg v-else-if="premise.id=='tractor'" class="premise-card__image" id="tractor" data-name="Tractor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 236.95"><path d="M271,20v80H231V20ZM160,180H40v20H160ZM0,200H40V80ZM200,100H120v80h80Zm-80,0H40v80h80Zm200,40H280v40h40ZM160,0H40V20H160ZM320,180H160v20H320Zm-40-80H200v80h80ZM80,100V20H40v80Zm240,40-40-40v40ZM160,100V20H120v80Z" style="fill:#b3b3b3"/><circle cx="262.05" cy="196.95" r="40" style="fill:gray"/><circle cx="98.05" cy="176.95" r="60" style="fill:gray"/></g></g></svg>
      
      <ul class="premise-card__requirementlist">
        <li v-if="premise.axles">{{ premise.axles }} Räder</li>
        <li v-if="premise.minParts">min. {{ premise.minParts }} Teile</li>
        <li v-if="premise.maxParts">max. {{ premise.maxParts }} Teile</li>
      </ul>
    </div>
  </template>
</template>

<script>
import Card from "./Card.vue";
import Icon from "../../ui/Icon.vue";

export default {
    components: {
    Card,
    Icon
  },
  props: {
    premise: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">
.premise-card {
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3em minmax(0, 1fr) 4em;
    gap: 0.5em;
    height: 100%;
  }

  &__requirementlist {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-top:0.5em;
    font-size: 1.125em;

    li {
      padding-left: 0;
    }
  }

  &__image {
    width: 80%;
    max-height: 100%;
    justify-self: center;
  }
}
</style>