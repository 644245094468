<template>
  <span class="icondu-icon">
    <svg v-if="name == 'team'" num="1" xmlns="http://www.w3.org/2000/svg" :width="width || '123.675'" :height="height || '102.012'" viewBox="0 0 123.675 102.012">
      <path d="M79.3,16.156A20.345,20.345,0,0,1,88.76,31.27,16.406,16.406,0,1,0,79.3,16.156ZM62.657,51.722a16.409,16.409,0,1,0-16.411-16.4A16.407,16.407,0,0,0,62.657,51.722Zm6.964,7.009H55.7A21.043,21.043,0,0,0,34.679,79.744V96.776l.049.267,1.169.366a95.727,95.727,0,0,0,28.572,4.6c15.447,0,24.393-4.4,24.952-4.683l1.089-.553h.116V79.744A21.026,21.026,0,0,0,69.621,58.731Zm33.036-18.9H88.845A20.238,20.238,0,0,1,82.6,53.916a24.956,24.956,0,0,1,17.835,23.891v5.245c13.647-.5,21.509-4.361,22.022-4.623l1.094-.56h.125V60.838a21.041,21.041,0,0,0-21.018-21.01Zm-74.684-7A16.287,16.287,0,0,0,36.7,30.286,20.331,20.331,0,0,1,44.34,17.333c.013-.308.049-.607.049-.922A16.413,16.413,0,1,0,27.973,32.824ZM42.719,53.916A20.249,20.249,0,0,1,36.473,39.9c-.509-.035-1.022-.076-1.535-.076H21.013A21.031,21.031,0,0,0,0,60.838V77.869l.036.268L1.21,78.5A98.444,98.444,0,0,0,24.88,82.95V77.807A24.955,24.955,0,0,1,42.719,53.916Z"/>
    </svg>
    
    <svg v-else-if="name == 'prozess'" num="5" xmlns="http://www.w3.org/2000/svg" :width="width || '131.637'" :height="height || '128.155'" viewBox="0 0 131.637 128.155">
      <g>
        <path d="M8.058,44.331a29.672,29.672,0,0,0,2.67,6.344L7.505,54.742a3.341,3.341,0,0,0,.245,4.424l5.558,5.553a3.321,3.321,0,0,0,4.42.251l4.04-3.2a29.066,29.066,0,0,0,6.6,2.79l.6,5.2a3.333,3.333,0,0,0,3.3,2.947H40.13a3.327,3.327,0,0,0,3.3-2.947l.58-5.014a29.794,29.794,0,0,0,7.067-2.857l3.906,3.094a3.346,3.346,0,0,0,4.424-.25l5.559-5.558a3.321,3.321,0,0,0,.245-4.42L62.175,50.9a29.091,29.091,0,0,0,2.91-6.947l4.684-.54a3.333,3.333,0,0,0,2.951-3.3V32.25a3.327,3.327,0,0,0-2.951-3.3l-4.617-.54a29.362,29.362,0,0,0-2.79-6.871l2.84-3.594a3.342,3.342,0,0,0-.246-4.424l-5.545-5.54a3.325,3.325,0,0,0-4.424-.25L51.5,10.491a29.266,29.266,0,0,0-7.224-3.026L43.75,2.951A3.327,3.327,0,0,0,40.447,0H32.59a3.328,3.328,0,0,0-3.3,2.951l-.527,4.514a29.064,29.064,0,0,0-7.41,3.134L17.728,7.728a3.336,3.336,0,0,0-4.42.25L7.75,13.532a3.326,3.326,0,0,0-.245,4.424l3.026,3.83A28.757,28.757,0,0,0,7.79,28.719l-4.843.554A3.333,3.333,0,0,0,0,32.576v7.858a3.326,3.326,0,0,0,2.947,3.3ZM36.523,23.076A12.992,12.992,0,1,1,23.536,36.067,13.009,13.009,0,0,1,36.523,23.076Z"/>
        <path d="M123.149,48.505,119.006,45a3.278,3.278,0,0,0-4.371.121l-2.294,2.143a23.626,23.626,0,0,0-6.1-1.973L105.6,42.13a3.313,3.313,0,0,0-3.5-2.63l-5.406.46a3.3,3.3,0,0,0-3.018,3.17l-.1,3.214a23.715,23.715,0,0,0-5.83,3.067L85,47.594a3.29,3.29,0,0,0-4.33.621l-3.5,4.17a3.283,3.283,0,0,0,.121,4.37l2.393,2.554a24.312,24.312,0,0,0-1.759,5.843l-3.424.7a3.317,3.317,0,0,0-2.634,3.5l.46,5.408a3.3,3.3,0,0,0,3.174,3.013l3.7.121a24.277,24.277,0,0,0,2.621,5l-2.067,3.12a3.292,3.292,0,0,0,.62,4.331l4.143,3.5a3.282,3.282,0,0,0,4.375-.121l2.71-2.535A23.674,23.674,0,0,0,97.157,93l.75,3.71a3.306,3.306,0,0,0,3.5,2.634l5.411-.46a3.3,3.3,0,0,0,3.013-3.17l.117-3.634a24.55,24.55,0,0,0,5.566-2.816l2.992,1.973a3.291,3.291,0,0,0,4.33-.621l3.5-4.143a3.276,3.276,0,0,0-.12-4.37l-2.407-2.554A23.7,23.7,0,0,0,125.7,73.68l3.29-.669a3.31,3.31,0,0,0,2.634-3.5l-.464-5.411a3.29,3.29,0,0,0-3.17-3.014l-3.3-.107a23.906,23.906,0,0,0-2.75-5.419l1.8-2.715a3.268,3.268,0,0,0-.593-4.339ZM102.76,79.779a10.635,10.635,0,1,1,9.7-11.5,10.639,10.639,0,0,1-9.7,11.5Z"/>
        <path d="M29.215,98.806a3.288,3.288,0,0,0-2.947,3.236l-.04,3.331a3.287,3.287,0,0,0,2.871,3.3l2.451.317a18.909,18.909,0,0,0,1.736,4.25l-1.58,1.947a3.282,3.282,0,0,0,.2,4.37l2.331,2.384a3.281,3.281,0,0,0,4.37.3l1.96-1.513a19.132,19.132,0,0,0,4.37,1.911l.264,2.527a3.284,3.284,0,0,0,3.237,2.946l3.33.04a3.272,3.272,0,0,0,3.3-2.866l.3-2.4a19.676,19.676,0,0,0,4.737-1.844l1.843,1.487a3.28,3.28,0,0,0,4.371-.2l2.38-2.326a3.283,3.283,0,0,0,.3-4.37L67.6,113.81a19.1,19.1,0,0,0,2-4.607l2.183-.223a3.283,3.283,0,0,0,2.946-3.241l.041-3.326a3.278,3.278,0,0,0-2.871-3.3l-2.129-.277a19.741,19.741,0,0,0-1.8-4.607L69.3,92.6a3.284,3.284,0,0,0-.2-4.375l-2.33-2.38a3.289,3.289,0,0,0-4.371-.3L60.8,86.761a19.414,19.414,0,0,0-4.79-2.08l-.21-2.063a3.284,3.284,0,0,0-3.236-2.951l-3.331-.04a3.288,3.288,0,0,0-3.3,2.87l-.263,2.054a19.865,19.865,0,0,0-4.96,2.027l-1.647-1.344a3.28,3.28,0,0,0-4.371.2L32.3,87.774a3.288,3.288,0,0,0-.3,4.371l1.411,1.817a19.1,19.1,0,0,0-1.884,4.607Zm21.5-3.764a8.662,8.662,0,1,1-8.768,8.554,8.667,8.667,0,0,1,8.768-8.554Z"/>
      </g>
    </svg>

    <svg v-else-if="name == 'innovation'" num="6" xmlns="http://www.w3.org/2000/svg" :width="width || '81.106'" :height="height || '118.569'" viewBox="0 0 81.106 118.569">
      <path d="M40.505,0A40.525,40.525,0,0,0,0,40.5c0,11.128,3.616,17.57,6.837,23.131,2.636,4.587,4.679,8.2,5.464,15.223a14.457,14.457,0,0,0,1.072,4.491,12.917,12.917,0,0,0-.779,4.3,10.922,10.922,0,0,0,.975,4.681A12.572,12.572,0,0,0,12.594,97a12.214,12.214,0,0,0,7.027,11.029,15.36,15.36,0,0,0,14.636,10.538H46.844a15.453,15.453,0,0,0,14.641-10.538A12.143,12.143,0,0,0,68.512,97a10.876,10.876,0,0,0-.976-4.685,11.954,11.954,0,0,0,.2-8.976A10.2,10.2,0,0,0,68.8,78.85c.783-7.027,2.837-10.636,5.469-15.223,3.22-5.661,6.837-12,6.837-23.227A40.66,40.66,0,0,0,40.505,0ZM56.311,102.355a9.066,9.066,0,0,1-2.764,3.219c-2.12,2.051-7.116,3.486-12.942,3.486s-10.822-1.433-12.942-3.484a8.529,8.529,0,0,1-2.08-2.23,16.046,16.046,0,0,1-1.146-2.212H56.782A11.1,11.1,0,0,1,56.311,102.355Zm1.831-13.271a2.338,2.338,0,0,1-1.961,1.11H25.08a2.362,2.362,0,0,1-1.694-.715A4.494,4.494,0,0,1,21.723,85.9a4.033,4.033,0,0,1,3.708-4.292H55.672a4.033,4.033,0,0,1,3.71,4.292A4.577,4.577,0,0,1,58.142,89.084Zm6.564-30.823A51.688,51.688,0,0,0,59.232,70.7H21.768A51.584,51.584,0,0,0,16.3,58.354c-2.924-5.073-5.362-9.466-5.362-17.759a29.57,29.57,0,0,1,59.139-.1C70.074,48.791,67.536,53.188,64.706,58.261Z"/>
    </svg>

    <svg v-else-if="name == 'auge'" num="9" xmlns="http://www.w3.org/2000/svg" :width="width || '170.896'" :height="height || '103.828'" viewBox="0 0 170.896 103.828">
      <g>
        <path d="M78.448,52.745a12.79,12.79,0,1,1,12.79-12.79,12.785,12.785,0,0,1-12.79,12.79m7-32.215a31.383,31.383,0,1,0,31.385,31.385A31.384,31.384,0,0,0,85.443,20.53"/>
        <path d="M12.066,51.582A135.79,135.79,0,0,0,31.35,71.1a106.93,106.93,0,0,0,24.1,15.189,74.308,74.308,0,0,0,30.33,6.762,73.636,73.636,0,0,0,30.207-6.74A104.536,104.536,0,0,0,139.84,71.174a132.513,132.513,0,0,0,19-19.482A116.372,116.372,0,0,0,140.8,32.482a97.41,97.41,0,0,0-23.673-14.991,77.263,77.263,0,0,0-62.985,0A99.18,99.18,0,0,0,30.287,32.46,118.04,118.04,0,0,0,12.066,51.582M85.8,103.828a84.925,84.925,0,0,1-34.886-7.777A117.713,117.713,0,0,1,24.276,79.223,137.187,137.187,0,0,1,.971,54.79a5.4,5.4,0,0,1-.118-6A120.2,120.2,0,0,1,23.116,24.42,110.209,110.209,0,0,1,49.659,7.689a88.063,88.063,0,0,1,72.022.021A108.91,108.91,0,0,1,148.061,24.5a118.976,118.976,0,0,1,22.008,24.438,5.39,5.39,0,0,1-.118,5.918,134.751,134.751,0,0,1-22.958,24.407,115.458,115.458,0,0,1-26.406,16.811,84.43,84.43,0,0,1-34.79,7.759"/>
      </g>
    </svg>

    <svg v-else-if="name == 'daten'" num="12" xmlns="http://www.w3.org/2000/svg" :width="width || '79.923'" :height="height || '109.869'" viewBox="0 0 79.923 109.869">
      <g>
        <ellipse cx="39.961" cy="15.619" rx="39.961" ry="15.619"/>
        <path d="M39.961,43.4C18.735,43.4,1.422,36.923.126,28.754A6.328,6.328,0,0,0,0,29.73V41.846c0,8.626,17.891,15.619,39.961,15.619s39.962-6.993,39.962-15.619V29.73a6.328,6.328,0,0,0-.126-.976C78.5,36.923,61.188,43.4,39.961,43.4Z"/>
        <path d="M39.961,69.6C18.735,69.6,1.422,63.125.126,54.956A6.312,6.312,0,0,0,0,55.932V68.048c0,8.626,17.891,15.619,39.961,15.619s39.962-6.993,39.962-15.619V55.932a6.312,6.312,0,0,0-.126-.976C78.5,63.125,61.188,69.6,39.961,69.6Z"/>
        <path d="M39.961,95.8C18.735,95.8,1.422,89.327.126,81.158A6.3,6.3,0,0,0,0,82.134V94.25c0,8.626,17.891,15.619,39.961,15.619s39.962-6.993,39.962-15.619V82.134a6.3,6.3,0,0,0-.126-.976C78.5,89.327,61.188,95.8,39.961,95.8Z"/>
      </g>
    </svg>



    <svg v-else-if="name == 'kreislauf'" num="17"  xmlns="http://www.w3.org/2000/svg" :width="width || '133.859'" :height="height || '116.005'" viewBox="0 0 133.859 116.005">
      <g>
        <path d="M133.551,60.79,120.2,36.716a2.421,2.421,0,0,0-4.193-.073L101.832,60.237a2.421,2.421,0,0,0,2.033,3.668l6.213.109a43.534,43.534,0,0,1-43.91,37.577A43.681,43.681,0,0,1,38.834,91.34l-.018-.015a2.432,2.432,0,0,0-3.35.17l-6.557,6.973a2.423,2.423,0,0,0,.175,3.492q.228.2.457.39A58.137,58.137,0,0,0,65.917,116c.348.007.692.009,1.04.009A58.01,58.01,0,0,0,124.6,64.267l6.795.119A2.422,2.422,0,0,0,133.551,60.79Z"/>
        <path d="M29.994,52.1,23.76,52a43.587,43.587,0,0,1,70.754-27.75,2.4,2.4,0,0,0,3.292-.266l6.44-7.09a2.437,2.437,0,0,0-.274-3.519,58,58,0,0,0-94.7,38.372l-6.8-.118a2.421,2.421,0,0,0-2.16,3.595L13.655,79.293a2.421,2.421,0,0,0,4.193.073l14.18-23.593A2.422,2.422,0,0,0,29.994,52.1Z"/>
      </g>
    </svg>

    <svg v-else-if="name == 'auto'" num="23" xmlns="http://www.w3.org/2000/svg" :width="width || '117.154'" :height="height || '104.022'" viewBox="0 0 117.154 104.022">
      <path d="M19.639,91.775c-.747,5.167.519,11.033-6.881,12C3.8,104.94.285,102.125.244,93.03.18,78.709-.4,64.348.491,50.082c.446-7.162,3.471-14.208,5.655-21.2,2.128-6.818,4.8-13.464,6.994-20.265C15.019,2.78,18.71-.024,24.9,0,47.541.089,70.178.056,92.815.024c5.514-.007,9.176,2.382,10.993,7.677,4.114,11.993,8.919,23.794,12.224,36,1.7,6.279.94,13.263,1.022,19.932.129,10.393.017,20.788.027,31.183.006,5.842-2.475,9.036-8.722,9.035-5.669,0-10.413-.829-10.237-7.978.084-3.38-.911-5.094-4.668-5.074-23.79.127-47.581.108-71.371.172A10.242,10.242,0,0,0,19.639,91.775ZM12.975,38.6h91.361c-3.06-9.242-5.787-18-8.984-26.575-.48-1.29-3.145-2.492-4.809-2.5q-31.863-.252-63.727,0c-1.675.013-4.363,1.184-4.841,2.465C18.776,20.568,16.048,29.323,12.975,38.6ZM23.062,71.517a10.157,10.157,0,0,0,9.7-9.853,9.847,9.847,0,0,0-10.426-9.686,9.488,9.488,0,0,0-9.2,10.067A9.8,9.8,0,0,0,23.062,71.517Zm71.668.028a9.915,9.915,0,0,0,9.477-9.979,9.5,9.5,0,0,0-10.4-9.551A9.684,9.684,0,0,0,84.6,62.141,10.063,10.063,0,0,0,94.73,71.545Z"/>
    </svg>


    <svg v-else-if="name == 'person'" num="26" xmlns="http://www.w3.org/2000/svg" :width="width || '68.049'" :height="height || '98.748'" viewBox="0 0 68.049 98.748">
      <path d="M34.03,39.916A19.958,19.958,0,1,0,14.069,19.964,19.956,19.956,0,0,0,34.03,39.916ZM42.5,46.105H25.564A25.6,25.6,0,0,0,0,71.663V92.379l.06.326,1.422.445a116.45,116.45,0,0,0,34.753,5.6c18.787,0,29.669-5.348,30.348-5.7l1.325-.673h.141V71.663A25.574,25.574,0,0,0,42.5,46.105Z"/>
    </svg>

    <svg v-else-if="name == 'sprechblasen'" num="31" xmlns="http://www.w3.org/2000/svg" :width="width || '140.407'" :height="height || '104.155'" viewBox="0 0 140.407 104.155">
      <g>
        <path d="M102.58,37.834c-20.891,0-37.826,5.045-37.826,26.935,0,20.666,16.935,26.561,37.826,26.561,1.306,0,2.6-.452,3.867-.452h.01c1.592,0,25.686,13.1,28.913,13.1.077,0,.141.188.194.174,2.48-.695-9.465-16.264-7.378-17.373a25.235,25.235,0,0,0,3.319-2.049c5.554-4.159,8.9-10.51,8.9-19.982C140.407,44.137,123.471,37.834,102.58,37.834Z"/>
        <path d="M57.245,68.406q-1.845.06-3.806.062c-1.592,0-3.167-.027-4.715-.084l-.208,0c-2.262,0-3.422.64-15.531,8.558-3.054,2-7.122,4.658-10.755,6.933.2-.535.382-1.043.553-1.507,3.92-10.656,6.08-16.528.642-19.418a26.965,26.965,0,0,1-3.517-2.217c-6.162-4.615-9.158-11.522-9.158-21.119,0-7.137,0-28.855,42.689-28.855,24.911,0,37.923,5.988,41.586,19.661,2.39-.159,4.879-.261,7.555-.261,1.172,0,2.308.022,3.421.059C100.106.022,65.513,0,53.439,0,9.271,0,0,21.53,0,39.6,0,52.632,4.531,62.619,13.464,69.307c.636.477,1.3.914,1.987,1.36-.655,2.219-1.868,5.467-2.756,7.883C9.2,88.053,7.727,92.3,9.469,95.7a5.314,5.314,0,0,0,3.712,2.607c.526.147,1.078-.433,1.64-.433h.005c3.146,0,7.191-1.577,24.043-12.6,3.923-2.566,8.692-5.148,10.646-6.236,1.3.035,2.6.112,3.924.112,2.236,0,4.36-.039,6.42-.147A33.563,33.563,0,0,1,57.245,68.406Z"/>
      </g>
    </svg>

    <svg v-else-if="name == 'richtig'" num="32" xmlns="http://www.w3.org/2000/svg" :width="width || '107.044'" :height="height || '84.897'" viewBox="0 0 107.044 84.897">
      <path d="M102.527,3.065a12.83,12.83,0,0,0-18.1,1.464L42.268,53.953,21.179,35.829a12.832,12.832,0,1,0-16.7,19.484L35.3,81.8a12.888,12.888,0,0,0,8.384,3.1c.33,0,.653,0,1-.025a12.947,12.947,0,0,0,8.771-4.465l50.533-59.246a12.85,12.85,0,0,0-1.464-18.1Z"/>
    </svg>

    <svg v-else-if="name == 'falsch'" num="33" xmlns="http://www.w3.org/2000/svg" :width="width || '86.463'" :height="height || '86.475'" viewBox="0 0 86.463 86.475">
      <path d="M11.687,86.475a11.616,11.616,0,0,0,8.268-3.425L43.231,59.773,66.508,83.051A11.693,11.693,0,0,0,83.043,66.514L59.767,43.236,83.044,19.962A11.693,11.693,0,1,0,66.508,3.426L43.231,26.7,19.955,3.425A11.693,11.693,0,0,0,3.419,19.962L26.7,43.236,3.419,66.514a11.693,11.693,0,0,0,8.268,19.961Z"/>
    </svg>

    <svg v-else-if="name == 'dokument'" num="34" xmlns="http://www.w3.org/2000/svg" :width="width || '109.922'" :height="height || '113.526'" viewBox="0 0 109.922 113.526">
      <g>
        <path d="M36.732,94.586H16.282a7.393,7.393,0,0,1-7.148-7.63V17.607A7.364,7.364,0,0,1,16.282,10H65.773a7.333,7.333,0,0,1,7.094,7.607v58.11l9.138-11.888V10.183C82.005,4.554,77.947,0,72.818,0H8.871C3.942,0,0,4.368,0,9.757V94.642c0,5.627,4.112,10.185,9.264,10.185h35.9l-4.254-4.018a17.715,17.715,0,0,1-4.175-6.223Z"/>
        <path d="M61.97,19.728H20.92a4.569,4.569,0,0,0,0,9.138H61.97a4.569,4.569,0,0,0,0-9.138Z"/>
        <path d="M61.97,38.34H20.92a4.56,4.56,0,0,0,0,9.12H61.97a4.56,4.56,0,0,0,0-9.12Z"/>
        <path d="M61.97,56.934H20.92a4.452,4.452,0,1,0,0,8.9H61.97a4.452,4.452,0,1,0,0-8.9Z"/>
        <path d="M16.344,79.3a4.489,4.489,0,0,0,4.576,4.453h15.3A18.176,18.176,0,0,1,39.2,78.624a16.248,16.248,0,0,1,4.893-3.793H20.92A4.5,4.5,0,0,0,16.344,79.3Z"/>
        <path d="M107.113,62.63a7.98,7.98,0,0,0-11.259.911L69.635,94.28,56.518,83.008A7.981,7.981,0,1,0,46.13,95.126L65.3,111.6a8.017,8.017,0,0,0,5.214,1.929c.206,0,.406,0,.625-.016a8.051,8.051,0,0,0,5.456-2.777l31.428-36.848a7.992,7.992,0,0,0-.91-11.255Z"/>
      </g>
    </svg>

    <svg v-else-if="name == 'notizen'" num="37" xmlns="http://www.w3.org/2000/svg" :width="width || '93'" :height="height || '109'" viewBox="0 0 93 109">
      <g>
        <path d="M9,9.05v90.9A8.991,8.991,0,0,0,17.931,109H84.069A8.991,8.991,0,0,0,93,99.95V9.05A8.991,8.991,0,0,0,84.069,0H17.931A8.991,8.991,0,0,0,9,9.05ZM73.826,98.619H28.174a8.991,8.991,0,0,1-8.93-9.05V19.431a8.991,8.991,0,0,1,8.93-9.05H73.826a8.991,8.991,0,0,1,8.93,9.05V89.569A8.991,8.991,0,0,1,73.826,98.619Z"/>
        <path d="M32.509,21.345H72.491A3.509,3.509,0,0,1,76,24.854v1.982a3.509,3.509,0,0,1-3.509,3.509H32.508A3.508,3.508,0,0,1,29,26.836V24.854A3.509,3.509,0,0,1,32.509,21.345Z"/>
        <path d="M32.509,40.345H72.492A3.508,3.508,0,0,1,76,43.853v1.983a3.509,3.509,0,0,1-3.509,3.509H32.508A3.508,3.508,0,0,1,29,45.836V43.854a3.509,3.509,0,0,1,3.509-3.509Z"/>
        <rect x="29" y="59.345" width="47" height="9" rx="3.509"/>
        <path d="M32.509,78.345H72.492A3.508,3.508,0,0,1,76,81.853v1.983a3.509,3.509,0,0,1-3.509,3.509H32.508A3.508,3.508,0,0,1,29,83.836V81.854A3.509,3.509,0,0,1,32.509,78.345Z"/>
        <rect y="26.345" width="23" height="13" rx="3.879"/>
        <rect y="69.345" width="23" height="13" rx="3.879"/>
      </g>
    </svg>


    <svg v-else-if="name == 'bildschirm'" num="38" xmlns="http://www.w3.org/2000/svg" :width="width || '103.416'" :height="height || '102.194'" viewBox="0 0 103.416 102.194">
      <path d="M92.446,0H10.528A10.192,10.192,0,0,0,.009,10.376q-.018,30.381,0,60.761a11.231,11.231,0,0,0,.312,2.781,10.369,10.369,0,0,0,10.458,7.823q14.739.02,29.482-.022c.967,0,1.136.275,1.122,1.146q-.087,4.005,0,8.012c.02.856-.209,1.089-1.091,1.085-6.212-.04-12.424,0-18.628-.04-.778,0-1.007.171-.992.964.054,2.775.05,5.544,0,8.323-.015.754.146.991.961.985,4.985-.042,9.969-.022,14.952-.022q22.476,0,44.96.017c.9,0,1.264-.119,1.23-1.142-.085-2.661-.076-5.34,0-8.007.029-.947-.258-1.127-1.16-1.109-6.176.042-12.344,0-18.529.042-.849,0-1.051-.235-1.035-1.04.049-2.7.056-5.409,0-8.112-.018-.845.168-1.091,1.071-1.089q14.686.056,29.376.02c6.423,0,10.916-4.446,10.918-10.788V10.83C103.389,4.331,99,0,92.446,0Zm2.633,68.606a5.258,5.258,0,0,1-5.257,5.258H13.371a5.258,5.258,0,0,1-5.257-5.258V13.948a5.257,5.257,0,0,1,5.257-5.257H89.822a5.257,5.257,0,0,1,5.257,5.257Z"/>
    </svg>

    <svg v-else-if="name == 'dokument_stift'" num="42" xmlns="http://www.w3.org/2000/svg" :width="width || '113.07'" :height="height || '109'" viewBox="0 0 113.07 109">
      <g>
        <path d="M0,9.05v90.9A8.991,8.991,0,0,0,8.931,109H75.069A8.991,8.991,0,0,0,84,99.95V9.05A8.991,8.991,0,0,0,75.069,0H8.931A8.991,8.991,0,0,0,0,9.05ZM64.826,98.619H19.174a8.991,8.991,0,0,1-8.93-9.05V19.431a8.991,8.991,0,0,1,8.93-9.05H64.826a8.991,8.991,0,0,1,8.93,9.05V89.569A8.991,8.991,0,0,1,64.826,98.619Z"/>
        <g>
          <rect x="68.822" y="32.614" :width="width || '19.739'" :height="height || '46.482'" transform="translate(69.865 -40.24) rotate(49.385)"/>
          <polygon points="47.9 82.261 51.798 65.916 64.647 80.9 47.9 82.261"/>
          <path d="M104.255,25.968h1.032a9.353,9.353,0,0,1,9.353,9.353v5.691a0,0,0,0,1,0,0H94.9a0,0,0,0,1,0,0V35.322a9.353,9.353,0,0,1,9.353-9.353Z" transform="translate(61.991 -67.842) rotate(49.385)"/>
        </g>
      </g>
    </svg>

    <svg v-else-if="name == 'download'" num="45" xmlns="http://www.w3.org/2000/svg" :width="width || '84.985'" :height="height || '101.011'" viewBox="0 0 84.985 101.011">
      <g>
        <path d="M84.973,50.637c-.166-1.714-1.891-4.956-7.231-4.956-5.448,0-7.016,3.336-7.084,5.1l0,30.5A5.587,5.587,0,0,1,65.022,86.8H19.963a5.585,5.585,0,0,1-5.633-5.525v-30.4l-.018-.3c-.218-1.82-2-4.9-7.309-4.9-5.539,0-7,3.4-7,5.2V90.485a10.591,10.591,0,0,0,10.633,10.526H74.352A10.591,10.591,0,0,0,84.985,90.485V50.879Z"/>
        <path d="M21.509,34.445c0-.479.222-1.581,2.228-1.571l9.2.042.14-30.471a2.593,2.593,0,0,1-.028-.329A1.935,1.935,0,0,1,33.645.709,3.077,3.077,0,0,1,35.858,0L50.014.066A2.21,2.21,0,0,1,52.477,2.2l-.14,30.164,8.437.037c2.356.01,2.706,1.133,2.7,1.8a3.956,3.956,0,0,1-1.237,2.341c-.539.732-15.259,20.07-17.4,22.19a2.722,2.722,0,0,1-2.077.881,3.272,3.272,0,0,1-2.174-1.127L22.826,36.826a4.016,4.016,0,0,1-1.317-2.381Z"/>
      </g>
    </svg>

    <svg v-else-if="name == 'sanduhr'" num="45" xmlns="http://www.w3.org/2000/svg" :width="width || '80.237'" :height="height || '103.765'" viewBox="0 0 80.237 103.765">
      <g>
        <rect width="80.237" height="13.577" rx="6.789"/>
        <rect y="90.188" width="80.237" height="13.577" rx="6.789"/>
        <path d="M13.5,100.2a4.45,4.45,0,0,1-3.476-1.664c-.4-.5-3.928-5.171-3.094-13.971.929-9.8,6.741-20.813,17.293-32.787-6.583-7.525-21.5-27.241-16.113-46.2a4.456,4.456,0,0,1,8.574,2.433c-2.685,9.459,1.882,19.868,6.187,26.935A79.634,79.634,0,0,0,33.4,48.579a4.456,4.456,0,0,1,.121,6.213C9.207,80.773,16.877,92.84,16.966,92.95A4.457,4.457,0,0,1,13.5,100.2Z"/>
        <path d="M69.6,98.534,62.652,92.95l-.08.1c.083-.118,8.025-12.082-16.474-38.262a4.456,4.456,0,0,1,.121-6.213c.218-.216,21.97-22.077,16.719-40.574a4.456,4.456,0,0,1,8.574-2.433c5.384,18.963-9.53,38.679-16.113,46.2C65.951,63.75,71.764,74.766,72.693,84.563,73.527,93.363,70,98.031,69.6,98.534Z"/>
        <path d="M49.858,34.83a1.323,1.323,0,0,1,1.065,2.11c-2.617,3.521-7.962,10.214-10.8,10.214s-8.188-6.693-10.8-10.214a1.322,1.322,0,0,1,1.065-2.11Z"/>
        <path d="M21.109,86.321c0-14.428,19.009-28.857,19.009-28.857S59.127,71.373,59.127,86.321Z"/>
      </g>
    </svg>


    <svg v-else-if="name == 'wiederholung'" num="48" xmlns="http://www.w3.org/2000/svg" :width="width || '98.449'" :height="height || '99.48'" viewBox="0 0 98.449 99.48">
      <path d="M97.153,39.058A48.923,48.923,0,0,0,81.087,12.787l6.182-9.95A1.857,1.857,0,0,0,85.549.006L52,2.579a1.856,1.856,0,0,0-1.581,2.544l12.559,31.22a1.857,1.857,0,0,0,3.3.287l6.96-11.2a34.545,34.545,0,1,1-33.58-8.381,1.853,1.853,0,0,0,1.3-2.2L38.459,4.174a1.864,1.864,0,0,0-2.311-1.369A49.219,49.219,0,1,0,97.153,39.058Z"/>
    </svg>

    <svg v-else-if="name == 'schritt'" num="55" xmlns="http://www.w3.org/2000/svg" :width="width || '96.459'" :height="height || '138.345'" viewBox="0 0 96.459 138.345">
      <g>
        <g>
          <path d="M65.576,100.5l21.287,3.821s6.819-4.634,9.337-35.374S82.054,33.722,82.054,33.722s-11.813-3.365-17.692,10.6c0,0-7.052,16.7-5.684,28.819a33.417,33.417,0,0,0,4.178,12.389C64.617,88.489,65.207,94.859,65.576,100.5Z"/>
          <path d="M64.921,106.706,86.13,110.6s1.414,29.118-15.9,27.7C70.225,138.295,51.4,134.883,64.921,106.706Z"/>
        </g>
        <g>
          <path d="M36,64.966,15.383,71.51S8.023,67.8,1.547,37.641,11.016.885,11.016.885,22.294-3.98,29.93,9.1c0,0,9.154,15.642,9.366,27.841A33.4,33.4,0,0,1,36.757,49.77C35.4,52.935,35.634,59.327,36,64.966Z"/>
          <path d="M37.451,71.037l-20.528,6.6S19.29,106.7,36.279,103.047C36.279,103.047,54.508,97.228,37.451,71.037Z"/>
        </g>
      </g>
    </svg>


    <svg v-else-if="name == 'pfeil'" xmlns="http://www.w3.org/2000/svg" :width="width || '115.527'" :height="height || '81.371'" viewBox="0 0 115.527 81.371">
      <path d="M66.9,81.371c-.919,0-3.063-.413-3.06-4.306V59.219H4.761a4.371,4.371,0,0,1-.635.051A3.738,3.738,0,0,1,1.4,58.137,5.884,5.884,0,0,1,0,53.854L0,26.408c0-3.793,2.694-4.8,4.115-4.8H62.6l0-16.358C62.6.686,64.766,0,66.071,0c2.078,0,4.158,1.981,4.545,2.381,1.415,1.035,39.047,29.411,43.176,33.54a5.284,5.284,0,0,1,1.731,4.009,6.38,6.38,0,0,1-2.167,4.232L71.515,78.8c-.46.5-2.587,2.574-4.612,2.574Z"/>
    </svg>

    <svg v-else-if="name == 'zahnrad'" num="xx" xmlns="http://www.w3.org/2000/svg" :width="width || '109.63'" :height="height || '109.57'" viewBox="0 0 17.963 17.957">
      <path d="M27.333,86.724a1.217,1.217,0,0,0-1.091,1.2l-.015,1.233a1.216,1.216,0,0,0,1.062,1.221l.907.117a7,7,0,0,0,.642,1.573l-.585.72a1.215,1.215,0,0,0,.074,1.617l.863.882a1.214,1.214,0,0,0,1.617.111l.725-.56a7.08,7.08,0,0,0,1.617.707l.1.935a1.215,1.215,0,0,0,1.2,1.09l1.232.015A1.211,1.211,0,0,0,36.9,96.523l.111-.888a7.281,7.281,0,0,0,1.753-.682l.682.55a1.214,1.214,0,0,0,1.617-.074l.881-.861a1.215,1.215,0,0,0,.111-1.617l-.518-.675a7.068,7.068,0,0,0,.74-1.7l.808-.083a1.215,1.215,0,0,0,1.09-1.2l.015-1.231a1.213,1.213,0,0,0-1.062-1.221l-.788-.1a7.3,7.3,0,0,0-.666-1.7l.492-.6a1.215,1.215,0,0,0-.074-1.619l-.862-.881a1.217,1.217,0,0,0-1.617-.111l-.592.45a7.183,7.183,0,0,0-1.773-.77l-.078-.763a1.215,1.215,0,0,0-1.2-1.092l-1.233-.015a1.217,1.217,0,0,0-1.221,1.062l-.1.76a7.351,7.351,0,0,0-1.835.75l-.609-.5a1.214,1.214,0,0,0-1.617.074l-.885.866a1.217,1.217,0,0,0-.111,1.617l.522.672a7.068,7.068,0,0,0-.7,1.7Zm7.956-1.393A3.205,3.205,0,1,1,32.045,88.5,3.205,3.205,0,0,1,35.289,85.331Z" transform="translate(-26.228 -79.627)"/>
    </svg>


  </span>
</template>

<script>
// Das Icon nimmt den Namen, der in der iCONDU-Übersicht genannt ist,
// Nur ohne den Zahlenvorsatz. Und in Kleinbuchstaben. 
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.icondu-icon {
  display: inline-block;
  svg {
    max-width: 100%;
    max-height: 100%;
    fill: currentColor;
  }
}
</style>