<template>
  <div class="player-overview">
    <h4>Anforderungen</h4>
    <ul>
      <li v-for="requirement in player.requirements" :key="requirement.id">
        {{ requirement }}
      </li>
    </ul>

    <h4>Methoden</h4>
    <ul>
      <li v-for="method in player.methods" :key="method.id">
        {{ method }}
      </li>
    </ul>

    <h4>Daten</h4>
    <ul>
      <li v-for="(data, category) in player.partRequests" :key="category">
        {{ data }}
      </li>
    </ul>

    <h4>Erspielte Daten</h4>
    <ul>
      <li v-for="part in player.parts" :key="part.id">
        {{ part.label }} <span v-if="part.type == 'body'">({{ part.bounds.max.x - part.bounds.min.x }}x{{ part.bounds.max.y - part.bounds.min.y }})</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    player: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.player-overview {
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0,0,0,0.1);
}
</style>