<template>
  <div class="match-results-page">
    <section class="match-results-page__content">
      <header class="match-results-page__header" v-if="isLoggedIn">
        <router-link class="button button--primary" :to="{ name: 'matches' }">Zurück zur Spielübersicht</router-link>
      </header>

      <h2>Team-Bewertung</h2>
      <div class="match-results-page__quizbar">
        <strong>Quiz:</strong>
        <quiz-progress-bar
          :answered-quiz-questions="answeredQuizQuestions"
          :player-amount="playerAmount"
        ></quiz-progress-bar>
      </div>
      <team-result
        :quiz-points="quizPoints"
        :players="players"
      ></team-result>

      <h2>Spieler-Bewertung</h2>
      <div class="results-screen__players">
        <player-result
          v-for="player in players"
          :key="player.id"
          :player="player"
          :player-name="player.name"
        ></player-result>
      </div>
    </section>

    <game-footer></game-footer>
  </div>
</template>

<script>
import { get } from "../services/api.js";
import QuizProgressBar from "../components/game/UIElements/QuizProgressBar.vue";
import PlayerResult from "../components/game/Screens/ResultsScreen/PlayerResult.vue";
import TeamResult from "../components/game/Screens/ResultsScreen/TeamResult.vue";
import GameFooter from "../components/ui/GameFooter.vue";
export default {
  components: {
    PlayerResult,
    TeamResult,
    QuizProgressBar,
    GameFooter
  },
  props: {
    matchId: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      players: {},
      answeredQuizQuestions: [],
      quizPoints: 0,
      playerNames: {},
      isLoggedIn: false
    };
  },
  computed: {
    playerAmount() {
      return Object.values(this.players).length;
    }
  },
  async created() {
    this.isLoggedIn = localStorage.getItem("token") != null;

    try {
      const result = await get(`/matchresults/${ this.matchId }`);
      const players = result.data.players.reduce((obj, p) => {obj[p.id] = p; return obj;}, {});
      this.players = players;
      this.answeredQuizQuestions = result.data.answeredQuizQuestions;
      this.quizPoints = result.data.quizPoints;

      console.log(players);
    } catch (error) {
      console.error(error);
    }
  }
}
</script>

<style lang="scss">
.match-results-page {
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: #eee;

  &__content {
    padding: 2rem;
  }

  &__players {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    overflow: auto;
  }

  &__quizbar {
    max-width: 36rem;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 0.5rem;
    align-items: center;
  }

  h2, h3 {
    color: $color-text;
  }

  h2 {
    margin-top: 1.25em;
  }
}
</style>