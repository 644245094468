<template>
  <div class="team-result">

    <div class="team-result__part">
      <h3>Spieler</h3>
      <div class="team-result__point">
        {{ addSign(playersTotal) }} Pkt
      </div>
    </div>

    <div class="team-result__part">
      <h3>Quiz</h3>
      <div class="team-result__point">
        {{ addSign(quizPoints) }} Pkt
      </div>
    </div>

    <div class="team-result__total">
      <h3>Gesamtbewertung</h3>
      <div class="team-result__point">
        {{ addSign(total) }} Pkt
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quizPoints: {
      type: Number,
      default: 0
    },
    players: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    playersTotal() {
      return Object.values(this.players).reduce((sum, player) => {
        return sum + player.points.total.sum;
      }, 0);
    },
    total() {
      return this.playersTotal + this.quizPoints;
    }
  },
  methods: {
    addSign(num) {
      if (num < 0.001) return (num).toString();
      return `+${num}`;
    }
  }
}
</script>

<style lang="scss">
.team-result {
  border: 1px solid $color-primary;
  box-shadow: 1px 2px 5px rgba(0,0,0,0.2);
  display: flex;
  background-color: #fff;
  margin-bottom: 1rem;

  &__part,
  &__total {
    width: 100%;
    text-align: center;
    padding: 1rem;
    border: 1px solid $color-primary;
  }

  &__total {
    color: $color-primary;
    background-color: #EDFBFD;
  }

  &__point {
    padding: 0.75rem 2rem;
    max-width: 15rem;
    font-weight: bold;
    border: 2px solid $color-primary;
    color: $color-primary;
    background-color: rgba($color-primary, 0.2);
    margin: 0 auto;
  }
}
</style>