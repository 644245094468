<template>
  <game-dialog>
    <div v-if="!isActivePlayer" class="text-center">
      {{ playerNames[activePlayer.id] }} ist am Zug.
    </div>

    <div class="column" v-if="isActivePlayer || canEscalate">
      <button-active-player
        @click="moves.updateCurrentMove({
          confirmedTurn: true
        })"
      >
        Spielfeld wählen
      </button-active-player>

      <button-active-player
        v-if="canEscalate"
        @click="moves.updateEscalation({
          useEscalation: true
        })"
      >
        {{ gameState.currentEscalation.label }}
      </button-active-player>

      <button-active-player
        type="secondary"
        @click="moves.endTurn()"
      >
        Zug überspringen
      </button-active-player>
    </div>
  </game-dialog>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";

import GameDialog from "../UIElements/GameDialog.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
export default {
  mixins: [GameComponentMixin],
  components: {
    GameDialog,
    ButtonActivePlayer
  },
  computed: {
    canEscalate() {
      return (
        this.gameState.currentEscalation &&
        this.gameState.currentEscalation.id
      );
    }
  }
}
</script>

<style>

</style>