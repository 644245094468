<template>
  <div class="player-result" :class="[`-is-player-${player.id}`]">
    <div class="player-result__namearea">
      <span class="player-result__name">{{ playerName }}</span>
    </div>

    <div class="player-result__resultarea">
      <h3 class="player-result__subtitle">
        Gesamt
      </h3>
      <div class="player-result__total">
        {{ total }} Pkt
      </div>
    </div>

    <div class="player-result__cararea">
      <div class="player-result__premisearea">
        <h3 class="player-result__subtitle player-result__title-premise">
          Produktziel
        </h3>
        <premise-card :premise="player.premise" :small="true"></premise-card>
      </div>
      <div class="player-result__carrow">
        <car class="player-result__car" :parts="playerBuiltParts"></car>
      </div>
      <div class="player-result__point player-result__gridspan2" :class="{
        '-succeeded': premisePoints > 0,
        '-failed': premisePoints <= 0
      }">
        +{{ premisePoints }} Pkt
      </div>
    </div>

    <h3 class="player-result__subtitle">
      Anforderungen
    </h3>
    <div class="player-result__requirementsboard">

      <icon class="player-result__icon" name="dokument"></icon>
      <article
        v-for="requirement in player.requirements"
        :key="requirement.id"
        class="player-result__requirement"
      >
        <div class="">{{ requirement.label }}</div>
        <div class="">{{ requirement.value }}{{ requirement.unit }}</div>
      </article>

      <icon class="player-result__icon" name="prozess"></icon>
      <article
        v-for="requirement in player.requirements"
        :key="`result-${requirement.id}`"
        class="player-result__simulationresult"
      >
        <div class="">{{ player.simulationResults[requirement.type] == null ? "— " : player.simulationResults[requirement.type] }}{{ requirement.unit }}</div>
      </article>

      <div></div>
      <div
        v-for="(point, key) in player.points.requirements"
        :key="key"
        class="player-result__requirementpoint"
        :class="{
          '-succeeded': point == 20,
          '-passed': point < 20 && point > 0,
          '-failed': point == 0
        }"
      >
        +{{ point }} Pkt
      </div>


    </div>
    <div class="player-result__point" :class="{
      '-succeeded': requirementPoints > 0,
      '-failed': requirementPoints <= 0
    }">
      +{{ requirementPoints }} Pkt
    </div>

    <h3 class="player-result__subtitle">
      Aufwände
    </h3>
    <div class="player-result__datagrid">
      <div class="player-result__datapart">
        <div>
          Du hast <strong>{{ player.points.parts.totalPartRequests }}</strong>
          Daten vereinbart und davon <strong>{{ player.points.parts.totalCollectedParts }}</strong>
          Daten eingesammelt.
        </div>
      </div>
      <div class="player-result__point player-result__datapoint" :class="{
        '-succeeded': player.points.parts.remainingPartRequests >= 0,
        '-failed': player.points.parts.remainingPartRequests < 0
      }">
        <span>{{ player.points.parts.remainingPartRequests }}</span>
      </div>
      <div class="player-result__datapart">
        <div>
          Nach der Datenoptimierung standen insgesamt <strong>{{ player.points.parts.totalParts }}</strong>
          Daten zur Verfügung, von denen du <strong>{{ player.points.parts.builtParts }}</strong>
          Daten verbaut hast.
        </div>
      </div>
      <div class="player-result__point player-result__datapoint" :class="{
        '-succeeded': player.points.parts.unbuiltParts >= 0,
        '-failed': player.points.parts.unbuiltParts < 0
      }">
        <span>{{ player.points.parts.unbuiltParts }}</span>
      </div>
      <div class="player-result__datapart">
        <div>
          Nach dem Spiel hattest du <strong>{{ player.movementPoints}}</strong> Bewegungspunkte übrig.
        </div>
      </div>
      <div class="player-result__point player-result__datapoint" :class="{
        '-succeeded': player.points.movement >= 0,
        '-failed': player.points.movement < 0
      }">
        <span>{{ player.points.movement }}</span>
      </div>
    </div>
    <div class="player-result__point" :class="{
      '-succeeded': dataPoints > 0,
      '-failed': dataPoints <= 0
    }">
      <span>{{ dataPoints }} Pkt</span>
    </div>

  </div>
</template>

<script>
import Car from "../../Car/Car.vue";
import Icon from "../../../ui/Icon.vue";
import PremiseCard from "../../Cards/PremiseCard.vue";
export default {
  components: {
    Car,
    Icon,
    PremiseCard
  },
  props: {
    player: {
      type: Object,
      default() {
        return null;
      }
    },
    playerName: {
      type: String,
      default: ""
    }
  },
  computed: {
    requirementPoints() {
      return this.player.points.total.requirements;
    },
    dataPoints() {
      return this.player.points.total.data;
    },
    premisePoints() {
      return this.player.points.total.premise;
    },
    total() {
      return this.player.points.total.sum;
    },
    playerBuiltParts() {
      const parts = this.player.parts || [];
      return parts.filter(
        part => this.player.builtPartIds.includes(part.uuid)
      );
    }
  }
}
</script>

<style lang="scss">
.player-result {
  --playercolor: $color-player-5;
  --playercolorlight: $color-player-5-light;
  --playercolormedium: $color-player-5-medium;

  border: 2px solid var(--playercolor);
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  background-color: #fff;
  padding: 1rem;
  padding-top: 0;
  position: relative;
  color: #555;
  max-width: 26rem;

  &__name {
    color: #fff;
    background-color: var(--playercolor);
    padding: 0.5rem;
    width: max-content;
    max-width: 100%;
    border-bottom-right-radius: 3px;
    display: block;
  }
  &__namearea {
    margin-left: -1rem;
    margin-right: -1rem;
    background-color: var(--playercolorlight);
  }

  &__resultarea {
    color: var(--playercolor);
    background-color: var(--playercolorlight);
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1rem;
    padding-top: 1px;
    text-align: center;
  }
  &__gridspan2 {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &__premisearea {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__cararea {
    display: grid;
    grid-template-columns: 7rem 1fr;
    padding: 0.5rem 0;
    margin-top: 1rem;
  }
  &__carrow {
    min-width: 11rem;
    height: 10rem;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1F1F1;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  &__car {
    width: 100%;
    height: 10rem;
  }

  &__subtitle {
    font-size: 1em;
    margin-bottom: 0;
  }

  &__point,
  &__total,
  &__requirementpoint {
    text-align: center;
    border-radius: 3px;
    padding: 0.25em;
    font-weight: bold;
    margin-top: 0.5rem;
    white-space: nowrap;
  }

  &__point {
    background-color: #F1F1F1;
    font-size: 1.25rem;

    &.-succeeded {
      color: $color-succeeded;
    }
    &.-passed {
      color: $color-passed;
    }
    &.-failed {
      color: $color-failed;
    }
  }
  &__total {
    font-size: 1.25rem;
    background-color: transparent;
    border: 1px solid var(--playercolor);
    background-color: var(--playercolormedium);
  }

  &__requirementpoint {
    margin-top: 0;
    border: 1px solid #F1F1F1;
    &.-succeeded {
      color: $color-succeeded;
    }
    &.-passed {
      color: $color-passed;
    }
    &.-failed {
      color: $color-failed;
    }
  }

  &__datagrid {
    display: grid;
    grid-template-columns: 1fr 4rem;
    gap: 0.5rem;
    margin-top: 0.25rem;
  }
  &__datapoint {
    display: flex;
    margin-top: 0;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid $color-uielement-border;
  }


  &__datapart {
    padding: 0.5em;
    border: 1px solid $color-uielement-border;
    border-radius: 5px;
  }


  &__requirementsboard {
    display: grid;
    font-size: 14px;
    grid-template-columns: 5em 1fr 1fr 1fr;
    gap: 0.5rem;
    padding: 0.5rem 0;
  }

  &__icon {
    color: $color-uielement-text;
    height: 3em;
    width: 3em;
    align-self: center;
    justify-self: center;
  }

  &__requirement,
  &__simulationresult {
    border: 1px solid $color-uielement-border;
    padding: 0.75em 0.5em;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.25em;
    line-height: 1.5;
  }

  &__title-premise {
    margin-top: -0.3em;
    margin-bottom: auto;
  }




  &.-is-player-0 {
    --playercolor: #{$color-player-0};
    --playercolorlight: #{$color-player-0-light};
    --playercolormedium: #{$color-player-0-medium};
  }
  &.-is-player-1 {
    --playercolor: #{$color-player-1};
    --playercolorlight: #{$color-player-1-light};
    --playercolormedium: #{$color-player-1-medium};
  }
  &.-is-player-2 {
    --playercolor: #{$color-player-2};
    --playercolorlight: #{$color-player-2-light};
    --playercolormedium: #{$color-player-2-medium};
  }
  &.-is-player-3 {
    --playercolor: #{$color-player-3};
    --playercolorlight: #{$color-player-3-light};
    --playercolormedium: #{$color-player-3-medium};
  }
  &.-is-player-4 {
    --playercolor: #{$color-player-4};
    --playercolorlight: #{$color-player-4-light};
    --playercolormedium: #{$color-player-4-medium};
  }
}
</style>