<template>
  <div class="escalation-dialogs-1">
    <game-dialog v-if="!escalation.introConfirmed">
      <div class="text-center">
        Du kannst beliebig viele Daten nachvereinbaren. 
        Pro Kategorie kostet dich dies 3 Bewegungspunkte.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            useEscalation: null
          })"
        >
          Zurück
        </button-active-player>
        <button-active-player
          @click="moves.updateEscalation({
            introConfirmed: true
          })"
        >
          Kategorie wählen
        </button-active-player>
      </div>

    </game-dialog>

    <game-dialog v-else-if="escalation.category === null">
      <div class="text-center">
        Wähle eine Kategorie, um zusätzliche Daten zu 
        vereinbaren:
      </div>

      <div class="column -categories">
        <data-card
          v-for="category in gameState.dataCategories"
          :key="category.id"
          :category="category"
          @click="moves.updateEscalation({
            category: category.id
          })"
          :clickable="true"
          :disabled="!isActivePlayer"
        ></data-card>
      </div>

      <div class="column">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            introConfirmed: false
          })"
        >
          Zurück
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else-if="escalation.amount === 0">
      <div class="text-center">
        Wie viele Daten möchtest du vereinbaren?
      </div>

      <div class="row">
        <input-field
          type="number"
          v-model.number="amount"
        ></input-field>
        <span>Daten</span>
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            category: null
          })"
        >
          Zurück
        </button-active-player>
        <button-active-player
          :disabled="amount == 0"
          @click="moves.updateEscalation({
            amount: amount
          })"
        >
          Weiter
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else-if="escalation.requestConfirmed == false">
      <div class="text-center">
        Möchtest du diese Daten vereinbaren? 
        Das kostet dich 3 Bewegungspunkte.
      </div>

      <div class="row">
        <data-card
          :category="selectedCategory"
          :amount="escalation.amount"
        ></data-card>
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            amount: 0
          })"
        >
          Zurück
        </button-active-player>
        <button-active-player
          @click="moves.confirmEscalation1()"
        >
          JA
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else>
      <div class="text-center">
        Die Daten wurden übernommen. Möchtest du weitere 
        Daten vereinbaren? Pro Kategorie kostet dich dies 
        3 Bewegungspunkte.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            amount: 0,
            requestConfirmed: false,
            category: null
          })"
        >
          Weitere Kategorie wählen
        </button-active-player>
        <button-active-player
          @click="moves.endTurn()"
        >
          Spielzug beenden
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";

import GameDialog from "../UIElements/GameDialog.vue";
import InputField from "../../input/InputField.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
import DataCard from "../Cards/DataCard.vue";

export default {
  mixins: [GameComponentMixin],
  components: {
    GameDialog,
    ButtonActivePlayer,
    InputField,
    DataCard
  },
  data() {
    return {
      amount: 0
    };
  },
  computed: {
    escalation() {
      return this.gameState.currentEscalation;
    },
    selectedCategory() {
      if (this.escalation.category === null) return null;
      return this.dataCategories.find(cat => cat.id === this.escalation.category);
    }
  }
}
</script>

<style>

</style>