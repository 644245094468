<template>
  <article class="build-screen">
    <car-builder
      :player="activePlayer"
      :car-parts-data="activePlayer.parts"
      :categories="dataCategories"
      :built-part-ids="activePlayer.builtPartIds"
      @build="moves.buildPart"
      @update-part="moves.updatePart"
      @remove-part="moves.removePart"
    ></car-builder>

    <timer
      class="build-screen__timer"
      v-if="endTime"
      :end-time="endTime"
      @expired="endBuilding()"
      @end="endBuilding()"
    ></timer>
  </article>
</template>

<script>
// Mixin
import GameComponentMixin from "../GameComponentMixin.js";

// Komponenten
import CarBuilder from "../Car/CarBuilder.vue";
import Timer from "../UIElements/Timer.vue";

import GameFooter from "../../ui/GameFooter.vue";

export default {
  mixins: [GameComponentMixin],
  components: {
    CarBuilder,
    Timer,
    GameFooter
  },
  computed: {
    endTime() {
      return (
        this.currentMove.startedAt +
        (this.currentMove.maxTime * 1000)
      );
    }
  },
  methods: {
    endBuilding() {
      this.moves.endBuilding();
    }
  }
}
</script>

<style lang="scss">
.build-screen {
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;

  &__timer {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
}
</style>