<template>
  <tr class="match-result" >
    <td class="td__index">
      {{ index + 1 }}
    </td>
    <td class="td__name">
      <strong class="match-result__name">{{ match.data.matchName}}<br>
      <strong class="match-result__date">{{ formattedDate }}</strong>
    </td>

    <td class="td__teamresult">
      <div class="team-result-admin">

        
        <div class="team-result-admin__container-players">
          <div class="team-result-admin__span">
            <span> Spieler </span>
          </div>
          <div class="team-result-admin__players">
            {{ teamPoints }}
          </div>
        </div>

        <div class="team-result-admin__container-quiz">
          <div class="team-result-admin__span">
            <span> Quiz </span>
          </div>
          <div class="team-result-admin__quiz">
          {{ match.data.quizPoints }} 
          </div>
        </div>

        <div class="team-result-admin__total">
          <strong>{{ totalPoints }} Pkt.</strong><br>
        </div>

        <router-link class="team-result-admin__screen" :to="{ name: 'matchResult', params: { matchId: match.matchId } }"><strong>Ergebnis-Screen</strong></router-link>

      </div>

    </td>
    <td class="td__player-results">
      <ol class="match-result-admin__playerlist">
        <li v-for="player in match.data.players" :key="player.id"><strong class="match-result__semibold">{{ player.name }}</strong>: {{ player.points.total.sum }} Pkt</li>
      </ol>
    </td>
    <td class="td__actions">
      <button class="button button--secondary match-result__button" @click="archiveResult">
        <span>Aus Rangliste entfernen</span>
      </button>
      <button class="button button--danger match-result__button" @click="deleteResult">
        <span>Endgültig löschen</span>
      </button>
    </td>
  </tr>
</template>

<script>
import { patch, destroy } from "../../services/api.js";
import Icon from "../ui/Icon.vue";
export default {
  components: { Icon },
  props: {
    match: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    teamPoints() {
      return Object.values(this.match.data.players).reduce((sum, player) => sum + player.points.total.sum, 0);
    },
    totalPoints() {
      return this.teamPoints + this.match.data.quizPoints;
    },
    formattedDate() {
      const d = new Date(this.match.createdAt);
      return d.toLocaleDateString();
    }
  },
  methods: {
    async archiveResult(mouseEvent) {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();
      const archived = this.match.archived || false;
      const newMatch = await patch(`/matchresults/${this.match.matchId}`, {
        archived: !archived
      }, {}, true);
      this.$emit("updated", newMatch);
    },
    deleteResult() {
      if (!confirm("Sicher, dass Sie dieses Ergebnis endgültig löschen möchten?")) return;
      try {
        destroy(`/matchresults/${this.match.matchId}`).then(() => {
          this.$emit("destroyed", this.match.matchId);
        });
      } catch(error) {
        alert("Es gab einen Fehler beim Löschen.");
        console.log(error);
      }
    }
  }
}
</script>

<!-- USES SAME STYLE AS MatchResult.vue -->