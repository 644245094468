<template>
  <div class="simulation-screen">
    <div>
      <car-simulation
        style="width: 60rem"
        :type="simtype"
        :car-parts-data="builtParts"
        :use-custom-renderer="false"
        @end="saveResult($event)"
      ></car-simulation>
      <select v-model="simtype">
        <option value="simulation">Mit Rampe</option>
        <option value="ruler">Ohne Rampe</option>
      </select>
    </div>
    <div>
      <h2>Ergebnisse</h2>
      <pre>{{ simulationResults }}</pre>
    </div>
    <game-footer></game-footer>
  </div>
</template>

<script>
import { GRID_SIZE } from '../../../../car/carPartCreator';
import CarSimulation from "../Car/CarSimulation.vue";
import GameComponentMixin from "../GameComponentMixin.js";

import GameFooter from "../../ui/GameFooter.vue";

export default {
  mixins: [GameComponentMixin],
  components: {
    CarSimulation,
    GameFooter
  },
  data() {
    return {
      simtype: "simulation",
      result: {},
      simulationResults: {
        length: null,
        height: null,
        groundDistance: null,
        weight: null,
        physicsMass: null,
        distanceTravelled: null
      }
    };
  },
  methods: {
    saveResult(simulationData) {
      console.log(simulationData);
      this.simulationResults.physicsMass = Math.round(simulationData.totalMass);
      switch (this.simtype) {
        case "ruler":
          const bounds = simulationData.boundsAtEnd;
          const width = Math.round(Math.abs(bounds.max.x - bounds.min.x) / GRID_SIZE);
          const height = Math.round(Math.abs(bounds.max.y - bounds.min.y) / GRID_SIZE);
          const groundDistance = Math.round((bounds.max.y - simulationData.bottomWithoutWheels) / GRID_SIZE);


          // save dimensions
          this.simulationResults.length = width;
          this.simulationResults.height = height;
          this.simulationResults.groundDistance = groundDistance;

          // save weight
          this.simulationResults.weight = simulationData.totalWeight;
          break;

        case "simulation":
          const xStart = Math.round(simulationData.boundsAtStart.min.x);
          const xEnd = Math.round(simulationData.boundsAtEnd.min.x);
          const delta = Math.round(Math.abs(xEnd - xStart) / GRID_SIZE);

          this.simulationResults.distanceTravelled = delta;
          break;
      }
    }
  }
}
</script>

<style lang="scss">
.simulation-screen {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: auto;
}
</style>