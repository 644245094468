<template>
  <form class="update-passwordform" @submit.prevent="onSubmit">
    <input-field
      label="Neues Passwort"
      type="password"
      v-model="newPassword"
    ></input-field>
    <button
      class="button"
      type="submit"
      :disabled="newPassword.length < 3"
    >
      <span v-if="newPassword.length < 3">Bitte mind. 3 Zeichen eingeben</span>
      <span v-else>Neues Passwort speichern</span>
    </button>
    <button
      class="update-passwordform__close button button--danger"
      @click="$emit('close')"
    >
      ⨉
    </button>
  </form>
</template>

<script>
import { patch } from "../../services/api.js";
import InputField from '../input/InputField.vue'
export default {
  components: { InputField },
  data() {
    return {
      newPassword: ""
    };
  },
  methods: {
    async onSubmit() {
      try {
        await patch("/account", {
          password: this.newPassword
        });
        this.$emit("close");
        alert("Passwort geändert.");
      } catch (error) {
        alert("Es ist ein Fehler aufgetreten.");
      }
    }
  }
}
</script>

<style lang="scss">
.update-passwordform {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;
  z-index: 1;
  width: 32rem;

  &__close {
    display: block;
    position: absolute;
    top: -1rem;
    right: -1rem;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    padding: 0;
  }
}
</style>