<template>
  <div class="part-configurator">
    <h3>Eigenes Teil bauen</h3>
    <select v-model="type" style="margin-bottom: 0.5rem; display: block;">
      <option value="Wheel">Rad</option>
      <option value="Rectangle">Rechteck</option>
      <option value="Triangle">Dreieck</option>
      <option value="Screw">Schraube</option>
      <option value="Achse">Achse</option>
      <option value="SlopedRect">Abgeschnittenes Rechteck</option>
    </select>
    <div v-if="type !== 'Screw' && type !== 'Achse'">
      <input-field type="number" v-model="width" label="Breite" :min="1"></input-field>
      <input-field v-if="type != 'Wheel'" type="number" v-model="height" label="Höhe" :min="1"></input-field>
    </div>
    <label v-if="type == 'Screw'" style="display: block;">
      <input type="checkbox" v-model="vertical"> <span>Vertikal?</span>
    </label>
    <div v-if="type != 'Screw' && type != 'Achse' && type != 'Wheel'" style="margin-top: 0.5rem;">
      <strong>Verbindungen</strong><br>
      <label>
        <input type="radio" v-model="connectType" value="h" /> <span>Horizontal</span>
      </label><br>
      <label>
        <input type="radio" v-model="connectType" value="v" /> <span>Vertikal</span>
      </label><br>
      <label>
        <input type="radio" v-model="connectType" value="both" /> <span>Beide</span> 
      </label>
    </div>
    <div v-if="type == 'Triangle' || type == 'SlopedRect'" style="margin-top: 0.5rem;">
      <strong>Ecke</strong><br>
      <label>
        <input type="radio" v-model="triangleType" value="lu" /> <span>Links Oben</span>
      </label><br>
      <label>
        <input type="radio" v-model="triangleType" value="ru" /> <span>Rechts Oben</span>
      </label><br>
      <label>
        <input type="radio" v-model="triangleType" value="ld" /> <span>Links Unten</span> 
      </label><br>
      <label>
        <input type="radio" v-model="triangleType" value="rd" /> <span>Rechts Unten</span>
      </label>
    </div>
    <div v-if="type == 'SlopedRect'" style="margin-top: 0.5rem;">
      <input-field type="number" v-model="slopeWidth" label="Breite Abschnitt" :min="1"></input-field>
      <input-field type="number" v-model="slopeHeight" label="Höhe Abschnitt" :min="1"></input-field>
    </div>
    <button class="button" style="margin-top: 0.5rem;" @click="submit">Erstellen!</button>
  </div>
</template>

<script>
import { createRectangleData, createWheelData, createConstraintData, createTriangleData, createSlopedRectData } from "../../../../car/carPartCreator";
import InputField from "../../input/InputField.vue";
const GRID_SIZE = 16;
export default {
  components: {
    InputField
  },
  data() {
    return {
      type: "Triangle",
      width: 8,
      height: 8,
      vertical: false,
      triangleType: 'lu',
      connectType: 'both',
      slopeWidth: 4,
      slopeHeight: 4
    }
  },
  watch: {
    width(value) {
      if (this.type != "SlopedRect") {
        this.slopeWidth = Math.ceil(value / 2);
      }
      else if (this.slopeWidth > this.width) this.slopeWidth = this.width;
    },
    height(value) {
      if (this.type != "SlopedRect") {
        this.slopeHeight = Math.ceil(value / 2);
      }
      else if (this.slopeHeight > this.height) this.slopeHeight = this.height;
    }
  },
  methods: {
    submit() {
      let newPart;
      switch (this.type) {
        case "Rectangle":
          newPart = createRectangleData(this.width, this.height, {
            connect: this.connectType
          });
          break;

        case "Triangle":
          newPart = createTriangleData(this.width, this.height, this.triangleType == 'ru' || this.triangleType == 'rd', this.triangleType == 'lu' || this.triangleType == 'ru', {
            connect: this.connectType
          });
          break;

        case "Wheel":
          newPart = createWheelData(this.width);
          break;
        
        case "Achse":
          newPart = createConstraintData(0);
          break;
        
        case "Screw":
          newPart = createConstraintData(1, { vertical: this.vertical });
          break;
        
        case "SlopedRect":
          newPart = createSlopedRectData(this.width, this.height, {
            connect: this.connectType,
            slopeWidth: Math.min(this.slopeWidth, this.width),
            slopeHeight: Math.min(this.slopeHeight, this.height),
            slopeIsRight: this.triangleType == 'ru' || this.triangleType == 'rd',
            slopeIsBottom: this.triangleType == 'ld' || this.triangleType == 'rd'
          });
          break;
      }
      console.log("created", this.type, newPart);
      this.$emit("create", newPart);
    }
  }
}
</script>

<style lang="scss">
.part-configurator {
  padding: 0.5rem;
  background-color: #ddd;
  flex: none;
  width: 100%;
}
</style>