<template>
  <div class="fielddialogs-build">

    <role-dialogs v-if="hasRoleAndRoleOpen">
      <template v-slot:intro>
        <p>
          Hallo, ich bin ein <strong>Simulationsexperte</strong>.
        </p>
        <p>
          Ich beschaffe mir die vereinbarten Daten, erstelle anschließend ein Simulationsmodell, bewerte mit den virtuellen Bewertungsmethoden die Fahrzeugfunktionen und gebe Optimierungsvorschläge an die Bauteil-Konstrukteure. Falls ich nicht rechtzeitig meine Bewertung liefern kann, greife ich rechtzeitig ein.
        </p>
        <p>
          Gut, dass du nach mir gefragt hast. Mit meiner Hilfe hast du <strong>90</strong> Sekunden
          Zeit dein Simulationsmodell aufzubauen.
        </p>
      </template>
      <template v-slot:question>
        <p>
          Hallo, hier ist wieder ein Simulationsexperte!
        </p>
      </template>
      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die Arbeit gehen.
        </p>
        <p>
          Mit meiner Hilfe hast du <strong>90</strong> Sekunden Zeit dein Simulationsmodell aufzubauen!
        </p>
      </template>
      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du nochmal Hilfe brauchst.
        </p>
      </template>
    </role-dialogs>

    <!-- QUIZFRAGE -->
    <quiz-dialogs v-else-if="(
      currentField.hasQuiz &&
      !currentMove.handledQuizQuestion
    )"></quiz-dialogs>


    <!-- NORMAL, noch nicht in KOLLABORATION: -->
    <template v-else-if="!currentMove.collaborationSelected">

      <!-- Auswählen -->
      <game-dialog v-if="currentMove.startedAt == null">
        <div class="text-center">
          Du hast 
          <span v-if="currentMove.solvedRoleQuiz">
            90
          </span>
          <span v-else>
            60
          </span>
          Sekunden Zeit, an deinem Fahrzeug zu bauen.
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endTurn()"
          >
            Abbrechen
          </button-active-player>

          <button-active-player
            v-if="isFieldCollaborative"
            @click="moves.startCollaboration()"
          >
            Kollaboration: Zug weitergeben
          </button-active-player>

          <button-active-player
            @click="moves.startBuilding()"
          >
            Starten
          </button-active-player>

        </div>
      </game-dialog>


      <game-dialog v-else-if="!currentMove.builderActive">
        <div class="text-center">
          Deine Zeit ist abgelaufen
        </div>
        
        <div class="row">
          <button-active-player
            @click="moves.endTurn()"
          >
            OK
          </button-active-player>
        </div>
      </game-dialog>
    </template>


    <!-- KOLLABORATION -->
    <collaboration-dialogs v-else-if="currentMove.collaborationSelected"></collaboration-dialogs>
  </div>
</template>

<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";


// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";
// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";

import MethodCard from "../Cards/MethodCard.vue";
import PlayerElement from "../UIElements/PlayerElement.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
import Icon from "../../ui/Icon.vue";
import CollaborationDialogs from "./CollaborationDialogs.vue";

export default {
  components: {
    GameDialog,
    RoleDialogs,
    MethodCard,
    PlayerElement,
    ButtonActivePlayer,
    QuizDialogs,
    Icon,
    CollaborationDialogs
  },
  mixins: [GameComponentMixin],
  computed: {
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    },
    isFieldCollaborative() {
      return this.currentField.isCollaborative && this.isCurrentPlayerActive;
    },
    playerHasMethod() {
      return this.activePlayer.methods.some(method => method.id == this.currentMove.selectedMethod.id);
    }
  },
  methods: {
    cancel() {
      this.moves.endTurn();
    }
  }
}
</script>

<style>

</style>