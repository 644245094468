<template>
  <div class="current-match">
    <game-client
      :player-id="playerID"
      :match-id="matchID"
      :player-name="playerName"
      :credentials="playerCredentials"
      @leave="leaveGame"
    ></game-client>
  </div>
</template>

<script>
import { post } from "../services/api";
import GameClient from "../components/game/GameClient.vue";

export default {
  components: {
    GameClient
  },
  computed: {
    matchID() {
      return localStorage.getItem("matchID");
    },
    playerName() {
      return localStorage.getItem("playerName");
    },
    playerCredentials() {
      return localStorage.getItem("playerCredentials");
    },
    playerID() {
      return localStorage.getItem("playerID");
    }
  },
  methods: {
    async leaveGame() {
      try {
        await post(`/matches/${this.matchID}/leave`, {
          playerID: this.playerID,
          credentials: this.playerCredentials
        });
        this.clearMatchCredentials();
        this.$router.push("/");
      } catch (error) {
        if (error.status == 404) {
          this.clearMatchCredentials();
          this.$router.push("/");
        }
        console.log(error);
      }
    },
    clearMatchCredentials() {
      localStorage.removeItem('matchID');
      localStorage.removeItem('playerID');
      localStorage.removeItem('playerName');
      localStorage.removeItem('playerCredentials');
    }
  }
}
</script>

<style lang="scss">
.current-match {
  min-height: 100vh;
}
</style>