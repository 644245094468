<template>
  <article class="results-screen">
    <section class="results-screen__content">
      <h2>Team-Bewertung</h2>
      <team-result
        :quiz-points="gameState.quizPoints"
        :players="players"
      ></team-result>

      <h2>Spieler-Bewertung</h2>
      <div class="results-screen__players">
        <player-result
          v-for="player in players"
          :key="player.id"
          :player="player"
          :player-name="playerNames[player.id]"
        ></player-result>
      </div>

      <h2>Link zur Ergebnis-Seite</h2>
      <router-link :to="{name: 'matchResult', params: { matchId: matchId} }">
        {{ protocol }}//{{ host }}/ergebnis/{{ matchId }}
      </router-link>
    </section>
    <game-footer></game-footer>
  </article>
</template>

<script>
import { post } from "../../../services/api.js";
import GameComponentMixin from "../GameComponentMixin.js";
import PlayerResult from "./ResultsScreen/PlayerResult.vue";
import TeamResult from "./ResultsScreen/TeamResult.vue";

import GameFooter from "../../ui/GameFooter.vue";


export default {
  mixins: [GameComponentMixin],
  components: {
    PlayerResult,
    TeamResult,
    GameFooter
  },
  data() {
    return {
      host: window.location.host,
      protocol: window.location.protocol
    };
  },
  mounted() {
    const results = this.createResults();
    this.safelyTransmitResults(results);
  },
  methods: {
    /**
     * Try transmitting results until it worked.
     */
    async safelyTransmitResults(results) {
      let transmitted = false;
      while (!transmitted) {
        transmitted = await this.transmitResult(results);
      }
      return true;
    },
    transmitResult(results) {
      // Übertrage die Spielergebnisse auf den server:
      return post(`/matchresults/${this.matchId}`, results).then(result => {
        return true;
      }).catch(error => {
        return false;
      });
    },
    createResults() {
      return {
        player: {
          id: this.myself.id,
          credentials: this.getCredentials()
        },
        data: {
          matchName: this.gameState.matchName,
          // player data
          players: Object.values(this.players).map(player => ({
            id: player.id,
            name: this.playerNames[player.id],

            // Ergebnisse
            points: player.points,
            simulationResults: player.simulationResults,
            marks: player.marks,
            movementPoints: player.movementPoints,

            // Voraussetzungen
            requirements: player.requirements,
            premise: player.premise,

            // Auto-Teile
            parts: player.parts,
            builtPartIds: player.builtPartIds
          })),

          // quiz
          answeredQuizQuestions: this.gameState.answeredQuizQuestions,
          quizPoints: this.gameState.quizPoints,

          totalPoints: Object.values(this.players).reduce((sum, player) => sum + player.points.total.sum, 0) + this.gameState.quizPoints
        }
      };
    },
    async leaveGame() {
      try {
        await post(`/matches/${this.matchId}/leave`, {
          playerID: this.myself.id,
          credentials: this.getCredentials()
        });
        this.clearMatchCredentials();
      } catch (error) {
        if (error.status == 404) {
          this.clearMatchCredentials();
        }
      }
    },
    clearMatchCredentials() {
      localStorage.removeItem('matchID');
      localStorage.removeItem('playerID');
      localStorage.removeItem('playerName');
      localStorage.removeItem('playerCredentials');
    }
  }
}
</script>

<style lang="scss">
.results-screen {
  height: 100%;
  overflow: auto;
  padding-top: 2rem;
  background-color: #eee;

  &__content {
    padding: 0 2rem;
    margin-bottom: 2rem;
  }

  &__players {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    overflow: auto;
  }

  h2, h3 {
    color: $color-text;
  }

  h2 {
    margin-top: 1.25em;
  }
}
</style>