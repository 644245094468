<template>
  <icon :name="iconName" class="grade-mark" :class="{
    '-succeeded': mark >= 2,
    '-passed': mark === 1,
    '-failed': mark <= 0
  }"></icon>
</template>

<script>
import Icon from "../../ui/Icon.vue";
export default {
  components: {
    Icon
  },
  props: {
    mark: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconName() {
      if (this.mark > 0) return "richtig";
      else return "falsch";
    }
  }
}
</script>

<style lang="scss">
.grade-mark {
  display: block;
  padding: 1em;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  border: 3px solid currentColor;

  &.-succeeded {
    color: $color-succeeded;
    background-color: $color-succeeded-light;
  }
  &.-passed {
    color: $color-passed;
    background-color: $color-passed-light;
  }
  &.-failed {
    color: $color-failed;
    background-color: $color-failed-light;
  }
}
</style>