<template>
  <button
    class="player-element"
    :class="[`-player-${player.id}`]"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    {{ playerName }}
  </button>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";
export default {
  mixins: [GameComponentMixin],
  props: {
    player: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    playerName() {
      return this.playerNames[this.player.id];
    }
  }
}
</script>

<style lang="scss">
.player-element {
  color: $color-uielement-text;
  background-color: $color-uielement-bg;
  border: 1px solid $color-uielement-border;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8em;
  text-align: center;
  padding: 1.5em 1em;
  font-weight: 600;
  color: #fff;

  &.-player-0 {
    background-color: $color-player-0;
    &:hover {
      filter: brightness(105%);
    }
  }
  &.-player-1 {
    background-color: $color-player-1;
    &:hover {
      filter: brightness(105%);
    }
  }
  &.-player-2 {
    background-color: $color-player-2;
    &:hover {
      filter: brightness(105%);
    }
  }
  &.-player-3 {
    background-color: $color-player-3;
    &:hover {
      filter: brightness(105%);
    }
  }
  &.-player-4 {
    background-color: $color-player-4;
    &:hover {
      filter: brightness(105%);
    }
  }

  &:disabled {
    &:hover {
      filter: none;
    }
  }
}
</style>