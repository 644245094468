import Matter, { Vector } from "matter-js";

/** @type {HTMLCanvasElement} */
let canvas;

/** @type {CanvasRenderingContext2D} */
let ctx;

let engine;

let animationFrameId;

export const dimensions = {
  WIDTH: 1400, // 1600
  HEIGHT: 800 // 1200
};

/**
 * @typedef RendererOptions
 * @property {HTMLElement} element
 * @property {Number} width
 * @property {Number} height
 */
let defaultOptions = {
  element: null,
  engine: null,
  width: dimensions.WIDTH,
  height: dimensions.HEIGHT
};

/**
 *
 * @param {RendererOptions} options
 */
export function create(options = {}) {
  options = Object.assign({}, defaultOptions, options)
  canvas = document.createElement("canvas");
  ctx = canvas.getContext("2d");

  canvas.width = options.width;
  canvas.height = options.height;

  options.element.appendChild(canvas);

  engine = options.engine;

  return canvas;
}

/**
 *
 */
export function render() {
  const bodies = Matter.Composite.allBodies(engine.world);

  const contraints = Matter.Composite.allConstraints(engine.world);

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  const mainBodies = bodies.filter(body => body.label != "Wheel");
  mainBodies.forEach(body => drawBody(body));
  const wheels = bodies.filter(body => body.label == "Wheel");
  wheels.forEach(body => drawBody(body));
  //contraints.forEach(contraint => drawConstraint(contraint));

  animationFrameId = window.requestAnimationFrame(render);
}

export function stop() {
  cancelAnimationFrame(animationFrameId);
}

export function drawBody(body, context = ctx) {
  if (!body.vertices) return;
  context.lineWidth = 0;
  for (let partIndex = 0; partIndex < body.parts.length; partIndex++) {
    if (body.parts.length > 1 && partIndex == 0) continue;
    const part = body.parts[partIndex];
    context.fillStyle = part.render.fillStyle;
    context.strokeStyle = part.render.fillStyle;
    context.beginPath();
    context.moveTo(part.vertices[0].x, part.vertices[0].y);
    part.vertices.forEach(vertex => {
      context.lineTo(vertex.x, vertex.y);
    });
    context.closePath();
    context.fill();
    context.stroke();
  }
}

export function drawConstraint(constraint, context = ctx) {
  const pointA = Vector.add(constraint.bodyA.position, constraint.pointA);
  const pointB = Vector.add(constraint.bodyB.position, constraint.pointB);
  context.lineWidth = constraint.length > 0 ? 1 : 3;
  context.fillStyle = "red";// constraint.render.fillStyle;
  context.strokeStyle = constraint.render.strokeStyle;
  context.beginPath();
  context.moveTo(pointA.x, pointA.y);
  context.lineTo(pointB.x, pointB.y);
  context.closePath();
  context.fill();
  context.stroke();
}