<template>
  <div class="board-field__container">
    <button
      class="board-field"
      :title="title"
      :disabled="canBeAccessed == false"
      @click="clickable ? $emit('click') : (function() {})()"
    >
      <div class="board-field__content">
        <transition name="board-field__fadetransition">
          <div
            class="board-field__header"
            v-show="isSpecial"
          >
            <icon v-if="field.hasRole" key="fieldIconRole" name="person" class="board-field__headericon"></icon>
            <icon v-else-if="field.hasQuiz" key="fieldIconQuiz" name="innovation" class="board-field__headericon"></icon>
            <!--<icon v-else-if="field.isCollaborative" key="fieldIconCollaboration" name="sprechblasen" class="board-field__headericon"></icon>-->
          </div>
        </transition>
        <div
          class="board-field__body" 
          :class="{'-is-selected': isSelected }"
        >
          <icon :name="field.icon || 'auge'" class="board-field__icon"></icon>
        </div>
      </div>
    </button>
    <!--<div v-if="clickable && isSelected" class="board-field__confirm">
      <button-active-player @click="moves.goToField">
        Für {{ pointsToMove }}
        <span v-if="pointsToMove > 1">Punkte</span>
        <span v-else>Punkt</span>
        hierher bewegen
      </button-active-player>
    </div>-->
  </div>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";
import Icon from "../../ui/Icon.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
export default {
  components: {
    Icon,
    ButtonActivePlayer
  },
  mixins: [GameComponentMixin],
  props: {
    area: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    canBeAccessed() {
      if (this.currentPlayer.area !== this.area.id) {
        return false;
      }


      if (this.area.gateAt && this.field.id > this.area.gateAt) {
        return this.currentPlayer.gatePasses.includes(0);
      }

      return true;
    },
    title() {
      return this.field.title;
    },
    description() {
      return this.field.description;
    },
    isSpecial() {
      return this.field.hasRole || this.field.hasQuiz;
    },
    isSelected() {
      return this.currentMove.selectedField == this.field.id;
    },
    pointsToMove() {
      return Math.abs(this.currentMove.selectedField - (this.currentPlayer.field || 0));
    }
  }
}
</script>

<style lang="scss">
.board-field {
  $border-color: $color-uielement-border;
  color: $color-uielement-milestone-border;

  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border: none;
  background-color: transparent;

  &:disabled {
    filter: brightness(50%);
  }
  &:focus {
    outline: none;
  }

  &__container {
    position: relative;
  }

  &__header {
    position: absolute;
    left: 50%;
    top: -1.5em;
    margin-left: -1.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border: 1px solid $color-uielement-milestone-border;
    padding: 0.5em;
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    transition: background-color 750ms;
    color: $color-uielement-milestone-border;
    background-color: #FFFFFF;
  }

  &__body {
    padding: 1.5em 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    background-color: #fff;
    transition: box-shadow 200ms ease-out;
    box-shadow: 0 3px 8px -2px rgba(0,0,0,0.2);
    overflow: hidden;
  }
  &:focus &__body {
    box-shadow: 0 5px 10px -3px rgba(0,0,0,0.3);
  }
  &__body.-is-selected {
    border-color: var(--active-player-color);
    border-width: 2px;
    border-style: solid;
  }

  &__headericon {
    height: 1.125em;
    width: 1.125em;
  }
  &__icon {
    height: 2.5em;
    width: 2.5em;
  }

  &__title {
    font-size: 1em;
  }

  &__fadetransition {

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    &-enter-active {
      transition: all 500ms ease-out;
    }
    &-leave-active {
      transition: all 120ms ease-in;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
    }
    &-enter {
      transform: translateX(-20px);
    }
    &-leave-to {
      transform: translateX(20px);
    }
  }

  &__confirm {
    font-size: 1rem;
    position: absolute;
    top: 85%;
    left: -2.5rem;
    right: -2.5rem;
    z-index: 1;
    &::before {
      content: "";
      display: block;
      margin: 0 auto;
      border-right: 1em solid transparent;
      border-left: 1em solid transparent;
      border-bottom: 1em solid var(--active-player-color);
      width: 0;
    }
  }
}
</style>