import AdminLogin from "./pages/AdminLogin.vue";
import AdminMatchOverview from "./pages/AdminMatchOverview.vue";
import JoinMatch from "./pages/JoinMatch.vue";
import CurrentMatch from "./pages/CurrentMatch.vue";
import SpectateMatch from "./pages/SpectateMatch.vue";
import MatchLobby from "./pages/MatchLobby.vue";
import MatchResult from "./pages/MatchResult.vue";

export const routes = [
  { path: '/', redirect: '/planspiel/beitreten' },
  { path: '/admin/login', component: AdminLogin, name: 'login' },
  { path: '/admin/planspiele', component: AdminMatchOverview, name: 'matches' },
  { path: '/ergebnis/:matchId', component: MatchResult, name: 'matchResult', props: true },
  { path: '/planspiel/beitreten', component: JoinMatch, name: 'joinMatch' },
  { path: '/planspiel/:urlMatchId/beitreten', component: JoinMatch, name: 'joinSpecificMatch', props: true },
  { path: '/planspiel/warteraum', component: MatchLobby, name: 'matchLobby' },
  { path: '/planspiel', component: CurrentMatch, name: 'match', beforeEnter: (to, from, next) => {
    if (localStorage.getItem('matchID')) next();
    else next({ name: 'joinMatch' });
  } },
  { path: '/planspiel/:matchId', component: SpectateMatch, name: 'spectateMatch', props: true }
];