<template>
  <div class="container-phase-selector">
    
    <!-- MEILENSTEIN Produktziele -->
    <div>
      <svg class="milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.17 42.17"><g id="af1d73ce-71cc-485f-9600-323ef3b76fdb" data-name="Ebene 2"><g id="fcea6138-205f-4009-84ef-47520e80ac54" data-name="Ebene 1"><rect x="7.18" y="7.18" width="27.82" height="27.82" transform="translate(-8.73 21.09) rotate(-45)" style="fill:#92d7e0;stroke:#c3c3c3;stroke-miterlimit:10;stroke-width:2px"/></g></g></svg>
    </div>

    <!-- PHASE 1 -->
    <div 
      class="phase"
      @click="$emit('select-phase', phases['collectRequirements'])" 
      :class="{ '-selected': selectedPhase.id == 'phase1'}" 
    >
      <span class="tooltip-text">Phase 1</span>
    </div>
      
 

    <!-- MEILENSTEIN LV -->
    <div>
      <svg class="milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.17 42.17"><g id="a639d3ef-0ccf-4fe7-b1cf-27ad4ea8f7a1" data-name="Ebene 2"><g id="e607cfff-cd85-471a-af10-277435d681c7" data-name="Ebene 1"><rect x="7.18" y="7.18" width="27.82" height="27.82" transform="translate(-8.73 21.09) rotate(-45)" style="fill:#198391;stroke:#c3c3c3;stroke-miterlimit:10;stroke-width:2px"/></g></g></svg>
    </div>

    <!-- PHASE 2 -->
    <div class="phase"
      @click="$emit('select-phase', phases['collectData'])"
      :class="{ '-selected': selectedPhase.id == 'phase2'}"
    >
      <span class="tooltip-text">Phase 2</span>
    </div>

    <!-- MEILENSTEIN DL -->
    <div>
      <svg class="milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.76 40.76"><g id="a6015294-4aa7-4a72-ba76-dd18a4cf4c33" data-name="Ebene 2"><g id="b04e0895-b4e5-4266-a371-ec1a350f0a90" data-name="Ebene 1"><rect x="6.47" y="6.47" width="27.82" height="27.82" transform="translate(-8.44 20.38) rotate(-45)" style="fill:#fff;stroke:#c3c3c3;stroke-miterlimit:10"/><rect x="1.08" y="12.87" width="26.81" height="3.22" transform="translate(-6 14.48) rotate(-45)" style="fill:#22c5e6"/><rect x="12.71" y="24.51" width="26.84" height="3.22" transform="translate(-10.82 26.13) rotate(-45)" style="fill:#22c5e6"/><rect x="6.73" y="18.53" width="26.81" height="3.22" transform="translate(-8.35 20.14) rotate(-45)" style="fill:#22c5e6"/></g></g></svg>
    </div>

    <!-- PHASE 3 -->
    <div class="phase"
      @click="$emit('select-phase', phases['build'])" 
      :class="{ '-selected': selectedPhase.id == 'phase3'}"
    >
      <span class="tooltip-text">Phase 3</span>
    </div>
    <!-- MEILENSTEIN Daten-Freeze -->
    <div>
      <svg class="milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.17 42.17"><g id="e1519f39-79cf-43b5-b845-9f2b0559f74f" data-name="Ebene 2"><g id="f1d86aa6-5dea-481b-86e8-69e5a9854596" data-name="Ebene 1"><rect x="7.18" y="7.18" width="27.82" height="27.82" transform="translate(-8.73 21.09) rotate(-45)" style="fill:#2eabbc;stroke:#c3c3c3;stroke-miterlimit:10;stroke-width:2px"/></g></g></svg>
    </div>

    <!-- PHASE 4 -->
    <div class="phase"
      @click="$emit('select-phase', phases['grading'])" 
      :class="{ '-selected': selectedPhase.id == 'grading'}"
    >
      <span class="tooltip-text">{{ phases['grading'].title }}</span>
    </div>
    <!-- MEILENSTEIN Status VPE -->
    <div>
      <svg class="milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.17 42.17"><g id="e89637ef-63d0-4856-b94a-31396542769b" data-name="Ebene 2"><g id="e070535d-8ab2-4583-b87b-29638c932ff8" data-name="Ebene 1"><rect x="7.18" y="7.18" width="27.82" height="27.82" transform="translate(-8.73 21.09) rotate(-45)" style="fill:#22c5e6;stroke:#c3c3c3;stroke-miterlimit:10;stroke-width:2px"/></g></g></svg>
    </div>
  <div>
</template>

<script>
import GameComponentMixin from "../.././GameComponentMixin.js";
import Dashboard from "../Dashboard.vue";

export default {
  mixins: [GameComponentMixin],
  components: { Dashboard },

  props: {
    selected: {
      type: Boolean,
      default: false,
    },

    selectedPhase: {
      type: Object,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.container-phase-selector {
  display: flex;
  gap: 0.2rem;
  padding: 1.5rem 0;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 1.75em;
  border-top-left-radius: 1.75em;
}

.milestone {
  width: 2.5em;
  height: auto;
  
} 

.phase {
  width: 90px;
  height: 30px;
  background: white;
  position: relative;
  margin-right: 1.3em;
  filter: drop-shadow(1px 3px 4px rgba(0,0,0,0.2));
  margin-left: 12px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    left: -15px;
    bottom: 0;
    width: 10px;
    height: 0;
    border-left: 15px solid transparent;
    border-top: 15px solid white; /* hast to be the same color as the background */
    border-bottom: 15px solid  white; /* hast to be the same color as the background */
    border-right: 10px solid  white;
  }

  &:before {
    content: "";
    position: absolute;
    right: -15px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 15px solid  white;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }

  &.-selected {
    background: $color-primary;
    filter: drop-shadow(1px 3px 3px rgba(0, 0, 0, 0.4));

    &:after {
      border-top-color: $color-primary;
      border-bottom-color: $color-primary;
      border-right-color: $color-primary
    }
    &:before {
      border-left-color: $color-primary;
    }
  }

  .tooltip-text {
    visibility: hidden;
    width: max-content;
    font-size: 16px;
    background-color: white;
    border: 1px solid darkgray;
    text-align: center;
    border-radius: 2px;
    padding: 5px 5px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    pointer-events: none;
    z-index: 1;
  }

  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
}
</style>