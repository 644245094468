import { getRandomRequirements } from "./Requirements.js";
import { getRandomQuizQuestion } from "./Quiz.js";
import { methods } from "./Methods.js";
import { dataCategories, TRIANGLE, WHEEL } from "./Data.js";
import { roles } from "./Roles.js";


export function createFieldGenerator() {
  let fieldIdCounter = 1;
  return function(type, options = {}) {
    options.id = fieldIdCounter++;
    return createField(type, options);
  }
}

export function createField(type, options = {}) {
  const { id = 0, hasRole = false, isCollaborative = false } = options;
  const field = Object.assign({}, fieldTemplates[type]);
  field.id = id;
  field.type = type;
  field.hasRole = hasRole;
  field.hasQuiz = false;
  field.isCollaborative = isCollaborative;
  return field;
}

export const fieldTypes = {
  GET_REQUIREMENT: 'get-requirement',
  GET_METHOD: 'get-method',
  GET_DATA: 'get-data',
  VIEW_DATABASE: 'view-database',
  COLLECT_DATA: 'collect-data',
  COLLECT_FROM_DATABASE: 'catalog',
  BUILD: 'build',
  SIMULATE: 'simulate',
  OPTIMIZE: 'optimize'
};

export const fieldTemplates = {
  // ABSCHNITT 1
  [fieldTypes.GET_REQUIREMENT]: {
    title: "Anforderungen",
    description: "Du erhältst eine Anforderung.",
    role: "eigenschaftsverantwortlicher", // Rollen-ID (siehe ./Resources/Roles.js),
    icon: "dokument"
  },
  [fieldTypes.GET_METHOD]: {
    title: 'Simulationsmethoden',
    description: 'Du erhältst eine zufällige Simulationsmethode.',
    role: "simulationsexperteA",
    icon: "prozess"
  },
  [fieldTypes.GET_DATA]: {
    title: "Benötigte Daten",
    description: "Du darfst einen Datensatz \"aufdecken\".",
    role: "datenmanagerA",
    icon: "daten"
  },
  [fieldTypes.VIEW_DATABASE]: {
    title: "Datenbank",
    description: "Du darfst in die Datenbank schauen.",
    role: "konstrukteurA",
    icon: "bildschirm"
  },

  // Abschnitt 2
  [fieldTypes.COLLECT_DATA]: {
    title: "Datenbereitstellung (Zufall)",
    description: "Du erhältst die vereinbarte Anzahl von Daten (zufällig).",
    role: "konstrukteurB",
    icon: "download"
  },
  [fieldTypes.COLLECT_FROM_DATABASE]: {
    title: "Daten aus Datenbank",
    description: "Der Spieler sammelt die Daten ein, die er je Kategorie vereinbart hat.",
    role: "datenmanagerB",
    icon: "bildschirm"
  },

  // ABSCHNITT 3
  [fieldTypes.BUILD]: {
    title: "Modellaufbau",
    description: "Aufbau des Fahrzeugs.",
    role: "simulationsexperteB",
    icon: "auto"
  },
  [fieldTypes.SIMULATE]: {
    title: "Auslegungssimulation",
    description: "Überprüfung der Anforderungen.",
    role: "datenmanagerC",
    icon: "prozess"
  },
  [fieldTypes.OPTIMIZE]: {
    title: "Daten optimieren",
    description: "Austausch von Bauteilen, die nicht geeignet sind.",
    role: "konstrukteurC",
    icon: "wiederholung"
  },
};


export function enterField(G, ctx, field) {
  const player = G.players[ctx.currentPlayer];

  let currentMoveData = {
    confirmedTurn: true,
    area: player.area,
    field: player.field,
  };

  if (field.hasQuiz) {
    const quizQuestion = getRandomQuizQuestion(ctx, player.area, G.answeredQuizQuestions);
    if (quizQuestion == null) {
      currentMoveData.quizQuestion = null;
      currentMoveData.handledQuizQuestion = true;
    }
    else {
      const shuffledQuizOptions = [];
      const optionsCopy = quizQuestion.options.slice();
      while (optionsCopy.length > 0) {
        const [op] = optionsCopy.splice(Math.floor(ctx.random.Number() * optionsCopy.length), 1);
        shuffledQuizOptions.push(op);
      }
      currentMoveData.quizQuestion = {
        id: quizQuestion.id,
        question: quizQuestion.question,
        shuffledOptions: shuffledQuizOptions,
        answer: null
      };
      currentMoveData.solvedQuizQuestion = null;
      currentMoveData.handledQuizQuestion = false;
    }
  }

  // ROLE:
  currentMoveData.solvedRoleQuiz = false;
  currentMoveData.roleActionDone = false;
  currentMoveData.answeredRoleQuestion = false;
  if (field.hasRole) {
    // randomly get one of the roles questions:
    const fieldRole = roles[field.role];
    const roleQuestionIndex = Math.floor(ctx.random.Number() * fieldRole.quizQuestions.length);
    const randomRoleQuestion = fieldRole.quizQuestions[roleQuestionIndex];
    currentMoveData.roleQuestion = randomRoleQuestion;
  }

  switch (field.type) {

    case fieldTypes.GET_REQUIREMENT:
      // get 3 random requirements:
      const requirements = getRandomRequirements(ctx, 3);

      // Erweitere die Daten des aktuellen Zugs:
      Object.assign(currentMoveData, {
        requirements,
        selectedAction: null, // eine aus: 'use', 'giveAway'
        selectedRequirement: null,
        selectedReqToReplace: null,
        selectedReqToDelete: null,
        confirmedRequirement: false,
        confirmedUse: false,
        confirmedDiscard: false,
        confirmedSwitch: false,
        switchedRequirements: false,
        hasRedrawn: false
      });

    break;

    case fieldTypes.GET_METHOD:
      // Mische die Methoden:
      const methodsCopy = methods.slice();
      const shuffledMethods = [];
      while (methodsCopy.length > 0) {
        const randomIndex = Math.floor(ctx.random.Number() * methodsCopy.length);
        const [entry] = methodsCopy.splice(randomIndex, 1);
        shuffledMethods.push(entry);
      }

      // Daten erweitern:
      Object.assign(currentMoveData, {
        shuffledMethods,
        selectedMethod: null,
        selectedAction: null
      });
    break;

    case fieldTypes.GET_DATA:
      // Erstelle drei zufällige Datenpakete
      const datasCopy = dataCategories.slice();
      const randomDatas = [];
      for(let i=0; i < 3; i++) {
        // Hole Kategorie
        const randomIndex = Math.floor(ctx.random.Number() * datasCopy.length);
        const [dataPacket] = datasCopy.splice(randomIndex, 1);

        // Datenmenge
        if (dataPacket.id == WHEEL) {
          // Stelle sicher, dass Räder immer in gerader Anzahl auftreten
          dataPacket.amount = Math.ceil(ctx.random.Number() * 4) * 2;
        }
        else if (dataPacket.id == TRIANGLE) {
          dataPacket.amount = Math.round(ctx.random.Number() * 4 + 2); // 2 - 6
        }
        else {
          dataPacket.amount = Math.round(ctx.random.Number() * 6 + 2); // 2 - 8
        }
        randomDatas.push(dataPacket);
      }

      Object.assign(currentMoveData, {
        randomDatas,
        category: null,
        amount: null,
        title: null,
        confirmed: false,
        hasSelectedData: false,
        selectedData: [],
      });
    break;

    case fieldTypes.VIEW_DATABASE:
      const brokenCategories = dataCategories.map(c => c.id);
      const brokenAmount = 1 + Math.floor(ctx.random.Number() * 3);
      while (brokenCategories.length > brokenAmount) {
        brokenCategories.splice(Math.floor(ctx.random.Number() * brokenCategories.length), 1);
      }

      Object.assign(currentMoveData, {
        brokenCategories,
        databaseOpenUntil: null,
        isDatabaseOpen: false,
        hasSeenDatabase: false,
        showCollaborationConfirm: false
      });
    break;

    case fieldTypes.COLLECT_DATA:
      Object.assign(currentMoveData, {
        category: null,
        title: null,
        parts: [],
        hasReselected: false,
        startedReroll: null,
        partIDsToReroll: [],
        confirmed: false,
      });
    break;

    case fieldTypes.COLLECT_FROM_DATABASE:
      Object.assign(currentMoveData, {
        category: null,
        parts: [],
        isDatabaseOpen: false,
        hasSelectedNewParts: false,
        hasConfirmedNewParts: false
      });
    break;


    case fieldTypes.BUILD:
      Object.assign(currentMoveData, {
        startedAt: null,
        builderActive: false,
        maxTime: 60
      });
    break;

    case fieldTypes.SIMULATE:
      Object.assign(currentMoveData, {
        methodId: null,
        simulationStarted: false,
        simulationDone: false,
        simulationComplete: false,
        startedAt: null, // started viewing database at
        isDatabaseOpen: false,
        hasRequestedDatabase: false,
        databaseOpenUntil: null,

        // Ergebnisse der Messung:
        carHeight: null,
        carWidth: null,
        carGroundDistance: null,
        carWeight: null,
        carDistanceTravelled: null
      });
    break;

    case fieldTypes.OPTIMIZE:
      Object.assign(currentMoveData, {
        // DATENBANK
        isDatabaseOpen: false,

        // TEILE
        partsToDiscard: [],
        parts: [], // []
        category: null,

        wantsToReturnParts: null,
        partsToDiscardSelected: false,
        partsToDiscardConfirmed: false,
        hasSelectedNewParts: false,
        hasConfirmedNewParts: false
      });
    break;


    default:
      console.warn("Keine Aktion für", field.type, "definiert.");
      ctx.events.endTurn();
      break;
  }

  currentMoveData = addCollaborationData(currentMoveData);

  // selected data
  currentMoveData.databaseSelectedCategory = null;
  currentMoveData.databaseSelectedParts = [];
  
  G.currentMove = currentMoveData;
}

function addCollaborationData(options) {
  return {
    collaborationSelected: null,
    selectedReceiver: null,
    confirmedReceiver: false,
    ...options
  };
}