<template>
  <portal :to="portalTarget" :order="order">
    <div
      class="game-dialog"
      :class="[targetClass, {
        '-has-border': hasBorder,
        '-extra-wide': extraWide,
        '-has-backdrop': backdrop
      }]"
      v-bind="{...$attrs}"
    >
      <div class="game-dialog__content" :class="{'-has-padding': hasPadding }">
        <slot :commands="{ close }"></slot>
      </div>
    </div>
  </portal>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";
export default {
  mixins: [GameComponentMixin],
  props: {
    portalTarget: {
      type: String,
      default: "dialogs"
    },
    order: {
      type: Number,
      default: 1
    },
    hasPadding: {
      type: Boolean,
      default: true
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    targetClass: {
      type: String,
      default: ""
    },
    extraWide: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: true
    };
  },
  computed: {
    commands() {
      return {
        close: this.close
      }
    }
  },
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss">
.game-dialog {
  pointer-events: all;
  background-color: #fff;
  max-width: 32rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.2);
  width: 100%;
  z-index: 1;
  max-height: 100%;
  overflow: auto;

  &.-extra-wide {
    max-width: 46rem;
  }
  
  &.-has-border {
    border: 3px solid var(--active-player-color);
  }

  &.-has-backdrop {
    box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.2), 0 0 0 5000px rgba(0,0,0,0.2);
  }

  // the parent of the dialog
  &__container {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    &.-has-padding {
      padding: 2rem;
    }
  }
}
</style>