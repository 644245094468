<template>
  <game-dialog target-class="database-dialog" portal-target="overlays" :backdrop="true">

    <header class="database-dialog__header">
      <div>
        <button-active-player
          type="secondary"
          v-if="category === null && currentMove.databaseSelectedCategory !== null"
          @click="moves.updateCurrentMove({
            databaseSelectedCategory: null
          })"
        >
          Zurück
        </button-active-player>
      </div>

      <h2 class="database-dialog__title">
        Datenbank
      </h2>

      <div>
        <timer
          class="database-dialog__timer"
          v-if="endTime"
          :end-time="endTime"
          @expired="closeDatabase()"
          @end="closeDatabase()"
        ></timer>
      </div>
    </header>

    <!-- AKTIVER SPIELER -->
    <template v-if="isActivePlayer || viewableByInactive">
      <div v-if="selectedCategory === null" class="database-dialog__categories">
        <button
          v-for="category in dataCategories"
          :key="category.id"
          :disabled="brokenCategories.includes(category.id)"
          class="database-dialog__categorybutton"
          @click="selectCategory(category)"
        >
          <span v-if="brokenCategories.includes(category.id)">
            DATEN NICHT VERFÜGBAR
          </span>
          <span v-else>
            Kategorie {{ (1 * category.id) + 1 }} – {{ category.title }}
          </span>
        </button>
      </div>

      <div v-else class="database-dialog__category">
        <h3 class="database-dialog__subtitle">
          Kategorie {{ (1 * selectedCategory.id) + 1 }} – {{ selectedCategory.title }}
        </h3>

        <div class="database-dialog__parts">
          <button
            v-for="part in categoryParts"
            :key="part.uuid"
            :disabled="!isActivePlayer || hasMaxParts"
            :class="{'-is-selected': isPartSelected(part) }"
            class="database-dialog__part"
            @click="selectPart(part)"
          >
            <car-part :part="part"></car-part>
            <div>
              <div v-if="part.type === 'constraint'">
                <div v-if="part.length === 0">
                  <div>Achse</div>
                  <p>Nötig, um Rad zu befestigen</p>
                </div>
                <div v-else>
                  <div>
                    Schraube
                    <span v-if="part.vertical">(Vertikal)</span>
                    <span v-else>(Horizontal)</span>
                  </div>
                  <p>Nötig, um zwei Bauteile zu verbinden</p>
                </div>
              </div>
              <template v-if="part.label === 'Wheel'">
                <div>Durchmesser: {{ part.width }} cm</div>
              </template>
              <template v-else-if="part.type === 'body'">
                <div>Gewicht: {{ part.weight }} kg</div>
                <div>Länge: {{ part.width }} cm</div>
                <div>Höhe: {{ part.height }} cm</div>
              </template>
            </div>
          </button>
        </div>
      </div>

      <div v-if="selectedPartOrders.length > 0">
        <h3 class="database-dialog__subtitle">
          Ausgewählt:
          <span v-if="maxSelectable !== null">
            ({{ totalSelected }} / {{ maxSelectable }})
          </span>
        </h3>
        <div class="database-dialog__cartparts">
          <div
            v-for="part in selectedPartOrders"
            :key="part.uuid"
            class="database-dialog__part -no-hover"
          >
            <car-part :part="part"></car-part>
            <div class="database-dialog__part__btn">
              <span>x{{ part.amount }}</span>
              <button
                class="button button--danger button--slim"
                title="Wieder entfernen"
                @click="deselectPart(part)"
              >-</button>
            </div>
          </div>
        </div>
      </div>

      <button-active-player
        v-if="endTime == null"
        class="database-dialog__close"
        :disabled="!hasMinParts"
        @click="closeDatabase()"
      >
        Fertig
      </button-active-player>
    </template>
    <template v-else>
      <div class="text-center">
        {{ playerNames[activePlayer.id] }} betrachtet die Datenbank.
      </div>
    </template>
  </game-dialog>
</template>

<script>
import _ from "lodash";
import GameComponentMixin from "../GameComponentMixin.js";

import GameDialog from "../UIElements/GameDialog.vue";
import CarPart from "../Car/CarPart.vue";
import Timer from "../UIElements/Timer.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";

export default {
  mixins: [GameComponentMixin],
  components: {
    GameDialog,
    CarPart,
    Timer,
    ButtonActivePlayer
  },
  props: {
    category: {
      type: Number,
      default: null
    },
    maxSelectable: {
      type: Number,
      default: Infinity
    },
    minSelectable: {
      type: Number,
      default: 0
    },
    viewableByInactive: {
      type: Boolean,
      default: true
    },
    brokenCategories: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    endTime() {
      if (this.currentMove.databaseOpenUntil == null) {
        return null;
      }
      return this.currentMove.databaseOpenUntil;
    },
    selectedCategoryId() {
      if (this.category === null) {
        return this.currentMove.databaseSelectedCategory;
      }
      return this.category;
    },
    selectedCategory() {
      return this.dataCategories.find(cat => cat.id === this.selectedCategoryId) || null;
    },
    categoryParts() {
      return this.gameState.data[this.selectedCategoryId];
    },
    selectedPartOrders() {
      if (this.currentMove.parts == null) {
        return [];
      }

      const partOrders = [];

      for (const part of this.currentMove.parts) {
        let partOrder = partOrders.find(p => (
          p.databaseIndex == part.databaseIndex &&
          p.category == part.category
        ));

        if (!partOrder) {
          partOrders.push({
            amount: 1,
            ...this.gameState.data[part.category][part.databaseIndex]
          });
        }
        else {
          partOrder.amount++;
        }
      }

      return partOrders;
    },
    totalSelected() {
      return this.selectedPartOrders.reduce(
        (sum, part) => sum + part.amount,
        0
      );
    },
    hasMaxParts() {
      return this.totalSelected >= this.maxSelectable;
    },
    hasMinParts() {
      return this.totalSelected >= this.minSelectable;
    }
  },
  methods: {
    selectCategory(category) {
      if (this.brokenCategories.includes(category.id)) {
        return false;
      }

      this.moves.updateCurrentMove({
        databaseSelectedCategory: category.id
      });
    },
    selectPart(part) {
      if (this.hasMaxParts) {
        return false;
      }

      this.moves.addPartToCart({
        category: part.category,
        databaseIndex: part.databaseIndex,
        amount: 1
      });
    },
    deselectPart(part) {
      return this.moves.removePartFromCart({
        category: part.category,
        databaseIndex: part.databaseIndex
      });
    },
    isPartSelected(part) {
      return this.selectedPartOrders.findIndex(p => (
        p.databaseIndex == part.databaseIndex &&
        p.category == part.category
      )) != -1;
    },
    closeDatabase() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss">
.database-dialog {
  color: $color-text;
  max-width: 42rem;

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    color: $color-text;
  }
  &__title {
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 0.5rem;
  }

  &__categories {
    padding-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
  &__categorybutton {
    display: block;
    width: 100%;
    text-align: left;
    background-color: $color-uielement-bg;
    border: 1px solid $color-uielement-border;
    border-radius: 0.25em;
    padding: 0.5em 1rem;
    &:hover {
      background-color: #fff;
      cursor: pointer;
    }
    &:disabled {
      background-color: $color-uielement-text;
      color: $color-uielement-text-dark;
    }
  }

  &__category {
    color: $color-text;
  }

  &__parts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 0.5rem;
  }

  &__part {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) 1fr;
    gap: 0.5em;
    text-align: left;
    background-color: $color-uielement-bg;
    border: 1px solid $color-uielement-border;
    padding: 0.5em;
    border-radius: 0.25em;

    &__btn {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 0.5rem;
    }

    &:hover {
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
    &.-no-hover {
      background-color: #fff;
    }
    &.-no-hover:hover {
      box-shadow: none;
    }

    &:disabled {
      background-color: #fff;
      filter: none;
      box-shadow: none;
    }
  }

  &__cartparts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  &__timer {
    font-size: 1.25em;
  }

  &__close {
    margin-top: 1rem;
  }
}
</style>