<template>
  <div
    v-if="selectedPlayer !== null"
    class="grid-container"
    :class="[`-player-${selectedPlayer.id}`]"
  >
    <div class="section -premise">
      <premise-card
        :premise="selectedPlayer.premise"
        :small="true"
        :face-up="true"
      ></premise-card>
    </div>

    <div class="section -requirements">
      <!-- ANFORDERUNGEN -->
      <requirement-card
        v-for="requirements in selectedPlayer.requirements"
        :key="requirements.id"
        :requirement="requirements"
        :small="true"
        :face-up="true"
      ></requirement-card>

      <requirement-card
        v-for="(requirements, index) in unfilledRequirements"
        :key="'index'+index"
        :face-up="false"
        :small="true"
      ></requirement-card>
    </div>

    <div class="section -methods">
      <!-- METHODEN -->
      <method-card
        v-for="methods in selectedPlayer.methods"
        :key="methods.id"
        :method="{label: methods.label}"
        :face-up="true"
        :small="true"
        :wide="true"
      ></method-card>

      <method-card
        v-for="(methods, index) in unfilledMethods"
        :key="'index'+index"
        :face-up="false"
        :small="true"
        :wide="true"
      ></method-card>

    </div>

    <div class="section -datas">
      <!-- DATEN -->
      <data-card
        v-for="(amount, category) in selectedPlayer.partRequests"
        :key="category"
        :category="{ title: dataCategories[category].title, id: category }"
        :amount="amount"
        :face-up="true"
        :small="true"
      ></data-card>

      <data-card
        v-for="(datas, index) in unfilledDatas"
        :key="'index'+index"
        :face-up="false"
        :small="true"
      ></data-card> 
    </div>
  </div>
</template>



<script>
import GameComponentMixin from "../.././GameComponentMixin.js"
import DataCard  from "../../Cards/DataCard.vue"
import RequirementCard from "../../Cards/RequirementCard.vue"
import MethodCard from "../../Cards/MethodCard.vue"
import PremiseCard from "../../Cards/PremiseCard.vue"


export default {
  mixins:[GameComponentMixin],
  components:{
  DataCard,
  RequirementCard,
  MethodCard,
  PremiseCard,

  },
  props: {
    selectedPlayer: {
      type: Object,
      default: null
    }
  },

  computed: {
    unfilledRequirements() {
      var Output="";
      for(var requirements in this.selectedPlayer.requirements){
        Output++;
      }
      return Array(3-Output)
    },

    unfilledMethods() {
      var Output="";
      for(var methods in this.selectedPlayer.methods){
        Output++;
      }
      return Array(3-Output)

    },

    unfilledDatas() {
      var Output="";
      for(var datas in this.selectedPlayer.partRequests){
        Output++;
      }
      return Array(5-Output)

    }

  }

}
</script>

<style lang="scss">

.grid-container{
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.section{
  border-width: 0.1rem;
  border-bottom-style: solid;
  border-color: lightgray; 
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;

  display: grid;
  gap: 1rem;

  &.-premise {
    grid-column: 1 / 2;
    border-right-style: solid;
    padding-top: 1.5em;
  }

  &.-requirements {
    grid-column: 2 / end;
    padding-top: 1.5em;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    justify-items: center;
  }

  &.-methods {
    grid-column: 1 / end;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }

  &.-datas {
    grid-column: 1 / end;
    border-bottom: none;
    padding-bottom: 1.5em;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}

</style>