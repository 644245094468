<template>
  <div class="page-login">
    <div class="page-joinmatch__before">
      <a href="https://www.haw-landshut.de/" target="_blank">
        <img src="../assets/hs-landshut-logo.jpg" alt="Hochschule Landshut Logo" class="page-joinmatch__logo" />
      </a>
    </div>
    <login-form class="page-login__form" @submit="onSubmit"></login-form>
    <game-footer></game-footer>
  </div>
</template>

<script>
import { post, setToken } from "../services/api";
import LoginForm from '../components/admin/LoginForm.vue';
import GameFooter from "../components/ui/GameFooter.vue";
export default {
  components: {
    LoginForm,
    GameFooter
  },
  methods: {
    async onSubmit(data) {
      try {
        const { token } = await post("/auth/login", data);
        setToken(token);
        this.$router.push({ name: 'matches' });
      } catch(error) {
        if (error.status === 400) {
          alert("Es gibt kein Konto mit diesen Anmeldedaten. E-Mail oder Passwort stimmen nicht.");
        }
        else if (error.status === 500) {
          alert("Auf dem Spieleserver ist ein Fehler aufgetreten.");
        }
        else {
          alert("Es ist ein unbekannter Fehler aufgetreten.");
          console.log(error.status);
          console.log(error.details);
        }
      }
    }
  }
}
</script>

<style lang="scss">
.page-login {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F6F6F6;

  &__before {
    display: flex;
    padding: 1rem;
  }

  &__form {
    margin: auto auto;
    margin-top: 4rem;
  }
}
</style>