<template>
  <div
    v-if="selectedPlayer"
    class="dashboard-phase3"
    :class="[`-player-${selectedPlayer.id}`]"
  >
    <div class="dashboard-phase3__modelcontainer">
      <h3>Modell</h3>
      <div class="dashboard-phase3__model">
        <car :parts="selectedBuiltParts" class="dashboard-phase3__car"></car>
        <div class="dashboard-phase3__partamount">
          Anzahl verbauter Teile: {{ selectedMainBuiltParts.length }}
        </div>
      </div>
    </div>
    <div class="dashboard-phase3__premise">
      <h3>Produktziel</h3>
      <premise-card
        :premise="selectedPlayer.premise"
      ></premise-card>
    </div>
    
    <div class="dashboard-phase3__data">
      <div>
        <h3>Anforderungen</h3>
        <div class="dashboard-phase3__requirements">
          <div
            v-for="requirement in selectedPlayer.requirements"
            :key="requirement.id"
            class="dashboard-phase3__requirement"
          >
            <div>{{ requirement.label }}</div>
            <div>{{ requirement.value }}{{ requirement.unit }}</div>
          </div>
        </div>
      </div>
      <div>
        <h3>Simulationsergebnisse</h3>
        <div class="dashboard-phase3__simulationresults">
          <div
            v-for="requirement in selectedPlayer.requirements"
            :key="requirement.id"
            class="dashboard-phase3__simulationresult"
          >
            <div>{{ requirement.label }}</div>
            <div>{{ selectedPlayer.simulationResults[requirement.type] || "— " }}{{ requirement.unit }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameComponentMixin from "../../GameComponentMixin.js";
import PremiseCard from "../../Cards/PremiseCard.vue";
import Car from "../../Car/Car.vue";
export default {
  mixins: [GameComponentMixin],
  components: {
    PremiseCard,
    Car
  },
  props: {
    selectedPlayer: {
      type: Object,
      default: null
    }
  },
  computed: {
    selectedBuiltParts() {
      return this.selectedPlayer.parts.filter(
        part => this.selectedPlayer.builtPartIds.includes(part.uuid)
      );
    },
    selectedMainBuiltParts() {
      // only rectangles and triangles
      return this.selectedBuiltParts.filter(p => p.type === 'body' && !p.label.includes("Wheel"));
    }
  }
}
</script>

<style lang="scss">
.dashboard-phase3 {
  display: block;
  color: $color-text;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1.5rem;
  font-size: 14px;

  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  &__modelcontainer {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
  }
  &__model {
    position: relative;
    min-height: 9rem;
    background-color: #FBFAFA;
    border: 1px solid $color-uielement-border;
    padding: 1.5rem;
    overflow: auto;
    width: 100%;
    scrollbar-width: thin;
  }
  &__partamount {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.5em 0.75em;
    font-weight: 600;
  }
  &__car {
    max-width: 100%;
    margin: 0 auto;
  }

  &__data {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    & > div {
      display: flex;
      flex-direction: column;
    }
  }

  &__requirements,
  &__simulationresults {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    height: 100%;
  }

  &__requirement,
  &__simulationresult {
    border: 1px solid $color-uielement-border;
    padding: 0 1rem;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.25em;
    width: 100%;
    line-height: 1.5;
  }
}
</style>