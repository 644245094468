<template>
  <div class="teammate-selector">
    <button
      :class="{
        '-open': selectedTeammateId == myself.id,
        [`-player-${myself.id}`]: selectedTeammateId == myself.id,
      }"
      @click="$emit('select-teammate', myself)"
    >
      Ich
    </button>

    <button
      v-for="player in otherPlayers"
      :key="player.id"
      :class="[
        {
          '-open': selectedTeammateId == player.id,
          [`-player-${player.id}`]: selectedTeammateId == player.id
        }
      ]"
      @click="$emit('select-teammate', player)"
    >
      {{ playerNames[player.id] }}
    </button>

  </div>
</template>

<script>
import GameComponentMixin from "../.././GameComponentMixin.js"
import Dashboard from '../Dashboard.vue'

export default {
  mixins:[GameComponentMixin],
  components: { Dashboard },
  props: {
    selectedTeammateId: {
      type: String,
      default: null
    }
  },
  computed: {
    otherPlayers() {
      return Object.values(this.players).filter(player => player.id !== this.myself.id);
    }
  }

}
</script>

<style lang="scss">

.teammate-selector {
  overflow: hidden;
  flex-shrink: 0;
}

.teammate-selector button {
  background-color: $color-tab-button;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 16px;
  transition: 0.3s;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  width: 7em;
  color: #fff;
  font-weight: 600;

  &:hover{
    background-color: #ddd;
  }

  &.-open {
    background-color:white;
  }

  &.-player-0 {
    background-color: $color-player-0;
  }

  &.-player-1 {
    background-color: $color-player-1;
  }

  &.-player-2 {
    background-color: $color-player-2;
  }

  &.-player-3 {
    background: $color-player-3;
  }

}


</style>