<template>
  <article class="board-screen">
    <div class="board-screen__content">
      <div class="board-screen__movementpoints">
        Bewegungspunkte:
        {{ myself.movementPoints }}
      </div>

      <board-area
        class="board-screen__board"
        :area="currentArea"
        @go-to-field="moves.selectField"
      ></board-area>

      <div class="board-screen__dashboards">
        <dashboard class="board-screen__dashboard"></dashboard>

        <!-- All dialogs go here -->
        <div class="board-screen__dialogbox">
          <div class="board-screen__activeplayer" :class="{
            '-is-active': amIActive
          }">
            <span v-if="amIActive">
              Du bist am Zug
            </span>
            <span v-else>
              {{ playerNames[activePlayer.id] }} ist am Zug
            </span>
          </div>
          <portal-target name="dialogs" class="board-screen__dialogs" multiple></portal-target>
        </div>
      </div>

      <game-footer></game-footer>
    </div>


    <confirm-move-dialog v-if="currentMove.selectedField != null && !currentPlayer.movedThisTurn"></confirm-move-dialog>

    <dialogs-escalation-1
      v-else-if="(
        gameState.currentEscalation.useEscalation === true &&
        gameState.currentEscalation.id === 'escalation-1'
      )"
    ></dialogs-escalation-1>
    <dialogs-escalation-2
      v-else-if="(
        gameState.currentEscalation.useEscalation === true &&
        gameState.currentEscalation.id === 'escalation-2'
      )"
    ></dialogs-escalation-2>

    <!-- Rundenstart-Dialog -->
    <turn-start-dialog v-else-if="!currentMove.confirmedTurn"></turn-start-dialog>

    <game-dialog v-else-if="!currentPlayer.movedThisTurn">
      <div class="text-center">
        <span v-if="isActivePlayer">Wähle ein Feld.</span>
        <span v-else>{{ playerNames[activePlayer.id] }} wählt ein Feld.</span>
      </div>
    </game-dialog>

    <!-- Dialoge des aktuellen Felds -->
    <field-dialogs v-else></field-dialogs>
  </article>
</template>

<script>
// Mixins
import GameComponentMixin from "../GameComponentMixin.js";

// Komponenten
import BoardArea from "../Board/BoardArea.vue";
import PlayerOverview from "../PlayerOverview.vue";
import FieldDialogs from "../FieldDialogs/FieldDialogs.vue";
import Dashboard from "../Dashboards/Dashboard.vue";

// Eskalations-Dialoge
import TurnStartDialog from "../FieldDialogs/TurnStartDialog.vue";
import DialogsEscalation1 from "../FieldDialogs/DialogsEscalation1.vue";
import DialogsEscalation2 from "../FieldDialogs/DialogsEscalation2.vue";
import ConfirmMoveDialog from "../FieldDialogs/ConfirmMoveDialog.vue";
import GameDialog from "../UIElements/GameDialog.vue";

import GameFooter from "../../ui/GameFooter.vue";

export default {
  components: {
    BoardArea,
    PlayerOverview,
    FieldDialogs,
    Dashboard,
    DialogsEscalation1,
    DialogsEscalation2,
    TurnStartDialog,
    ConfirmMoveDialog,
    GameDialog,
    GameFooter
  },
  mixins: [GameComponentMixin]
}
</script>

<style lang="scss">
.board-screen {
  background-color: #fafafa;
  height: 100%;
  overflow: auto;

  &__content {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2rem min-content 1fr min-content;
  }

  &__dashboards {
    align-self: start;
    display: grid;
    grid-template-columns: min-content minmax(0, 1fr);
    gap: 2em;
    margin-bottom: 4em;
    justify-items: center;
    height: min-content;
    width: 100%;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 0;
    min-height: min-content;
  }

  &__dashboard {
    justify-self: end;
    height: min-content;
    max-width: 60rem;
    margin-bottom: 2rem;
  }

  &__dialogbox {
    justify-self: start;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2.25rem 1fr;
    gap: 0.25rem;
    width: 100%;
    min-height: min-content;
    background-color: var(--active-player-color-medium);

  }

  &__dialogs {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    width: 100%;
    max-height: calc(100vh - 18rem);
  }

  &__activeplayer {
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 1.3rem;
    margin-top: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    text-align: center;
    background-color: var(--active-player-color);
    font-weight: 600;
    color: #fff;
    width: auto;
    height: 2em;

    &.-is-active {
      background-color: var(--player-color);
      color: #fff;
    }
  }

  &__movementpoints {
    background-color: #fff;
    padding: 1em 1.25em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    width: max-content;
    height: 2rem;
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    color: $color-text;
    font-weight: 600;
    margin-left: 1rem;
  }
}
</style>