<template>
  <div class="car-builder">
    <div class="car-builder__main -in-mode-build" @contextmenu.prevent="">

      <!-- KATALOG -->
      <div class="car-builder__catalog" ref="catalog" v-if="parts">
        <details-element
          v-for="category in categories"
          :key="category.id"
        >
          <template v-slot:summary>
            <h3 class="car-builder__catalogtitle">
              {{ category.title }}
            </h3>
            <div class="car-builder__cataloginfo">
              <span>
                Gesamt: {{ partInCategoryCount[category.id] }}
              </span>
              <span>
                Unverbaut: {{ Object.values(freePartsByCategoryAndIndex[category.id] || {}).reduce((sum, arr) => sum + arr.length, 0) }}
              </span>
            </div>
          </template>
          <template v-slot:default>
            <div class="car-builder__parts">
              <div
                v-for="(partGroup, databaseIndex) in freePartsByCategoryAndIndex[category.id]"
                :key="databaseIndex"
                class="car-builder__part"
              >
                <car-part
                  :part="partGroup[0]"
                  :parts="parts"
                  :drop-blocked="dropBlocked"
                  :use-position="true"
                  @mounted="addCarPartDragListener"
                ></car-part>
                <span class="car-builder__part__label">
                  x{{ partGroup.length }}
                </span>
              </div>
            </div>
          </template>
        </details-element>
      </div>

      <!-- BUILD AREA -->
      <div class="car-builder__buildarea" ref="droptarget">
        <div class="car-builder__floor"></div>
        <car-part
          v-for="part in builtParts"
          :key="part.uuid"
          :part="part"
          :parts="parts"
          :drop-blocked="dropBlocked"
          :use-position="true"
          :show-border="true"
          @mounted="addCarPartDragListener"
        ></car-part>

        <div class="car-builder__dashboard">
          <div class="car-builder__requirements">
            <icon class="car-builder__icon" name="dokument"></icon>
            <article
              v-for="requirement in player.requirements"
              :key="requirement.id"
              class="car-builder__requirement"
            >
              <div class="">{{ requirement.label }}</div>
              <div class="">{{ requirement.value }}{{ requirement.unit }}</div>
            </article>
          </div>
          <div class="car-builder__simulationresults">
            <icon class="car-builder__icon" name="prozess"></icon>
            <article
              v-for="requirement in player.requirements"
              :key="`result-${requirement.id}`"
              class="car-builder__simulationresult"
            >
              <div class="">{{ player.simulationResults[requirement.type] == null ? "— " : player.simulationResults[requirement.type] }}{{ requirement.unit }}</div>
            </article>
          </div>
          <premise-card
            class="car-builder__premise"
            :premise="player.premise"
            :small="true"
          ></premise-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import interact from "interactjs";
import { Query } from "matter-js";
import Vue from "vue";
import _ from "lodash";

import {
  createRectangleData,
  createWheelData,
  createConstraintData,
  createTriangleData,
  getCompositeData,
  compositeFromData,
  bodyFromPoints,
  bodyToData,
  bodyFromData,
  setBodyPosition,
  GRID_SIZE,
  rotateBody
} from "../../../../car/carPartCreator";

import DetailsElement from "../UIElements/DetailsElement.vue";
import CarPart from "./CarPart.vue";
import CarPartConfigurator from "./CarPartConfigurator.vue";
import Icon from '../../ui/Icon.vue';
import PremiseCard from "../Cards/PremiseCard.vue";
import { WHEEL } from '../../../../game/Resources/Data';
import { getAxleConnectionAt } from "../../../../game/Moves.js";

const CONSTRAINT_MARGIN = GRID_SIZE * 1.5; // ADJUST THIS MARGIN IN  CarPart.vue  TOO!

const cursorModes = {
  BUILD: 0,
  ROTATE: 1,
  DELETE: 2
};

export default {
  components: {
    CarPart,
    CarPartConfigurator,
    DetailsElement,
    Icon,
    PremiseCard
  },
  props: {
    player: {
      type: Object,
      default() {
        return {};
      }
    },
    carPartsData: {
      type: Array
    },
    categories: {
      type: Array
    },
    builtPartIds: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      cursorMode: cursorModes.BUILD,
      cursorModes: cursorModes,
      snapOffset: { x: 0, y: 0},
      dropzoneReady: false,
      dropBlocked: false,
      savedCars: []
    };
  },
  computed: {
    parts() {
      return this.carPartsData.slice();
    },
    partInCategoryCount() {
      return _.mapValues(this.categories, category => {
        if (category.id in this.partsInCategory) {
          return this.partsInCategory[category.id].length;
        }
        return 0;
      });
    },
    partsInCategory() {
      return this.parts.reduce((acc, part) => {
        if (part.category in acc) {
          acc[part.category].push(part);
        }
        else {
          acc[part.category] = [part];
        }
        return acc;
      }, {});
    },
    freePartsByCategoryAndIndex() {
      return this.freeParts.reduce((acc, part) => {
        if (part.category in acc) {
          const category = acc[part.category];
          if (part.databaseIndex in category) {
            category[part.databaseIndex].push(part);
          }
          else {
            category[part.databaseIndex] = [part];
          }
        }
        else {
          acc[part.category] = {
            [part.databaseIndex]: [part]
          };
        }
        return acc;
      }, {});
    },
    partsById() {
      return this.parts.reduce((acc, part) => { acc[part.uuid] = part; return acc; }, {});
    },
    freeParts() {
      if (!this.dropzoneReady) return [];
      return this.parts.filter(part => !this.builtPartIds.includes(part.uuid));
    },
    builtParts:{
      get() {
        return this.parts.filter(part => this.builtPartIds.includes(part.uuid));
      },
      set(newParts) {
        for (const part of this.builtParts) {
          this.deletePart(part);
        }
        for (const part of newParts) {
          this.parts.push(part);
          this.builtPartIds.push(part.uuid);
        }
      }
    },
    snapGrid() {
      return interact.createSnapGrid({
        x: GRID_SIZE,
        y: GRID_SIZE,
        offset: this.snapOffset
      });
    }
  },
  watch: {
    savedCars(value) {
      localStorage.setItem("savedCars", JSON.stringify(value));
    }
  },
  created() {
    this.savedCars = JSON.parse(localStorage.getItem("savedCars") || '[]');
  },
  mounted() {
    if (this.$refs.droptarget) {
      const dropZoneRect = this.$refs.droptarget.getBoundingClientRect();
      this.snapOffset.x = dropZoneRect.x;
      this.snapOffset.y = dropZoneRect.y;
      this.dropzoneReady = true;

      interact(this.$refs.droptarget).dropzone({
        accept: ".car-part",
        ondrop: this.onPartDrop,
        checker: this.canDropPart
      });

      console.log(dropZoneRect);
    }

    if (this.$refs.catalog) {
      interact(this.$refs.catalog).dropzone({
        accept: ".car-part",
        ondrop: this.onPartReturn,
        checker: (event, mouseEvent, dropped) => {
          if (dropped) {
            this.dropBlocked = false;
          }
          return dropped;
        }
      });
    }

    console.log("partsByCat", _.cloneDeep(this.freePartsByCategoryAndIndex));
  },
  beforeDestroy() {

  },
  methods: {
    changeCursorMode(newMode) {
      this.cursorMode = newMode;
    },
    canDropPart(event, mouseEvent, dropped) {
      const partId = event.target.dataset.uuid;

      this.dropBlocked = !dropped;
      if (!dropped) return false;

      if (!this.$refs || !this.$refs.droptarget) {
        event.interactable.unset();
        return false;
      }

      const holdingPart = this.partsById[partId];

      let dx = Math.round(event.dx);
      let dy = Math.round(event.dy);

      // add a test body to check if the new space is free:
      const dropZonePosition = this.$refs.droptarget.getBoundingClientRect();
      const partPosition = event.target.getBoundingClientRect();

      let offset = { x: 0, y: 0 };
      if (holdingPart.type === 'body') {
        offset = {
          x: holdingPart.position.x - holdingPart.bounds.min.x,
          y: holdingPart.position.y - holdingPart.bounds.min.y
        };
      }
      else {
        offset = { x: CONSTRAINT_MARGIN, y: CONSTRAINT_MARGIN };
      }

      const newPosition = {
        x: Math.round(partPosition.x + dx - dropZonePosition.x) + offset.x,
        y: Math.round(partPosition.y + dy - dropZonePosition.y) + offset.y
      };

      if (holdingPart.type === 'constraint') {
        dropped = this.getConnectionData(holdingPart, newPosition) != null;
      }
      else if (this.bodyCollides(holdingPart, newPosition)) {
        dropped = false;
      }

      this.dropBlocked = !dropped;
      return dropped;
    },
    bodyCollides(part, position = null) {
      // set defaults for optional parameters:
      if (position === null) position = part.position;

      const excludeId = part.uuid;
      const type = part.label;

      // create a virtual matter-js body for the collisions
      const partA = bodyFromPoints(position.x, position.y, part.vertices);
      partA.uuid = excludeId;

      if (type != "Wheel") {
        // check collisions with all parts that are not wheels
        const partsWithoutWheels = this.builtParts.filter(part => part.type === 'body' && part.label != "Wheel" && part.uuid != excludeId);

        const bodies = partsWithoutWheels.map(bodyFromData);

        const collisions = Query.collides(partA, bodies);
        if (collisions.length > 0) {
          const maxDepth = collisions.reduce((sum, collision) => sum + collision.depth, 0);
          if (maxDepth > 0.1) {
            return true;
          }
        }
      }
      else if (type === "Wheel") {
        // check collisions with all parts that are not wheels
        const otherWheels = this.builtParts.filter(part => part.label === "Wheel" && part.uuid != excludeId);
        const bodies = otherWheels.map(bodyFromData);
        const collisions = Query.collides(partA, bodies);
        if (collisions.length > 0) {
          return true;
        }
      }
      return false;
    },
    onPartDrop(event) {
      const droppedPartId = event.relatedTarget.dataset.uuid;
      const droppedPart = this.partsById[droppedPartId];
      const droppedPartIndex = this.parts.findIndex(part => part.uuid === droppedPartId);

      console.log(`${droppedPartId} was dropped.`);

      // set the new position of the dropped part
      const dropZonePosition = event.currentTarget.getBoundingClientRect();
      const partPosition = event.relatedTarget.getBoundingClientRect();

      let updatedBody = droppedPart;

      if (droppedPart.type == 'constraint') {
        const newPosition = {
          x: Math.round(partPosition.x - dropZonePosition.x) + CONSTRAINT_MARGIN,
          y: Math.round(partPosition.y - dropZonePosition.y) + CONSTRAINT_MARGIN
        };

        const connectionData = this.getConnectionData(droppedPart, newPosition);

        if (connectionData) {
          const { bodyA, bodyB, posA, posB } = connectionData;
          updatedBody = createConstraintData(
            droppedPart.length * (1/GRID_SIZE),
            {
              vertical: droppedPart.vertical,
              category: droppedPart.category,
              databaseIndex: droppedPart.databaseIndex,
              bodyAUuid: bodyA.uuid,
              bodyBUuid: bodyB.uuid,
              pointA: posA,
              pointB: posB
            }
          );
          updatedBody.uuid = droppedPartId;
        }

      }
      else {
        // PART IS BODY

        const offset = {
          x: droppedPart.position.x - droppedPart.bounds.min.x,
          y: droppedPart.position.y - droppedPart.bounds.min.y
        };

        const newPosition = {
          x: Math.round(partPosition.x - dropZonePosition.x) + offset.x,
          y: Math.round(partPosition.y - dropZonePosition.y) + offset.y
        };

        if (
          this.builtPartIds.includes(droppedPart.uuid) &&
          newPosition.x === droppedPart.position.x &&
          newPosition.y === droppedPart.position.y
        ) {
          console.log("dropped at same position");
          return false;
        }

        updatedBody = setBodyPosition(droppedPart, newPosition);
      }

      this.$emit("build", droppedPart.uuid);
      this.updatePart(updatedBody);
    },
    getConnectionData(constraint, position) {
      // get all the bodies that are at the center of the new constraint:

      if (constraint.length == 0) {
        return getAxleConnectionAt(this.builtParts, position);
      }

      if (constraint.length > 0) {
        // constraints with a length of more than 0 only attach to main bodies
        const attachableBodyParts = this.builtParts.filter(part => part.type == 'body' && part.label != 'Wheel');
        const attachableBodies = attachableBodyParts.map(bodyFromData);

        const bodiesAtStart = Query.point(attachableBodies, position);
        if (bodiesAtStart.length == 0) return null;

        let endPosition = {
          x: position.x,
          y: position.y
        };

        if (constraint.vertical) endPosition.y += constraint.length;
        else endPosition.x += constraint.length;

        const bodiesAtEnd = Query.point(attachableBodies, endPosition);
        if (bodiesAtEnd.length == 0) return null;

        const bodyA = bodiesAtStart[0];
        const bodyB = bodiesAtEnd[0];

        if (bodyA.uuid === bodyB.uuid) return null;

        const connectRequired = constraint.vertical ? 'v' : 'h';
        if (
          bodyA.connect !== connectRequired && bodyA.connect !== 'both' ||
          bodyB.connect !== connectRequired && bodyB.connect !== 'both'
        ) {
          console.log(bodyA.connect, bodyB.connect, connectRequired);
          return null;
        }

        const posA = {
          x: position.x - bodyA.position.x,
          y: position.y - bodyA.position.y
        };
        const posB = {
          x: endPosition.x - bodyB.position.x,
          y: endPosition.y - bodyB.position.y
        };

        return { bodyA, bodyB, posA, posB };
      }

      return null;
    },

    onPartReturn(event) {
      console.log("dropped part", event.relatedTarget);
      const droppedPartId = event.relatedTarget.dataset.uuid;
      const droppedPart = this.partsById[droppedPartId];

      this.$emit("remove-part", droppedPart.uuid);
    },

    /**
     * Attach a drag listener to a car part
     */
    addCarPartDragListener(carPartComponent) {
      interact(carPartComponent.$el)
        .draggable({
          manualStart: false,
          modifiers: [
            interact.modifiers.snap({
              targets: [this.snapGrid],
              range: Infinity,
              relativePoints: [ { x: 0, y: 0 } ]
            })
          ]
        })
        .on('down', event => {
          // Starts dragging or rotates.
          let cursorMode = this.cursorMode;

          if (event.ctrlKey) {
            cursorMode = this.cursorModes.DELETE;
          }

          // rotate on right click
          if (event.button == 2) {
            cursorMode = this.cursorModes.ROTATE;
          }

          switch (cursorMode) {
            case this.cursorModes.DELETE:
              this.removePart(carPartComponent.part);
              break;

            case this.cursorModes.ROTATE:
              // don't rotate screws and axles
              if (carPartComponent.part.type === 'constraint' || carPartComponent.part.label === "Wheel") {
                break;
              }
              const rotatedBodyData = this.rotatePart(carPartComponent.part);
              if (
                this.builtPartIds.includes(rotatedBodyData.uuid) &&
                this.bodyCollides(rotatedBodyData)
              ) {
                carPartComponent.isBeeingDragged = true;
                this.dropBlocked = true;
              }
              window.addEventListener("mouseup", this.checkRotatedPart.bind(this, rotatedBodyData, carPartComponent), {
                once: true
              });
              break;

            default:
              // starte eine normale interaktion
              const interaction = event.interaction;
              if (!interaction.interacting()) {
                interaction.start(
                  { name: 'drag' },
                  event.interactable,
                  event.currentTarget
                );
              }
              break;
          }
        })
        .on('dragstart', event => {
          carPartComponent.isBeeingDragged = true;
        })
        .on('dragmove', event => {
          carPartComponent.offsetX += event.dx;
          carPartComponent.offsetY += event.dy;
        })
        .on('dragend', event => {
          if (!this.dropBlocked) {
            carPartComponent.lastDropSuccessful = true;
          } else {
            carPartComponent.lastDropSuccessful = false;
          }
          carPartComponent.offsetX = 0;
          carPartComponent.offsetY = 0;
          carPartComponent.isBeeingDragged = false;
        });
    },
    checkRotatedPart(part, carPartComponent) {
      // rotate body back if it collides:
      if (
        this.builtPartIds.includes(part.uuid) &&
        this.bodyCollides(part)
      ) {
        this.rotatePart(part, false, -90);
        console.log("rotation invalid");
      }
      carPartComponent.isBeeingDragged = false;
      this.dropBlocked = false;
    },
    rotatePart(partData, checkCollision = false, angle = 90) {
      if (partData.type === 'constraint') return partData;
      const rotatedBodyData = rotateBody(partData, angle);
      this.updatePart(rotatedBodyData);

      return rotatedBodyData;
    },
    removePart(part) {
      this.$emit("remove-part", part.uuid);
    },
    deletePart(part) {
      const index = this.parts.indexOf(part);
      this.parts.splice(index, 1);
    },
    updatePart(partData) {
      const index = this.parts.findIndex(part => part.uuid == partData.uuid);
      this.$emit("update-part", {
        index,
        part: partData
      });
    }
  }
}
</script>

<style lang="scss">
.car-builder {
  width: 100%;

  &__main {
    position: relative;
    display: flex;
    height: 100%;
    overflow: auto;

    &.-in-mode-build {
      .car-part {
        cursor: auto;
      }
    }
    &.-in-mode-rotate {
      .car-part {
        cursor: se-resize;
      }
    }
    &.-in-mode-delete {
      .car-part {
        cursor:not-allowed;
      }
    }
  }

  &__catalog {
    width: 20rem;
    flex: none;
  }
  &__catalogtitle {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    pointer-events: none;
  }
  &__cataloginfo {
    font-size: 14px;
    color: #999;
    pointer-events: none;
    user-select: none;
  }

  &__parts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    z-index: 2;
    align-items: flex-start;
  }

  &__part {
    border: 1px solid #EDEDED;
    background-color: #FBFAFA;
    padding: 0.5em;
    z-index: 2;
    text-align: center;

    &__label {
      display: block;
      margin-top: 0.25rem;
      font-size: 14px;
      color: #999;
      user-select: none;
    }
  }

  &__buildarea {
    width: 100%;
    height: 100%;
    background-color: var(--active-player-color-medium);
    position: relative;
    position: sticky;
    top: 0;

    .car-part {
      position: absolute;
    }
  }

  &__floor {
    position: absolute;
    left: 0;
    right: 0;
    top: 520px;
    height: 0px;
    border-bottom: 3px dashed $color-text;
    //bottom: 0;
    pointer-events: none;
  }

  &__dashboard {
    user-select: none;
    position: absolute;
    font-size: 14px;
    bottom: 1em;
    right: 1em;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 0.5rem;
    padding: 0.5em;
    color: $color-uielement-text-dark;
    border-radius: 0.5em;
  }
  &__premise {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    width:8.5em;
    height:auto;
 
  }

  &__icon {
    color: $color-uielement-text;
    height: 2.5em;
    width: 2.5em;
    align-self: center;
    justify-self: center;
  }

  &__requirements,
  &__simulationresults {
    display: grid;
    grid-template-columns: 4em 1fr 1fr 1fr;
    gap: 0.5em;
  }

  &__requirement,
  &__simulationresult {
    border: 1px solid $color-uielement-border;
    padding: 0.5em 0.5em;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.25em;
    line-height: 1.5;
  }
}
</style>