<template>
  <div class="fielddialogs-catalog">

    <role-dialogs v-if="hasRoleAndRoleOpen">
      <template v-slot:intro>
        <p>
          Hallo, ich bin eine <strong>Datenmanagerin</strong>.
        </p>
        <p>
          Ich unterstütze die Simulationsexperten bei der Beschaffung und Bereitstellung der vereinbarten Daten für den Aufbau der Simulationsmodelle und greife rechtzeitig ein, falls sich Terminverzögerungen abzeichnen.        </p>
        <p>
          Gut, dass du nach mir gefragt hast. Mit meiner Hilfe darfst du in der
          Datenbank bis zu <strong>10</strong> Daten aus dieser Kategorie auswählen.
        </p>
      </template>
      <template v-slot:question>
        <p>
          Hallo, hier ist wieder eine Datenmanagerin!
        </p>
      </template>
      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die Arbeit gehen.
        </p>
        <p>
          Mit meiner Hilfe darfst du in der Datenbank bis zu <strong>10</strong> Daten aus einer beliebigen Kategorie auswählen..
        </p>
      </template>
      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du nochmal Hilfe brauchst.
        </p>
      </template>
    </role-dialogs>

    <!-- QUIZFRAGE -->
    <quiz-dialogs v-else-if="(
      currentField.hasQuiz &&
      !currentMove.handledQuizQuestion
    )"></quiz-dialogs>

    <collaboration-dialogs v-else-if="currentMove.collaborationSelected"></collaboration-dialogs>

<!--     <game-dialog v-else-if="currentMove.hasConfirmedNewParts">
      <div class="text-center">
        Die Daten wurden übernommen.
      </div>

      <div class="row">
        <button-active-player
          @click="moves.confirmCatalogParts()"
        >
          OK
        </button-active-player>
      </div>
    </game-dialog> -->

    <game-dialog :extra-wide="true" v-else-if="currentMove.hasSelectedNewParts">
      <template v-if="currentMove.parts && currentMove.parts.length > 0">
        <div class="text-center">
          Du hast folgende Daten ausgewählt:
        </div>

        <div class="fielddialogs-optimize__partgrid">
          <part-card
            v-for="part in currentMove.parts"
            :key="part.uuid"
            :part="part"
          ></part-card>
        </div>
      </template>
      <div v-else class="text-center">
        Du hast keine Daten ausgewählt.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateCurrentMove({
            hasSelectedNewParts: false,
            isDatabaseOpen: true
          })"
        >
          Zurück
        </button-active-player>
        
        <button-active-player
          @click="moves.confirmCatalogParts()"
        >
          Daten übernehmen
        </button-active-player>
      </div>
    </game-dialog>

    <!-- DATENBANK -->
    <database-dialog
      v-else-if="currentMove.isDatabaseOpen"
      :max-selectable="maxAmount"
      :category="currentMove.category"
      @close="moves.updateCurrentMove({
        hasSelectedNewParts: true
      })"
    ></database-dialog>

    <!-- ANZAHL/DATEN BESTÄTIGEN -->
    <game-dialog v-else-if="currentMove.category != null">
      <div class="text-center">
        In Kategorie "{{ selectedCategory.title }}" sind 
        {{ remainingPartRequests[currentMove.category] }} Daten 
        noch nicht bereitgestellt worden.
      </div>
      <p class="text-center">
        Du kannst bis zu {{ maxAmount }} Daten auswählen.
      </p>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateCurrentMove({
            category: null
          })"
        >
          Zurück
        </button-active-player>
        
        <button-active-player
          @click="moves.updateCurrentMove({
            isDatabaseOpen: true
          })"
        >
          Daten jetzt auswählen
        </button-active-player>
      </div>
    </game-dialog>

    <!-- BEREITS ALLE DATEN GESAMMELT -->
    <game-dialog v-else-if="noPartRequestsLeft">
      <div class="text-center">
        Du hast bereits alle Daten gesammelt.
      </div>

      <div class="row">
        <button-active-player
          @click="moves.endTurn()"
        >
          OK
        </button-active-player>
        <button-active-player
          v-if="isFieldCollaborative"
          @click="moves.startCollaboration()"
        >
          Kollaboration: Zug weitergeben
        </button-active-player>
      </div>
    </game-dialog>

    <!-- KATEGORIE AUSWÄHLEN -->
    <game-dialog v-else>
      <div class="text-center">
        Wähle eine Kategorie, in deren Datenbank du 
        Daten auswählen möchtest.
      </div>

      <div class="column -categories">
        <data-card
          v-for="category in dataCategories"
          :key="category.id"
          :category="category"
          :clickable="true"
          :disabled="!isActivePlayer"
          :forbidden="remainingPartRequests[category.id] <= 0"
          @click="moves.updateCurrentMove({
            category: category.id
          })"
        ></data-card>
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.endTurn()"
        >
          Keine Daten einsammeln
        </button-active-player>
        <button-active-player
          v-if="isFieldCollaborative"
          @click="moves.startCollaboration()"
        >
          Kollaboration: Zug weitergeben
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>

<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";


// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";
// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";
import CollaborationDialogs from "./CollaborationDialogs.vue";
import DatabaseDialog from "./DatabaseDialog.vue";

import PartCard from "../Cards/PartCard.vue";
import DataCard from "../Cards/DataCard.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";

export default {
  components: {
    GameDialog,
    RoleDialogs,
    CollaborationDialogs,
    DatabaseDialog,
    PartCard,
    DataCard,
    ButtonActivePlayer,
    QuizDialogs
  },
  data() {
    return {
      localSelectedMethod: null
    };
  },
  mixins: [GameComponentMixin],
  computed: {
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    },
    isFieldCollaborative() {
      return this.currentField.isCollaborative && this.isCurrentPlayerActive;
    },
    noPartRequestsLeft() {
      return Object.values(this.remainingPartRequests).every(val => val <= 0);
    },
    selectedCategory() {
      return this.dataCategories.find(cat => this.currentMove.category === cat.id);
    },
    maxAmount() {
      const maxSelectable = this.currentMove.solvedRoleQuiz ? 10 : 5;
      const amountInCat = this.currentMove.category !== null ? this.remainingPartRequests[this.currentMove.category] : 0;
      return Math.min(maxSelectable, amountInCat);
    }
  },
  methods: {
    selectMethodAndAction(method, action) {
      this.moves.selectMethod(method);
      this.moves.setAction(action);
    },
    cancel() {
      this.moves.endTurn();
    }
  }
}
</script>

<style>

</style>