<template>
  <div class="escalation-dialogs-2">
    <game-dialog v-if="!escalation.introConfirmed">
      <div class="text-center">
        Du kannst alle vereinbarten aber noch nicht 
        eingesammelten Daten nachträglich sammeln 
        (Zufalls-Prinzip). Pro Kategorie kostet dich das 
        <strong>3</strong> Bewegungspunkte.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            useEscalation: null
          })"
        >
          Zurück
        </button-active-player>

        <button-active-player
          @click="moves.updateEscalation({
            introConfirmed: true
          })"
        >
          Kategorie wählen
        </button-active-player>
      </div>

    </game-dialog>

    <game-dialog v-else-if="escalation.category === null">
      <div class="text-center">
        Wähle eine Kategorie, deren Daten du bekommen möchtest.
      </div>

      <div class="column -categories">
        <data-card
          v-for="category in dataCategories"
          :key="category.id"
          :category="category"
          :amount="remainingPartRequests[`${category.id}`] || null"
          :forbidden="remainingPartRequests[`${category.id}`] == 0"
          :clickable="true"
          :disabled="!isActivePlayer"
          :horizontal="true"
          @click="moves.updateEscalation({
            category: category.id
          })"
        ></data-card>
      </div>

      <div class="row">
        <button-active-player
          v-if="!escalation.usedEscalation"
          type="secondary"
          @click="moves.updateEscalation({
            introConfirmed: false
          })"
        >
          Zurück
        </button-active-player>

        <button-active-player
          v-else
          type="secondary"
          @click="moves.endTurn()"
        >
          Zug beenden
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else-if="escalation.parts.length == 0">
      <div class="text-center">
        In Kategorie {{ (1 * escalation.category) + 1 }} 
        sind {{ remainingPartRequests[escalation.category] }} 
        Daten angefordert, die noch nicht bereitsgestellt wurden.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            category: null
          })"
        >
          Zurück
        </button-active-player>
        <button-active-player
          @click="moves.getPartsInEscalation2()"
        >
          Daten für <strong>3 BW</strong> einsammeln.
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog :extra-wide="true" v-else-if="escalation.keepData === null">
      <div class="text-center">
        Du hast folgende Daten erhalten.
      </div>

      <div class="fielddialogs-optimize__partgrid">
        <part-card
          v-for="part in escalation.parts"
          :key="part.uuid"
          :part="part"
        ></part-card>
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            keepData: false
          })"
        >
          Daten nicht übernehmen
        </button-active-player>
        <button-active-player
          @click="moves.confirmEscalation2()"
        >
          Daten übernehmen
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else-if="escalation.keepData === true">
      <div class="text-center">
        Die Daten wurden übernommen. Möchtest du weitere
        Daten sammeln? Pro Kategorie kostet dich dies 
        3 Bewegungspunkte.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            category: null,
            parts: [],
            keepData: null
          })"
        >
          Weitere Kategorie wählen
        </button-active-player>
        <button-active-player
          @click="moves.endTurn()"
        >
          Spielzug beenden
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else-if="escalation.keepData === false">
      <div class="text-center">
        Es wurden keine Daten übernommen. Möchtest du 
        andere Daten sammeln? Pro Kategorie kostet dich 
        dies 3 Bewegungspunkte.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.updateEscalation({
            category: null,
            parts: [],
            keepData: null
          })"
        >
          Weitere Kategorie wählen
        </button-active-player>
        <button-active-player
          @click="moves.endTurn()"
        >
          Spielzug beenden
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>

<script>
import GameComponentMixin from "../GameComponentMixin.js";

import GameDialog from "../UIElements/GameDialog.vue";
import InputField from "../../input/InputField.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
import DataCard from "../Cards/DataCard.vue";
import PartCard from "../Cards/PartCard.vue";

export default {
  mixins: [GameComponentMixin],
  components: {
    GameDialog,
    ButtonActivePlayer,
    InputField,
    DataCard,
    PartCard
  },
  computed: {
    escalation() {
      return this.gameState.currentEscalation;
    },
    selectedCategory() {
      if (this.escalation.category === null) return null;
      return this.dataCategories.find(cat => cat.id === this.escalation.category);
    }
  }
}
</script>

<style>

</style>