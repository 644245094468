/**
 * @returns {Requirement[]}
 */
export function getAllRequirements() {
  const requirements = [
    {
      id: 0,
      label: "Länge",
      type: "length",
      value: 24,
      unit: "cm"
    },
    {
      id: 1,
      label: "Länge",
      type: "length",
      value: 32,
      unit: "cm"
    },
    {
      id: 2,
      label: "Länge",
      type: "length",
      value: 48,
      unit: "cm"
    },

    {
      id: 3,
      label: "Höhe",
      type: "height",
      value: 16,
      unit: "cm"
    },
    {
      id: 4,
      label: "Höhe",
      type: "height",
      value: 24,
      unit: "cm"
    },
    {
      id: 5,
      label: "Höhe",
      type: "height",
      value: 32,
      unit: "cm"
    },

    {
      id: 6,
      label: "Bodenfreiheit",
      type: "groundDistance",
      value: 8,
      unit: "cm"
    },
    {
      id: 7,
      label: "Bodenfreiheit",
      type: "groundDistance",
      value: 4,
      unit: "cm"
    },
    {
      id: 8,
      label: "Bodenfreiheit",
      type: "groundDistance",
      value: 6,
      unit: "cm"
    },

    {
      id: 9,
      label: "Rollweg",
      type: "distanceTravelled",
      value: 55,
      unit: "cm"
    },
    {
      id: 10,
      label: "Rollweg",
      type: "distanceTravelled",
      value: 65,
      unit: "cm"
    },
    {
      id: 11,
      label: "Rollweg",
      type: "distanceTravelled",
      value: 75,
      unit: "cm"
    },

    {
      id: 12,
      label: "Gewicht",
      type: "weight",
      value: 54,
      unit: "kg"
    },
    {
      id: 13,
      label: "Gewicht",
      type: "weight",
      value: 84,
      unit: "kg"
    },
    {
      id: 14,
      label: "Gewicht",
      type: "weight",
      value: 123,
      unit: "kg"
    }
  ];

  return requirements;
}

/**
 *
 * @param {Number} id
 */
export function getRequirementById(id) {
  const all = getAllRequirements();
  return all.find(requirement => requirement.id == id);
}

/**
 * Get (three) different random requirements
 * @param {Number} amount
 * @returns {Requirement[]}
 */
export function getRandomRequirements(ctx, amount = 3) {
  const allRequirements = getAllRequirements();
  const requirements = [];
  for (let i = 0; i < amount; i++) {
    requirements.push(
      allRequirements.splice(Math.floor(ctx.random.Number() * allRequirements.length), 1)[0]
    );
  }
  return requirements;
}


/**
 * @typedef {Object} Requirement
 * @property {Number} id
 * @property {string} label
 * @property {string} type
 * @property {Number} value
 * @property {string} unit
 */