<template>
  <div class="board-area">
    <div class="board-area__container">
      

      <!-- FELDER -->
      <div class="board-area__fields">
        
        <!-- MEILENSTEINE vorne -->
        <div class="board-area__field">
          <div class="board-area__milestone-container">
            <svg v-if="currentPhase.id=='phase1'" class="board-area__milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.17 42.17"><g id="af1d73ce-71cc-485f-9600-323ef3b76fdb" data-name="Ebene 2"><g id="fcea6138-205f-4009-84ef-47520e80ac54" data-name="Ebene 1"><rect x="7.18" y="7.18" width="27.82" height="27.82" transform="translate(-8.73 21.09) rotate(-45)" style="fill:#92d7e0;stroke:#c3c3c3;stroke-miterlimit:10;stroke-width:2px"/></g></g></svg>
            <svg v-if="currentPhase.id=='phase2'" class="board-area__milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.17 42.17"><g id="a639d3ef-0ccf-4fe7-b1cf-27ad4ea8f7a1" data-name="Ebene 2"><g id="e607cfff-cd85-471a-af10-277435d681c7" data-name="Ebene 1"><rect x="7.18" y="7.18" width="27.82" height="27.82" transform="translate(-8.73 21.09) rotate(-45)" style="fill:#198391;stroke:#c3c3c3;stroke-miterlimit:10;stroke-width:2px"/></g></g></svg>
            <svg v-if="currentPhase.id=='phase3'" class="board-area__milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.76 40.76"><g id="a6015294-4aa7-4a72-ba76-dd18a4cf4c33" data-name="Ebene 2"><g id="b04e0895-b4e5-4266-a371-ec1a350f0a90" data-name="Ebene 1"><rect x="6.47" y="6.47" width="27.82" height="27.82" transform="translate(-8.44 20.38) rotate(-45)" style="fill:#fff;stroke:#c3c3c3;stroke-miterlimit:10"/><rect x="1.08" y="12.87" width="26.81" height="3.22" transform="translate(-6 14.48) rotate(-45)" style="fill:#22c5e6"/><rect x="12.71" y="24.51" width="26.84" height="3.22" transform="translate(-10.82 26.13) rotate(-45)" style="fill:#22c5e6"/><rect x="6.73" y="18.53" width="26.81" height="3.22" transform="translate(-8.35 20.14) rotate(-45)" style="fill:#22c5e6"/></g></g></svg>
            <span v-if="currentPhase.id=='phase1'" class="board-area__milestone-text">Produkt&shy;ziele</span>
            <span v-if="currentPhase.id=='phase2'">Leistungs&shy;vereinbarung</span>
            <span v-if="currentPhase.id=='phase3'">Daten&shy;lieferung</span>
          </div>
        </div>
        
        <template
          v-for="field in fields"
        >
          <board-field
            :key="field.id"
            :field="field"
            :area="area"
            class="board-area__field"
            @click="$emit('go-to-field', field.id)"
          ></board-field>
          <div
            v-if="area.gateAt == field.id"
            :key="`${field.id}-gate`"
            class="board-area__gate"
          ></div>
        </template>

        <!-- MEILENSTEINE hinten -->
        <div class="board-area__field">
          <div class="board-area__milestone-container">
            <svg v-if="currentPhase.id=='phase1'" class="board-area__milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.17 42.17"><g id="a639d3ef-0ccf-4fe7-b1cf-27ad4ea8f7a1" data-name="Ebene 2"><g id="e607cfff-cd85-471a-af10-277435d681c7" data-name="Ebene 1"><rect x="7.18" y="7.18" width="27.82" height="27.82" transform="translate(-8.73 21.09) rotate(-45)" style="fill:#198391;stroke:#c3c3c3;stroke-miterlimit:10;stroke-width:2px"/></g></g></svg>
            <svg v-if="currentPhase.id=='phase2'" class="board-area__milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.76 40.76"><g id="a6015294-4aa7-4a72-ba76-dd18a4cf4c33" data-name="Ebene 2"><g id="b04e0895-b4e5-4266-a371-ec1a350f0a90" data-name="Ebene 1"><rect x="6.47" y="6.47" width="27.82" height="27.82" transform="translate(-8.44 20.38) rotate(-45)" style="fill:#fff;stroke:#c3c3c3;stroke-miterlimit:10"/><rect x="1.08" y="12.87" width="26.81" height="3.22" transform="translate(-6 14.48) rotate(-45)" style="fill:#22c5e6"/><rect x="12.71" y="24.51" width="26.84" height="3.22" transform="translate(-10.82 26.13) rotate(-45)" style="fill:#22c5e6"/><rect x="6.73" y="18.53" width="26.81" height="3.22" transform="translate(-8.35 20.14) rotate(-45)" style="fill:#22c5e6"/></g></g></svg>
            <svg v-if="currentPhase.id=='phase3'" class="board-area__milestone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.17 42.17"><g id="e1519f39-79cf-43b5-b845-9f2b0559f74f" data-name="Ebene 2"><g id="f1d86aa6-5dea-481b-86e8-69e5a9854596" data-name="Ebene 1"><rect x="7.18" y="7.18" width="27.82" height="27.82" transform="translate(-8.73 21.09) rotate(-45)" style="fill:#2eabbc;stroke:#c3c3c3;stroke-miterlimit:10;stroke-width:2px"/></g></g></svg>
            <span v-if="currentPhase.id=='phase1'">Leistungs&shy;vereinbarung</span>
            <span v-if="currentPhase.id=='phase2'">Daten&shy;lieferung</span>
            <span v-if="currentPhase.id=='phase3'" class="board-area__milestone-text">Daten-Freeze</span>
          </div>
        </div>
      </div>

      <!-- SPIELER -->
      <transition-group name="flip-list" tag="div" class="board-area__playerplaces">
        <div
          v-for="place in playerPlaces"
          :key="place.id"
          class="board-area__playerplace"
          :class="{
            '-last-in-field': place.lastInField,
            '-is-before-gate': place.isBeforeGate
          }"
        >
          <board-player
            v-if="place.player"
            :player="place.player"
            :title="playerNames[place.player.id]"
            :ctx="ctx"
          ></board-player>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import GameComponentMixin from "../GameComponentMixin.js";

import BoardField from "./BoardField.vue";
import BoardPlayer from "./BoardPlayer.vue";
export default {
  components: {
    BoardField,
    BoardPlayer
  },
  mixins: [GameComponentMixin],
  props: {
    area: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    fields() {
      return this.area.fields;
    },
    playerPlaceAmount() {
      return this.fields.length * this.ctx.numPlayers;
    },
    playerPlaces() {
      const playerPlaces = [];
      let nextEmptyPlaceId = this.ctx.numPlayers;

      // Create the start field places:
      // (field id == null)
      for (let fieldIndex = 0; fieldIndex < this.ctx.numPlayers; fieldIndex++) {
        const place = {};
        const player = _.find(this.players, player => player.fieldIndex === fieldIndex && player.field == null);
        if (player) {
          place.player = player;
          place.id = player.id;
        }
        else place.id = nextEmptyPlaceId++;
        place.lastInField = fieldIndex % this.ctx.numPlayers == this.ctx.numPlayers - 1
        playerPlaces.push(place);
      }

      // Add all "REAL" fields
      for (let field of this.area.fields) {
        const fieldId = field.id;

        for (let fieldIndex = 0; fieldIndex < this.ctx.numPlayers; fieldIndex++) {
          const place = {};
          const player = _.find(this.players, player => player.fieldIndex === fieldIndex && player.field == fieldId);
          if (player) {
            place.player = player;
            place.id = player.id;
          }
          else place.id = nextEmptyPlaceId++;
          place.lastInField = fieldIndex % this.ctx.numPlayers == this.ctx.numPlayers - 1
          if (place.lastInField) {
            place.isBeforeGate = this.area.gateAt == fieldId;
          }
          playerPlaces.push(place);
        }
      }

      // Add end milestone
      for (let fieldIndex = 0; fieldIndex < this.ctx.numPlayers; fieldIndex++) {
        const place = {};
        place.id = nextEmptyPlaceId++;
        place.lastInField = fieldIndex % this.ctx.numPlayers == this.ctx.numPlayers - 1
        playerPlaces.push(place);
      }
      
      return playerPlaces;
    }
  }
}
</script>

<style lang="scss">
.board-area {
  font-size: 14px;
  $gap: 0.5em;
  $gateWidth: 1em;
  overflow-x: auto;
  padding: 0 1rem;
  padding-top: 1.25rem;
  padding-bottom: 1rem;

  border-bottom: 2px solid $color-uielement-border;

 
  &__container {
    width: min-content;
    margin: 0 auto;
  }

  &__milestone-container {
    align-self: start;
    text-align: center;
    width: fit-content;
    
  }

  &__milestone {
    height: 3rem;
    width: auto;
    
  }

  &__milestone-text {
    display: inline-block;
    margin-bottom: 0.5em;
    margin-top: 0.5em;

  }


  &__fields {
    display: flex;
    gap: $gap;
    width: min-content;
    align-items: center;
    height: 6rem;
    margin-bottom: 0.5em;

  }

  &__field {
    width: 5.5em;
    flex: none;
  }

  &__gate {
    width: $gateWidth;
    background-color: #555;
    border-radius: 5000px; // gigantic number to get complete rounded edges
    height: 6rem;
  }

  &__playerplaces {
    display: flex;
  }
  &__playerplace {
    width: 100%;
    display: flex;
    justify-content: center;

    &.-last-in-field {
      margin-right: $gap;
      &:last-child {
        margin-right: 0;
      }
    }
    &.-is-before-gate {
      margin-right: (2 * $gap) + $gateWidth;
    }
  }
}

.flip-list-move {
  transition: transform 500ms;
}
</style>