<template>
  <div
    v-if="endTime"
    class="timer"
  >
    <icon class="timer__icon" name="sanduhr"></icon>
    <span>{{ `${remainingMinutes}`.padStart(2,0) }}:{{ `${remainingSeconds}`.padStart(2,0) }}</span>

    <button class="timer__stopbutton" @click="$emit('end')">
      <icon name="falsch" width="16" height="16"></icon>
    </button>
  </div>
</template>

<script>
import Icon from "../../ui/Icon.vue";
export default {
  components: {
    Icon
  },
  props: {
    endTime: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      now: 0,
      intervalHandle: null
    };
  },
  computed: {
    totalRemainingSeconds() {
      if (!this.endTime) return null;
      return Math.floor(
        (this.endTime - this.now) / 1000
      );
    },
    remainingSeconds() {
      return Math.max(0, this.totalRemainingSeconds % 60);
    },
    remainingMinutes() {
      return Math.max(0, Math.floor(this.totalRemainingSeconds / 60));
    },
    isTimeExpired() {
      if (this.totalRemainingSeconds == null) return false;
      return this.totalRemainingSeconds < 0;
    }
  },
  watch: {
    // Beobachte die Zeit und stoppe den Bauprozess, sobald
    // sie abgelaufen ist.
    isTimeExpired(itIs) {
      if (itIs) {
        this.$emit("expired");
        clearInterval(this.intervalHandle);
      }
    }
  },
  created() {
    this.updateNow();
    this.intervalHandle = setInterval(this.updateNow.bind(this), 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalHandle);
  },
  methods: {
    updateNow() {
      this.now = Date.now();
    }
  }
}
</script>

<style lang="scss">
.timer {
  padding: 0.5em 1em;
  text-align: center;
  background-color: #FEA0A0;
  border-radius: 0.5em;
  font-weight: 600;
  color: $color-uielement-text-darker;
  font-size: 1.5em;
  user-select: none;
  position: relative;
  font-family: $monospace-font;

  &__icon {
    height: 1.1em;
    width: 1.1em;
    vertical-align: bottom;
  }

  &__stopbutton {
    background: none;
    color: $color-uielement-text-darker;
    border-radius: 50%;
    border: none;
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate3d(50%, -50%, 0);
    background-color: #FEA0A0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);

    &:hover {
      background-color: $color-failed;
      color: #fff;
    }
  }
}
</style>