<template>
  <card
    class="method-card"
    @click="$emit('click', $event)"
    v-bind="{...$attrs}"
  >
    <template v-slot:default>
      <h1>{{ method.label }}</h1>
    </template>
    <template v-slot:back>
      <icon class="method-card__icon" name="prozess" color="#b9b9b9"></icon>
    </template>
  </card>
</template>

<script>
import Card from "./Card.vue";
import Icon from "../../ui/Icon.vue";
export default {
  components: {
    Card,
    Icon
  },
  props: {
    method: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">
.method-card {
  &__icon {
    height: 3.5rem;
    width: 3.5rem;
  }
}
</style>