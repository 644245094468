import _ from "lodash";

export default {
  inject: ['getPlayerId', 'getGameState', 'getCtx', 'getMoves', 'getPlayerNames', 'getMatchId', 'getCredentials', 'isSpectated'],
  
  computed: {
    gameState() {
      return this.getGameState();
    },
    ctx() {
      return this.getCtx();
    },
    matchId() {
      return this.getMatchId();
    },
    playerId() {
      return this.getPlayerId();
    },
    moves() {
      return this.getMoves();
    },
    playerNames() {
      return this.getPlayerNames();
    },
    currentArea() {
      return this.gameState.areas[this.currentPlayer.area];
    },
    currentField() {
      return this.currentArea.fields.find(
        field => field.id === this.currentPlayer.field
      );
    },
    currentMove() {
      return this.gameState.currentMove;
    },
    currentPhaseData() {
      return this.gameState.currentPhaseData;
    },
    currentPhase(){
      return this.phases[this.ctx.phase];
    },
    phases(){
      return this.gameState.phases;
    },
    players() {
      return this.gameState.players;
    },
    currentPlayer() {
      return this.players[this.ctx.currentPlayer];
    },
    activePlayer() {
      if (this.ctx.activePlayers) {
        const activePlayerId = Object.keys(this.ctx.activePlayers)[0];
        return this.gameState.players[activePlayerId];
      }
      return this.currentPlayer;
    },
    inactivePlayers() {
      return Object.values(this.players).filter(player => this.activePlayer.id != player.id);
    },
    isActivePlayer() {
      if (this.isSpectated) {
        return false;
      }
      if (this.ctx.activePlayers) {
        const activePlayerIds = Object.keys(this.ctx.activePlayers);
        return activePlayerIds.includes(String(this.playerId));
      }
      return this.currentPlayer.id == this.playerId;
    },
    isCurrentPlayer() {
      return this.playerId == this.currentPlayer.id;
    },
    isCurrentPlayerActive() {
      return this.activePlayer.id == this.currentPlayer.id;
    },
    myId() {
      return this.playerId || this.ctx.currentPlayer;
    },
    myself() {
      return this.players[this.myId];
    },
    amIActive() {
      if (this.ctx.activePlayers) {
        const activePlayerIds = Object.keys(this.ctx.activePlayers);
        return activePlayerIds.includes(String(this.myId));
      }
      return this.currentPlayer.id == this.myId;
    },
    dataCategories() {
      return this.gameState.dataCategories;
    },
    builtParts() {
      const player = _.cloneDeep(this.activePlayer);
      return player.parts.filter(part => player.builtPartIds.includes(part.uuid));
    },
    remainingPartRequests() {
      return Object.values(this.dataCategories).reduce((remaining, cat) => {
        const requested = this.activePlayer.partRequests[cat.id] || 0;
        if (!requested) remaining[cat.id] = 0;
        else {
          const partsInCat = this.allPartsOfCategory(this.activePlayer, cat.id);
          remaining[cat.id] = requested - partsInCat.length;
        }
        return remaining;
      }, {});
    },
    myTeammates() {
      return Object.values(this.players).filter(player => player.id != this.myself.id);
    }
  },
  methods: {
    allPartsOfCategory(player, categoryId) {
      return player.parts.filter(part => part.category == categoryId);
    },
    getBuiltParts(player) {
      return player.parts.filter(part => player.builtPartIds.includes(part.uuid));
    }
  }
};