<template>
  <div 
    class="board-player"
    :class="[`board-player-${player.id}`, {'-is-current': isCurrent }]"
  >
    <div v-show="isCurrent" class="board-player__currentmark"></div>
  </div>
</template>

<script>
export default {
  props: ['player', 'ctx'],
  computed: {
    isCurrent() {
      return this.player.id == this.ctx.currentPlayer;
    }
  }
}
</script>

<style lang="scss">
.board-player {
  position: relative;
  border-radius: 50px;
  background-color: $color-uielement-bg;
  border: 1px solid transparent;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  color: #fff;
  user-select: none;

  &-0 {
    background-color: $color-player-0;
  }
  &-1 {
    background-color: $color-player-1;
  }
  &-2 {
    background-color: $color-player-2;
  }
  &-3 {
    background-color: $color-player-3;
  }
  &-4 {
    background-color: $color-player-4;
  }

  /*&.-is-current {
    border-color: $color-primary;
    color: #fff;
    font-weight: bold;

    &::before {
      border-bottom-color: $color-primary;
    }
  }*/

  &__currentmark {
    display: block;
    background-color: #fff;
    border-radius: 50%;
    height: 0.5em;
    width: 0.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.25em;
    margin-left: -0.25em;
  }
}
</style>