<template>
  <card
    class="part-card"
    :face-up="true"
    v-bind="{...$attrs}"
    @click="$emit('click', $event)"
  >
    <template v-slot:default>
      <car-part
        class="part-card__image"
        :part="part"
        :use-position="false"
        :max-height="maxHeight"
      ><car-part>
    </template>
  </card>
</template>

<script>
import Card from "./Card.vue";
import Icon from "../../ui/Icon.vue";
import CarPart from "../Car/CarPart.vue";
import { HIGH_RECTANGLE, LOW_RECTANGLE, SCREW, TRIANGLE, WHEEL } from '../../../../game/Resources/Data';
export default {
  components: {
    Card,
    Icon,
    CarPart
  },
  props: {
    part: {
      type: Object,
      default: null
    }
  },
  computed: {
    maxHeight() {
      const category = this.part.category;
      if (category === SCREW) {
        return 60;
      }
      else if (category === WHEEL) {
        return 160;
      }
      else if (category === TRIANGLE) {
        return 120;
      }
      else if (category === HIGH_RECTANGLE) {
        return 120;
      }
      else if (category === LOW_RECTANGLE) {
        return 240;
      }
      return 240;
    }
  }
}
</script>

<style lang="scss">
.part-card {
  overflow: hidden;
  &__image {
    transform: scale(0.6);
  }
}
</style>