// **********************************************
// *******             MOVES               ******
// **********************************************
// Hier sind alle Spielzüge definiert.

// Imports
import { INVALID_MOVE } from 'boardgame.io/core';
import { bodyFromData, createConstraintData, GRID_SIZE } from '../car/carPartCreator.js';
// Datentypen
import { GameState, Context } from "./Game.js";
import { getRandomPartsFromCategory, createPart, dataCategories, createAllData } from './Resources/Data.js';
// Ressourcen
import { enterField } from './Resources/Fields';
import { Player } from './Resources/Players.js';
import { hasFulfilledPremise } from './Resources/Premises.js';
import { quizQuestions, questionIdsByArea } from "./Resources/Quiz.js";
import { getRandomRequirements } from './Resources/Requirements.js';
import { Query, Vector } from 'matter-js';



// ================================================
// ALLGEMEINES
// =================================================
// Spielzüge, die phasenübergreifend relevant sind.

export function selectField(G, ctx, fieldId) {
  const player = G.players[ctx.currentPlayer];

  if (G.currentEscalation.useEscalation === true) {
    return INVALID_MOVE;
  }

  // Nur EINMAL pro Zug bewegen.
  if (player.movedThisTurn) {
    return INVALID_MOVE;
  }

  // Nicht auf das Feld, wo wir gerade sind.
  if (fieldId != null && fieldId == player.field) {
    G.currentMove.selectedField = null;
    G.currentMove.confirmedTurn = false;
  }
  else {
    G.currentMove.selectedField = fieldId;
    G.currentMove.confirmedTurn = true;
  }
}

/**
 * Bewegt den aktuellen Spieler zum angegebenen Feld.
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number} fieldId
 */
export function goToField(G, ctx) {
  const player = G.players[ctx.currentPlayer];
  const currentArea = G.areas[player.area];

  const fieldId = G.currentMove.selectedField;

  // Nicht bewegen, wenn zug nicht bestätigt
  if (!G.currentMove.confirmedTurn) {
    return INVALID_MOVE;
  }

  // Nur EINMAL pro Zug bewegen.
  if (player.movedThisTurn) {
    return INVALID_MOVE;
  }

  // Nicht auf das Feld, wo wir gerade sind.
  if (fieldId == player.field) {
    return INVALID_MOVE;
  }

  const fields = currentArea.fields;
  const newField = fields.find(field => field.id == fieldId);

  // Nur, wenn das Feld im aktuellen Bereich existiert.
  if (!newField) {
    return INVALID_MOVE;
  }

  const difference = Math.abs(fieldId - (player.field || 0));

  // Spieler auf Feld verschieben:
  player.field = fieldId;
  player.movedThisTurn = true;
  player.movementPoints -= difference;

  // Nimm den ersten freien Platz auf dem Feld
  // (Jedes Feld hat so viele Plätze wie es Spieler gibt)
  const players = Object.values(G.players);
  const freeIndices = [...Array(players.length).keys()]; // array filled with 0, 1, 2, ... , n
  players.forEach(p => {
    if (p.id == player.id) return;
    if (p.area == player.area && p.field == fieldId) {
      freeIndices.splice(freeIndices.indexOf(p.fieldIndex), 1);
    }
  });
  player.fieldIndex = freeIndices[0];

  // Führe ein Feld-spezifisches Skript beim betreten aus.
  // (Siehe Fields.js)
  enterField(G, ctx, newField);
}


/**
 * Benutze diese Funktion, um einen Zug zu beenden.
 * @param {GameState} G
 * @param {Context} ctx
 */
export function endTurn(G, ctx) {
  G.currentMove = {};
  ctx.events.endTurn();
}

/**
 * Überschreibt beliebige Einträge in currentMove
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Object} options
 */
export function updateCurrentMove(G, ctx, options) {
  for (const option in options) {
    G.currentMove[option] = options[option];
  }
}



// ======================
// ====  ESCALATION  ====
// ======================

export function updateEscalation(G, ctx, escData) {
  G.currentEscalation = Object.assign({}, G.currentEscalation, escData);
}

export function confirmEscalation1(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);
  const category = G.currentEscalation.category;
  const amount = G.currentEscalation.amount;
  setPartRequestAmount(G, ctx, activePlayer, category, amount);

  activePlayer.movementPoints -= 3;

  G.currentEscalation.requestConfirmed = true;
}

export function getPartsInEscalation2(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);
  const remainingPartRequests = getRemainingPartRequests(activePlayer);
  const category = G.currentEscalation.category;
  const amount = remainingPartRequests[category];

  const parts = getRandomPartsFromCategory(ctx, category, amount);
  G.currentEscalation.parts = parts;

  activePlayer.movementPoints -= 3;
  G.currentEscalation.usedEscalation = true;
}

export function confirmEscalation2(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);
  const category = G.currentEscalation.category;

  activePlayer.parts = activePlayer.parts.concat(G.currentEscalation.parts);

  G.currentEscalation.keepData = true;
}



// =========================
// ====  KOLLABORATION  ====
// =========================


export function startCollaboration(G, ctx) {
  G.currentMove.collaborationSelected = true;
}
export function endCollaboration(G, ctx) {
  G.currentMove.collaborationSelected = false;
}

export function setCollaboration(G, ctx, value = null) {
  G.currentMove.collaborationSelected = value;
}

/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Object} options
 */
export function activateReceiver(G, ctx, { receiver = null, action = null, stage = null, data = {} } = {}) {
  setAction(G, ctx, action);

  if (receiver != null) {
    G.currentMove.selectedReceiver = receiver;
  }

  ctx.events.setActivePlayers({
    // Enumerate the set of players and the stages that they
    // are in.
    value: {
      [String(G.currentMove.selectedReceiver.id)]: stage,
    },

    // This takes the stage configuration to the
    // value prior to this setActivePlayers call
    // once the set of active players becomes empty
    // (due to players either calling endStage or
    // a moveLimit ending the stage for them).
    revert: true
  });

  // Überschreibe alle Daten in currentMove, die in
  // data genannt werden
  for (const prop in data) {
    G.currentMove[prop] = data[prop];
  }
}


// ==================================
// === QUIZ
// ==================================

export function answerQuizQuestion(G, ctx, answerId) {
  const question = quizQuestions.find(q => q.id === G.currentMove.quizQuestion.id);

  G.currentMove.quizQuestion.chosenAnswer = answerId;

  if (answerId === question.answer) {
    G.quizPoints += (question.points || 5);
    G.currentMove.solvedQuizQuestion = true;
    G.answeredQuizQuestions.push(G.currentMove.quizQuestion.id);
  }
  else {
    G.currentMove.solvedQuizQuestion = false;
  }

  G.currentMove.quizQuestion.answer = question.answer;
}

export function closeQuizQuestion(G, ctx) {
  G.currentMove.handledQuizQuestion = true;

  // Quiz-Felder deaktivieren, wenn nun alle Fragen beantwortet
  const areaId = G.currentPhaseData.areaId;
  const amountOfPlayers = Object.values(G.players).length;
  const neededQuizQuestions = amountOfPlayers + 1;
  const answeredQuestionsInArea = questionIdsByArea[areaId].filter(
    qId => G.answeredQuizQuestions.includes(qId)
  );
  if (answeredQuestionsInArea.length >= neededQuizQuestions) {
    const area = G.areas[areaId];
    area.fields.forEach(field => {
      field.hasQuiz = false;
    });
  }
}



// =====================================
// BEREICH 1A: Anforderungen und Methoden
// =====================================

const GATEPASS_REQUIREMENTS = 0;

// 1️⃣ ANFORDERUNGEN

/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {*} requirement
 */
export function selectRequirement(G, ctx, requirement) {
  if (G.currentMove.selectedRequirement != null) {
    return INVALID_MOVE;
  }
  G.currentMove.selectedRequirement = requirement;
}

/**
 * @param {GameState} G
 */
export function resetRequirement(G) {
  G.currentMove.selectedRequirement = null;
}

export function redrawRequirements(G, ctx) {
  G.currentMove.requirements = getRandomRequirements(ctx, 3);
  G.currentMove.hasRedrawn = true;
}

/**
 * Ende des Zugs: Bestätigung der aktuellen Einstellungen
 * @param {GameState} G
 * @param {Context} ctx
 */
export function confirmRequirement(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);

  // Falls eine zum Abwerfen gewählt, werfe diese ab
  if (G.currentMove.selectedReqToReplace != null) {
    const discardIndex = activePlayer.requirements.findIndex(req => req.id == G.currentMove.selectedReqToReplace);
    activePlayer.requirements.splice(discardIndex, 1);
  }

  // Neue Anforderung hinzufügen
  if (G.currentMove.selectedRequirement != null) {
    activePlayer.requirements.push(G.currentMove.selectedRequirement);
  }

  if (activePlayer.requirements.length >= 3) {
    activePlayer.gatePasses.push(GATEPASS_REQUIREMENTS);
  }

  endTurn(G, ctx);
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number} reqId
 */
export function setRequirementToReplace(G, ctx, reqId) {
  G.currentMove.selectedReqToReplace = reqId;
}

/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {String} reqId
 */
export function setAction(G, ctx, action) {
  const possibleActions = ['discard', 'use', 'giveAway', null];
  if (!possibleActions.includes(action)) {
    return INVALID_MOVE;
  }

  G.currentMove.selectedAction = action;
}


/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 */
export function selectRequirementAndProceed(G, ctx) {
  setAction(G, ctx, "use");
  const activePlayer = getActivePlayer(G, ctx);

  if (activePlayer.requirements.length >= 3) {
    return;
  }
  else if (activePlayer.requirements.some(req => req.type === G.currentMove.selectedRequirement.type)) {
    return;
  }
  else {
    confirmRequirement(G, ctx);
  }
}


/**
 * Ende des Zugs: Bestätigung der aktuellen Einstellungen
 * @param {GameState} G
 * @param {Context} ctx
 * @param {String} reqId
 */
export function setActionAndConfirmRequirement(G, ctx, action) {
  setAction(G ,ctx ,action);
  confirmRequirement(G ,ctx);
}

/**
 * @param {GameState} G
 * @param {Context} ctx
 */
export function switchRequirements(G, ctx, nextAction = "use") {
  const activePlayer = getActivePlayer(G, ctx);

  // Entferne die ausgewählte alte Karte aus dem Spielerdeck
  const discardIndex = activePlayer.requirements.findIndex(req => req.id == G.currentMove.selectedReqToReplace);
  const discardedRequirement = activePlayer.requirements.splice(discardIndex, 1)[0];

  // Füge die neue Karte dem Deck hinzu:
  const selectedRequirement = G.currentMove.selectedRequirement;
  activePlayer.requirements.push(selectedRequirement);

  G.currentMove.selectedReqToReplace = selectedRequirement.id;
  G.currentMove.selectedRequirement = discardedRequirement;
  G.currentMove.switchedRequirements = !G.currentMove.switchedRequirements;

  setAction(G, ctx, nextAction);
}



// 2️⃣ METHODEN

/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Object} method
 */
export function selectMethod(G, ctx, method) {
  if (G.currentMove.selectedMethod != null) {
    if (!G.currentMove.solvedRoleQuiz) {
      return INVALID_MOVE;
    }
  }
  G.currentMove.selectedMethod = method;
}

/**
 * @param {GameState} G
 * @param {Context} ctx
 */
export function takeMethod(G, ctx) {
  // Nur erlaubt, wenn eine Methode ausgewählt ist.
  if (G.currentMove.selectedMethod == null) {
    return INVALID_MOVE;
  }

  const player = getActivePlayer(G, ctx);

  // Nur erlaubt, wenn der Spieler die Methode noch nicht hat
  if (player.methods.find(m => m.id == G.currentMove.selectedMethod.id)) {
    return INVALID_MOVE;
  }

  player.methods.push(G.currentMove.selectedMethod);

  endTurn(G, ctx);
}



// =====================================
// BEREICH 1B: Daten  ==================
// =====================================


// I DATEN SAMMELN

/**""
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Object} data
 * @param {Number} data.id
 * @param {Number} data.amount
 * @param {String} data.title
 */
export function selectData(G, ctx, data){
  // Entferne, wenn schon gewählt
  const index = G.currentMove.selectedData.findIndex(
    d => d.id === data.id
  );

  if (index != -1) {
    G.currentMove.selectedData.splice(index, 1);
  }
  // ansonsten, füge hinzu
  else {
    if (G.currentMove.selectedData.length >= 2) {
      return INVALID_MOVE;
    }
    G.currentMove.selectedData.push(data);
  }
}

/**""
 *
 * @param {GameState} G
 * @param {Context} ctx
 */
export function resetData(G, ctx){
  G.currentMove.selectedData = [];
  G.currentMove.hasSelectedData = false;
  G.currentMove.amount = null;
}


/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {String} category
 */
export function choosePartRequestCategory(G, ctx, category, title) {
  G.currentMove.category = category;
  G.currentMove.title = title;
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {String} category
 */
export function resetCategory(G, ctx) {
  G.currentMove.category = null;
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number} amount
 */
export function choosePartRequestAmount(G, ctx, amount) {
  if (!Number.isInteger(amount)) {
    return INVALID_MOVE;
  }
  G.currentMove.amount = amount;

  G.currentMove.selectedData.push({
    id: G.currentMove.category,
    title: G.currentMove.title,
    amount
  });

  G.currentMove.hasSelectedData = true;
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number} amount
 */
export function resetAmount(G, ctx) {
  G.currentMove.amount = null;
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 */
export function confirmPartRequest(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);

  setPartRequestAmount(G, ctx, activePlayer, G.currentMove.category, G.currentMove.amount);

  G.currentMove.confirmed = true;
  G.currentMove.hasSelectedData = true;

  endTurn(G, ctx);
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Array} selectedData
 */
export function takeData(G, ctx){
  // Nur erlaubt, wenn ein Datenpaket ausgewählt ist.
  if (G.currentMove.selectedData.length == 0) {
    return INVALID_MOVE;
  }

  const activePlayer = getActivePlayer(G, ctx);

  for (const selData of G.currentMove.selectedData) {
    const category = selData.id;
    const amount = selData.amount;
    setPartRequestAmount(G, ctx, activePlayer, category, amount);
  }

  G.currentMove.confirmed = true;
  endTurn(G ,ctx);
}

function setPartRequestAmount(G, ctx, player, category, amount) {
  if (player.partRequests[category] == null) {
    player.partRequests[category] = 0;
  }

  player.partRequests[category] += amount;
}


// II DATENBANK

export function startViewingDatabase(G, ctx, roleIncreasesTime = false) {
  const now = Date.now();
  G.currentMove.isDatabaseOpen = true;

  let timeUntilEnd = 30;
  if (roleIncreasesTime && G.currentMove.solvedRoleQuiz) {
    timeUntilEnd = 45;
  }

  G.currentMove.databaseOpenUntil = now + timeUntilEnd * 1000;
}

export function closeDatabase(G, ctx) {
  G.currentMove.isDatabaseOpen = false;
}




// Alle Züge der Phase 1 als Gruppe:
export const phase1Moves = {
  // DEBUG
  addAllParts,

  // ALLGEMEINE
  selectField,
  goToField,
  endTurn,
  setAction,
  updateCurrentMove,
  answerQuizQuestion,
  closeQuizQuestion,
  updateEscalation,
  confirmEscalation1,
  confirmEscalation2,
  getPartsInEscalation2,

  // ANFORDERUNGEN
  selectRequirement,
  resetRequirement,
  confirmRequirement,
  setRequirementToReplace,
  switchRequirements,
  redrawRequirements,
  setActionAndConfirmRequirement,
  selectRequirementAndProceed,

  // KOLLABORATION
  activateReceiver,
  startCollaboration,
  endCollaboration,

  //DATEN
  selectData,
  resetData,
  choosePartRequestCategory,
  resetCategory,
  choosePartRequestAmount,
  resetAmount,
  confirmPartRequest,
  takeData,

  startViewingDatabase,
  closeDatabase,


  // METHODEN
  selectMethod,
  takeMethod,

  // ROLLEN-ZÜGE:
  markRoleAsSeen,
  answerRoleQuiz,
  closeQuiz,

  // TEST:
  updatePart,
  buildPart,
  removePart
};




// =================================
// BEREICH 2: Daten sammeln
// =================================

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {String} category
 * @param {String} title
 */
export function choosePartCategory(G, ctx, category, title) {
  const activePlayer = getActivePlayer(G, ctx);
  const remainingPartRequests = getRemainingPartRequests(activePlayer);
  if (remainingPartRequests[String(category)] <= 0) {
    console.log(category, Object.keys(remainingPartRequests));
    return INVALID_MOVE;
  }
  G.currentMove.category = category;
  G.currentMove.title = title;
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 */
export function collectRandomParts(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);
  const category = G.currentMove.category;

  const remainingPartRequests = getRemainingPartRequests(activePlayer);
  const amount = remainingPartRequests[category];

  const parts = getRandomPartsFromCategory(ctx, category, amount);
  G.currentMove.parts = parts;
}

/**
 * Beginnt den Austauch von Teilen
 * @param {GameState} G
 * @param {Context} ctx
 */
 export function startReroll(G, ctx){
   G.currentMove.startedReroll = true;
 }

 /**
 * Beendet den Austauch von Teilen
 * @param {GameState} G
 * @param {Context} ctx
 */
export function endReroll(G, ctx){
  G.currentMove.startedReroll = false;
}

export function markPartToReroll(G, ctx, partID){
  // entferne Teil, falls schon ausgewählt:
  if (G.currentMove.partIDsToReroll.includes(partID)) {
    G.currentMove.partIDsToReroll.splice(G.currentMove.partIDsToReroll.indexOf(partID), 1);
    return;
  }

  G.currentMove.partIDsToReroll.push(partID);
}

/**
 * Wähle Teile aus dem aktuellen Ergebnis aus,
 * die neu gewürfelt werden soll.
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number[]} partIds
 */
export function choosePartsToReroll(G, ctx) {
  // Man kann nur einmal seine Teile umtauschen:
  if (G.currentMove.hasReselected) {
    return INVALID_MOVE;
  }

  const partsToDiscard = G.currentMove.parts.filter(part =>
    G.currentMove.partIDsToReroll.includes(part.uuid)
  );
  const idsToExclude = partsToDiscard.map(
    part => part.databaseIndex
  );
  const partsToKeep = G.currentMove.parts.filter(part =>
    G.currentMove.partIDsToReroll.includes(part.uuid) === false
  );

  const newParts = getRandomPartsFromCategory(
    ctx,
    G.currentMove.category,
    partsToDiscard.length,
    idsToExclude
  );

  G.currentMove.parts = partsToKeep.concat(newParts);

  // Markiere, dass der Spieler schon neu gewählt hat:
  G.currentMove.hasReselected = true;
  G.currentMove.startedReroll = false;
}

export function confirmParts(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);
  activePlayer.parts = activePlayer.parts.concat(G.currentMove.parts);

  G.currentMove.confirmed = true;
  endTurn(G ,ctx);
}



// DATENKATALOG-Auswahl
// (die meisten Funktionen der normalen Datenwahl kann hier verwendet werden)


export function addPartToCart(G, ctx, partOrder) {
  const amount = partOrder.amount || 1;
  for (let i = 0; i < amount; i++) {
    const newPart = createPart(
      partOrder.category,
      partOrder.databaseIndex
    );
    G.currentMove.parts.push(newPart);
  }
}

export function removePartFromCart(G, ctx, partOrder) {
  const parts = G.currentMove.parts;
  const partIndex = parts.findIndex(
    p => (
      p.category == partOrder.category &&
      p.databaseIndex === partOrder.databaseIndex
    )
  );

  if (partIndex == -1) {
    return INVALID_MOVE;
  }

  parts.splice(partIndex, 1);
}

/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Array} partOrderList
 */
export function addPartsToCart(G, ctx, partOrderList) {
  for (const partOrder of partOrderList) {
    addPartToCart(G, ctx, partOrder);
  }
}

export function confirmCatalogParts(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);
  activePlayer.parts = activePlayer.parts.concat(G.currentMove.parts);

  endTurn(G, ctx);
}



// Alle Züge der Phase 2 als Gruppe:
export const phase2Moves = {
  // DEBUG
  addAllParts,

  // ALLGEMEINE
  selectField,
  goToField,
  endTurn,
  setAction,
  updateCurrentMove,
  answerQuizQuestion,
  closeQuizQuestion,
  updateEscalation,
  confirmEscalation1,
  confirmEscalation2,
  getPartsInEscalation2,

  // KOLLABORATION
  activateReceiver,
  startCollaboration,
  endCollaboration,

  // ROLLE:
  markRoleAsSeen,
  answerRoleQuiz,
  closeQuiz,

  // Aktuelle Phase
  choosePartCategory,
  collectRandomParts,
  markPartToReroll,
  choosePartsToReroll,
  addPartToCart,
  removePartFromCart,
  confirmParts,
  confirmCatalogParts,
  startReroll,
  endReroll,
  resetCategory,

  // TEST:
  updatePart,
  buildPart,
  removePart
};






// =================================
// BEREICH 3: Bauen und simulieren
// =================================


// I) Bauen

export function startBuilding(G, ctx) {
  G.currentMove.startedAt = Date.now();
  G.currentMove.builderActive = true;
  G.currentMove.maxTime = 60;

  if (G.currentMove.solvedRoleQuiz) {
    G.currentMove.maxTime = 90;
  }
}

export function endBuilding(G, ctx) {
  G.currentMove.builderActive = false;

  endTurn(G, ctx);
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Object} param2
 * @param {Number} param2.index
 */
export function updatePart(G, ctx, { index, part }) {
  const activePlayer = getActivePlayer(G, ctx);

  const oldPart = activePlayer.parts[index];
  activePlayer.parts[index] = part;

  if (part.type === "body") {

    // if the part changed position, remove all connected constraints
    if (oldPart.position.x != part.position.x || oldPart.position.y != part.position.y) {
      const connectedConstraints = getConnectedConstraints(activePlayer, part);
      if (part.label !== "Wheel") {
        for (const screw of connectedConstraints) {
          removePart(G, ctx, screw.uuid);
        }
      }

      // WHEEL:
      else {
        const builtParts = activePlayer.parts.filter(p => activePlayer.builtPartIds.includes(p.uuid));
        const connectionData = getAxleConnectionAt(builtParts, part.position);

        // remove all axles if there is no part at the new wheel position
        if (connectionData === null) {
          for (const axle of connectedConstraints) {
            removePart(G, ctx, axle.uuid);
          }
        }

        // if there's a connection at the new position, update the axle too:
        else {
          for (const axle of connectedConstraints) {
            const newAxle = createConstraintData(
              axle.length * (1 / GRID_SIZE),
              {
                vertical: axle.vertical,
                category: axle.category,
                databaseIndex: axle.databaseIndex,
                bodyAUuid: part.uuid,
                bodyBUuid: connectionData.bodyB.uuid,
                pointA: {
                  x: 0, y: 0
                },
                pointB: {
                  x: connectionData.posB.x,
                  y: connectionData.posB.y
                }
              }
            );
            newAxle.uuid = axle.uuid;
            updatePart(G, ctx, {
              index: activePlayer.parts.findIndex(p => p.uuid === axle.uuid),
              part: newAxle
            });
          }
        }
      }
    }
  }
}

export function removePart(G, ctx, uuid) {
  const activePlayer = getActivePlayer(G, ctx);
  const partIndex = activePlayer.builtPartIds.indexOf(uuid);
  if (partIndex === -1) return;
  activePlayer.builtPartIds.splice(partIndex, 1);

  const part = activePlayer.parts.find(p => p.uuid === uuid);
  if (part.type === "body") {
    // Remove connected constraints
    const connectedConstraints = getConnectedConstraints(activePlayer, part);
    for (const screw of connectedConstraints) {
      removePart(G, ctx, screw.uuid);
    }
  }

  // Remove connections from constraints
  if (part.type === "constraint") {
    part.bodyA = null;
    part.bodyB = null;
    part.pointA = null;
    part.pointB = null;
  }
}

/**
 * Markiere ein Teil als gebaut
 * @param {GameState} G
 * @param {Context} ctx
 * @param {String} partUuid
 */
export function buildPart(G, ctx, partUuid) {
  const activePlayer = getActivePlayer(G, ctx);

  // Baue das Teil nur, wenn es noch nicht gebaut ist.
  if (!activePlayer.builtPartIds.includes(partUuid)) {
    activePlayer.builtPartIds.push(partUuid);
  }
}

/**
 *
 * @param {Player} player
 * @param {*} part
 */
function getConnectedConstraints(player, part) {
  if (part.type !== "body") {
    return [];
  }

  const builtConstraints = player.parts.filter(p => p.type === 'constraint' && player.builtPartIds.includes(p.uuid));
  return builtConstraints.filter(p => p.bodyA != null && p.bodyB != null && (p.bodyA.uuid === part.uuid || p.bodyB.uuid === part.uuid));
}

/**
 *
 * @param {Array} builtParts
 * @param {Vector} position
 */
export function getAxleConnectionAt(builtParts, position) {
  // Constraints with a length of zero only attach to wheels
  const attachableBodyParts = builtParts.filter(part => part.label == 'Wheel');
  const attachableBodies = attachableBodyParts.map(bodyFromData);

  const wheels = Query.point(attachableBodies, position);
  if (wheels.length == 0) {
    return null;
  }
  const wheel = wheels[0];

  const wheelCenter = wheel.position;

  const otherBodies = builtParts.filter(part => part.type == 'body' && part.label != 'Wheel').map(bodyFromData);
  const bodiesAtWheelCenter = Query.point(otherBodies, wheelCenter);

  if (bodiesAtWheelCenter.length == 0) {
    return null;
  }

  const mainBody = bodiesAtWheelCenter[0];

  const posA = { x: 0, y: 0 };
  const posB = {
    x: wheel.position.x - mainBody.position.x,
    y: wheel.position.y - mainBody.position.y
  };
  return {
    bodyA: wheel,
    bodyB: mainBody,
    posA,
    posB
  };
}


// II) Simulieren

// (!) Die Datenbank-Handlung nutzt die gleichen
//     Funktionen wie das Datenbank-Feld in Abschnitt 2

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 */
export function selectSimulationMethod(G, ctx, methodId) {
  G.currentMove.methodId = methodId;
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 */
export function startSimulation(G, ctx) {
  G.currentMove.simulationStarted = true;
}

/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Object} simulationData
 */
export function saveSimulationResults(G, ctx, simulationData) {
  const activePlayer = getActivePlayer(G, ctx);

  _saveSimResult(G, ctx, activePlayer, G.currentMove.methodId, simulationData)

  G.currentMove.simulationComplete = true;
}

function _saveSimResult(G, ctx, player, method, simulationData) {
  switch (method) {
    case "ruler":
      if (simulationData) {
        const bounds = simulationData.boundsAtEnd;
        const width = Math.round(Math.abs(bounds.max.x - bounds.min.x) / GRID_SIZE);
        const height = Math.round(Math.abs(bounds.max.y - bounds.min.y) / GRID_SIZE);
        const groundDistance = Math.round((bounds.max.y - simulationData.bottomWithoutWheels) / GRID_SIZE);

        G.currentMove.carWidth = width;
        G.currentMove.carHeight = height;
        G.currentMove.carGroundDistance = groundDistance;

        player.simulationResults.length = width;
        player.simulationResults.height = height;
        player.simulationResults.groundDistance = groundDistance;
      }
      else {
        G.currentMove.carWidth = null;
        G.currentMove.carHeight = null;
        G.currentMove.carGroundDistance = null;

        player.simulationResults.length = null;
        player.simulationResults.height = null;
        player.simulationResults.groundDistance = null;
      }
      break;

    case "scale":
      if (simulationData) {
        G.currentMove.carWeight = simulationData.totalWeight;
        player.simulationResults.weight = simulationData.totalWeight;
      }
      else {
        G.currentMove.carWeight = null;
        player.simulationResults.weight = null;
      }
      break;

    case "simulation":
      if (simulationData) {
        const xStart = Math.round(simulationData.boundsAtStart.min.x);
        const xEnd = Math.round(simulationData.boundsAtEnd.min.x);
        const delta = Math.round(Math.abs(xEnd - xStart) / GRID_SIZE);

        G.currentMove.carDistanceTravelled = delta;
        player.simulationResults.distanceTravelled = delta;
      }
      else {
        G.currentMove.carDistanceTravelled = null;
        player.simulationResults.distanceTravelled = null
      }
      break;
  }
}

export function endSimulation(G, ctx) {
  G.currentMove.simulationDone = true;
}


// III) Datenoptimierung

// choose partCategory

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {String} partUuid
 */
export function addPartToDiscard(G, ctx, partUuid) {

  // Entfernen, wenn vorhanden
  if (G.currentMove.partsToDiscard.includes(partUuid)) {
    G.currentMove.partsToDiscard.splice(G.currentMove.partsToDiscard.indexOf(partUuid), 1);
  }

  // Nicht mehr als drei Teile
  else if (G.currentMove.partsToDiscard.length >= 3) {
    return INVALID_MOVE;
  }

  // sonst hinzufügen
  else {
    G.currentMove.partsToDiscard.push(partUuid);
  }
}

export function openDatabase(G, ctx) {
  G.currentMove.isDatabaseOpen = true;
}

/**
 * @param {GameState} G
 * @param {Context} ctx
 */
export function confirmOptimization(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);

  // Speichere die Anzahl gesammelter Teile, bevor die neuen
  // optimierten Teile eingesammelt werden.
  // So verfälscht die Optimierung die Anzahl der Teile nicht.
  saveCollectedPartsPoints(activePlayer);

  // Teile abwerfen:
  for (const uuid of G.currentMove.partsToDiscard) {
    const index = activePlayer.parts.findIndex(
      part => part.uuid === uuid
    );
    activePlayer.parts.splice(index, 1);
  }

  // Teile aufnehmen:
  const newParts = G.currentMove.parts;
  activePlayer.parts = activePlayer.parts.concat(newParts);

  endTurn(G, ctx);
}



// Alle Züge der Phase 3 als Gruppe:
export const phase3Moves = {
  // DEBUG
  addAllParts,

  // ALLGEMEINE
  selectField,
  goToField,
  endTurn,
  setAction,
  updateCurrentMove,
  answerQuizQuestion,
  closeQuizQuestion,
  updateEscalation,
  confirmEscalation1,
  confirmEscalation2,
  getPartsInEscalation2,

  // KOLLABORATION
  activateReceiver,
  startCollaboration,
  endCollaboration,

  // ROLLE:
  markRoleAsSeen,
  answerRoleQuiz,
  closeQuiz,

  // Bauen:
  startBuilding,
  endBuilding,
  updatePart,
  buildPart,
  removePart,

  // Simulation
  selectSimulationMethod,
  startSimulation,
  endSimulation,
  saveSimulationResults,

  // Optimieren
  openDatabase,
  addPartToDiscard,
  confirmOptimization,

  // Datenbank-Methoden aus 2:
  addPartToCart,
  removePartFromCart,
  confirmParts,
  choosePartCategory,

  // Datenbank-Methoden aus 1B:
  startViewingDatabase,
  closeDatabase
};



/// ZUSATZFUNKTIONEN

/**
 *
 * @param {Player} player
 */
function getRemainingPartRequests(player) {
  return Object.values(dataCategories).reduce((remaining, cat) => {
    const requested = player.partRequests[cat.id] || 0;
    if (requested === 0) remaining[cat.id] = 0;
    else {
      const partsInCat = player.parts.filter(part => part.category == cat.id);
      remaining[cat.id] = requested - partsInCat.length;
    }
    return remaining;
  }, {});
}


// ====================================
// ERGEBNISBEREICH
// ====================================

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Object} param2
 */
export function submitResults(G, ctx, { playerId, scale, ruler, simulation } = {}) {
  const player = G.players[playerId];
  if (player == null) {
    return INVALID_MOVE;
  }

  _saveSimResult(G, ctx, player, 'ruler', ruler);
  _saveSimResult(G, ctx, player, 'scale', scale);
  _saveSimResult(G, ctx, player, 'simulation', simulation);

  calculateRequirementMarks(G, ctx, player);
  calculatePartPoints(G, ctx, player);
  calculatePremiseMarks(G, ctx, player);
  calculateMovementPoints(G, ctx, player);
  calculateTotalPoints(G, ctx, player);
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Player} player
 */
export function calculateRequirementMarks(G, ctx, player) {
  const MARK_FAILED = 0;
  const MARK_PASSED = 1;
  const MARK_SUCCEEDED = 2;

  const marks = {};
  const points = {};

  player.requirements.forEach(requirement => {
    const measurement = player.simulationResults[requirement.type];

    // no measurement? we set the difference to Infinity to make sure we fail
    const difference = measurement === null ? Infinity : Math.abs(requirement.value - measurement);

    let point = 0;
    let mark = MARK_FAILED;
    if (difference < requirement.value * 0.05) {
      mark = MARK_SUCCEEDED;
      point = 20;
    }
    else if (difference < requirement.value * 0.10) {
      mark = MARK_PASSED;
      point = 15;
    }
    else if (difference < requirement.value * 0.35){
      mark = MARK_PASSED;
      point = 10;
    }
    marks[requirement.type] = mark;
    points[requirement.type] = point;
  });

  player.marks.requirements = marks;
  player.points.requirements = points;
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Player} player
 */
export function calculatePartPoints(G, ctx, player) {
  saveCollectedPartsPoints(player);
  player.points.parts.builtParts = player.builtPartIds.length;
  player.points.parts.totalParts = player.parts.length;
  player.points.parts.unbuiltParts = player.points.parts.builtParts - player.points.parts.totalParts;
}

/**
 * Speichert die Anzahl der gesammelten und nicht eingesammelten Teile.
 * Funktioniert nur beim ersten Aufruf.
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Player} player
 */
function saveCollectedPartsPoints(player) {
  if (!player.points.parts) {
    player.points.parts = {};
  }
  // Stelle sicher, dass der Wert nur EINMAL gespeichert wird:
  if (player.points.parts.totalPartRequests != null) {
    return;
  }
  // Summiere alle Datenanforderungen
  player.points.parts.totalPartRequests = Object.values(player.partRequests).reduce((sum, amount) => sum + amount, 0);
  player.points.parts.totalCollectedParts = player.parts.length;
  player.points.parts.remainingPartRequests = player.points.parts.totalCollectedParts - player.points.parts.totalPartRequests;
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Player} player
 */
export function calculatePremiseMarks(G, ctx, player) {
  const isFulfilled = hasFulfilledPremise(player);
  if (isFulfilled) {
    player.points.premise = 15;
  }
  else {
    player.points.premise = 0;
  }
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Player} player
 */
export function calculateMovementPoints(G, ctx, player) {
  if (player.movementPoints < 0) {
    player.points.movement = player.movementPoints;
  }
  else {
    player.points.movement = 0;
  }
}


export function calculateTotalPoints(G, ctx, player) {
  const requirementPoints = Object.values(player.points.requirements || {}).reduce(
    (sum, point) => sum + point, 0
  );
  const dataPoints = player.points.parts.remainingPartRequests + player.points.parts.unbuiltParts + player.points.movement;
  const premisePoints = player.points.premise || 0;

  // Save player points
  player.points.total = {
    sum: requirementPoints + dataPoints + premisePoints,
    requirements: requirementPoints,
    data: dataPoints,
    premise: premisePoints
  };
}




// ====================================
// Zwischenbereich: Milestone
// ====================================

/**
 * @param {GameState} G
 * @param {Context} ctx
 */
export function markAsReady(G, ctx, playerId) {
  const waitingOnPlayers = G.currentMove.waitingOnPlayers;
  const playerIndex = waitingOnPlayers.indexOf(playerId);
  if (playerIndex != -1) {
    waitingOnPlayers.splice(playerIndex, 1);
  } else {
    return INVALID_MOVE;
  }
}




// ====================================
// ========   ROLLEN-QUIZ   ===========
// ====================================


/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {String} role
 */
export function markRoleAsSeen(G, ctx, role) {
  G.seenRoles[role] = true;
  G.currentMove.solvedRoleQuiz = true;
  G.currentMove.roleActionDone = true;
}

/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number} answerId
 */
export function answerRoleQuiz(G, ctx, answerId) {
  const quizQuestion = G.currentMove.roleQuestion;

  if (quizQuestion.correctAnswer === answerId) {
    G.currentMove.solvedRoleQuiz = true;
  }
  else {
    G.currentMove.solvedRoleQuiz = false;
  }

  G.currentMove.answeredRoleQuestion = true;
}

/**
 * @param {GameState} G
 */
export function closeQuiz(G) {
  G.currentMove.roleActionDone = true;
}




// === DEBUG ===

export function addAllParts(G, ctx) {
  const activePlayer = getActivePlayer(G, ctx);

  // add 10 pieces of each part
  for (let i = 0; i < 10; i++) {
    const dataEntries = createAllData();
    let dataEntriesArray = [];
    for (const cat in dataEntries) {
      dataEntriesArray = dataEntriesArray.concat(dataEntries[cat]);
    }
    activePlayer.parts = activePlayer.parts.concat(dataEntriesArray);
  }

  console.log("add all parts");
}




// =====================================
// === UNBENUTZT...
// =====================================


/**
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Array} partIds
 */
export function saveBuiltParts(G, ctx, partIds) {
  const player = G.players[ctx.currentPlayer];
  player.builtPartIds = partIds;
}

export function updateParts(G, ctx, parts) {
  const player = G.players[ctx.currentPlayer];
  player.parts = parts.slice();
}


/**
 * Gibt den Spieler zurück, der jetzt gerade etwas tun kann
 * (kann auch nicht sein Zug sein)
 * @param {GameState} G
 * @param {Context} ctx
 */
function getActivePlayer(G, ctx) {
  if (ctx.activePlayers) {
    const activePlayerId = Object.keys(ctx.activePlayers)[0];
    return G.players[activePlayerId];
  }
  return G.players[ctx.currentPlayer];
}

/**
 * Gibt den Spieler zurück, der gerade am Zug ist
 * (kann auch gerade nichts tun können)
 * @param {GameState} G
 * @param {Context} ctx
 */
function getCurrentPlayer(G, ctx) {
  return G.players[ctx.currentPlayer];
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 */
function getCurrentField(G, ctx) {
  const currentPlayer = G.players[ctx.currentPlayer];
  const currentArea = G.areas[currentPlayer.area];
  return currentArea.fields.find(field => field.id == currentPlayer.field);
}
