export const methods = [
  {
    id: "ruler",
    type: "ruler",
    label: "Lineal"
  },
  {
    id: "scale",
    type: "scale",
    label: "Waage"
  },
  {
    id: "simulation",
    type: "simulation",
    label: "Rampe"
  }
];