<template>
  <card
    class="requirement-card"
    @click="$emit('click', $event)"
    v-bind="{...$attrs}"
  >
    <template v-slot:default>
      <h1>{{ requirement.label }}</h1>
      <p class="requirement-card__value">{{ requirement.value }}{{ requirement.unit }}</p>
    </template>
    <template v-slot:back>
      <icon name="dokument" color="#b9b9b9"></icon>
    </template>
  </card>
</template>

<script>
import Card from "./Card.vue";
import Icon from "../../ui/Icon.vue";
export default {
  components: {
    Card,
    Icon
  },
  props: {
    requirement: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">
.requirement-card {
  &__value {
    margin-top: 0;
    font-size: 1.25em;
  }
}
</style>