<template>
  <svg
    :is="useTransform ? 'g' : 'svg'"
    class="car-part"
    :viewBox="useTransform ? null : viewBox"
    :width="width * scale"
    :height="maxHeight < 0 ? height : maxHeight"
    :data-uuid="part.uuid"
    :style="usePosition ? {
      transform: transform,
      left: `${left}px`,
      top: `${top}px`
    } : {}"
    :class="{
      'car-part--wheel': part.label === 'Wheel',
      'car-part--constraint': part.type === 'constraint',
      '-is-drop-blocked': isBeeingDragged && dropBlocked,
      '-is-beeing-dragged': isBeeingDragged,
      '-last-drop-successful': lastDropSuccessful,
      '-is-small' : small,
      '-show-border': showBorder
    }"
    @contextmenu.prevent=""
  >
    <!-- 
    :transform="useTransform ? `translate(${left}, ${top})` : ''"
    -->
    <path v-if="part.type == 'body'" :d="pathDescription" :fill="pathFill" />
    <template v-else>
      <template v-if="part.length === 0">
        <circle
          v-if="partA"
          class="car-part__axlecircle"
          r="7"
          :cx="partA.position.x + part.pointA.x"
          :cy="partA.position.y + part.pointA.y"
        />
        <circle
          v-else
          class="car-part__axlecircle"
          r="7"
          :cx="0"
          :cy="0"
        />
      </template>
      <template v-else>
        <circle
          v-if="partA"
          class="car-part__screwcircle"
          r="3"
          :cx="partA.position.x + part.pointA.x"
          :cy="partA.position.y + part.pointA.y"
        />
        <template v-if="partB">
          <line
            class="car-part__screwline"
            :x1="partA.position.x + part.pointA.x"
            :y1="partA.position.y + part.pointA.y"
            :x2="partB.position.x + part.pointB.x"
            :y2="partB.position.y + part.pointB.y"
          />
          <circle
            class="car-part__screwcircle"
            r="3"
            :cx="partB.position.x + part.pointB.x"
            :cy="partB.position.y + part.pointB.y"
          />
        </template>
        <template v-else>
          <line
            class="car-part__screwline"
            x1="0"
            y1="0"
            :x2="part.vertical ? 0 : part.length"
            :y2="part.vertical ? part.length : 0"
            stroke="black"
          />
          <circle
            class="car-part__screwcircle"
            r="3"
            :cx="0"
            :cy="0"
          />
          <circle
            class="car-part__screwcircle"
            r="3"
            :cx="part.vertical ? 0 : part.length"
            :cy="part.vertical ? part.length : 0"
          />
        </template>
      </template>
    </template>
    <!--
      <rect :x="part.bounds.min.x" :y="part.bounds.min.y" :width="width" :height="height" fill="rgba(255,0,0,0.4)" />
    -->
  </svg>
</template>

<script>
import _ from "lodash";
import { GRID_SIZE } from '../../../../car/carPartCreator';
export default {
  props: {
    part: {
      type: Object,
      required: true
    },
    dropBlocked: {
      type: Boolean,
      default: false
    },
    parts: {
      type: Array,
      default() {
        return [];
      },
    },
    small: {
      type: Boolean,
      default: false
    },
    usePosition: {
      type: Boolean,
      default: false
    },
    showBorder: {
      type: Boolean,
      default: false
    },
    useTransform: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number,
      default: -1
    },
    displayHeight: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      offsetX: 0,
      offsetY: 0,
      isBeeingDragged: false,
      lastDropSuccessful: false,
      gridSize: GRID_SIZE
    };
  },
  computed: {
    scale() {
      if (this.displayHeight > 0 && this.maxHeight > 0) {
        return this.displayHeight / this.maxHeight;
      }
      return 1;
    },
    connect() {
      if (this.part.type === 'body') {
        return this.part.connect;
      }
      return 'both';
    },
    patternRotation() {
      return this.connect == 'h' ? 90 : 0;
    },
    pathFill() {
      return this.connect == 'both' ? this.part.render.fillStyle : `url(#pattern${this.part.uuid})`;
    },
    left() {
      return Math.round(this.bounds.min.x);
    },
    top() {
      return Math.round(this.bounds.min.y);
    },
    bounds() {
      if (this.part.type == 'body') return this.part.bounds;

      const margin = this.gridSize * 1.5;

      if (this.partA == null || this.partB == null) {
        return {
          min: { x: -margin, y: -margin },
          max: { x: this.part.length + margin, y: this.part.length + margin }
        }
      }

      const pointA = {
        x: this.partA.position.x + this.part.pointA.x,
        y: this.partA.position.y + this.part.pointA.y
      };
      const pointB = {
        x: this.partB.position.x + this.part.pointB.x,
        y: this.partB.position.y + this.part.pointB.y
      };
      return {
        min: {
          x: Math.min(pointA.x, pointB.x) - margin,
          y: Math.min(pointA.y, pointB.y) - margin
        },
        max: {
          x: Math.max(pointA.x, pointB.x) + margin,
          y: Math.max(pointA.y, pointB.y) + margin
        }
      }
    },
    transform() {
      return `translate(${this.offsetX}px, ${this.offsetY}px)`;
    },
    pathDescription() {
      let pathString = `M${ this.part.vertices[0].x} ${ this.part.vertices[0].y} `;
      for (let i = 1; i < this.part.vertices.length; i++) {
        pathString += `L${ this.part.vertices[i].x} ${ this.part.vertices[i].y} `;
      }
      pathString += 'Z';
      return pathString;
    },
    width() {
      return Math.round(this.bounds.max.x - this.bounds.min.x);
    },
    height() {
      return Math.round(this.bounds.max.y - this.bounds.min.y);
    },
    viewBox() {
      if (this.maxHeight >= 0) {
        const vCenter = this.top + this.height/2;
        return `${this.left} ${vCenter - this.maxHeight/2} ${this.width} ${this.maxHeight}`;
      }
      return `${this.left} ${this.top} ${this.width} ${this.height}`;
    },
    partA() {
      if (this.part.bodyA) return this.parts.find(part => part.uuid == this.part.bodyA.uuid);
      else return null;
    },
    partB() {
      if (this.part.bodyB) return this.parts.find(part => part.uuid == this.part.bodyB.uuid);
      else return null;
    }
  },
  mounted() {
    this.$emit("mounted", this);
  }
}
</script>

<style lang="scss">
.car-part {
  display: block;
  touch-action: none;
  transition: transform 200ms ease-out;

  &--wheel {
    z-index: 1;
  }
  &--constraint {
    z-index: 2;
  }

  &.-is-beeing-dragged {
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
    z-index: 4;
    transition: none;
    pointer-events: none;
  }
  &.-last-drop-successful {
    transition: none;
  }

  &.-is-beeing-dragged.-is-drop-blocked {
    opacity: 0.6;
    filter: drop-shadow(0 0 2px rgba(255, 100, 130, 1.0));
  }

  &.-is-small {
    max-height: 54px;
    max-width: 54px;
  }

  &.-show-border {
    stroke: #777;
  }

  &__screwline {
    stroke-width: 1px;
    stroke: #222;
  }
  &__screwcircle {
    fill: #222;
  }
  &__axlecircle {
    fill: #CCC;
    stroke: #777;
    stroke-width: 3px;
  }
}
</style>