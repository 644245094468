import _ from "lodash";
import { Stage, TurnOrder } from "boardgame.io/core";

import * as Moves from "./Moves";

import { createFieldGenerator, fieldTypes } from "./Resources/Fields";
import { createPlayers, Player } from "./Resources/Players.js";
import { methods } from "./Resources/Methods";
import { createAllData, dataCategories } from "./Resources/Data";
import { getAllRequirements } from "./Resources/Requirements";
import { roles, QuizQuestion } from "./Resources/Roles.js";
import { givePlayersPremises } from "./Resources/Premises.js";
import { questionIdsByArea } from "./Resources/Quiz.js";

export const VPE = {
  name: 'vpe',

  minPlayers: 1,
  maxPlayers: 4,

  setup: (ctx, setupData = {}) => {
    const players = createPlayers(ctx);
    const requirements = getAllRequirements();
    const data = createAllData();

    givePlayersPremises(ctx, players);

    const DEBUG = false;

    if (DEBUG) {
      // for debug: add all parts to player 0

      players['0'].methods = methods.slice(0);
      players['0'].requirements = requirements.slice(0, 3);

      players['0'].parts = _.flatten(Object.values(data)).slice();
      players['0'].partRequests = {
        0: 10,
        1: 5,
        3: 5,
        4: 15
      };
    }



    return {
      matchName: setupData.matchName || "",
      // currentMove wird beim Betreten eines Felds mit relevanten Daten gefüllt
      currentMove: {},
      currentPhaseData: {},
      currentEscalation: {},
      seenRoles: {},
      answeredQuizQuestions: [],
      areas: [
        createGameArea({
          id: 0,
          title: "Vereinbarung Leistungsumfang",
          fields: [
            fieldTypes.GET_REQUIREMENT,
            fieldTypes.VIEW_DATABASE,
            fieldTypes.GET_REQUIREMENT,
            fieldTypes.GET_METHOD,
            fieldTypes.GET_REQUIREMENT,
            fieldTypes.GET_METHOD,
            fieldTypes.VIEW_DATABASE,
            fieldTypes.GET_METHOD,
            fieldTypes.GET_DATA,
            fieldTypes.GET_DATA,
            fieldTypes.VIEW_DATABASE,
            fieldTypes.GET_DATA
          ]
        }),
        createGameArea({
          id: 1,
          title: "Daten Sammeln",
          fields: [
            fieldTypes.COLLECT_DATA,
            fieldTypes.COLLECT_DATA,
            fieldTypes.COLLECT_FROM_DATABASE,
            fieldTypes.COLLECT_FROM_DATABASE,
            fieldTypes.COLLECT_DATA,
            fieldTypes.COLLECT_FROM_DATABASE,
            fieldTypes.COLLECT_DATA,
            fieldTypes.COLLECT_FROM_DATABASE,
            fieldTypes.COLLECT_FROM_DATABASE,
            fieldTypes.COLLECT_DATA
          ]
        }),
        createGameArea({
          id: 2,
          title: "Bauen",
          fields: [
            fieldTypes.OPTIMIZE,
            fieldTypes.BUILD,
            fieldTypes.OPTIMIZE,
            fieldTypes.BUILD,
            fieldTypes.SIMULATE,
            fieldTypes.BUILD,
            fieldTypes.OPTIMIZE,
            fieldTypes.SIMULATE,
            fieldTypes.OPTIMIZE,
            fieldTypes.BUILD,
          ]
        })
      ],

      // Zusatzinformationen zu den einzelnen Spielphasen
      phases: {
        introduction: {
          type: "milestone",
          title: "Einleitung",
          id: "introduction"
        },
        collectRequirements: {
          type: "board",
          title: "Vereinbarung Leistungsumfang",
          id: "phase1",
        },
        milestoneLVVP: {
          type: "milestone",
          title: "Meilenstein",
        },
        collectData: {
          type: "board",
          title: "Datenbereitstellung",
          id:"phase2",
        },
        milestoneDBT: {
          type: "milestone",
          title: "Meilenstein"
        },
        build: {
          type: "board",
          title: "Auslegung & Optimierung",
          id: "phase3",
        },
        grading: {
          type: "grading",
          title: "Auswertung",
          id: "grading"
        },
        results: {
          type: "results",
          id: "results",
          title: "Bewertung"
        }
      },
      quizPoints: 0,
      requirements,
      data,
      methods,
      players,
      roles,
      dataCategories
    };
  },

  phases: {
    introduction: createMilestonePhase({
      start: true,
      next: "collectRequirements"
    }),

    collectRequirements: createBoardPhase({
      movementPoints: 20,
      phaseSettings: {
        areaId: 0,
        maxTurns: 12 // Runden  in der Phase
      },

      moves: Moves.phase1Moves,
      turn: {
        // stages sind Abschnitte in einem Zug, in dem andere
        // Regeln gelten.
        // Zum Beispiel kann man sie nutzen, um es anderen
        // Spielern zu ermöglichen in einem Zug zu spielen,
        // in dem sie nicht dran sind.
        // Genutzt werden sie mit setActivePlayers.
        stages: {
          receiveRequirement: {
            moves: {
              setAction: Moves.setAction,
              confirmRequirement: Moves.confirmRequirement,
              resetRequirement: Moves.resetRequirement,
              setRequirementToReplace: Moves.setRequirementToReplace,
              endTurn: Moves.endTurn
            }
          },
          receiveMethod: {
            moves: {
              takeMethod: Moves.takeMethod,
              endTurn: Moves.endTurn
            }
          }
        }
      },
      next: "milestoneLVVP"
    }),

    milestoneLVVP: createMilestonePhase({
      next: "collectData"
    }),

    collectData: createBoardPhase({
      movementPoints: 10,
      onTurnBegin(G, ctx) {
        // show escalation in round 1
        if (G.currentPhaseData.currentRound == 1) {
          G.currentEscalation = {
            visible: false,
            id: "escalation-1",
            label: "Nacharbeit: Daten nachvereinbaren",
            useEscalation: null,
            introConfirmed: false,
            category: null,
            amount: 0,
            requestConfirmed: false
          };
        }
      },
      onTurnEnd(G) {
        G.currentEscalation = {};
      },
      phaseSettings: {
        areaId: 1,
        maxTurns: 6
      },
      moves: Moves.phase2Moves,
      next: "milestoneDBT"
    }),

    milestoneDBT: createMilestonePhase({
      next: "build"
    }),

    build: createBoardPhase({
      movementPoints: 20,
      onTurnBegin(G, ctx) {
        // show escalation in round 1
        if (G.currentPhaseData.currentRound == 1) {
          G.currentEscalation = {
            visible: false,
            id: "escalation-2",
            label: "Nacharbeit: Fehlende Daten sammeln",
            useEscalation: null,
            introConfirmed: false,
            category: null,
            parts: [],
            keepData: null,
            usedEscalation: false
          };
        }
      },
      onTurnEnd(G) {
        G.currentEscalation = {};
      },
      phaseSettings: {
        areaId: 2,
        maxTurns: 10
      },
      moves: Moves.phase3Moves,
      next: "grading"
    }),

    grading: createMilestonePhase({
      moves: {
        submitResults: Moves.submitResults,
        markAsReady: Moves.markAsReady
      },
      next: "results"
    }),

    results: {
      onBegin(G, ctx) {
        // Lasse alle Spieler gleichzeitig spielen.
        ctx.events.setActivePlayers({
          all: Stage.NULL,
          revert: true
        });
      },
      turn: {
        order: TurnOrder.RESET
      }
    }
  }
};


/**
 *
 * @param {Object} options
 * @param {Number} options.id
 * @param {String} options.title
 * @param {String[]} options.fields
 */
function createGameArea(options = {}) {
  const createNextField = createFieldGenerator();
  const fields = options.fields.map(fieldtype => createNextField(fieldtype));
  return {
    id: options.id,
    title: options.title,
    fields: fields,
    gateAt: options.gateAt
  };
}



/**
 * Erstellt eine neue Phase, die auf dem Spielbrett
 * stattfindet.
 */
function createBoardPhase({
  start = false,
  turn = {},
  moves = {},
  next = null,
  phaseSettings = { maxTurns: 5, areaId: 0 },
  onBegin = function() {},
  onTurnBegin = function() {},
  onTurnEnd = function() {},
  movementPoints = 20
} = {}) {
  return {
    start: start,

    onBegin(G, ctx) {
      G.currentPhaseData = {
        currentRound: 0,
        maxTurns: phaseSettings.maxTurns || 5,
        areaId: phaseSettings.areaId || 0
      };

      // Zu Beginn jeder Phase werden die Daten aller
      // Spieler aktualisiert.
      moveAllPlayersToArea(G, ctx, phaseSettings.areaId || 0);
      giveAllPlayersMovementPoints(G, ctx, movementPoints);

      onBegin(G, ctx);

      console.log("Phase beginnt");
    },
    endIf: allPlayersReachedMaxTurn,
    onEnd(G, ctx) {
      // Wir setzen die Züge der Spieler für die nächste
      // Brettphase am Ende einer Phase.
      // Dadurch haben die Spieler am Anfang der nächsten
      // Phase bereits Züge und die Phase endet nicht sofort.
      resetPlayerTurns(G, ctx);
    },

    // Speichere alle möglichen Züge,
    // stelle aber sicher, dass die Spieler immer bereit sein können
    moves: moves,

    turn: Object.assign(
      {
        order: TurnOrder.RESET,
        onBegin(G, ctx) {
          const phase = G.currentPhaseData;
          const currentPlayer = G.players[ctx.currentPlayer];
          G.currentMove.confirmedTurn = false;
          updatePlayerMoveData(G, ctx);

          if (currentPlayer.id === '0') {
            phase.currentRound += 1;
            shuffleSpecialFields(G, ctx);
          }

          onTurnBegin(G, ctx);
        },
        onEnd(G, ctx) {
          onTurnEnd(G, ctx);
          console.log(`Spieler ${ctx.currentPlayer} Zug vorbei`);
        }
      },
      turn
    ),

    next: next
  };
}


/**
 * Erstellt eine Spielphase, bei dem alle Spieler
 * angeben müssen, dass sie bereit sind, dann geht das
 * Spiel weiter.
 */
function createMilestonePhase({
  next = "",
  moves,
  start = false
}) {
  return {
    start,
    onBegin(G, ctx) {
      G.currentPhaseData = {};
      // Erstelle eine Liste aller Spieler, auf die
      // gewartet wird. (Wenn sie leer ist, geht
      // es weiter)
      G.currentMove = {
        waitingOnPlayers: Object.keys(G.players)
      };
      // Lasse alle Spieler gleichzeitig spielen
      ctx.events.setActivePlayers({
        all: Stage.NULL,
        revert: true
      });
    },
    endIf(G, ctx) {
      return (
        G.currentMove.waitingOnPlayers &&
        G.currentMove.waitingOnPlayers.length == 0
      );
    },
    onEnd(G, ctx) {
      G.currentPhaseData = {};
      G.currentMove = {};
    },
    turn: {
      order: TurnOrder.RESET
    },
    moves: Object.assign(
      {},
      moves,
      {
        markAsReady: Moves.markAsReady,
        endTurn: Moves.endTurn
      }
    ),
    next: next
  };
}


/**
 * Wird zu Beginn jedes Spielzugs in einem Bereich mit Feldern
 * aufgerufen.
 * @param {GameState} G
 * @param {Context} ctx
 */
function updatePlayerMoveData(G, ctx) {
  const player = G.players[ctx.currentPlayer];
  player.movedThisTurn = false;
}

/**
 * Test: Hat kein Spieler mehr Züge übrig?
 * @param {GameState} G
 * @param {Context} ctx
 */
function allPlayersReachedMaxTurn(G, ctx) {
  const currentPhaseData = G.currentPhaseData;
  if (
    currentPhaseData == null ||
    currentPhaseData.currentRound == null
  ) {
    return false;
  }

  return currentPhaseData.currentRound > G.currentPhaseData.maxTurns;
}




/**
 * Allen Spielern Bewegungspunkte zuweisen.
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number} movementPoints
 */
function giveAllPlayersMovementPoints(G, ctx, movementPoints) {
  const players = Object.values(G.players);
  for (const player of players) {
    player.movementPoints += movementPoints;
  }
}

/**
 * Allen Spielern verbleibende Züge zuweisen.
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number} turns
 */
function resetPlayerTurns(G, ctx) {
  const players = Object.values(G.players);
  for (const player of players) {
    player.turnsThisPhase = 0;
  }
}

/**
 *
 * @param {GameState} G
 * @param {Context} ctx
 * @param {Number} areaId
 */
function moveAllPlayersToArea(G, ctx, areaId) {
  const area = G.areas[areaId];
  // Bewege alle Spieler in diesen Bereich:
  Object.values(G.players).forEach(
    (player, index) => {
      player.area = areaId;
      player.field = null;
      player.fieldIndex = index;
    }
  );
}


function shuffleSpecialFields(G, ctx) {
  const areaId = G.currentPhaseData.areaId;
  const area = G.areas[areaId];

  const fields = area.fields;

  // remove special attributes
  fields.forEach(field => {
    field.hasRole = false;
    field.isCollaborative = Object.values(G.players).length > 1; // MAKE ALL FIELDS COLLABORATIVE (if there are more players)
    field.hasQuiz = false
  });

  const fieldsCopy = fields.slice(0);
  const fieldGroups = [];

  if (area.gateAt != null) {
    fieldGroups.push(fieldsCopy.slice(0, area.gateAt));
    fieldGroups.push(fieldsCopy.slice(area.gateAt));
  }
  else {
    fieldGroups.push(fieldsCopy);
  }

  // get the amount of questions that have to be answered
  // in this area
  const amountOfPlayers = Object.values(G.players).length;
  const neededQuizQuestions = amountOfPlayers + 1;
  const answeredQuestionsInArea = questionIdsByArea[areaId].filter(
    qId => G.answeredQuizQuestions.includes(qId)
  );

  // do this for each part
  fieldGroups.forEach(fieldsInGroup => {

    const amountOfSpecial = Math.ceil(2 / fieldGroups.length);

    // add quiz if not all questions have been answered
    if (answeredQuestionsInArea.length < neededQuizQuestions) {
      for (let i = 0; i < amountOfSpecial; i++) {
        const quizFieldCopy = takeRandomEntry(ctx, fieldsInGroup);
        const quizField = fields.find(f => f.id === quizFieldCopy.id);
        quizField.hasQuiz = true;
      }
    }


    // add a role to one of each different field type
    const differentFieldTypes = fields.reduce((types, field) => {
      if (!types.includes(field.type)) {
        types.push(field.type);
      }
      return types;
    }, []);
    for (const fieldType of differentFieldTypes) {
      const fieldsOfType = fieldsInGroup.filter(f => f.type === fieldType);

      if (fieldsOfType.length == 0) continue;

      const roleFieldCopy = takeRandomEntry(ctx, fieldsOfType);

      // remove this field from the fieldsInGroup
      const fieldsInGroupIndex = fieldsInGroup.findIndex(f => f.id === roleFieldCopy.id);
      fieldsInGroup.splice(fieldsInGroupIndex, 1);

      const roleField = fields.find(f => f.id === roleFieldCopy.id);
      roleField.hasRole = true;
    }
  });
}

/**
 *
 * @param {Array} arr
 */
function takeRandomEntry(ctx, arr) {
  const index = Math.floor(ctx.random.Number() * arr.length);
  const [entry] = arr.splice(index, 1);
  return entry;
}







// =================
// Type declarations

export class GameState {
  constructor() {
    /** @type {CurrentMove} */
    this.currentMove = {};
    this.currentPhaseData = {};
    this.areas = [];
    this.data = [];
    this.methods = [];
    /** @type {Object.<string, Player>} */
    this.players = {};
    /** @type {Array} */
    this.roles = [];
    this.dataCategories = [];
  }
}

export class Context {
  constructor() {
    this.numPlayers = 0;
    this.currentPlayer = "0";
    this.activePlayers = {};
  }
}

/**
 * @typedef {Object} CurrentMove
 * @property {QuizQuestion} roleQuestion
 * @property {Boolean} solvedRoleQuiz
 * @property {Boolean} roleActionDone
 * @property {Boolean} answeredRoleQuestion
 * @property {Boolean} confirmedTurn
 * @property {number} area
 * @property {number} field
 */