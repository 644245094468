<template>
  <div class="fielddialogs-data">
    <role-dialogs v-if="hasRoleAndRoleOpen">
      <template v-slot:intro>
        <p>Hallo, ich bin eine <strong>Datenmanagerin</strong>.</p>
        <p>
          Ich unterstütze die Simulationsexperten beim Finden der zuständigen Bauteil-Konstrukteure, bei der Vereinbarung des Umfangs der zu liefernden Daten sowie bei der Dokumentation in der Leistungsvereinbarung.
        </p>
        <p>
          Gut, dass du nach mir gefragt hast. Mit meiner Hilfe darfst du aus
          einer beliebigen Datenkategorie eine beliebige Anzahl an Datensätzen
          (Daten) vereinbaren.
        </p>
      </template>
      <template v-slot:question>
        <p>
          Hallo, hier ist wieder eine Datenmanagerin!
        </p>
      </template>
      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die Arbeit gehen.
        </p>
        <p>
          Mit meiner Hilfe darfst du aus einer beliebigen Datenkategorie 
          eine beliebige Anzahl an Datensätzen (Daten) vereinbaren!
        </p>
      </template>
      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du nochmal
          Hilfe brauchst.
        </p>
      </template>
    </role-dialogs>

    <!-- QUIZFRAGE -->
    <quiz-dialogs
      v-else-if="currentField.hasQuiz && !currentMove.handledQuizQuestion"
    ></quiz-dialogs>

    <!-- ENDE -->
<!--     <template v-else-if="currentMove.confirmed == true">
      <game-dialog>
        <div class="text-center">
            Sehr gut! Die Daten wurden deinem Dashboard hinzugefügt.
          </div>
          <div class="row">
            <button-active-player @click="moves.endTurn()">
              Zug abschließen
            </button-active-player>
          </div>
      </game-dialog>
    </template> -->

    <!-- Weitergeben -->
    <template v-else-if="isCurrentPlayerActive && currentMove.collaborationSelected === true">
      <game-dialog v-if="!currentMove.selectedReceiver">
        <div class="text-center">
          An welchen Spieler möchtest du die Daten weitergeben?
        </div>

        <div class="row">
          <player-element
            v-for="player in inactivePlayers"
            :key="player.id"
            :player="player"
            @click="moves.activateReceiver({
              receiver: player,
              data: {
                selectedReceiver: null,
                collaborationSelected: false,
              },
            })"
          ></player-element>
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endCollaboration()"
          >
            Zurück
          </button-active-player>
        </div>
      </game-dialog>
    </template>

    <!-- 1. Auswählen -->
    <template v-else-if="currentMove.hasSelectedData === false">
      <!-- Ohne Rolle --> 
      <game-dialog v-if="!currentMove.solvedRoleQuiz">
        <!-- Normale Version, drei offene Datenpakete aus denen zwei gewählt werden können -->

        <div v-if="isCurrentPlayerActive" class="text-center">Daten sammeln</div>
        <div class="text-center">
          Wähle bis zu <strong>2</strong> Datenpakete aus!
        </div>

        <div class="row">
          <data-card
            v-for="data in currentMove.randomDatas"
            :key="data.id"
            :category="data"
            :amount="data.amount"
            :clickable="true"
            :disabled="!isActivePlayer"
            :selected="currentMove.selectedData.some((d) => d.id === data.id)"
            :forbidden="
              !currentMove.selectedData.some((d) => d.id === data.id) &&
              currentMove.selectedData.length >= 2
            "
            @click="moves.selectData(data)"
          ></data-card>
        </div>

        <!-- Zug überspringen ABBRUCH -->
        <div class="row">
          <button-active-player type="secondary" @click="moves.endTurn()">
            Keine Daten vereinbaren
          </button-active-player>
          <button-active-player
            :disabled="currentMove.selectedData.length == 0"
            @click="
              moves.updateCurrentMove({
                hasSelectedData: true,
              })
            "
          >
            Bestätigen ({{currentMove.selectedData.length}}/2)
          </button-active-player>
        </div>
      </game-dialog>

      <!-- mit Rolle -->
      <template v-else-if="currentMove.solvedRoleQuiz">
        <!-- Rolle: Kategorie wählen -->
        <game-dialog v-if="currentMove.category === null">
          <div class="text-center">
            Wähle eine beliebige Kategorie, aus der du Daten vereinbaren möchtest:
          </div>

          <div class="column -categories">
            <data-card
              v-for="category in gameState.dataCategories"
              :key="category.id"
              :category="category"
              :clickable="true"
              :disabled="!isActivePlayer"
              @click="
                moves.choosePartRequestCategory(category.id, category.title)
              "
            ></data-card>
          </div>

          <!-- Zug überspringen ABBRUCH -->
          <div class="row">
            <button-active-player type="secondary" @click="moves.endTurn()">
              Keine Daten vereinbaren
            </button-active-player>
          </div>
        </game-dialog>

        <!-- Rolle: Menge vereinbaren -->
        <game-dialog v-else-if="currentMove.amount === null">
          <div class="row">Wie viele Daten möchtest du vereinbaren?</div>

          <div class="row--cardleft">
            <data-card
              :category="
                dataCategories.find((c) => c.id === currentMove.category)
              "
            ></data-card>
            <label>
              <input v-model.number="localAmount" :disabled="!isActivePlayer" />
              Daten
            </label>
          </div>

          <div class="row">
            <button-active-player type="secondary" @click="moves.resetCategory()">
              Zurück
            </button-active-player>
            <button-active-player
              @click="moves.choosePartRequestAmount(localAmount)"
            >
              Weiter
            </button-active-player>
          </div>
        </game-dialog>

        <!-- ==> Zum Schritt Bestätigung.  -->
      </template>
    </template>

    <!-- Daten ANNEHMEN (außer Buttons, da Rückkehr zu diesem Dialog bei Übergabe)-->
    <game-dialog v-else-if="!isCurrentPlayerActive">
      <div class="text-center">Möchtest du diese Daten vereinbaren?</div>

      <div class="row">
        <data-card
          v-for="data in currentMove.selectedData"
          :key="data.id"
          :disabled="true"
          :category="data"
          :amount="data.amount"
        ></data-card>
      </div>

      <div class="row">
        <!-- Button-Variante:Kollaboration -->
        <button-active-player
          v-if="!isCurrentPlayerActive"
          type="secondary"
          @click="moves.endTurn()"
        >
          Ablehnen
        </button-active-player>

        <button-active-player
          v-if="isCurrentPlayerActive"
          type="secondary"
          @click="
            moves.updateCurrentMove({
              hasSelectedData: false,
            })
          "
        >
          Zurück
        </button-active-player>

        <button-active-player
          v-if="isCurrentPlayerActive"
          @click="moves.takeData()"
        >
          JA
        </button-active-player>

        <!-- Button-Variante:Kollaboration -->
        <button-active-player
          v-if="!isCurrentPlayerActive"
          @click="moves.takeData()"
        >
          Annehmen
        </button-active-player>
      </div>
    </game-dialog>

    <!-- Bestätigung: Daten vereinbaren -->
    <game-dialog v-else-if="currentMove.hasSelectedData">
      <div class="text-center">Möchtest du diese Daten vereinbaren?</div>

      <div class="row">
        <data-card
          v-for="data in currentMove.selectedData"
          :key="data.id"
          :disabled="true"
          :category="data"
          :amount="data.amount"
        ></data-card>
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.resetData()"
        >
          Zurück
        </button-active-player>

        <button-active-player
          v-if="isFieldCollaborative"
          @click="moves.startCollaboration()"
        >
          Kollaboration: Daten weitergeben
        </button-active-player>

        <button-active-player @click="moves.takeData()">
          JA
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>


<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";

// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";
// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";

import DataCard from "../Cards/DataCard.vue";
import PlayerElement from "../UIElements/PlayerElement.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
import Icon from "../../ui/Icon.vue";

export default {
  components: {
    GameDialog,
    RoleDialogs,
    QuizDialogs,
    DataCard,
    PlayerElement,
    ButtonActivePlayer,
    Icon,
  },
  data() {
    return {
      localSelectedMethod: null,
      localAmount: null,
    };
  },
  mixins: [GameComponentMixin],
  computed: {
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    },
    isFieldCollaborative() {
      return this.currentField.isCollaborative && this.isCurrentPlayerActive;
    },
    playerHasMethod() {
      return this.activePlayer.methods.some(
        (method) => method.id == this.currentMove.selectedMethod.id
      );
    },
  },
  methods: {
    selectMethodAndAction(method, action) {
      this.moves.selectMethod(method);
      this.moves.setAction(action);
    },
    cancel() {
      this.moves.endTurn();
    },
  },
};
</script>

<style>
</style>