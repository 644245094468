<template>
  <div class="fielddialogs-simulate">
    <!-- ROLLEN-FRAGE -->
    <role-dialogs v-if="hasRoleAndRoleOpen">
      <template v-slot:intro>
        <p>
          Hallo, ich bin eine <strong>Datenmanagerin</strong>.
        </p>
        <p>
          Ich unterstütze die Simulationsexperten und die Bauteil-Konstrukteure in der Optimierungsphase beim Datenaustausch und achte auf eine saubere Dokumentation der Änderungen in den Systemen und Datenbanken.
          Ich sorge auch dafür, dass alle Simulationsexperten Kenntnis zu Änderungen erhalten.
        </p>
        <p>
          Gut, dass du nach mir gefragt hast. Mit meiner Hilfe darfst du nochmal
          <strong>30</strong> Sekunden in die Datenbank schauen, um einen Überblick zu erlangen,
          welche Daten ausgetauscht werden können.
        </p>
      </template>
      <template v-slot:question>
        <p>
          Hallo, hier ist wieder eine Datenmanagerin!
        </p>
      </template>
      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die Arbeit gehen.
        </p>
        <p>
          Mit meiner Hilfe darfst
          du nochmal <strong>30</strong> Sekunden in die Datenbank schauen, um einen Überblick zu
          erlangen, welche Daten ausgetauscht werden können.
        </p>
      </template>
      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du
          nochmal Hilfe brauchst.
        </p>
      </template>
    </role-dialogs>

    <!-- QUIZ-FRAGE -->
    <quiz-dialogs v-else-if="(
      currentField.hasQuiz &&
      !currentMove.handledQuizQuestion
    )"></quiz-dialogs>

    <database-dialog
      v-else-if="currentMove.isDatabaseOpen"
      :max-selectable="0"
      @close="moves.endTurn()"
    ></database-dialog>


    <game-dialog
      v-else-if="(
        isFieldCollaborative &&
        currentMove.collaborationSelected == null
      )"
    >
      <div class="text-center">
        Teste dein Fahrzeug mithilfe der Simulationsmethoden, die du in der Leistungsvereinbarung vereinbart hast.
      </div>
      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.endTurn()"
        >
          Abbrechen
        </button-active-player>

        <button-active-player
          @click="moves.startCollaboration()"
        >
          Kollaboration: Zug weitergeben
        </button-active-player>

        <button-active-player
          @click="moves.endCollaboration()"
        >
          Methode wählen
        </button-active-player>
      </div>
    </game-dialog>

    <template v-else-if="currentMove.hasRequestedDatabase">
      <game-dialog>
        <div class="text-center">
          Du hast 30 Sekunden, um dir die Datenbank anzusehen.
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endTurn()"
          >
            Abbrechen
          </button-active-player>

          <button-active-player
            @click="moves.startViewingDatabase()"
          >
            Datenbank öffnen
          </button-active-player>
        </div>
      </game-dialog>
    </template>

    <collaboration-dialogs
      v-else-if="currentMove.collaborationSelected">
    </collaboration-dialogs>

    <template v-else-if="!playerHasAnyMethod">
      <game-dialog>
        <div class="text-center">
          Du hast keine Methoden vereinbart, die du nutzen
          kannst.
        </div>

        <div class="row">
          <button-active-player
            v-if="isCurrentPlayerActive"
            type="secondary"
            @click="moves.updateCurrentMove({
              collaborationSelected: null
            })"
          >
            Zurück
          </button-active-player>
          <button-active-player
            @click="moves.endTurn()"
          >
            Zug beenden
          </button-active-player>
        </div>
      </game-dialog>
    </template>

    <template v-else-if="playerHasAnyMethod">

      <!-- METHODE WÄHLEN -->
      <game-dialog v-if="currentMove.methodId == null">
        <div class="text-center">
          Wähle eine deiner Methoden, um eine Auslegungssimulation
          durchzuführen.
        </div>

        <div class="row">
          <method-card
            v-for="method in activePlayer.methods"
            :key="method.id"
            :method="method"
            :clickable="true"
            :disabled="!isActivePlayer"
            @click="moves.selectSimulationMethod(method.id)"
          ></method-card>
        </div>

        <div class="row">
          <button-active-player
            v-if="isFieldCollaborative"
            type="secondary"
            @click="moves.updateCurrentMove({
              collaborationSelected: null
            })"
          >
            Zurück
          </button-active-player>
          <button-active-player
            v-else
            type="secondary"
            @click="moves.endTurn()"
          >
            Abbrechen
          </button-active-player>
        </div>

      </game-dialog>


      <!-- Methode bestätigen -->
      <game-dialog v-else-if="!currentMove.simulationStarted">
        <div class="text-center">
          Du hast folgende Methode gewählt:
        </div>

        <div class="row">
          <method-card
            :method="selectedMethod"
          ></method-card>
        </div>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.selectSimulationMethod(null)"
          >
            Zurück
          </button-active-player>

          <button-active-player
            @click="moves.startSimulation()"
          >
            Jetzt simulieren
          </button-active-player>
        </div>
      </game-dialog>

      <!-- SIMULATION... -->
      <game-dialog v-else-if="!currentMove.simulationDone">
        <div class="text-center text-simulation">
        {{selectedMethod.label}} wird angewendet...
        </div>

        <!-- METHODENSIMULATION -->
        <car-simulation
          v-if="builtParts.length > 0"
          :type="selectedMethod.id"
          :car-parts-data="builtParts"
          :auto-start="true"
          :max-time="selectedMethod.id === 'simulation' ? 10000 : 3000"
          @end="submitSimulationResult($event)"
        ></car-simulation>
        <div v-else class="text-center">
          Du hast noch kein Auto zusammengebaut.
        </div>

        <div class="row">
          <button-active-player
            v-if="builtParts.length == 0"
            @click="moves.endSimulation()"
          >
            Weiter
          </button-active-player>
          <button-active-player
            v-else-if="currentMove.simulationComplete"
            @click="moves.endSimulation()"
          >
            Zu den Ergebnissen
          </button-active-player>
        </div>
      </game-dialog>

      <!-- ERGEBNISSE -->
      <game-dialog v-else-if="currentMove.simulationDone">
        <template v-if="builtParts.length > 0">
          <div class="text-center">
            Dein Simulationsergebnis
          </div>

          <div class="row">
            <method-card
              :method="selectedMethod"
            ></method-card>

            <div>
              <div v-if="selectedMethod.id == 'simulation'">
                <div>
                  Rollweg: {{ currentMove.carDistanceTravelled }}
                </div>
              </div>
              <div v-else-if="selectedMethod.id == 'scale'">
                <div>
                  Gewicht: {{ currentMove.carWeight }}
                </div>
              </div>
              <div v-else-if="selectedMethod.id == 'ruler'">
                <div>Länge: {{ currentMove.carWidth }}</div>
                <div>Höhe: {{ currentMove.carHeight }}</div>
                <div>Bodenfreiheit: {{ currentMove.carGroundDistance }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            Du hast <strong>keine Daten verbaut</strong>, daher gibt es
            kein Ergebnis.
          </div>
        </template>

        <div class="row">
          <button-active-player
            type="secondary"
            @click="moves.endTurn()"
          >
            Spielzug beenden
          </button-active-player>

          <button-active-player
            v-if="currentMove.solvedRoleQuiz"
            @click="moves.updateCurrentMove({
              hasRequestedDatabase: true
            })"
          >
            Datenbank öffnen
          </button-active-player>
        </div>
      </game-dialog>

    </template>
  </div>
</template>

<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";


// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";
// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";
// Kollaborationsdialoge sind geeignet, um den Zug
// weiter zu geben.
import CollaborationDialogs from "./CollaborationDialogs.vue";
import DatabaseDialog from "./DatabaseDialog.vue";

import MethodCard from "../Cards/MethodCard.vue";
import CarSimulation from "../Car/CarSimulation.vue";

import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";

export default {
  components: {
    GameDialog,
    RoleDialogs,
    QuizDialogs,
    CollaborationDialogs,
    DatabaseDialog,
    MethodCard,
    CarSimulation,
    ButtonActivePlayer
  },
  data() {
    return {
      simulationOver: false
    };
  },
  mixins: [GameComponentMixin],
  computed: {
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    },
    isFieldCollaborative() {
      return this.currentField.isCollaborative && this.isCurrentPlayerActive;
    },
    playerHasAnyMethod() {
      return this.activePlayer.methods.length > 0;
    },
    selectedMethod() {
      return this.gameState.methods.find(
        method => method.id === this.currentMove.methodId
      );
    }
  },
  methods: {
    submitSimulationResult(data) {
      console.log("data", data);
      this.moves.saveSimulationResults(data);
    }
  }
}
</script>

<style lang="scss">

.text-simulation {
  padding-bottom: 0.5em;
}

</style>