<template>
  <div class="fielddialogs-database">

    <role-dialogs v-if="hasRoleAndRoleOpen">
      <template v-slot:intro>
        <p>
          Hallo, ich bin eine <strong>Bauteil-Konstrukteurin</strong>.
        </p>
        <p>
          Ich vereinbare mit den Simulationsexperten und mit der Unterstützung der Datenmanager die zu liefernden CAD-Daten und stelle diese mit den erforderlichen Zusatzinformationen strukturiert
          in den vorgesehenen Systemen und Datenbanken bereit.
        </p>
        <p>
          Gut, dass du nach mir gefragt hast. Mit meiner Hilfe darfst du <strong>45</strong> Sekunden
          lang die Datenbank ansehen.
        </p>
      </template>
      <template v-slot:question>
        <p>
          Hallo, hier ist wieder eine Bauteil-Konstrukteurin!
        </p>
      </template>
      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die Arbeit gehen.
          Gut, dass du nach mir gefragt hast.
        </p>
        <p>
          Mit meiner Hilfe darfst du <strong>45</strong> Sekunden
          lang die Datenbank ansehen! 
        </p>
      </template>
      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du nochmal Hilfe brauchst.
        </p>
      </template>
    </role-dialogs>

    <!-- QUIZFRAGE -->
    <quiz-dialogs v-else-if="(
      currentField.hasQuiz &&
      !currentMove.handledQuizQuestion
    )"></quiz-dialogs>

    <!-- DATENBANK ANSEHEN -->
    <database-dialog
      v-else-if="currentMove.isDatabaseOpen"
      :max-selectable="0"
      :broken-categories="
        currentMove.solvedRoleQuiz ? [] : currentMove.brokenCategories
      "
      @close="moves.endTurn()"
    ></database-dialog>

    <!-- Auswählen -->
    <game-dialog v-else-if="currentMove.solvedRoleQuiz">
      <div class="text-center">
        Du hast 45 Sekunden Zeit, um die vollständige Datenbank anzusehen.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.endTurn()"
        >
          Abbrechen
        </button-active-player>
        <button-active-player @click="moves.startViewingDatabase(true)">
          Datenbank öffnen
        </button-active-player>
      </div>
    </game-dialog>

    <game-dialog v-else>
      <div class="text-center">
        Du hast 30 Sekunden Zeit, um die Datenbank anzusehen. Für manche
        Informationen fehlen dir allerdings die Berechtigungen.
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.endTurn()"
        >
          Abbrechen
        </button-active-player>

        <button-active-player
          @click="moves.startViewingDatabase(true);"
        >
          Datenbank öffnen
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>

<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";

// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";
// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";

import DatabaseDialog from "./DatabaseDialog.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";

export default {
  components: {
    GameDialog,
    RoleDialogs,
    QuizDialogs,
    DatabaseDialog,
    ButtonActivePlayer,
  },
  mixins: [GameComponentMixin],
  computed: {
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    }
  }
};
</script>

<style>
</style>