/**
 * @type {RoleList}
 */
export const roles = {

  // ABSCHNITT 1
  eigenschaftsverantwortlicher: { // requirement
    id: "eigenschaftsverantwortlicher",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Definieren von Anforderungen"
          },
          {
            id: 1,
            label: "Bereitstellen von Daten"
          },
          {
            id: 2,
            label: "Planen der Projekttermine"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, Anforderungen zu definieren und mit den Simulationsexperten den Bewertungsumfang zu vereinbaren.",
        correctAnswer: 0
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Festlegen der Produktziele"
          },
          {
            id: 1,
            label: "Vereinbarung des virtuellen Bewertungsumfangs"
          },
          {
            id: 2,
            label: "Planen der Projekttermine"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, Anforderungen zu definieren und mit den Simulationsexperten den Bewertungsumfang zu vereinbaren.",
        correctAnswer: 1
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Zusammenfassung der Funktionsbewertung für den Statustermin"
          },
          {
            id: 1,
            label: "Bewertung der Funktionen mittels Simulation"
          },
          {
            id: 2,
            label: "Unterstützung der Simulationsexperten"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, Anforderungen zu definieren und mit den Simulationsexperten den Bewertungsumfang zu vereinbaren.",
        correctAnswer: 0
      }
    ],
    image: "Funktionsverantwortlicher"
  },
  simulationsexperteA: { // method
    id: "simulationsexperteA",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Planen der Projekttermine",
          },
          {
            id: 1,
            label: "Bereitstellen von Daten"
          },
          {
            id: 2,
            label: "Auswahl der Bewertungs-Methoden"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, mit den Funktionsverantwortlichen den Bewertungsumfang zu vereinbaren, die Simulationsmethode festzulegen und anschließend den Datenbedarf zu ermitteln.",
        correctAnswer: 2
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Vereinbarung des virtullen Bewertungsumfangs"
          },
          {
            id: 1,
            label: "Erstellen der Daten für die Bauteile"
          },
          {
            id: 2,
            label: "Festlegen der Produktziele"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, mit den Funktionsverantwortlichen den Bewertungsumfang zu vereinbaren, die Simulationsmethode festzulegen und anschließend den Datenbedarf zu ermitteln.",
        correctAnswer: 0
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Unterstützung des Datenmanagers"
          },
          {
            id: 1,
            label: "Bereitstellen von Daten"
          },
          {
            id: 2,
            label: "Ermittlung des Datenbedarfs"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, mit den Funktionsverantwortlichen den Bewertungsumfang zu vereinbaren, die Simulationsmethode festzulegen und anschließend den Datenbedarf zu ermitteln.",
        correctAnswer: 2
      }
    ],
    image: "Simulationsexperte"
  },
  datenmanagerA: { // data
    id: "datenmanagerA",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Definieren von Anforderungen",
          },
          {
            id: 1,
            label: "Unterstützung bei der Vereinbarung der Daten"
          },
          {
            id: 2,
            label: "Aufbau der Simulationsmodelle"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten beim Finden des zuständigen Bauteil-Konstrukteurs, bei der Vereinbarung des Umfangs der zu liefernden Daten, sowie bei der Dokumentation in der Leistungsvereinbarung zu unterstützen.",
        correctAnswer: 1
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Ermittlung des Datenbedarfs",
          },
          {
            id: 1,
            label: "Erstellung der Daten"
          },
          {
            id: 2,
            label: "Finden der richtigen Ansprechpartner"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten beim Finden des zuständigen Bauteil-Konstrukteurs, bei der Vereinbarung des Umfangs der zu liefernden Daten, sowie bei der Dokumentation in der Leistungsvereinbarung zu unterstützen.",
        correctAnswer: 2
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Definieren von Anforderungen",
          },
          {
            id: 1,
            label: "Überblick vermitteln zu verfügbaren Daten"
          },
          {
            id: 2,
            label: "Aufbau der Simulationsmodelle"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten beim Finden des zuständigen Bauteil-Konstrukteur, bei der Vereinbarung des Umfangs der zu liefernden Daten, sowie bei der Dokumentation in der Leistungsvereinbarung zu unterstützen.",
        correctAnswer: 1
      },
    ],
    image: "Datenmanagerin"
  },
  konstrukteurA: { // Database
    id: "konstrukteurA",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Vereinbarung des Bewertungsumfangs",
          },
          {
            id: 1,
            label: "Vereinabrung der zu liefernden CAD-Daten"
          },
          {
            id: 2,
            label: "Festlegen der Produktziele"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, mit den Simulationsexperten und mit Unterstützung der Datenmanager die zu liefernden CAD-Daten zu vereinbaren, und diese mit den erforderlichen Zusatzinformationen strukturiert in den vorgesehenen Systemen und Datenbanken bereitzustellen.",
        correctAnswer: 1
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Datenmanagement",
          },
          {
            id: 1,
            label: "Unterstützung des Datenmanagers"
          },
          {
            id: 2,
            label: "Ergänzung der Zusatzinformationen in der Datenbank"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, mit den Simulationsexperten und mit Unterstützung der Datenmanager die zu liefernden CAD-Daten zu vereinbaren, und diese mit den erforderlichen Zusatzinformationen strukturiert in den vorgesehenen Systemen und Datenbanken bereitzustellen.",
        correctAnswer: 2
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Strukturierung der Daten in der Datenbank",
          },
          {
            id: 1,
            label: "Definieren von Anforderungen"
          },
          {
            id: 2,
            label: "Aufbau der Simulationsmodelle"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, mit den Simulationsexperten und mit Unterstützung der Datenmanager die zu liefernden CAD-Daten zu vereinbaren, und diese mit den erforderlichen Zusatzinformationen strukturiert in den vorgesehenen Systemen und Datenbanken bereitzustellen.",
        correctAnswer: 0
      }
    ],
    image: "Bauteilkonstrukteurin"
  },

  // ABSCHNITT 2

  konstrukteurB: { // Collect
    id: "konstrukteurB",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Unterstützung bei der Vereinbarung der Daten",
          },
          {
            id: 1,
            label: "Umsetzung der Bauteil-Anforderungen"
          },
          {
            id: 2,
            label: "Aufbau der Simulationsmodelle"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die CAD-Daten für unseren jeweiligen Teileumfang zu erstellen oder zu beschaffen und diese termingerecht in den vorgesehenen Systemen und Datenbanken strukturiert abzulegen.",
        correctAnswer: 1
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Beschaffen der CAD-Daten von Lieferanten",
          },
          {
            id: 1,
            label: "Kontaktieren der Simulationsexperten"
          },
          {
            id: 2,
            label: "Ergänzung der Anforderungen"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die CAD-Daten für unseren jeweiligen Teileumfang zu erstellen oder zu beschaffen und diese termingerecht in den vorgesehenen Systemen und Datenbanken strukturiert abzulegen.",
        correctAnswer: 0
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Termingerechte Bereitstellung der Daten",
          },
          {
            id: 1,
            label: "Auswahl der Bewertungsmethode"
          },
          {
            id: 2,
            label: "Strukturierung der Eigenschaften der Bauteile"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die CAD-Daten für unseren jeweiligen Teileumfang zu erstellen oder zu beschaffen und diese termingerecht in den vorgesehenen Systemen und Datenbanken strukturiert abzulegen.",
        correctAnswer: 0
      }
    ],
    image: "Bauteilkonstrukteurin"
  },
  datenmanagerB: { // catalog
    id: "datenmanagerB",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Korrigieren falscher Daten",
          },
          {
            id: 1,
            label: "Unterstützung bei der Datenbeschaffung"
          },
          {
            id: 2,
            label: "Unterstützung beim Aufbau der Simulationsmodelle"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten bei der Beschaffung und Bereitstellung der vereinbarten Daten für den Aufbau der Simulationsmodelle zu unterstützen und rechtzeitig einzugreifen, falls sich Terminverzögerungen abzeichnen. ",
        correctAnswer: 1
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Nachbessern der Leistungsvereinbarung",
          },
          {
            id: 1,
            label: "Sicherstellung termingerechter Datenlieferung"
          },
          {
            id: 2,
            label: "Unterstützung bei der Ablage der CAD-Daten"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten bei der Beschaffung und Bereitstellung der vereinbarten Daten für den Aufbau der Simulationsmodelle zu unterstützen und rechtzeitig einzugreifen, falls sich Terminverzögerungen abzeichnen. ",
        correctAnswer: 1
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Ablegen der CAD-Daten",
          },
          {
            id: 1,
            label: "Erstellen der Bauteilübersicht"
          },
          {
            id: 2,
            label: "Rechtzeitiges Eingreifen bei drohendem Terminverzug"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten bei der Beschaffung und Bereitstellung der vereinbarten Daten für den Aufbau der Simulationsmodelle zu unterstützen und rechtzeitig einzugreifen, falls sich Terminverzögerungen abzeichnen. ",
        correctAnswer: 2
      }
    ],
    image: "Datenmanagerin"
  },

  // ABSCHNITT 3
  simulationsexperteB: {  // build
    id: "simulationsexperteB",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Bewertung aller möglichen Funktionen",
          },
          {
            id: 1,
            label: "Beschaffung der Daten für den Modellaufbau"
          },
          {
            id: 2,
            label: "Anpassen der Anforderungen"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die vereinbarten Daten zu beschaffen, anschließend ein Simulationsmodell zu erstellen, mit den virtuellen Bewertungs-Methoden die Fahrzeugfunktionen zu bewerten und Optimierungsvorschläge an den Bauteil-Konstrukteur abzugeben. Falls ich meine Bewertungen nicht rechtzeitig liefern kann, greife ich rechtzeitig ein.",
        correctAnswer: 1
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Bewertung der vereinbarten Funktionen",
          },
          {
            id: 1,
            label: "Zusammenfassen aller Bewertungsergebnisse"
          },
          {
            id: 2,
            label: "Optimierung der Bauteile"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die vereinbarten Daten zu beschaffen, anschließend ein Simulationsmodell zu erstellen, mit den virtuellen Bewertungs-Methoden die Fahrzeugfunktionen zu bewerten und Optimierungsvorschläge an den Bauteil-Konstrukteur abzugeben. Falls ich meine Bewertungen nicht rechtzeitig liefern kann, greife ich rechtzeitig ein.",
        correctAnswer: 0
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Aufbau des Simulationsmodells",
          },
          {
            id: 1,
            label: "Bereitstellen von Daten"
          },
          {
            id: 2,
            label: "Rechtzeitiges Eingreifen bei drohendem Terminverzug"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die vereinbarten Daten zu beschaffen, anschließend ein Simulationsmodell zu erstellen, mit den virtuellen Bewertungs-Methoden die Fahrzeugfunktionen zu bewerten und Optimierungsvorschläge an den Bauteil-Konstrukteur abzugeben. Falls ich meine Bewertungen nicht rechtzeitig liefern kann, greife ich rechtzeitig ein.",
        correctAnswer: 2
      }
    ],
    image: "Simulationsexperte"
  },
  datenmanagerC: {
    id: "datenmanagerC",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Rechtzeitiges Eingreifen bei fehlenden Bewertungen",
          },
          {
            id: 1,
            label: "Unterstützung beim Datenaustausch"
          },
          {
            id: 2,
            label: "Optimierung der Simulationsmodelle"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten und die Bauteil-Konstrukteur in der Optimierungsphase beim Datenaustausch zu unterstützen und auf eine saubere Dokumentation der Änderungen in den Systemen und Datenbanken zu achten. Ich sorge auch dafür, dass alle Simulationsexperten Kenntnis zu Änderungen erhalten.",
        correctAnswer: 1
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Ablegen der optimierten CAD-Daten",
          },
          {
            id: 1,
            label: "Optimierung der CAD-Daten"
          },
          {
            id: 2,
            label: "Information der Simulationsexperten zu Änderungen"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten und die Bauteil-Konstrukteur in der Optimierungsphase beim Datenaustausch zu unterstützen und auf eine saubere Dokumentation der Änderungen in den Systemen und Datenbanken zu achten. Ich sorge auch dafür, dass alle Simulationsexperten Kenntnis zu Änderungen erhalten.",
        correctAnswer: 2
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Durchführung der Auslegungssimulationen",
          },
          {
            id: 1,
            label: "Aktualisierung der Leistungsvereinbarung"
          },
          {
            id: 2,
            label: "Sicherstellung der Dokumentation der Änderungen"
          }
        ],
        correctedAnswer: "Unsere Aufgaben bestehen darin, die Simulationsexperten und die Bauteil-Konstrukteur in der Optimierungsphase beim Datenaustausch zu unterstützen und auf eine saubere Dokumentation der Änderungen in den Systemen und Datenbanken zu achten. Ich sorge auch dafür, dass alle Simulationsexperten Kenntnis zu Änderungen erhalten.",
        correctAnswer: 2
      }
    ],
    
    image: "Datenmanagerin"
  },
  konstrukteurC: { // optimize
    id: "konstrukteurC",
    quizQuestions: [
      {
        id: 0,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Optimierung der Konstruktion",
          },
          {
            id: 1,
            label: "Aktualisierung der Leistungsvereinbarung"
          },
          {
            id: 2,
            label: "Optimierung der Simulationsmodelle"
          }
        ],
        correctAnswer: 0,
        correctedAnswer: "Unsere Aufgaben bestehen darin, die CAD-Daten für unseren jeweiligen Teileumfang mit der Hilfe der Simulationsergebnisse zu optimieren. Wir müssen dabei genau auf den Termin für den Daten-Freeze achten und bei Problemen rechtzeitig eingreifen."
      },
      {
        id: 1,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Weiterentwicklung der Anforderungen",
          },
          {
            id: 1,
            label: "Sicherstellung eines termingerechten Daten-Freeze"
          },
          {
            id: 2,
            label: "Aufbau der Simulationsmodelle"
          }
        ],
        correctAnswer: 1,
        correctedAnswer: "Unsere Aufgaben bestehen darin, die CAD-Daten für unseren jeweiligen Teileumfang mit der Hilfe der Simulationsergebnisse zu optimieren. Wir müssen dabei genau auf den Termin für den Daten-Freeze achten und bei Problemen rechtzeitig eingreifen."
      },
      {
        id: 2,
        question: "Weißt du noch, was meine Aufgaben sind?",
        options: [
          {
            id: 0,
            label: "Rechtzeitiges Eingreifen bei drohendem Terminverzug",
          },
          {
            id: 1,
            label: "Terminverschiebung beantragen"
          },
          {
            id: 2,
            label: "Weitere Optimierung ablehnen"
          }
        ],
        correctAnswer: 0,
        correctedAnswer: "Unsere Aufgaben bestehen darin, die CAD-Daten für unseren jeweiligen Teileumfang mit der Hilfe der Simulationsergebnisse zu optimieren. Wir müssen dabei genau auf den Termin für den Daten-Freeze achten und bei Problemen rechtzeitig eingreifen."
      }
    ],
    image: "Bauteilkonstrukteurin"
  }
};



// Typdefinitionen für autocomplete


export class Role {
  constructor() {
    this.id = "";
    /**
     * @type {QuizQuestion[]}
     */
    this.quizQuestions = [];
  }
}

/**
 * @typedef {Object} QuizOption
 * @property {number} id
 * @property {string} label
 */

export class QuizQuestion {
  constructor() {
    this.id = 0;
    this.question = "";
    /** @type {QuizOption[]} */
    this.options = [];
    this.correctAnswer = 0;
    this.correctedAnswer = ""; 
  }
}

/**
 * @typedef {Object.<string, Role>} RoleList
 */