<template>
  <div class="car-simulation">
    <div
      ref="canvas"
      class="car-simulation__canvascontainer"
    ></div>
    <div v-if="!autoStart">
      <button class="button" @click="startSimulation">Simulieren</button>
      <button class="button" @click="resetScene">Zurücksetzen</button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Matter, { Vector, Composite, Body } from "matter-js";
import * as Renderer from "../../../services/carCanvasRenderer";
import { bodyFromData, constraintFromData, shrewBodiesTogether } from "../../../../car/carPartCreator.js";

const WIDTH = Renderer.dimensions.WIDTH;
const HEIGHT = Renderer.dimensions.HEIGHT;

export default {
  props: {
    carPartsData: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: String,
      default: "simulation" // Nimmt die ids der Methoden ()
    },
    autoStart: {
      type: Boolean,
      default: false
    },
    useCustomRenderer: {
      type: Boolean,
      default: true
    },
    maxTime: {
      type: Number,
      default: 10000
    }
  },
  data() {
    const startPosSim = Vector.create(0.025 * WIDTH, 0.583 * HEIGHT);
    const startPosOther = Vector.create(0.5 * WIDTH, 0.9 * HEIGHT);
    console.log(startPosSim, startPosOther);
    return {
      runnerCancelId: null,
      intervalCancelId: null,
      timeoutCancelId: null,
      boundsAtStart: null,
      boundsAtEnd: null,
      startPosSimulation: startPosSim,
      startPosOther: startPosOther,
      stopOnEachFrame: false
    };
  },
  watch: {
    carPartsData: {
      immediate: true,
      handler(newValue, oldValue) {
        if (this.arePartsDifferent(newValue, oldValue)) {
          console.log("new parts in simulation");
          if (this.runner) {
            this.resetScene();
          }
        }
      }
    }
  },
  created() {
    this.initMatterJs();
  },
  mounted() {
    this.startRenderer();
    this.resetScene();
    if (this.autoStart) {
      this.startSimulation();
    }
  },
  beforeDestroy() {
    if (this.intervalCancelId) {
      clearInterval(this.intervalCancelId);
    }
    if (this.timeoutCancelId) {
      clearTimeout(this.timeoutCancelId);
    }
    Matter.Engine.clear(this.engine);
    Matter.Runner.stop(this.runner);
    if (this.render) {
      Matter.Render.stop(this.render);
    }
    Renderer.stop();
  },
  methods: {
    initMatterJs() {
      this.engine = Matter.Engine.create({
        constraintIterations: 2
      });
      this.engine.enableSleeping = true;
      this.engine.world.gravity.y = 1.0;
      this.runner = Matter.Runner.create({
        isFixed: true,
        enabled: true
      });
      Matter.Runner.start(this.runner, this.engine);

      /*Matter.Events.on(this.runner, "afterUpdate", event => {
        if (this.stopOnEachFrame) {
          this.runner.enabled = false;
        }
        console.log(this.engine.world);
      });*/
    },
    resetScene() {
      if (this.intervalCancelId) {
        clearInterval(this.intervalCancelId);
      }
      if (this.timeoutCancelId) {
        clearTimeout(this.timeoutCancelId);
      }
      this.runner.enabled = false;
      Matter.World.clear(this.engine.world);

      this.createCarFromParts();
      this.setupScene();
    },
    createCarFromParts() {
      const { bodies, constraints } = this.createCarPartObjects();
      const car = Matter.Composite.create( { label: 'Car' });
      car.label = "Car";

      for (const carPart of bodies) {
        Matter.Composite.addBody(car, carPart);
      }
      for (const shrew of constraints) {
        Matter.Composite.addConstraint(car, shrew);
      }

      const startPos = this.type == 'simulation' ? this.startPosSimulation : this.startPosOther;

      const carBounds = Matter.Composite.bounds(car);
      const carCenter = Matter.Vector.div(Matter.Vector.add(carBounds.min, carBounds.max), 2);
      const carBottom = Vector.create((carBounds.min.x + carBounds.max.x) / 2, carBounds.max.y);
      const carLeftBottom = Vector.create(carBounds.min.x, carBounds.max.y);

      let deltaPos = {};
      if (this.type == 'simulation') {
        deltaPos = Matter.Vector.sub(
          startPos,
          carLeftBottom
        );
      }
      else {
        deltaPos = Matter.Vector.sub(
          startPos,
          carBottom
        );
      }


      Matter.Composite.translate(car, deltaPos);
      Matter.World.add(this.engine.world, [car]);
      console.log("Car: ", car);
    },
    createCarPartObjects() {
      const partsClone = _.cloneDeep(this.carPartsData);

      const bodiesData = partsClone.filter(
        part => part.type === 'body'
      );
      let bodies = bodiesData.map(bodyFromData);

      const shrewData = partsClone.filter(part => part.type === 'constraint' && part.length > 0);
      bodies = shrewBodiesTogether(shrewData, bodies);


      const wheelAnchorsData = partsClone.filter(
        part => part.type === 'constraint' && part.length == 0
      );
      const wheelAnchors = wheelAnchorsData.map(
        part => constraintFromData(part, bodies)
      );
      const constraints = wheelAnchors;

      return {bodies, constraints };
    },
    setupScene() {
      const staticBodyOptions = {
        isStatic: true,
        collisionFilter: {
          group: 2,
          category: 16,
          mask: 0b1111111111111111
        },
        render: {
          strokeStyle: 'transparent'
        },
        friction: 0.8,
        label: "Floor"
      };

      const w = WIDTH;
      const h = HEIGHT;

      console.log("simType", this.type);

      if (this.type == "simulation") {
        const ramp = Matter.Bodies.fromVertices(0.281 * w, 0.9583 * h, [
          Vector.create(0,0),
          Vector.create(0.235 * w, 0.17 * h),
          Vector.create(0.35 * w, 0.23 * h),
          Vector.create(0.47 * w, 0.25 * h),
          Vector.create(w + 20, 0.25 * h),
          Vector.create(w + 20, 0.42 * h),
          Vector.create(0, 0.42 * h)
        ],
        staticBodyOptions);

        Matter.World.add(this.engine.world, [ramp]);
      }

      const floor = Matter.Bodies.rectangle(w / 2, h - 20, w * 2 + 10, 60, staticBodyOptions);
      Matter.World.add(this.engine.world, [floor]);
    },
    startRenderer() {

      if (this.useCustomRenderer) {
        // Custom Renderer
        const canvas = Renderer.create({
          element: this.$refs.canvas,
          engine: this.engine
        });
        Renderer.render();
      }


      // MatterJS renderer
      // (Good for debug)
      else {

        const render = Matter.Render.create({
          element: this.$refs.canvas,
          engine: this.engine,
          options: {
            width: WIDTH,
            height: HEIGHT,
            showVelocity: true,
            showAngleIndicator: true,
            showCollisions: true,
            showPositions: true,
            showInternalEdges: true,
            showIds: false,
          }
        });
        Matter.Render.run(render);
        this.render = render;
      }

    },
    startSimulation() {
      Matter.Common._seed = 0;
      this.resetScene();
      this.boundsAtStart = this.getBounds();

      this.runner.enabled = true;
      this.intervalCancelId = setInterval(this.stopIfNoPartsMoving.bind(this), 1000);
      // stop simulation completely after [10] seconds
      this.timeoutCancelId = setTimeout(this.stopAndEmitResults.bind(this), this.maxTime);
    },
    continueSim() {
      this.runner.enabled = true;
    },
    arePartsStillMoving() {
      const bodies = Composite.allBodies(this.engine.world);
      const moving = bodies.some(body => Vector.magnitude(body.velocity) > 0.65);
      return moving;
    },
    stopIfNoPartsMoving() {
      const moving = this.arePartsStillMoving();
      console.log("parts moving?", moving);
      if (!moving) {
        this.stopAndEmitResults();
      }
    },
    stopAndEmitResults() {
      clearInterval(this.intervalCancelId);
      clearTimeout(this.timeoutCancelId);
      setTimeout(() => {
        this.runner.enabled = false;
        this.boundsAtEnd = this.getBounds();

        this.$emit("end", {
          bounds: this.boundsAtStart,
          bottomWithoutWheels: this.getBottomWithoutWheels(),
          boundsAtStart: this.boundsAtStart,
          boundsAtEnd: this.boundsAtEnd,
          totalWeight: this.getTotalWeight(),
          totalMass: this.getTotalMass()
        });
      }, 2000);
    },
    getTotalWeight() {
      const composites = Composite.allComposites(this.engine.world);
      const car = composites.find(c => c.label == "Car");
      if (!car) return 0;

      const partsWithoutWheels = car.bodies.filter(b => b.label != "Wheel");

      return partsWithoutWheels.reduce((sum, body) => {
        const partsWeight = body.parts.reduce((sum, part) => sum + (part.weight || 0), 0);
        return sum + partsWeight;
      }, 0); // + body.mass
    },
    getTotalMass() {
      // return mass (not used in calculations, only for debug)
      const composites = Composite.allComposites(this.engine.world);
      const car = composites.find(c => c.label == "Car");
      if (!car) return 0;

      const partsWithoutWheels = car.bodies.filter(b => b.label != "Wheel");

      return partsWithoutWheels.reduce((sum, body) => {
        const partsWeight = body.parts.reduce((sum, part) => sum + (part.weight || 0), 0);
        return sum + body.mass;
      }, 0);
    },
    getBounds() {
      const composites = Composite.allComposites(this.engine.world);
      const car = composites.find(c => c.label == "Car");
      if (!car) return null;

      return Matter.Composite.bounds(car)
    },
    getBottomWithoutWheels() {
      const composites = Composite.allComposites(this.engine.world);
      const car = composites.find(c => c.label == "Car");
      if (!car) return null;

      const partsWithoutWheels = car.bodies.filter(b => b.label != "Wheel");

      return partsWithoutWheels.reduce(
        (maxY, part) => Math.max(maxY, part.bounds.max.y),
        -Infinity
      );
    },
    arePartsDifferent(a, b) {
      if (b == null) return true;
      for (const partA of a) {
        const partB = b.find(p => p.uuid === partA.uuid);
        if (!partB) return true;
        if (partA.type == "body") {
          const positionsSame = (
            partA.position.x == partB.position.x &&
            partA.position.y == partB.position.y
          );
          if (!positionsSame) return true;
        }
        else if (partB.type == "constraint") {
          const positionsSame = (
            partA.pointA.x == partB.pointA.x &&
            partA.pointA.y == partB.pointA.y &&
            partA.pointB.x == partB.pointB.x &&
            partA.pointB.y == partB.pointB.y
          );
          if (!positionsSame) return true;
        }
      }
      return false;
    }
  }
}
</script>

<style lang="scss">
.car-simulation {
  &__canvascontainer {
    background-color: var(--active-player-color-medium);
  }
  canvas {
    display: block;
    width: 100%;
  }
}
</style>