export const premises = [
  {
    title: "Rennauto",
    id: "racecar",
    axles: 2,
    maxParts: 10
  },
  {
    title:"Traktor",
    id: "tractor",
    axles: 2,
    minParts: 10
  },
  {
    title: "Truck",
    id: "truck",
    axles: 4,
    maxParts: 16
  },
  {
    title:"Lokomotive",
    id: "train",
    axles: 4,
    minParts: 16
  },
];

/**
 *
 * @param {Object} players
 */
export function givePlayersPremises(ctx, players) {
  const premisesCopy = premises.slice();
  const playersArray = Object.values(players);

  for (const player of playersArray) {
    const [premise] = premisesCopy.splice(Math.floor(ctx.random.Number() * premisesCopy.length), 1);
    player.premise = premise;
  }
}

/**
 * Überprüfe, ob ein Spieler seine Prozessziel erfüllt hat
 * @param {*} player
 */
export function hasFulfilledPremise(player) {
  const premise = player.premise;

  const allBuiltParts = player.parts.filter(p => player.builtPartIds.includes(p.uuid));

  // Achsen:
  if (premise.axles !== null) {
    const axlesAmount = allBuiltParts.filter(p => p.type === 'constraint' && p.length === 0).length;
    if (axlesAmount != premise.axles) return false;
  }

  // Teilezahl:

  // Es werden nur "reguläre" Teile verwendet, keine Schrauben, Achsen oder Räder
  // Also nur Rechtecke und Dreiecke.
  const mainBuiltParts = allBuiltParts.filter(part => (
    part.type === 'body' && !part.label.includes('Wheel')
  ));
  if (premise.maxParts && mainBuiltParts.length > premise.maxParts) {
    return false;
  }
  if (premise.minParts && mainBuiltParts.length < premise.minParts) {
    return false;
  }


  return true;
}




export class Premise {
  constructor() {
    this.id = "";
    this.title = "";
    this.axles = -1;
    this.minParts = -1;
    this.maxParts = -1;
  }
}
