<template>
  <div class="page-joinmatch">
    <div class="page-joinmatch__before">
      <a href="https://www.haw-landshut.de/" target="_blank">
        <img src="../assets/hs-landshut-logo.jpg" alt="Hochschule Landshut Logo" class="page-joinmatch__logo" />
      </a>
    </div>
    <article class="page-joinmatch__main">
      <header class="page-joinmatch__header">
        <!--<img src="../assets/hs-landshut-logo.jpg" alt="Hochschule Landshut Logo" class="page-joinmatch__logo" /> -->
        <h1>Willkommen zum Planspiel Virtuelle Produktentwicklung</h1>
      </header>
      <p>
        <template v-if="!urlMatchId">
          Sobald Du einen Beitrittscode von deinem Professor erhalten
          haben, können Sie hier einem Spiel beitreten.
        </template>
      </p>
      <form class="page-joinmatch__form" @submit.prevent="joinMatch">
        <input-field v-if="urlMatchId == null" label="Beitrittscode" v-model="matchID" :required="true"></input-field>
        <input-field label="Spielername eingeben" v-model="playerName" :required="true"></input-field>
        <button
          class="button page-joinmatch__button"
          type="submit"
          :disabled="!playerName || playerName === ''"
        >
          Beitreten
        </button>
      </form>
    </article>
    <game-footer></game-footer>
  </div>
</template>

<script>
import { post } from "../services/api";

import InputField from "../components/input/InputField.vue";
import GameFooter from "../components/ui/GameFooter.vue";
export default {
  components: {
    InputField,
    GameFooter
  },
  props: {
    urlMatchId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      matchID: null,
      playerName: ""
    };
  },
  created() {
    if (this.urlMatchId) {
      this.matchID = this.urlMatchId;
    }
  },
  methods: {
    async joinMatch() {
      try {
        const data = {
          playerName: this.playerName
        };

        const { playerCredentials, playerID, matchID } = await post(`/matches/${this.matchID}/join`, data, {}, false);

        data.playerCredentials = playerCredentials;
        data.matchID = matchID;
        data.playerID = playerID;

        console.log("myPlayerData", data);

        this.saveMatchCredentials(data);

        this.$router.push({ name: 'matchLobby' });
      } catch(error) {
        if (error.status === 409) {
          alert("Alle Plätze in diesem Spiel sind bereits belegt.");
        }
        else if (error.status === 500) {
          alert("Es ist ein Fehler auf dem Spieleserver aufgetreten.");
        }
        else if (error.status === 404) {
          alert("Dieses Spiel läuft nicht mehr oder ist nicht erreichbar.");
        }
        else {
          alert("Ein unbekannter Fehler ist aufgetreten.");
          console.log(error, error.status);
        }
      }
    },
    saveMatchCredentials({ matchID, playerCredentials, playerID, playerName }) {
      console.log(`joined match ${matchID} as player ${playerID} with code ${playerCredentials}`);
      localStorage.setItem('playerCredentials', playerCredentials);
      localStorage.setItem('playerID', playerID);
      localStorage.setItem('playerName', playerName);
      localStorage.setItem('matchID', matchID);
    }
  }
}
</script>

<style lang="scss">
.page-joinmatch {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background-color: #F6F6F6;

  &__before {
    display: flex;
    padding: 1rem;
  }

  &__main {
    padding: 1rem;
    width: 40rem;
    max-width: 100%;
    margin: auto auto;
    margin-top: 4rem;
  }

  &__header {
    font-size: 1.125rem;
    //display: grid;
    //grid-template-columns: 3.75em 1fr;
    //gap: 0.75em;

    & > h1 {
      color: $color-text;
      margin: 0;
      font-size: 2em;
    }
    & > img {
      margin-top: 0.5em;
    }
    margin-bottom: 2rem;
  }

  h2 {
    color: $color-text;
  }

  &__form {
    display: grid;
    gap: 1rem;
    justify-items: start;
  }
  &__button {
    align-self: center;
  }

  &__logo {
    max-width: 6rem;
    min-width: 20px;
  }
}
</style>