/**
 * @type {Question[]}
 */
export const quizQuestions = [
  // BEREICH 1 - LEISTUNGSVEREINBARUNG
  {
    id: 0,
    points: 10,
    question: "Vervollständige den Satz: „Im Leistungsumfang ist vereinbart…“",
    options: [
      {
        id: 0,
        label: "… welche Anforderungen und Ziele bis zum Meilenstein „Status VPE“ mittels Simulation verifiziert oder validiert werden sollen"
      },
      {
        id: 1,
        label: "… welche Daten bis zum Meilenstein „Status VPE“ bereitgestellt und optimiert werden sollen"
      },
      {
        id: 2,
        label: "… wie viele Simulationen bis zum Meilenstein „Status VPE“ durchgeführt werden sollen"
      }
    ],
    area: 0,
    answer: 0
  },
  {
    id: 1,
    points: 10,
    question: "Was wird in der „Leistungsvereinbarung“ festgehalten?",
    options: [
      {
        id: 0,
        label: "Die Produktziele, der Leistungsumfang und die erforderlichen Daten"
      },
      {
        id: 1,
        label: "Die Produktziele, die Verantwortlichkeiten der Beteiligten und die erforderlichen Daten"
      },
      {
        id: 2,
        label: "Die zu erbringende Leistung mit Kosten und Terminen"
      }
    ],
    area: 0,
    answer: 0
  },
  {
    id: 2,
    points: 10,
    question: "Welchen Mehrwert erzeugt die Virtuelle Produktentwicklung?",
    options: [
      {
        id: 0,
        label: "Frühzeitiges Erkennen von Zielkonflikten und Vermeidung von späten Änderungen"
      },
      {
        id: 1,
        label: "Einen vollständigen Ersatz der physischen Erprobung"
      },
      {
        id: 2,
        label: "Eine Halbierung der Entwicklungskosten durch den Verzicht auf Prototypen"
      }
    ],
    area: 0,
    answer: 0
  },
  {
    id: 3,
    points: 10,
    question: "Was bedeutet Virtuelle Produktentwicklung?",
    options: [
      {
        id: 0,
        label: "Die Produkt-, Eigenschafts- und Funktionsentwicklung mithilfe virtueller Werkzeuge"
      },
      {
        id: 1,
        label: "Die dreidimensionale digitale Beschreibung und Visualisierung des Produkts"
      },
      {
        id: 2,
        label: "Die Produktentwicklung in virtuellen Teams nach den Vorgaben des Systems-Engineerings"
      }
    ],
    area: 0,
    answer: 0
  },
  {
    id: 4,
    points: 10,
    question: "Warum brauchen wir eine Virtuelle Produktentwicklung?",
    options: [
      {
        id: 0,
        label: "Zur Lösung von Zielkonflikten bezüglich Eigenschaften und Funktionen"
      },
      {
        id: 1,
        label: "Für eine vollständige Virtuelle Freigabe der Eigenschaften und Funktionen"
      },
      {
        id: 2,
        label: "Die Virtuelle Produktentwicklung ist nicht wirklich erforderlich und dient nur zur Visualisierung"
      }
    ],
    area: 0,
    answer: 0
  },
  {
    id: 5,
    points: 10,
    question: "Was regelt der Prozess der Virtuellen Produktentwicklung?",
    options: [
      {
        id: 0,
        label: "Die Erstellung von Bildern und Filmen für das Marketing und den Showroom"
      },
      {
        id: 1,
        label: "Den Inhalt und die Qualität von Simulationsmodellen"
      },
      {
        id: 2,
        label: "Die Zusammenarbeit aller betroffenen Rollen und die Abfolge definierter Phasen und Meilensteine"
      }
    ],
    area: 0,
    answer: 2
  },
  {
    id: 6,
    points: 10,
    question: "Was sind die wichtigsten Elemente der Virtuellen Produktentwicklung?",
    options: [
      {
        id: 0,
        label: "Ein eigenstädiger Terminplan, um ausreichend Zeit für die Simulation zu haben"
      },
      {
        id: 1,
        label: "Abgestimmte Prozessschritte, definierte Rollen und Schnittstellen, sowie ein definiertes Zusammenwirken der einzelnen Disziplinen"
      },
      {
        id: 2,
        label: "Eine vorgegebene Systematik ist hinderlich und würde einem agilen Projektmanagement entgegenstehen"
      }
    ],
    area: 0,
    answer: 1
  },

  // === BEREICH 2 - DATENERZEUGUNG===
  {
    id: 7,
    points: 10,
    question: "Welcher Datenumfang muss zum Meilenstein „Datenlieferung“ vorhanden sein?",
    options: [
      {
        id: 0,
        label: "Eine erste Version aller vereinbarten Daten; Vollständigkeit ist besonders wichtig"
      },
      {
        id: 1,
        label: "Erste Daten für den Modellaufbau in der vereinbarten Detaillierung und Datenqualität"
      },
      {
        id: 2,
        label: "Vollständig auskonstruierte und abgesicherte Daten entsprechend der Leistungsvereinbarung"
      }
    ],
    area: 1,
    answer: 0
  },
  {
    id: 8,
    points: 10,
    question: "Von wem werden die Simulationsexperten bei der Beschaffung der Daten unterstützt?",
    options: [
      {
        id: 0,
        label: "Von Datenmanagern"
      },
      {
        id: 1,
        label: "Von den Bauteil-Konstrukteuren"
      },
      {
        id: 2,
        label: "Vom Projektleiter und den Funktionsverantwortlichen"
      }
    ],
    area: 1,
    answer: 0
  },
  {
    id: 9,
    points: 10,
    question: "Wer ist für die Erstellung der Daten verantwortlich?",
    options: [
      {
        id: 0,
        label: "Die Simulationsexperten erstellen die Daten für ihre Modelle selbst und beschaffen diese bei den Lieferanten"
      },
      {
        id: 1,
        label: "Die Bauteil-Konstrukteure erstellen oder beschaffen die vereinbarten Daten und legen diese in den vorgesehenen Systemen ab"
      },
      {
        id: 2,
        label: "Der System-Lieferant erstellt die Daten und übergibt diese den Datenmanagern"
      }
    ],
    area: 1,
    answer: 1
  },
  {
    id: 10,
    points: 10,
    question: "Woher wissen die Bauteil-Konstrukteure welche Daten sie erstellen müssen?",
    options: [
      {
        id: 0,
        label: "Die Bauteil-Konstrukteure kennen den Verantwortungs- und Lieferumfang ihrer Abteilung"
      },
      {
        id: 1,
        label: "Die zu erstellenden Daten sind in der Stückliste festgelegt"
      },
      {
        id: 2,
        label: "Die zu liefernden Daten sind in der Leistungsvereinbarung beschrieben"
      }
    ],
    area: 1,
    answer: 2
  },
  {
    id: 11,
    points: 10,
    question: "Was passiert wenn ein Bauteil-Konstrukteur seine Daten nicht rechtzeitig bereitstellt?",
    options: [
      {
        id: 0,
        label: "Der Simulationsexperte kann nicht rechtzeitig mit der Modellerstellung beginnen"
      },
      {
        id: 1,
        label: "Die Modellerstellung verzögert sich und viele Bauteil-Konstrukteure erhalten keine Empfehlungen für die Optimierung der Konstruktion"
      },
      {
        id: 2,
        label: "Die Modellerstellung verzögert sich und der Bauteil-Konstrukteur erhält keine Empfehlungen für die Optimierung seines Bauteils"
      }
    ],
    area: 1,
    answer: 1
  },
  {
    id: 12,
    points: 10,
    question: "Muss zum Meilenstein „Datenlieferung“ die Konstruktion bereits abgeschlossen sein?",
    options: [
      {
        id: 0,
        label: "Nein, es muss nur eine 1.Version für den Start des Modellaufbaus verfügbar sein"
      },
      {
        id: 1,
        label: "Ja, da ansonsten die darauf aufbauende Simulation keine Empfehlung für die Optimierung ermöglicht"
      },
      {
        id: 2,
        label: "Ja, da der Meilenstein „Datenlieferung“ gleichzeitig „Daten-Freeze“ bedeutet"
      }
    ],
    area: 1,
    answer: 0
  },
  {
    id: 13,
    points: 10,
    question: "Dürfen die Daten in der kommenden Phase „Auslegung und Optimierung“ nochmal verändert werden?",
    options: [
      {
        id: 0,
        label: "Nein, es ist ein „Daten-Freeze“ erforderlich, da alle Bewertungen auf dem gleichen Datenstand erfolgen sollen"
      },
      {
        id: 1,
        label: "Eine Änderung der Daten ist erst in der nächsten Projektphase möglich"
      },
      {
        id: 2,
        label: "Ja, da Ziel der Phase „Auslegung und Optimierung“ eine iterative Verbesserung der Konstruktion ist"
      }
    ],
    area: 1,
    answer: 2
  },


  // BEREICH 3 - AUSLEGUNG UND OPTIMIERUNG
  {
    id: 14,
    points: 10,
    question: "Was ist das Ziel der Phase „Auslegung und Optimierung“?",
    options: [
      {
        id: 0,
        label: "Die Simulationsexperten prüfen, ob Ziele und Anforderungen erfüllt werden und geben Optimierungsempfehlungen an die Bauteil-Konstrukteure"
      },
      {
        id: 1,
        label: "Die Simulationsexperten verifizieren und validieren die Ziele und Anforderungen"
      },
      {
        id: 2,
        label: "Die Simulationsexperten erstellen und optimieren das Simulationsmodell"
      }
    ],
    area: 2,
    answer: 0
  },
  {
    id: 15,
    points: 10,
    question: "Was bedeutet der Meilenstein „Daten-Freeze“?",
    options: [
      {
        id: 0,
        label: "Der bis dahin verfügbare Datenstand wird für den Modellaufbau „eingefroren“"
      },
      {
        id: 1,
        label: "Der vereinbarte Datenumfang wird in den Systemen gegen Änderungen gesperrt"
      },
      {
        id: 2,
        label: "Alle vereinbarten Daten müssen verfügbar und zueinander konsistent sein, sie werden für die folgenden Bewertungs-Phase „eingefroren“"
      }
    ],
    area: 2,
    answer: 2
  },
  {
    id: 16,
    points: 10,
    question: "Benutzen in der Phase „Auslegung und Optimierung“ alle Simulationsexperten den gleichen Datenstand?",
    options: [
      {
        id: 0,
        label: "Nein, da in dieser Phase die einzelnen Disziplinen parallel arbeiten und der Bauteil-Konstrukteur die einzelnen Erkenntnisse zusammenführt"
      },
      {
        id: 1,
        label: "Ja, da sonst neue Zielkonflikte entstehen"
      },
      {
        id: 2,
        label: "Die Simulationsexperten verwenden grundsätzlich unterschiedliche Datenstände"
      }
    ],
    area: 2,
    answer: 0
  },
  {
    id: 17,
    points: 10,
    question: "Warum ist für die Bewertung zum Meilenstein „Status-VPE“ ein Daten-Freeze erforderlich?",
    options: [
      {
        id: 0,
        label: "Dies ist erforderlich, da der „Status VPE“ der Abschluss der Bauteil-Konstruktion ist"
      },
      {
        id: 1,
        label: "Der Daten-Freeze ist erforderlich, da alle Bewertungen auf dem gleichen Datenstand erfolgen sollen"
      },
      {
        id: 2,
        label: "Dies erleichtert den Simulationsexperten die Arbeit"
      }
    ],
    area: 2,
    answer: 1
  },
  {
    id: 18,
    points: 10,
    question: "Was ist das Ziel der kommenden Phase „Bewertung“?",
    options: [
      {
        id: 0,
        label: "Die Simualtionsmodelle werden auf Basis der „eingefrorenen Daten“ aktualisiert"
      },
      {
        id: 1,
        label: "Die Erfüllung der Ziele und Anforderungen bzgl. Eigenschaften und Funktionen wird entsprechend dem vereinbarten Leistungsumfang geprüft und evtl. Maßnahmen abgeleitet"
      },
      {
        id: 2,
        label: "Die Eigenschafts- und Funktionsentwicklung wird abgeschlossen, bei Nichterreichung der Ziele werden diese angepasst"
      }
    ],
    area: 2,
    answer: 1
  },
  {
    id: 19,
    points: 10,
    question: "Was bedeutet der Meilenstein „Status-VPE“?",
    options: [
      {
        id: 0,
        label: "Das Simulationsmodell wird fertiggestellt"
      },
      {
        id: 1,
        label: "Es erfolgt eine Bewertung von Eigenschaften unf Funktionen auf Basis eines konsistenten Datenstands"
      },
      {
        id: 2,
        label: "Die Simulationsexperten berichten den aktuelllen Zwischenstand"
      }
    ],
    area: 2,
    answer: 1
  },
  {
    id: 20,
    points: 10,
    question: "Welche Aufgabe hat der Simulationsexperte, wenn die vereinbarten Ziele nicht erreicht werden?",
    options: [
      {
        id: 0,
        label: "Er muss bei der Projektleitung die Anpassung der Ziele beantragen"
      },
      {
        id: 1,
        label: "Er sollte Maßnahmen für Optimierung der Konstruktion zur Zielerreichung vorschlagen"
      },
      {
        id: 2,
        label: "Er wartet, bis die Bauteil-Konstrukteure neue Daten liefern und führt eine neue Simulation durch"
      }
    ],
    area: 2,
    answer: 1
  },
];

/**
 * @param {Context} ctx
 * @param {Number} areaId
 * @param {Array} excludeIds
 * @returns {Question?}
 */
export function getRandomQuizQuestion(ctx, areaId = null, excludeIds = []) {
  let questions = quizQuestions.slice();

  if (areaId != null) {
    questions = questions.filter(q => q.area == areaId);
  }

  if (excludeIds) {
    questions = questions.filter(q => !excludeIds.includes(q.id));
  }

  if (questions.length == 0) return null;

  return questions[Math.floor(ctx.random.Number() * questions.length)];
}

export const questionIdsByArea = quizQuestions.reduce((acc, q) => {
  if (acc[q.area] == null) acc[q.area] = [];
  acc[q.area].push(q.id);
  return acc;
}, {});


class Question {
  constructor() {
    this.id = 0;
    this.points = 10;
    this.question = "";
    this.options = [{
      id: 0,
      label: ""
    }];
    this.area = 0;
    this.answer = 0;
  }
}