<template>
  <card
    class="premise-card"
    :disabled="true"
    @click="$emit('click', $event)"
    v-bind="{...$attrs}"
  >

  <template v-slot:default>
    <h1>Verbaut</h1>
    <ul class="premise-card__requirementlist">
      <li v-if="premise.axles">{{ axlesAmount }} Räder</li>
      <li v-if="premise.minParts || premise.maxParts">{{ mainBuiltPartsAmount }} Teile</li>
    </ul>
    <slot></slot>
  </template>
</template>

<script>
import Card from "./Card.vue";
import Icon from "../../ui/Icon.vue";

export default {
    components: {
    Card,
    Icon
  },
  props: {
    premise: {
      type: Object,
      default: null
    },
    player: {
      type: Object,
      required: true,
      default: null
    }
  },
  computed: {
    builtParts() {
      return this.player.parts.filter(part => this.player.builtPartIds.includes(part.uuid));
    },
    axlesAmount() {
      return this.builtParts.filter(p => p.type === 'constraint' && p.length === 0).length;
    },
    mainBuiltPartsAmount() {
      // get only rectangles and triangles, no screws, axles or wheels:
      const mainParts = this.builtParts.filter(part => (
        part.type === 'body' && !part.label.includes("Wheel")
      ));
      return mainParts.length;
    }
  }
}
</script>

<style lang="scss">
.premiseresult-card {}
</style>