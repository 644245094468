const BASE_URL = process.env.NODE_ENV == 'production' ? "/api" : "http://localhost:3000/api";

export const GAME_URL = process.env.NODE_ENV == 'production' ? `${window.location.protocol}//${window.location.hostname}` : `${window.location.protocol}//${window.location.hostname}:8000`;

class HttpError extends Error {
  /**
   * @param {String} message
   * @param {Number} httpCode
   * @param {Object} details
   */
  constructor(message, httpCode, details) {
    super(message);

    this.name = "HttpError";
    this.status = httpCode;

    this.httpCode = httpCode;
    this.details = details;
  }
}

function getToken() {
  return localStorage.getItem("token");
}
export function setToken(value) {
  localStorage.setItem("token", value);
}
export function clearToken() {
  localStorage.removeItem("token");
}

/**
 *
 * @param {String} url
 * @param {Object} options
 * @param {Boolean} shouldUseAuth
 */
async function baseFetch(url, options = {}, shouldUseAuth = true) {
  options.mode = "cors";
  if (!('headers' in options)) {
    options.headers = {};
  }
  if (shouldUseAuth && getToken() != null) {
    options.headers['Authorization'] = `token ${getToken()}`;
  }

  const response = await fetch(BASE_URL + url, options);
  if (response.ok) {
    return response.json();
  }
  else {
    let error = { message: `Error status ${response.status}` };
    if (response.bodyUsed) {
      error = await response.json();
    }
    throw new HttpError(error.message, response.status, error.details);
  }

}

/**
 *
 * @param {String} url
 * @param {Object} options
 * @param {Boolean} shouldUseAuth
 */
export function post(url, data, options = {}, shouldUseAuth = true) {
  options.headers ?
    options.headers['Content-Type'] = 'application/json' :
    options.headers = {'Content-Type': 'application/json'};
  options.method = "POST";
  options.body = JSON.stringify(data);
  return baseFetch(url, options, shouldUseAuth);
}

/**
 *
 * @param {String} url
 * @param {Object} options
 * @param {Boolean} shouldUseAuth
 */
export function patch(url, data, options = {}, shouldUseAuth = true) {
  options.headers ?
    options.headers['Content-Type'] = 'application/json' :
    options.headers = {'Content-Type': 'application/json'};
  options.method = "PATCH";
  options.body = JSON.stringify(data);
  return baseFetch(url, options, shouldUseAuth);
}

/**
 *
 * @param {String} url
 * @param {Object} options
 * @param {Boolean} shouldUseAuth
 */
export function destroy(url, data, options = {}, shouldUseAuth = true) {
  options.headers ?
    options.headers['Content-Type'] = 'application/json' :
    options.headers = {'Content-Type': 'application/json'};
  options.method = "DELETE";
  options.body = JSON.stringify(data);
  return baseFetch(url, options, shouldUseAuth);
}

export function get(url, options = {}, shouldUseAuth = true) {
  options.method = "GET";
  return baseFetch(url, options, shouldUseAuth);
}