<template>
  <button
    class="card"
    :disabled="forbidden || !clickable || disabled"
    :class="{ 
      '-is-selected': selected, 
      '-is-small' : small, 
      '-forbidden': forbidden , 
      '-is-wide': wide,
      '-is-small-and-wide': smallWide,
      '-is-clickable': !forbidden && clickable
      }"
    @click="$emit('click', $event)"
  >
    <div v-if="faceUp" class="card__frontside">
      <slot></slot>
    </div>
    <div v-else class="card__backside">
      <slot name="back"></slot>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    faceUp: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    forbidden: {
      type: Boolean,
      default: false
    },
    wide: {
      type:Boolean,
      default:false
    },
    smallWide: {
      type:Boolean,
      default:false
    }

  }
}
</script>

<style lang="scss">
.card {
  font-size: 14px;
  color: $color-uielement-text;
  background-color: #fafafa;
  border: 1px solid $color-uielement-border;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13em;
  width: 10em;
  min-width: 0;
  text-align: center;
  position: relative;
  transition: color 150ms ease-out, background-color 150ms ease-out;
  max-width: 100%;
  max-height: 100%;

  h1, h2 {
    font-size: 1.25em;
  }

  &:focus {
    box-shadow: 0 2px 6px rgba(0,0,0,0.2), 0 0 0 1px $color-uielement-border;
    border-color: $color-uielement-border;
    outline: none;
  }


  &.-is-selected {
    border: 2px solid var(--active-player-color);
  }

  &.-is-small {
    font-size: 10px;

    .icondu-icon {
      height: 4em;
    }
  }

  &.-is-wide {
    width: 18em;
    height: 12em;
  }

  &.-is-small-and-wide {
    width:12em;
    height: 8em; 
    font-size: 10px;
    // margin-bottom: 1.5em;

    .icondu-icon {
      width: 2rem;
      height:auto;
    }
  }

  &.-is-clickable {
    &:not(:disabled) {
      cursor: pointer;
      background-color: $color-uielement-bg;
      &:hover:not(:disabled) {
        color: var(--active-player-color);
        border-color: var(--active-player-color);
      }
    }
  }

  &.-forbidden {
    box-shadow: none;
    &::after {
      content: '';
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.1);
      position: absolute;
    }
  }

  &__mark {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
  }

  &__frontside,
  &__backside {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>