<template>
  <button
    class="button"
    :class="[`button--${type}`]"
    v-on="$listeners"
    v-bind="{...$attrs}"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary"
    }
  }
}
</script>