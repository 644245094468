<template>
  <div class="fielddialogs-method">

    <role-dialogs v-if="hasRoleAndRoleOpen">
      <template v-slot:intro>
        <p>
          Hallo, ich bin ein <strong>Simulationsexperte</strong>.
        </p>
        <p>
          Ich überlege mir, welche Simulations- und Bewertungsmethoden geeignet sind und vereinbare mit den Funktionsverantwortlichen den Leistungssumfang. Anschließend überlege ich mir, welche Daten ich hierfür benötige.
        </p>
        <p>
          Gut, dass du nach mir gefragt hast. 
          Mit meiner Hilfe darfst du aus den 
          Bewertungsmethoden eine beliebige 
          aussuchen!
        </p>
      </template>
      <template v-slot:question>
        <p>
          Hallo, hier ist wieder ein Simulationsexperte!
        </p>
      </template>
      <template v-slot:correct>
        <p>
          Sehr gut! Dann lass uns mal an die 
          Arbeit gehen.
        </p>
        <p>
          Mit meiner Hilfe darfst du in diesem 
          Zug alle Bewertungsmethoden aufdecken
          und dir aussuchen welche
          davon du behalten möchtest!
        </p>
      </template>
      <template v-slot:wrong>
        <p>
          Ich muss jetzt erstmal wieder an die Arbeit. Ruf an, wenn du nochmal Hilfe brauchst.
        </p>
      </template>
    </role-dialogs>

    <!-- QUIZFRAGE -->
    <quiz-dialogs v-else-if="(
      currentField.hasQuiz &&
      !currentMove.handledQuizQuestion
    )"></quiz-dialogs>

    <!-- Auswählen -->
    <template v-else-if="currentMove.selectedMethod == null">
      
      <!-- Normale Version, drei verdeckte Methoden -->
      <game-dialog v-if="!currentMove.solvedRoleQuiz">
        <div v-if="isCurrentPlayerActive" class="text-center">
          Simulationsmethode ziehen
        </div>
        
        <div class="row">
          <method-card
            v-for="method in currentMove.shuffledMethods"
            :key="method.id"
            :method="method"
            :selected="false"
            :face-up="false"
            :clickable="true"
            :disabled="!isActivePlayer"
            @click="moves.selectMethod(method);"
          ></method-card>
        </div>

        <div class="text-center">
          Decke eine Simulationsmethode auf!
        </div>
      </game-dialog>

      <!-- (with solved Quiz) Auswahl aus allen Methoden -->
      <game-dialog v-else>
        <div class="text-center">
          Simulationsmethode wählen
        </div>
        
        <div class="row">
          <method-card
            v-for="method in currentMove.shuffledMethods"
            :key="method.id"
            :method="method"
            :clickable="true"
            :disabled="!isActivePlayer"
            :selected="localSelectedMethod ? method.id == localSelectedMethod.id : false"
            @click="localSelectedMethod = method"
          ></method-card>
        </div>

        <div class="row" v-if="localSelectedMethod">
          <button-active-player
            @click="moves.selectMethod(localSelectedMethod);"
          >
            Behalten
          </button-active-player>
        </div>
      </game-dialog>
    </template>

    <template v-else-if="currentMove.selectedAction != 'giveAway'">
      <!-- Ohne KOLLABORATION -->
      <template v-if="isFieldCollaborative == false">
        <game-dialog v-if="!playerHasMethod">
          <div class="text-center">
            <template v-if="isCurrentPlayerActive">
              Super! Diese Methode hattest du noch nicht freigeschaltet.
            </template>
            <template v-else>
              {{ playerNames[currentPlayer.id] }} hat dir diese Methode gesendet.
            </template>
          </div>

          <div class="row">
            <method-card
              :method="currentMove.selectedMethod"
            ></method-card>
          </div>

          <div class="row">
            <button-active-player
              v-if="!isCurrentPlayerActive"
              type="secondary"
              @click="moves.endTurn()"
            >
              Ablehnen
            </button-active-player>
            <button-active-player
              @click="moves.takeMethod()"
            >
              <span v-if="isCurrentPlayerActive">OK</span>
              <span v-else>Annehmen</span>
            </button-active-player>
          </div>
        </game-dialog>

        <game-dialog v-if="playerHasMethod">
          <div class="text-center">
            Schade, du besitzt die Simulationsmethode
            {{ currentMove.selectedMethod.label }}
            bereits. <span v-if="isCurrentPlayerActive">Mehr Erfolg beim nächsten Mal!</span>
          </div>

          <div class="row">
            <method-card
              :method="currentMove.selectedMethod"
            ></method-card>
          </div>

          <div class="row">
            <button-active-player
              @click="moves.endTurn();"
            >
              OK
            </button-active-player>
          </div>
        </game-dialog>
      </template>
      
      
      <!-- KOLLABORATION ? -->
      <game-dialog v-if="isFieldCollaborative">

        <div class="text-center" v-if="playerHasMethod">
          Schade, du besitzt die Simulationsmethode
          {{ currentMove.selectedMethod.label }} bereits.
          Möchtest du sie an einen anderen Spieler weitergeben?
        </div>
        <div class="text-center" v-if="!playerHasMethod">
          Möchtest du die Simulationsmethode bei dir freischalten oder an einen anderen Spieler weitergeben?
        </div>

        <div class="row">
          <method-card
            :method="currentMove.selectedMethod"
          ></method-card>
        </div>

        <div class="row">

          <button-active-player
            v-if="playerHasMethod"
            @click="moves.endTurn();"
          >
            Abwerfen
          </button-active-player>

          <button-active-player
            v-if="isFieldCollaborative"
            @click="moves.setAction('giveAway');"
          >
            Weitergeben
          </button-active-player>

          <button-active-player
            v-if="!playerHasMethod"
            @click="moves.takeMethod();"
          >
            Verwenden
          </button-active-player>

        </div>
      </game-dialog>
    </template>
    <!-- ENDE: selbst benutzen -->

    <!-- Weitergeben -->
    <game-dialog v-if="currentMove.selectedAction == 'giveAway' && !currentMove.selectedReceiver">
      <div class="text-center">
        An welchen Spieler möchtest du die Simulationsmethode weitergeben?
      </div>

      <div class="row">
        <player-element
          v-for="player in inactivePlayers"
          :key="player.id"
          :player="player"
          @click="moves.activateReceiver({
            receiver: player,
            action: 'use',
            stage: 'receiveMethod'
          })"
        ></player-element>
      </div>

      <div class="row">
        <button-active-player
          type="secondary"
          @click="moves.setAction('use')"
        >
          Zurück
        </button-active-player>
      </div>
    </game-dialog>
  </div>
</template>

<script>
// Im GameComponentMixin sind viele Daten definiert,
// die auf den Stand des Spiels zugreifen.
import GameComponentMixin from "../GameComponentMixin.js";


// KOMPONENTEN
// Dialoge sind mit einer eigenen Komponente gelöst.
import GameDialog from "../UIElements/GameDialog.vue";
// Der RollenDialog ist eine Standard-Abfolge von Dialogen
// die die alle Rollen wiederverwendet werden können
import RoleDialogs from "./RoleDialogs.vue";
import QuizDialogs from "./QuizDialogs.vue";

import MethodCard from "../Cards/MethodCard.vue";
import PlayerElement from "../UIElements/PlayerElement.vue";
import ButtonActivePlayer from "../UIElements/ButtonActivePlayer.vue";
import Icon from "../../ui/Icon.vue";

export default {
  components: {
    GameDialog,
    RoleDialogs,
    QuizDialogs,
    MethodCard,
    PlayerElement,
    ButtonActivePlayer,
    Icon
  },
  data() {
    return {
      localSelectedMethod: null
    };
  },
  mixins: [GameComponentMixin],
  computed: {
    hasRoleAndRoleOpen() {
      return this.currentField.hasRole && !this.currentMove.roleActionDone;
    },
    isFieldCollaborative() {
      return this.currentField.isCollaborative && this.isCurrentPlayerActive;
    },
    playerHasMethod() {
      return this.activePlayer.methods.some(method => method.id == this.currentMove.selectedMethod.id);
    }
  },
  methods: {
    selectMethodAndAction(method, action) {
      this.moves.selectMethod(method);
      this.moves.setAction(action);
    },
    cancel() {
      this.moves.endTurn();
    }
  }
}
</script>

<style>

</style>