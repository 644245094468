<template>
  <form class="auth-form" @submit="submit">
    <input-field type="email" name="email" label="E-Mail" v-model="email" :required="true"></input-field>
    <input-field type="password" name="password" label="Passwort" v-model="password" :required="true"></input-field>
    <input type="submit" class="button" value="Anmelden"/>
  </form>
</template>

<script>
import InputField from "../input/InputField";
export default {
  components: {
    InputField
  },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    submit(event) {
      this.$emit("submit", { email: this.email.toLowerCase().trim(), password: this.password });
      event.preventDefault();
      return false;
    },
    clear() {
      this.password = "";
      this.email = "";
    }
  }
}
</script>

<style lang="scss">
.auth-form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  gap: 1rem;
}
</style>