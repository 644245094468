// Datentypen
import { Premise } from "./Premises.js";
import { Context } from "../Game.js";

/**
 * @param {Context} ctx
 * @returns {Object.<string, Player>}
 */
export function createPlayers(ctx) {
  const players = {};
  for (let i = 0; i < ctx.numPlayers; i++) {
    const id = `${i}`;
    const player = createPlayer(id, i);
    players[id] = player;
  }
  return players;
}

/**
 * @param {String} id
 * @param {Number} fieldIndex
 * @returns {Player}
 */
export function createPlayer(id, fieldIndex = 0) {
  const player = {
    id,
    area: 0, // index of the area
    field: null, // index of the field
    fieldIndex: fieldIndex, // the place IN the field (multiple players can be on one field)

    // Bewegung
    movedThisTurn: false,
    movementPoints: 0,

    requirements: [],
    methods: [],
    partRequests: {},
    parts: [],
    builtPartIds: [],
    gatePasses: [],
    simulationResults: {
      height: null,
      length: null,
      groundDistance: null,
      distanceTravelled: null,
      weight: null
    },
    marks: {
      requirements: {}
    },
    points: {}
  };
  return player;
}


// TYP-DEFINITIONEN

export class Player {
  constructor() {
    this.id = "";
    this.area = 0;
    this.field = 0;
    this.fieldIndex = 0;
    this.turnsThisPhase = 0;
    this.movedThisTurn = false;
    this.movementPoints = 0;

    /** @type {Array} */
    this.requirements = [];

    /** @type {Array} */
    this.methods = [];

    /** @type {Object} */
    this.partRequests = {};

    /** @type {Array} */
    this.parts = [];

    /** @type {String[]} */
    this.builtPartIds = [];

    /** @type {Number[]} */
    this.gatePasses = [];

    this.simulationResults = {
      sizes: {
        /** @type {Number} */
        height: null,
        /** @type {Number} */
        width: null,
        /** @type {Number} */
        groundDistance: null
      },
      /** @type {Number} */
      distanceTravelled: null,
      /** @type {Number} */
      weight: null
    };

    /** @type {Premise} */
    this.premise = null;

    this.marks = {
      /** @type {Object.<string, Number>} */
      requirements: {}
    };

    this.points = {
      movement: 0,
      premise: 0,
      parts: {},
      requirements: {}
    };
  }
}